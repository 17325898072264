import React, { useState } from "react";

import { formatDuration } from "../../functions/GeneralFunctions";

import "../../App.css";

export default function VideoPreview({ data, handleClick }) {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div
      style={{
        width: 340,
        backgroundColor: "rgba(0,0,0,0.8)",
        borderRadius: 8,
        overflow: "hidden",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        border: "0.5px solid black",
        cursor: "pointer",
        margin: "10px 10px 0px 0px",
      }}
      className="dark-container"
      onClick={() => {
        handleClick(data);
      }}
    >
      <div
        style={{
          width: "100%",
          paddingTop: "56.25%",
          backgroundImage: isHovering
            ? `url('https://image.mux.com/${data.playbackId}/animated.gif')`
            : `url('https://image.mux.com/${data.playbackId}/thumbnail.jpg')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
        }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <p
          style={{
            position: "absolute",
            bottom: "5px",
            right: "5px",
            margin: 0,
            padding: "4px 8px",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            color: "white",
            fontSize: 12,
            borderRadius: "4px",
          }}
        >
          {formatDuration(data.duration)}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ maxWidth: "75%" }}>
          <p style={{ margin: "5px 0 0 5px", fontWeight: 600 }}>{data.title}</p>
        </div>
        <p style={{ margin: "5px 5px 0 0", fontSize: 12 }}>
          {data.views === 1 ? `1 view` : `${data.views} views`}
        </p>
      </div>
      <div style={{ padding: "0px 5px" }}>
        <p style={{ margin: "5px 0 5px 0", fontWeight: 300 }}>
          {data.uploaderName}
        </p>
      </div>
    </div>
  );
}
