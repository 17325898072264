import React from "react";

export default function InformationOverview({ handleToggle }) {
  return (
    <div>
      <h2>
        International Health Regulations (IHRs) and Pandemic Agreement Concerns
      </h2>
      <p>
        The Australian government has agreed to changes to a treaty called the
        International Health Regulations (IHRs) that come into force on 1 June
        2025 and is on track to sign another called the Pandemic Agreement.
      </p>
      <p>
        If these documents take effect, Australians will face unprecedented
        hardships. The planned treaties contain draconian provisions allowing
        unelected overseas bureaucrats, at their discretion, to threaten many
        areas of our lives, causing:
      </p>

      <h3>Physical Harm</h3>
      <ul>
        <li>Lockdowns and curfews</li>
      </ul>

      <h3>Financial Harm</h3>
      <ul>
        <li>Small business closures and job losses</li>
        <li>Huge payments to overseas agencies</li>
        <li>Depressed economy</li>
      </ul>

      <h3>Social Harm</h3>
      <ul>
        <li>Imposed isolation</li>
        <li>
          Inability to visit family, friends, and loved ones, attend weddings,
          funerals, etc.
        </li>
        <li>Increased domestic violence</li>
        <li>
          Damage to interpersonal relationships, impeding everything from
          children’s social development to seniors’ protection against dementia
        </li>
      </ul>

      <h3>Mental Health Problems</h3>
      <ul>
        <li>Increased depression, loneliness, anxiety, and suicide</li>
      </ul>

      <h3>Travel Restrictions</h3>
      <ul>
        <li>Inside Australia and overseas</li>
      </ul>

      <h3>Educational Problems</h3>
      <ul>
        <li>School closures</li>
        <li>Reduced tuition and poorer academic achievement</li>
        <li>Some students disadvantaged by online learning</li>
        <li>HSC students unable to sit exams</li>
      </ul>

      <h3>Health and Well-being</h3>
      <ul>
        <li>
          Inability to make own decisions about health, removing bodily autonomy
        </li>
        <li>Dictation of “one size fits all” medical interventions</li>
      </ul>

      <h3>Freedom of Thought and Expression</h3>
      <ul>
        <li>
          Preventing sharing information the government doesn’t approve of
          ("misinformation")
        </li>
      </ul>

      <p>
        The government is pressing ahead with these treaties despite woefully
        inadequate consultation. The planned treaties will do more harm than
        good to Australian families.
      </p>
      <p>
        Australia Exits the WHO, AMPS (the Australian Medical Professional
        Society), and the Winston Smith Initiative oppose the provisions of the
        IHRs and are calling for Australia to withdraw from this treaty
        altogether. They further call on Minister Butler not to sign the
        Pandemic Agreement.
      </p>
      <hr style={{ width: "100%" }}></hr>
      <h3>Want to know more?</h3>
      <p>Further information can be found here:</p>
      <ul>
        <li>
          <a
            href="https://cil.nus.edu.sg/wp-content/uploads/2024/06/The-New-IHR-Amendments-2024_27.06.2024-FINAL.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Extended Analysis of IHR Amendments
          </a>
        </li>
        <li>
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              handleToggle("ten-reasons");
            }}
          >
            10 Reasons to Stop This Legislation
          </a>
        </li>
        <li>
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              handleToggle("eight-ways");
            }}
          >
            8 Ways the Treaties Will Harm Families
          </a>
        </li>
        <li>
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              handleToggle("letter-tips");
            }}
          >
            Tips for writing to politicians
          </a>
        </li>
        <li>
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              handleToggle("debra-vid");
            }}
          >
            Video by Debra Yuille
          </a>
        </li>
        <li>
          <a
            href="https://australiaexitsthewho.com/wp-content/uploads/2024/07/WCHA-Letter-22Jun24.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Open Letter to Anthony Albanese from Professor Ian Brighthope
          </a>
        </li>
        <li>
          <a
            href="https://australiaexitsthewho.com/wp-content/uploads/2024/07/Letter-to-PM-14-Ministers.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Open Letter to Anthony Albanese from 14 federal parliamentarians
          </a>
        </li>
        <li>
          <a
            href="https://libbyklein.substack.com/p/whats-all-this-about-the-who?r=1cmbrn&utm_campaign=post&utm_medium=web&triedRedirect=true"
            target="_blank"
            rel="noopener noreferrer"
          >
            Helpful Powerpoint by Libby Klein
          </a>
        </li>
      </ul>
    </div>
  );
}
