import React, { useEffect } from "react";

import CircularProgress from "@mui/material/CircularProgress";

export default function CharTracker({ chars, charLimit }) {
  useEffect(() => {
    if (chars >= charLimit) {
      alert(`This input cannot exceed ${charLimit} characters.`);
    }
  }, [chars]);

  return (
    <CircularProgress
      variant="determinate"
      value={(chars / charLimit) * 100}
      size={20}
      sx={{
        backgroundColor: chars < charLimit ? "rgba(0,0,0,0.1)" : "pink",
        borderRadius: 90,
        margin: "0px 4px 0px 0px",
      }}
    />
  );
}
