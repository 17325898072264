import React, { useState, useEffect } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import { validateMarker } from "../functions/GeneralFunctions";

import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

export default function MarkerConfirmation() {
  ////  INITS
  const params = useParams();

  ////  STATES
  const [pageStatus, setPageStatus] = useState("idle");

  ////  FUNCTIONS
  const handleValidation = async () => {
    try {
      await validateMarker(params.markerId);
      setTimeout(() => {
        setPageStatus("success");
      }, 500);
    } catch (err) {
      console.log(err);
      setPageStatus("error");
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (params?.markerId) {
      handleValidation();
    }
  }, []);

  return (
    <ScreenContainer
      styling={{
        background: "linear-gradient(to bottom, #C5D2C0, #B0C2B4, #A2B5BA)",
      }}
    >
      <Navbar />
      {pageStatus === "idle" ? null : pageStatus === "success" ? (
        <>
          <div style={{ height: 150, width: 150 }}>
            <DotLottieReact
              src="https://lottie.host/ed77a13f-b127-4131-b8cb-b3e7bab6ab25/Fpxtr3MZdz.lottie"
              autoplay
            />
          </div>
          <h2>Success!</h2>
          <h4 style={{ textAlign: "center" }}>
            Your marker is now visible on the map!
          </h4>
        </>
      ) : pageStatus === "loading" ? (
        <>
          <h4 style={{ textAlign: "center" }}>Verifying marker ...</h4>
          <br></br>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CircularProgress />
          </div>
        </>
      ) : pageStatus === "error" ? (
        <>
          <h2>
            <h4 style={{ textAlign: "center" }}>
              There was an error verifying your marker.
            </h4>
          </h2>
        </>
      ) : null}
    </ScreenContainer>
  );
}
