////  REVISIT: conditional rendering of profile menu options

import React, { useState, useContext, useEffect } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import EmailIcon from "@mui/icons-material/Email";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import PersonIcon from "@mui/icons-material/Person";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "../../utils/Hooks";

import GenericDrawer from "./GenericDrawer";
import { logout } from "../../functions/GeneralFunctions";
import NotificationBell from "./NotificationBell";

import { GlobalContext } from "../../App";

export default function Navbar() {
  ////  INITS
  const navigate = useNavigate();
  const GlobalCtxt = useContext(GlobalContext);
  const windowSize = useWindowSize();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  ////  STATES
  const [drawerOpen, setDrawerOpen] = useState(false);

  ////  FUNCTIONS

  ////    ////    ////    ////
  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            setTimeout(() => {
              navigate("/profile");
            }, 250);
          }}
        >
          Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setTimeout(() => {
              navigate("/secretary");
            });
          }}
        >
          Secretary Suite
        </MenuItem>
        <MenuItem
          onClick={() => {
            logout();
            handleClose();
            const pathSegments = window.location.pathname.split("/");
            const component = pathSegments[1];
            if (component && component === "home") {
              setTimeout(() => {
                window.location.reload();
              }, 250);
            } else {
              setTimeout(() => {
                navigate(`/home/${GlobalCtxt.nationalId}/National/reload`);
              }, 250);
            }
          }}
        >
          Logout
        </MenuItem>
      </Menu>
      <GenericDrawer
        open={drawerOpen}
        handleClose={() => {
          setDrawerOpen(false);
        }}
      >
        <h3>MENU</h3>
        <hr></hr>
        <List>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                navigate(`/home/${GlobalCtxt.nationalId}/National`);
              }}
            >
              <ListItemText primary="Home" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                navigate(`/declarationproject`);
              }}
            >
              <ListItemText primary="Declaration Project" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                navigate(`/declarations`);
              }}
            >
              <ListItemText primary="Declaration Hub" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                navigate(`/we-say-no`);
              }}
            >
              <ListItemText primary="We Say No! campaign" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                navigate(`/flyer-flow`);
              }}
            >
              <ListItemText primary="Deliver Flyers" />
            </ListItemButton>
          </ListItem>
        </List>
      </GenericDrawer>
      <div
        style={{
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          top: 0,
          left: 0,
          width: "100vw",
          backgroundColor: "rgba(0,0,0,0.9)",
          zIndex: 1000,
          boxSizing: "border-box",
          padding: "5px 10px",
        }}
      >
        <div style={{ width: "33%" }}>
          <IconButton onClick={() => setDrawerOpen((prev) => !prev)}>
            <MenuIcon
              sx={{ marginRight: "10px", color: "white" }}
              fontSize="large"
            />
          </IconButton>
        </div>
        {windowSize.width > 768 ? (
          <img
            src="https://storage.googleapis.com/bucket-775/navbar_logo_transparent.png"
            alt="Navbar Logo"
            style={{
              maxHeight: "60px",
              height: "auto",
              width: "auto",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(`/home/${GlobalCtxt.nationalId}/National`);
            }}
          />
        ) : (
          <div style={{ width: "33%" }} />
        )}
        {localStorage.getItem("electorate") ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              width: "33%",
            }}
          >
            <NotificationBell />
            <IconButton
              onClick={() => {
                navigate(`/chat`);
              }}
            >
              <EmailIcon fontSize="large" sx={{ color: "grey" }} />
            </IconButton>
            <IconButton onClick={handleClick}>
              <PersonIcon fontSize="large" sx={{ color: "grey" }} />
            </IconButton>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              width: "33%",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                navigate("/login");
              }}
            >
              LOG IN
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
