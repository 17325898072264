import React from "react";

export default function PreviewText() {
  return (
    <>
      <h4>We've got a new flyer out, and we need your help to deliver it!</h4>
      <p>
        Just like last time, our goal is to raise the Australian public's
        awareness of IHR amendments and Pandemic Treaty. If approved, these
        agreements would hand over extraordinary powers to unaccountable and
        unelected bureaucrats at the World Health Organisation.
      </p>
      <p>
        We need to make it unmistakably clear to our politicians that the
        Australian public{" "}
        <span style={{ fontWeight: "bold" }}>will not stand for this.</span> The
        flyer is designed to not just inform, but to stir everday people to{" "}
        <span style={{ fontWeight: "bold" }}>action.</span>
      </p>
      <p>
        To avoid accusations of distributing "junk mail", we've structured the
        flyer as a Community News Bulletin.{" "}
        <span style={{ fontWeight: "bold" }}>
          We invite you to join us in delivering this bulletin all over our
          beautiful country!
        </span>
      </p>
    </>
  );
}
