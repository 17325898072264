import React, { useState, useContext } from "react";

import GenericTextField from "../common-components/GenericTextField";
import TypeEffectText from "../common-components/TypeEffectText";
import { getAiSummary } from "../../functions/NewsFunctions";
import { GlobalContext } from "../../App";

import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import { useNavigate } from "react-router-dom";

import "../../App.css";

export default function AiSidebar() {
  ////  INITS
  const GlobalCtxt = useContext(GlobalContext);
  const navigate = useNavigate();

  ////  STATES
  const [text, setText] = useState("");
  const [simplified, setSimplified] = useState(false);

  ////  FUNCTIONS
  const handleTextSubmit = async (txt) => {
    try {
      setText("fetching");
      const completion = await getAiSummary(txt, simplified);
      if (
        Array.isArray(completion?.data?.choices) &&
        completion.data.choices[0]?.message?.content
      ) {
        setText(completion.data.choices[0].message.content);
      } else {
        alert(
          "There was an error generating your AI summary. If this issue persists, please let us know via the Troubleshooting Channel."
        );
        setText("");
      }
    } catch (err) {
      console.log(err);
      alert(
        "Error generating AI summary! If this issue persists, please let us know via the Troubleshooting Channel."
      );
      setText("");
    }
  };

  ////  EFFECTS

  return (
    <div style={{ width: "100%", paddingTop: "10px 0px" }}>
      {GlobalCtxt?.userInfo?.tier >= 3 ? (
        <>
          <p style={{ color: "lightgrey", textAlign: "center" }}>
            Copy and paste text from the embedded article into the field below.
            Our AI model will summarise it for you!
          </p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Switch
              onChange={(e) => {
                setSimplified(e.target.checked);
              }}
            />
            <p
              style={{
                fontSize: 14,
                marginLeft: 5,
                opacity: simplified ? 1 : 0.5,
                color: "lightgrey",
              }}
            >
              Keep it simple?
            </p>
          </div>
          <GenericTextField
            rows={8}
            divStyling={{ backgroundColor: "lightgrey" }}
            buttonVariant="contained"
            handleSubmit={handleTextSubmit}
            charLimit={10000}
          />
          <br></br>
          {text === "fetching" ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  marginBottom: 5,
                }}
                className="color-transition"
              >
                Generating your summary ...
              </p>
              <div style={{ height: 150, width: 150 }}>
                <DotLottieReact
                  src="https://lottie.host/907b877e-c3b8-4608-bd01-7e7748dcc06e/nEiMW9l1gV.lottie"
                  autoplay
                  loop
                ></DotLottieReact>
              </div>
            </div>
          ) : text ? (
            <TypeEffectText text={text} textColor="lightgrey" />
          ) : null}
        </>
      ) : (
        <>
          <br></br>
          <p style={{ color: "lightgrey", textAlign: "center" }}>
            This feature is reserved for users on the Investor Plus subscription
            tier.
          </p>
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <Button
              variant="contained"
              onClick={() => {
                navigate("/upgrade");
              }}
            >
              UPGRADE!
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
