import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { timeSince } from "../../functions/GeneralFunctions";

export default function ConvoPreview({ data, handleClick }) {
  ////  INITS
  const navigate = useNavigate();

  ////  STATES
  const [recipientId, setRecipientId] = useState("");
  const [recipientName, setRecipientName] = useState("");

  ////  EFFECTS
  useEffect(() => {
    if (data) {
      if (data?.participants[0] === localStorage.getItem("_id")) {
        setRecipientId(data.participants[1]);
      } else {
        setRecipientId(data.participants[0]);
      }

      if (data?.participantNames[0] === localStorage.getItem("username")) {
        setRecipientName(data.participantNames[1]);
      } else {
        setRecipientName(data.participantNames[0]);
      }
    }
  }, [data]);

  return (
    <div
      style={{
        padding: 10,
        backgroundColor: "whitesmoke",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        marginBottom: "10px",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        transition: "background-color 0.2s ease",
      }}
      onClick={() => {
        handleClick(data);
        navigate(`/chat/${recipientId}/${recipientName}`);
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = "#e0efff";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = "whitesmoke";
      }}
    >
      <p style={{ fontWeight: "bold", fontSize: "1rem", margin: 5 }}>
        {data.participantNames[0] === localStorage.getItem("username")
          ? data.participantNames[1]
          : data.participantNames[0]}
      </p>
      <p style={{ opacity: 0.7, fontSize: "0.85rem", margin: 0 }}>
        {timeSince(data.lastUpdated)}
      </p>
    </div>
  );
}
