import React, { useContext } from "react";

import { useNavigate } from "react-router-dom";

import { GlobalContext } from "../../App";

export default function SignInPrompt({ action }) {
  const navigate = useNavigate();
  const GlobalCtxt = useContext(GlobalContext);

  return (
    <div
      style={{
        width: "100%",
        padding: "5px 10px",
        boxSizing: "border-box",
        backgroundColor: "whitesmoke",
        marginBottom: 10,
        boxShadow: GlobalCtxt.genericBoxShadow,
        borderRadius: 4,
      }}
    >
      <p>
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            navigate("/register");
          }}
        >
          Sign in
        </a>{" "}
        to start {action}!
      </p>
    </div>
  );
}
