import React from "react";

const EightWays = () => {
  return (
    <div>
      <h2>8 Ways New Treaties Will Harm Families</h2>
      <p>
        New treaties supported by the Australian government will negatively
        impact our lives. These two documents are called the 2024 International
        Health Regulations and the Pandemic Agreement and will cause:
      </p>

      <h3>Physical Harm</h3>
      <ul>
        <li>Lockdowns and curfews</li>
      </ul>

      <h3>Financial Harm</h3>
      <ul>
        <li>Small business closures and job losses</li>
        <li>Huge payments to overseas agencies</li>
        <li>Depressed economy</li>
      </ul>

      <h3>Social Harm</h3>
      <ul>
        <li>Imposed isolation</li>
        <li>
          Inability to visit family, friends and loved ones, attend weddings,
          funerals, etc.
        </li>
        <li>Increased domestic violence</li>
        <li>
          Damage to interpersonal relationships, impeding everything from
          children’s social development to seniors’ protection against dementia
        </li>
      </ul>

      <h3>Mental Health Problems</h3>
      <ul>
        <li>Increased depression, loneliness, anxiety, and suicide</li>
      </ul>

      <h3>Travel Restrictions</h3>
      <ul>
        <li>Inside Australia and overseas</li>
      </ul>

      <h3>Educational Problems</h3>
      <ul>
        <li>School closures</li>
        <li>Reduced tuition and poorer academic achievement</li>
        <li>Some students disadvantaged by online learning</li>
        <li>HSC students unable to sit exams</li>
      </ul>

      <h3>Health and Well-being</h3>
      <ul>
        <li>
          Inability to make own decisions about health, removing bodily autonomy
        </li>
        <li>Dictation of “one size fits all” medical interventions</li>
      </ul>

      <h3>Freedom of Thought and Expression</h3>
      <ul>
        <li>
          Prevention from sharing information the government doesn’t approve of
          (“misinformation”)
        </li>
      </ul>

      <p>
        Australia must not let overseas bureaucrats, acting for big business,
        ruin our lives. Find out more, including what you can do, here.
      </p>
    </div>
  );
};

export default EightWays;
