import React from "react";

export default function DynamicFrame({ styling, children, windowSize }) {
  const defaultStyling = {
    width: "100vw",
    maxWidth: windowSize.width > 768 ? windowSize.width * 0.96 : null,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxSizing: "border-box",
    border: "1px solid black",
    height: "100vh",
  };

  return <div style={{ ...defaultStyling, ...styling }}>{children}</div>;
}
