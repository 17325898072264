import React, { useContext, useEffect, useState } from "react";

import { QueueContext } from "./QueueCtxtProvider";
import { sendWitnessedDeclaration } from "../../functions/AffidavitFunctions";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

const fieldStyles = {
  width: "100%",
  padding: 5,
  boxSizing: "border-box",
  alignItems: "center",
};

export default function DecDisplayTab() {
  ////  INITS
  const QueueCtxt = useContext(QueueContext);

  ////  STATES
  const [name, setName] = useState("");
  const [qual, setQual] = useState("");
  const [residential, setResidential] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [date, setDate] = useState("");
  const [monthYear, setMonthYear] = useState("");
  const [signing, setSigning] = useState(false);

  ////  FUNCTIONS
  const signDeclaration = async () => {
    try {
      if (!name) {
        alert("Enter your name before signing.");
        return;
      }
      if (!qual) {
        alert("Enter your qualification before signing.");
        return;
      }
      if (!residential) {
        alert("Enter your work or home address before signing.");
        return;
      }
      if (!email && !phone) {
        alert(
          "Enter either your email address or phone number before signing."
        );
        return;
      }
      if (!date) {
        alert("Enter today's date before signing.");
        return;
      }
      if (!monthYear) {
        alert("Enter the current month and year before signing.");
        return;
      }
      setSigning(true);
      const doc = await sendWitnessedDeclaration({
        witnessName: name,
        witnessLocation: residential,
        witnessDate: date,
        witnessMY: monthYear,
        sigUrl: QueueCtxt?.witnessData?.signature || null,
        witnessQual: qual,
        witnessAddress: residential,
        witnessEmail: email,
        witnessPhone: phone,
        fName: QueueCtxt.selectedDec?.fName || "",
        lName: QueueCtxt.selectedDec?.lName || "",
        address: QueueCtxt.selectedDec?.address || "",
        employment: QueueCtxt.selectedDec?.employment || "",
        affPoints: QueueCtxt.selectedDec?.affPoints || "",
        email: QueueCtxt.selectedDec?.email || "",
        phone: QueueCtxt.selectedDec?.phone || "",
        urls: QueueCtxt.selectedDec?.urls || [],
      });
      setSigning("signed");
    } catch (err) {
      console.log(err);
      alert("Error signing declaration!");
      setSigning(false);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (QueueCtxt.witnessData?.fName && QueueCtxt.witnessData?.lName) {
      setName(`${QueueCtxt.witnessData.fName} ${QueueCtxt.witnessData.lName}`);
    }
    if (QueueCtxt.witnessData?.occupation) {
      setQual(QueueCtxt.witnessData.occupation);
    }
    if (QueueCtxt.witnessData?.address) {
      setResidential(QueueCtxt.witnessData.address);
    }
    if (QueueCtxt.witnessData?.email) {
      setEmail(QueueCtxt.witnessData.email);
    }
    if (QueueCtxt.witnessData?.phone) {
      setPhone(QueueCtxt.witnessData.phone);
    }
  }, [QueueCtxt?.witnessData]);

  useEffect(() => {
    if (signing === "signed") {
      setTimeout(() => {
        setSigning(false);
      }, 10000);
    }
  }, [signing]);

  return (
    <>
      {QueueCtxt?.selectedDec ? (
        <iframe
          style={{ width: "100%", height: "60%", minHeight: "60%" }}
          src={`https://storage.googleapis.com/bucket-775/dec-${QueueCtxt.selectedDec.code}_${QueueCtxt.selectedDec.createdAt}`}
        />
      ) : null}
      {QueueCtxt?.selectedDec ? (
        <div
          style={{
            width: "100%",
            padding: "5px 5px 40px 5px",
            boxSizing: "border-box",
            backgroundColor: "rgba(0,0,0,0.25)",
            marginTop: 10,
          }}
        >
          <p style={{ textAlign: "center" }}>Witness Information</p>
          <div style={fieldStyles}>
            <p style={{ marginRight: 10 }}>Full name:</p>
            <TextField
              value={name}
              sx={{ backgroundColor: "whitesmoke", borderRadius: "4px" }}
              fullWidth={true}
              size="small"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div style={fieldStyles}>
            <p style={{ marginRight: 10 }}>Qualification:</p>
            <TextField
              value={qual}
              sx={{ backgroundColor: "whitesmoke", borderRadius: "4px" }}
              fullWidth={true}
              size="small"
              onChange={(e) => {
                setQual(e.target.value);
              }}
            />
          </div>
          <div style={fieldStyles}>
            <p style={{ marginRight: 10 }}>Address:</p>
            <TextField
              value={residential}
              sx={{ backgroundColor: "whitesmoke", borderRadius: "4px" }}
              fullWidth={true}
              size="small"
              onChange={(e) => {
                setResidential(e.target.value);
              }}
            />
          </div>
          <div style={fieldStyles}>
            <p style={{ marginRight: 10 }}>Email:</p>
            <TextField
              value={email}
              sx={{ backgroundColor: "whitesmoke", borderRadius: "4px" }}
              fullWidth={true}
              size="small"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div style={fieldStyles}>
            <p style={{ marginRight: 10 }}>Phone:</p>
            <TextField
              value={phone}
              sx={{ backgroundColor: "whitesmoke", borderRadius: "4px" }}
              fullWidth={true}
              size="small"
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
          </div>
          <div style={fieldStyles}>
            <p style={{ marginRight: 10 }}>Date:</p>
            <TextField
              value={date}
              sx={{ backgroundColor: "whitesmoke", borderRadius: "4px" }}
              fullWidth={true}
              size="small"
              placeholder="i.e. 15"
              onChange={(e) => {
                setDate(e.target.value);
              }}
            />
          </div>
          <div style={fieldStyles}>
            <p style={{ marginRight: 10 }}>Month/Year:</p>
            <TextField
              value={monthYear}
              sx={{ backgroundColor: "whitesmoke", borderRadius: "4px" }}
              fullWidth={true}
              size="small"
              placeholder="i.e. November 2024"
              onChange={(e) => {
                setMonthYear(e.target.value);
              }}
            />
          </div>
          <div
            style={{
              width: "100%",
              padding: 10,
              boxSizing: "border-box",
              border: "2px solid red",
              margin: "20px 0px",
            }}
          >
            <p style={{ fontSize: 14, color: "orange", textAlign: "center" }}>
              By signing electronically, you agree that all information in the
              'Witness Information' field is true and correct. You understand
              that intentionally providing false information on a statutory
              declaration is a criminal offence.
            </p>
          </div>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            {signing === true ? (
              <CircularProgress />
            ) : signing === false ? (
              <Button variant="contained" onClick={signDeclaration}>
                SIGN DECLARATION
              </Button>
            ) : signing === "signed" ? (
              <p style={{ color: "limegreen" }}>
                Signing successful! The declarant will receive their signed copy
                shortly.
              </p>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
}
