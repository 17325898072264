import React, { useState, useEffect, useContext } from "react";

import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ChatIcon from "@mui/icons-material/Chat";
import IconButton from "@mui/material/IconButton";

import { submitLike } from "../../functions/ContentFunctions";

import { GlobalContext } from "../../App";

export default function InteractionsBar({
  contentRef,
  authorId,
  likeCount,
  commentCount,
  handleComment,
  type,
  fontSize,
  margin,
  colors,
  border,
}) {
  ////  INITS
  const GlobalCtxt = useContext(GlobalContext);

  ////  STATES
  const [alreadyLiked, setAlreadyLiked] = useState(false);
  const [justLiked, setJustLiked] = useState(false);
  const [localLikeCount, setLocalLikeCount] = useState(likeCount);

  ////  FUNCTIONS
  const handleLike = async () => {
    try {
      if (!localStorage.getItem("electorate")) {
        alert("Sign up for free to start interacting with posts!");
        return;
      }

      if (!alreadyLiked && !justLiked) {
        setJustLiked(true);
        setLocalLikeCount((prev) => prev + 1);
        await submitLike({
          contentId: contentRef,
          type,
          authorId: authorId ? authorId : null,
        });
        GlobalCtxt.handleNewLike(contentRef);
      }
    } catch (err) {
      console.log(err);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (GlobalCtxt?.userInfo) {
      const pastLikes = GlobalCtxt.userInfo?.likedIds;
      if (pastLikes && pastLikes.includes(contentRef)) {
        setAlreadyLiked(true);
      }
    }
  }, []);

  return (
    <>
      {contentRef && type ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: margin,
            border: border,
            borderRadius: 90,
            padding: border ? 5 : 0,
            boxSizing: "border-box",
            backgroundColor: colors?.background || "",
          }}
        >
          <IconButton
            sx={{
              backgroundColor:
                alreadyLiked || justLiked ? colors?.liked : "grey",
              border: "0.5px solid black",
              borderRadius: 360,
              padding: "4px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleLike();
            }}
          >
            <ThumbUpIcon
              sx={{
                marginRight: "2px",
                fontSize: `${fontSize}px`,
                color: "white",
              }}
            />
          </IconButton>
          <p
            style={{
              margin: "0px 12px 0px 4px",
              fontSize: fontSize,
              color: colors?.text,
            }}
          >
            {localLikeCount}
          </p>
          {handleComment === "hide" ? null : (
            <>
              <IconButton
                sx={{
                  backgroundColor: "dodgerblue",
                  border: "0.5px solid black",
                  borderRadius: 360,
                  padding: "4px",
                }}
                onClick={() => {
                  if (handleComment) {
                    handleComment();
                  }
                }}
              >
                <ChatIcon
                  sx={{
                    marginRight: "2px",
                    fontSize: `${fontSize}px`,
                    color: "white",
                  }}
                />
              </IconButton>
              <p
                style={{
                  margin: "0px 12px 0px 4px",
                  fontSize: fontSize,
                  color: colors?.text,
                }}
              >
                {commentCount}
              </p>
            </>
          )}
        </div>
      ) : null}
    </>
  );
}
