import React, { useContext, useEffect } from "react";

import { QueueContext } from "./QueueCtxtProvider";
import QueueInvite from "../affidavit-components/QueueInvite";

import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";

export default function QueueManagementTab() {
  ////  INITS
  const QueueCtxt = useContext(QueueContext);
  const params = useParams();

  ////  EFFECTS
  useEffect(() => {
    if (params.roomName) {
      QueueCtxt.updateCurrentQueue(params.roomName);
    }
  }, []);

  return (
    <>
      <div style={{ width: "100%", backgroundColor: "skyblue" }}>
        <h3 style={{ textAlign: "center", color: "black" }}>Session queue</h3>
      </div>
      {QueueCtxt?.queues?.[params.roomName] && QueueCtxt.currentQueue ? (
        <>
          {QueueCtxt.queues[params.roomName]?.open ? (
            <p style={{ textAlign: "center" }}>
              Your session{" "}
              <span style={{ fontWeight: "bold" }}>
                ({QueueCtxt.currentQueue})
              </span>{" "}
              is active!
            </p>
          ) : null}
          <div style={{ width: "100%", marginBottom: 10 }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {QueueCtxt.queues[params.roomName]?.open ? (
                <Button
                  variant="outlined"
                  color="warning"
                  size="small"
                  onClick={() => {
                    QueueCtxt.closeQueue();
                  }}
                >
                  CLOSE QUEUE
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="warning"
                  size="small"
                  sx={{ marginTop: "10px" }}
                  onClick={() => {
                    QueueCtxt.reactivateQueue();
                  }}
                >
                  REOPEN QUEUE
                </Button>
              )}
            </div>
          </div>
          <hr style={{ opacity: 0.5, width: "98%" }}></hr>
          {Array.isArray(QueueCtxt.queues[params.roomName]?.waiting) &&
          QueueCtxt.queues[params.roomName]?.open ? (
            <>
              {QueueCtxt.queues[params.roomName].waiting.length > 0 ? (
                <p style={{ opacity: 0.5, fontSize: 14 }}>
                  Press 'INVITE' to send an invite request to the first user in
                  the queue. Once you send the invitation, they will have 60
                  seconds to accept.
                </p>
              ) : null}
              <p style={{ fontWeight: 600 }}>
                Users in queue:{" "}
                {QueueCtxt.queues[params.roomName].waiting.length}
              </p>
              {QueueCtxt.queues[params.roomName].waiting.map((el, i) => {
                return (
                  <QueueInvite data={el} index={i} key={`${i}-${el.decId}`} />
                );
              })}
            </>
          ) : null}
        </>
      ) : (
        <>
          <p style={{ textAlign: "center", opacity: 0.5 }}>
            Your session isn't active.
          </p>
          <p style={{ textAlign: "center", width: "96%" }}>
            When you click the button below, users will be able to begin
            queueing for your session.
          </p>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={() => {
              QueueCtxt.openQueue(params.roomName);
            }}
          >
            ACTIVATE SESSION!
          </Button>
        </>
      )}
    </>
  );
}
