import React from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";

import Button from "@mui/material/Button";
import MuxPlayer from "@mux/mux-player-react";
import { useNavigate } from "react-router-dom";

export default function HowTo() {
  const navigate = useNavigate();

  return (
    <ScreenContainer>
      <Navbar />
      <h3>Volunteer registration walkthrough</h3>
      <Button
        variant="contained"
        color="success"
        onClick={() => {
          navigate("/flyer-flow");
        }}
      >
        REGISTER HERE!
      </Button>
      <br></br>
      <div
        style={{ width: "100vw", maxWidth: 768, margin: "20px 0px 20px 0px" }}
      >
        <MuxPlayer
          playbackId="mdR502Cek4da6HaUSmQRVZGoiMxKNmHNk8B8coIWPxmg"
          metadata={{
            video_id: "vhjsKlCDsMx1g1jY02XdNozp4qWzyPRpGufh00QCR02Cno",
            video_title: "Flyers",
          }}
        />
      </div>
      <hr style={{ width: "100vw", maxWidth: 768 }}></hr>
    </ScreenContainer>
  );
}
