// REVISIT: automatically set broadcasts to private; make sure randos can't enter them from the lobby
// instead of redirect to conference room, button should redirect to Stream page
// this is more for the MeetingPreview component

import React, { useState, useContext } from "react";

import LobbyWrapper from "../components/conference-components/LobbyWrapper";
import LobbyDiv from "../components/conference-components/LobbyDiv";
import {
  createVideoEvent,
  createDailyRoom,
} from "../functions/ConferenceFunctions";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams, useNavigate } from "react-router-dom";
import GroupsIcon from "@mui/icons-material/Groups";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import SchoolIcon from "@mui/icons-material/School";

import { GlobalContext } from "../App";

const callTypeStyles = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "rgba(0,0,0,0.1)",
  border: "0.5px solid black",
  padding: 5,
  boxSizing: "border-box",
  marginBottom: 8,
  cursor: "pointer",
};

const ConferenceSchedule = () => {
  ////    INITS
  const params = useParams();
  const navigate = useNavigate();
  const GlobalCtxt = useContext(GlobalContext);

  ////  STATES
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(null);
  const [meetingScheduled, setMeetingScheduled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [callType, setCallType] = useState(null);
  const [callTypeDraft, setCallTypeDraft] = useState(null);
  const [eventName, setEventName] = useState("");
  const [eventDuration, setEventDuration] = useState(null);

  ////  FUNCTIONS
  const onChangeDate = (newDate) => {
    setDate(newDate);
  };

  const onChangeTime = (e) => {
    setTime(e.target.value);
  };

  const createUnix = () => {
    try {
      const [hours, minutes] = time.split(":");
      const appointmentDate = new Date(date);
      appointmentDate.setHours(parseInt(hours, 10));
      appointmentDate.setMinutes(parseInt(minutes, 10));
      appointmentDate.setSeconds(0);
      appointmentDate.setMilliseconds(0);
      return Math.floor(appointmentDate.getTime());
    } catch (err) {
      alert("Please enter a valid time.");
      return false;
    }
  };

  const abortSubmission = () => {
    //
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const decodedName = decodeURIComponent(params.channelName);
      if (eventName.length > 160) {
        alert("Event name cannot exceed 160 characters.");
        setLoading(false);
        return;
      }
      if (eventName.length < 2) {
        alert("Event name must contain at least two characters.");
        setLoading(false);
        return;
      }
      let parsedEventDuration = eventDuration;
      if (parsedEventDuration) {
        parsedEventDuration = parseFloat(eventDuration);
        if (isNaN(parsedEventDuration)) {
          alert("Invalid duration input.");
          setLoading(false);
          return;
        } else if (parsedEventDuration < 0.25 || parsedEventDuration > 3) {
          alert("Duration must be between 0.25 and 3 hours.");
          setLoading(false);
          return;
        }
        parsedEventDuration *= 3600000;
      } else {
        parsedEventDuration = 10800000;
      }

      const timestamp = createUnix();
      if (!timestamp) {
        alert("Invalid time.");
        setLoading(false);
        return;
      }
      const roomName = await createVideoEvent({
        channelId: params.channelId,
        channelName: decodedName,
        time: timestamp,
        moderators: [
          {
            username: localStorage.getItem("username"),
            _id: localStorage.getItem("_id"),
          },
        ],
        callType,
        eventName,
        duration: parsedEventDuration,
      });

      await createDailyRoom({
        roomName: roomName.data,
        callType,
        duration: parsedEventDuration / 1000,
        startTime: timestamp / 1000,
      });
      setLoading(false);
      setMeetingScheduled(true);
    } catch (err) {
      console.log(err);
      alert(err.message);
      setLoading(false);
    }
  };

  return (
    <LobbyWrapper mode={callTypeDraft ? callTypeDraft : "meeting"}>
      <LobbyDiv>
        {meetingScheduled ? (
          <>
            <h2 style={{ textAlign: "center" }}>
              Meeting succcessfully scheduled!
            </h2>
            <br></br>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  navigate(
                    `/conference-room/${params.channelId}/${decodeURIComponent(
                      params.channelName
                    )}`
                  );
                }}
                color="info"
              >
                Return to lobby
              </Button>
            </div>
          </>
        ) : (
          <>
            {callType ? (
              <>
                <IconButton
                  onClick={() => {
                    setCallType(null);
                    setCallTypeDraft(null);
                  }}
                >
                  <ArrowBackIcon sx={{ color: "grey" }} />
                </IconButton>
                <h3 style={{ textAlign: "center" }}>Schedule {callType}</h3>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    fullWidth={true}
                    placeholder="Event name ..."
                    sx={{ backgroundColor: "whitesmoke", borderRadius: 2 }}
                    onChange={(e) => {
                      setEventName(e.target.value);
                    }}
                  />
                  <br></br>
                  <Calendar onChange={onChangeDate} value={date} />
                  <br></br>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p>Select time:</p>
                    <input
                      aria-label="Time"
                      type="time"
                      style={{
                        minWidth: 150,
                        fontSize: 16,
                        marginLeft: "10px",
                      }}
                      onChange={onChangeTime}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p>Duration:</p>
                    <TextField
                      type="number"
                      placeholder="Hours (min 0.25, max 3)"
                      sx={{
                        marginLeft: "5px",
                        minWidth: "275px",
                        backgroundColor: "white",
                        borderRadius: 2,
                      }}
                      onChange={(e) => {
                        setEventDuration(e.target.value);
                      }}
                    />
                  </div>
                  <p
                    style={{ fontSize: 12, textAlign: "center", maxWidth: 350 }}
                  >
                    Users will be automatically removed from the call when the
                    duration expires. If the field above is left blank, the
                    maximum duration will apply.
                  </p>
                  <br></br>
                  {loading === true ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      SCHEDULE!
                    </Button>
                  )}
                  <hr style={{ width: "100%" }}></hr>
                </div>
              </>
            ) : (
              <>
                <h4 style={{ textAlign: "center" }}>
                  What type of call do you want to schedule?
                </h4>
                <div
                  style={{
                    ...callTypeStyles,
                    ...{
                      border:
                        callTypeDraft === "meeting"
                          ? "2px solid black"
                          : "0.5px solid grey",
                    },
                  }}
                  onClick={() => setCallTypeDraft("meeting")}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <GroupsIcon fontSize="large" />
                    <h4 style={{ margin: "5px 0px 5px 10px" }}>Meeting</h4>
                  </div>
                  <p style={{ textAlign: "center" }}>
                    Host a remote meeting that any paying user can join.
                    Non-paying users can gain access using a one-time code
                    generated by the Channel Secretary.
                  </p>
                </div>
                <div
                  style={{
                    ...callTypeStyles,
                    ...{
                      border:
                        callTypeDraft === "broadcast"
                          ? "2px solid black"
                          : "0.5px solid grey",
                    },
                  }}
                  onClick={() => {
                    if (GlobalCtxt.userInfo?.tier >= 3) {
                      setCallTypeDraft("broadcast");
                    } else {
                      alert(
                        "You must be subscribed to the Community Leader tier to host a live broadcast."
                      );
                    }
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <PodcastsIcon fontSize="large" />
                    <h4 style={{ margin: "5px 0px 5px 10px" }}>Broadcast</h4>
                  </div>
                  <p style={{ textAlign: "center" }}>
                    Broadcast live to this website and to your external social
                    media channels. Anyone will be able to watch, but you'll
                    have control over who enters the call.
                  </p>
                </div>
                <div
                  style={{
                    ...callTypeStyles,
                    ...{
                      border:
                        callTypeDraft === "webinar"
                          ? "2px solid black"
                          : "0.5px solid grey",
                    },
                  }}
                  onClick={() => {
                    if (GlobalCtxt.userInfo?.tier >= 3) {
                      setCallTypeDraft("webinar");
                    } else {
                      alert(
                        "You must be subscribed to the Community Leader tier to host a webinar."
                      );
                    }
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <SchoolIcon fontSize="large" />
                    <h4 style={{ margin: "5px 0px 5px 10px" }}>Webinar</h4>
                  </div>
                  <p style={{ textAlign: "center" }}>
                    Spread the word with a webinar of up to 200 participants.
                    Audience members' cameras and microphones will be turned
                    off, so the stage is all yours!
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 10,
                  }}
                >
                  <Button
                    onClick={() => {
                      if (!callTypeDraft) {
                        alert("Select one of the above options.");
                      } else {
                        setCallType(callTypeDraft);
                      }
                    }}
                    variant="contained"
                  >
                    CONFIRM CALL TYPE
                  </Button>
                </div>
                <hr></hr>
              </>
            )}
          </>
        )}
      </LobbyDiv>
    </LobbyWrapper>
  );
};

export default ConferenceSchedule;
