import React, { useState, useEffect, useRef, useContext } from "react";

import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import PaletteIcon from "@mui/icons-material/Palette";
import CircularProgress from "@mui/material/CircularProgress";
import GroupsIcon from "@mui/icons-material/Groups";
import DOMPurify from "dompurify";

import ColorPalette from "../common-components/ColorPalette";
import LiveLobby from "./LiveLobby";

import { socket } from "../../socket";
import { SocketContext } from "./SocketCtxtProvider";

export default function SocketContainer({ isAdmin }) {
  ////  INITS
  const messageContainerRef = useRef();
  const SocketCtxt = useContext(SocketContext);

  const tabTextStyles = {
    color: "white",
    textAlign: "center",
    cursor: "pointer",
  };

  ////  STATES
  const [whichTab, setWhichTab] = useState("chat");
  const [showPalette, setShowPalette] = useState(false);
  const [textColor, setTextColor] = useState("green");
  const [currentMessage, setCurrentMessage] = useState("");

  ////  FUNCTIONS
  const changeColor = (color) => {
    setTextColor(color);
  };

  const submitMessage = () => {
    try {
      if (currentMessage) {
        socket.emit("send-message", {
          user: localStorage.getItem("username"),
          content: DOMPurify.sanitize(currentMessage),
          color: textColor,
          room: SocketCtxt.room,
        });
        setCurrentMessage("");
      }
    } catch (err) {
      console.log(err);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [SocketCtxt.liveMessages]);

  return (
    <div
      style={{
        height: "100%",
        maxHeight: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {SocketCtxt.room ? (
        <>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              padding: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <div
                style={{
                  width: "48%",
                  backgroundColor: whichTab === "chat" ? "#333" : "#111",
                  padding: "10px 0",
                  borderRadius: "8px",
                  cursor: "pointer",
                  textAlign: "center",
                  transition: "background-color 0.3s ease",
                }}
                onClick={() => {
                  setWhichTab("chat");
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = "#444")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    whichTab === "chat" ? "#333" : "#111")
                }
              >
                <p
                  style={{
                    ...tabTextStyles,
                    fontWeight: whichTab === "chat" ? 600 : 300,
                    textDecoration: whichTab === "chat" ? "underline" : "none",
                    margin: 0,
                    color: "#fff",
                  }}
                >
                  CHAT
                </p>
              </div>
              <div
                style={{
                  width: "48%",
                  backgroundColor: whichTab === "lobby" ? "#333" : "#111",
                  padding: "10px 0",
                  borderRadius: "8px",
                  cursor: "pointer",
                  textAlign: "center",
                  transition: "background-color 0.3s ease",
                }}
                onClick={() => {
                  setWhichTab("lobby");
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = "#444")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    whichTab === "lobby" ? "#333" : "#111")
                }
              >
                <p
                  style={{
                    ...tabTextStyles,
                    fontWeight: whichTab === "lobby" ? 600 : 300,
                    textDecoration: whichTab === "lobby" ? "underline" : "none",
                    margin: 0,
                    color: "#fff",
                  }}
                >
                  LOBBY
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0px 8px",
              }}
            >
              <GroupsIcon sx={{ color: "lightgrey" }} />
              <p style={{ marginLeft: 4, color: "lightgrey" }}>
                {SocketCtxt.viewerCount}
              </p>
            </div>
          </div>
          {whichTab === "chat" ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  width: "100%",
                  overflowY: "auto",
                  padding: 5,
                  margin: "20px 0px",
                  boxSizing: "border-box",
                }}
                ref={messageContainerRef}
              >
                {SocketCtxt.liveMessages &&
                Array.isArray(SocketCtxt.liveMessages)
                  ? SocketCtxt.liveMessages.map((el, i) => {
                      return (
                        <p
                          key={`${i}-${el.time}`}
                          style={{
                            color: el.color,
                            fontWeight: 300,
                            marginBottom: 5,
                          }}
                        >
                          {DOMPurify.sanitize(el.user)}:{" "}
                          <span style={{ color: "lightgrey" }}>
                            {DOMPurify.sanitize(el.content)}
                          </span>
                        </p>
                      );
                    })
                  : null}
              </div>
              <TextField
                fullWidth={true}
                multiline={true}
                rows={3}
                sx={{ border: `0.5px solid ${textColor}`, borderRadius: "2px" }}
                inputProps={{ style: { color: textColor } }}
                value={currentMessage}
                onChange={(e) => {
                  setCurrentMessage(e.target.value);
                }}
              />
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 5,
                  marginTop: 5,
                }}
              >
                <IconButton
                  onClick={() => {
                    setShowPalette((prev) => !prev);
                  }}
                >
                  <PaletteIcon sx={{ color: "lightgrey" }} />
                </IconButton>
                {showPalette ? (
                  <div style={{ display: "flex", flex: 1, height: "100%" }}>
                    <ColorPalette handleClick={changeColor} />
                  </div>
                ) : null}

                <Button
                  sx={{ margin: "2px" }}
                  disabled={currentMessage ? false : true}
                  onClick={submitMessage}
                >
                  SUBMIT
                </Button>
              </div>
            </>
          ) : whichTab === "lobby" ? (
            <LiveLobby isAdmin={isAdmin} />
          ) : (
            <p>{whichTab}</p>
          )}
        </>
      ) : (
        <>
          {isAdmin ? (
            <p style={{ textAlign: "center" }}>
              Lobby will become accessible after you launch the live stream.
            </p>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p style={{ fontWeight: 300 }}>Data not available.</p>
            </div>
          )}
        </>
      )}
    </div>
  );
}
