import React, { useState, useEffect, useContext } from "react";

import DynamicFrame from "../common-components/DynamicFrame";
import GenericMain from "../common-components/GenericMain";
import GenericSidebar from "../common-components/GenericSidebar";
import GenericModal from "../common-components/GenericModal";
import {
  validateLocation,
  validateName,
  validatePhone,
} from "../../functions/GeneralFunctions";
import AddressAutoComplete from "../common-components/AddressAutocomplete";

import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";

import { AffidavitContext } from "../../screens/AffidavitFlow";

const textFieldStyles = {
  width: "96%",
  maxWidth: 400,
  marginBottom: "4px",
  marginRight: "5px",
  backgroundColor: "white",
  borderRadius: "4px",
};

export default function AffidavitDiv2({
  windowSize,
  updateCurrentDiv,
  disabled,
}) {
  ////  INITS
  const AffidavitCtxt = useContext(AffidavitContext);

  ////  STATES
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [address, setAddress] = useState("");
  const [employed, setEmployed] = useState("");
  const [employment, setEmployment] = useState(null);
  const [phone, setPhone] = useState("");
  const [manualAddress, setManualAddress] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  ////  FUNCTIONS
  const handleSubmit = async () => {
    try {
      const fNameValid = validateName(fName);
      if (fNameValid === false) {
        alert("Invalid input in field 'first name'.");
        return;
      }
      const lNameValid = validateName(lName);
      if (lNameValid === false) {
        alert("Invalid input in field 'last name'.");
        return;
      }
      if (employment) {
        let employmentValid = validateLocation(employment);
        if (employmentValid === false) {
          alert(
            "Invalid occupation title. Please avoid using uncommon characters."
          );
          return;
        }
      }
      if (phone) {
        let phoneValid = validatePhone(phone);
        if (phoneValid === false) {
          alert("Invalid phone number.");
          return;
        }
      }
      if (!address) {
        alert("Please enter a contact address.");
        return;
      }
      let addressValid = validateLocation(address);
      if (addressValid === false) {
        alert("Please enter a valid address.");
        return;
      }
      await AffidavitCtxt.updateData({
        fName,
        lName,
        address,
        phone: phone ? phone : "",
        employment: employment ? employment : "Unemployed",
      });
      updateCurrentDiv(2);
      setTimeout(() => {
        setButtonClicked(true);
      }, 2000);
    } catch (err) {
      console.log(err);
    }
  };

  const grabAddress = (add) => {
    try {
      setAddress(add);
    } catch (err) {
      console.log(err);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (employed) {
      if (employed == "true") {
        setEmployment("");
      } else if (employed == "false") {
        setEmployment("Retiree");
      } else if (employed == "Prefer not to say") {
        setEmployment(null);
      }
    } else {
      setEmployment(null);
    }
  }, [employed]);

  useEffect(() => {
    //
  }, [manualAddress]);

  return (
    <>
      <GenericModal
        open={manualAddress === "pending" ? true : false}
        handleClose={() => setManualAddress(false)}
      >
        <p style={{ textAlign: "center" }}>
          We use Google's autocomplete feature to ensure that addresses are
          properly formatted. This minimises the risk that your Declaration will
          be deemed invalid.
        </p>
        <p style={{ textAlign: "center" }}>
          If you're not comfortable using this feature, you may deactivate it,
          but please ensure that your address follows the correct format.
        </p>
        <p style={{ textAlign: "center", fontWeight: "bold" }}>
          '[street address], [town/suburb] [state/territory], Australia'
        </p>
        <br></br>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Button
            variant="contained"
            color="warning"
            onClick={() => setManualAddress(true)}
          >
            Deactivate autocomplete
          </Button>
        </div>
        <br></br>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Button onClick={() => setManualAddress(false)}>
            Use autocomplete
          </Button>
        </div>
      </GenericModal>
      {windowSize.width > 768 ? (
        <h2>Basic information</h2>
      ) : (
        <h3>Basic information</h3>
      )}
      <p
        style={{
          textAlign: "center",
          maxWidth: 600,
          width: "96%",
          fontSize: windowSize.width > 768 ? 16 : 14,
        }}
      >
        The following details are required in order for your Declaration to be
        legally valid.
      </p>
      <DynamicFrame windowSize={windowSize} styling={{ height: "65vh" }}>
        <GenericMain styling={{ backgroundColor: "#f5f5f5" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              marginBottom: 5,
              flexWrap: "wrap",
            }}
          >
            <TextField
              placeholder="First name"
              sx={textFieldStyles}
              onChange={(e) => {
                setFName(e.target.value);
              }}
              size={windowSize.width > 900 ? "medium" : "small"}
              disabled={disabled}
            />
            <TextField
              placeholder="Last name"
              sx={textFieldStyles}
              onChange={(e) => {
                setLName(e.target.value);
              }}
              size={windowSize.width > 900 ? "medium" : "small"}
              disabled={disabled}
            />
            <TextField
              placeholder="Phone number (optional)"
              sx={textFieldStyles}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              size={windowSize.width > 900 ? "medium" : "small"}
              disabled={disabled}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", flex: 1 }}>
              {manualAddress === true ? (
                <TextField
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  fullWidth={true}
                  placeholder="Address (work or home)"
                  size={windowSize.width > 900 ? "medium" : "small"}
                  disabled={disabled}
                  sx={{
                    maxWidth: "805px",
                    backgroundColor: "white",
                    borderRadius: "4px",
                  }}
                />
              ) : (
                <AddressAutoComplete
                  placeholder="Address (work or home)"
                  grabAddress={grabAddress}
                />
              )}

              <Tooltip title="Deactivate Google autocomplete">
                <IconButton onClick={() => setManualAddress("pending")}>
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <p style={{ fontSize: 12, opacity: 0.5, maxWidth: 800 }}>
            If you're not comfortable giving your home address, please enter a
            different address at which you could be contacted.
          </p>
          {/* <div
            style={{
              width: "100%",
              display: "flex",
              //justifyContent: "center",
              flexWrap: "wrap",
              marginBottom: 10,
            }}
          >
            
            <TextField
              placeholder="Town/suburb"
              sx={{
                width: "48%",
                maxWidth: 375,
                marginRight: "10px",
                marginBottom: "10px",
              }}
              onChange={(e) => {
                setSuburb(e.target.value);
              }}
              size={windowSize.width > 900 ? "medium" : "small"}
              disabled={disabled}
            />
            <FormControl>
              <InputLabel id="demo-simple-select-label">
                State/territory
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={location}
                label="State/territory"
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
                sx={{
                  width: 375,
                  marginBottom: "10px",
                }}
                disabled={disabled}
              >
                <MenuItem value={"Australian Capital Territory"}>
                  Australian Capital Territory
                </MenuItem>
                <MenuItem value={"New South Wales"}>New South Wales</MenuItem>
                <MenuItem value={"Northern Territory"}>
                  Northern Territory
                </MenuItem>
                <MenuItem value={"Queensland"}>Queensland</MenuItem>
                <MenuItem value={"South Australia"}>South Australia</MenuItem>
                <MenuItem value={"Tasmania"}>Tasmania</MenuItem>
                <MenuItem value={"Victoria"}>Victoria</MenuItem>
                <MenuItem value={"Western Australia"}>
                  Western Australia
                </MenuItem>
              </Select>
            </FormControl>
          </div>*/}
          {/*<FormControl disabled={disabled}>
            <FormLabel id="controlled-radio-buttons-group">
              I want my statement to be:
            </FormLabel>
            <RadioGroup
              aria-labelledby="controlled-radio-buttons-group"
              defaultValue={sworn}
              name="controlled-radio-buttons-group"
              onChange={(e) => {
                setSworn(e.target.value);
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Sworn (on a Bible)"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Affirmed (not on a Bible)"
              />
            </RadioGroup>
          </FormControl> */}
          <br></br>
          <div style={{ display: "flex", alignItems: "center" }}>
            <FormLabel>Are you currently employed?</FormLabel>
            <select
              style={{ marginLeft: "10px", fontSize: 16 }}
              onChange={(e) => {
                setEmployed(e.target.value);
              }}
              value={employed}
              disabled={disabled}
            >
              <option value={""}>Prefer not to say</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>
          {employed === "" ? (
            <p style={{ fontSize: 12, opacity: 0.5, maxWidth: 800 }}>
              If you select 'Prefer not to say', your employment will be listed
              as 'Unemployed' by default.
            </p>
          ) : null}
          <br></br>
          {employed === "true" ? (
            <div style={{ width: 375, maxWidth: "98vw" }}>
              <TextField
                placeholder="Occupation"
                fullWidth
                sx={{ maxWidth: "400px" }}
                size={windowSize.width > 768 ? "medium" : "small"}
                onChange={(e) => {
                  setEmployment(e.target.value);
                }}
                disabled={disabled}
              />
            </div>
          ) : employed === "false" ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <FormLabel sx={{ marginRight: "10px" }}>
                Which of the following best describes you?
              </FormLabel>
              <select
                style={{
                  //marginLeft: 10,
                  fontSize: 16,
                }}
                onChange={(e) => {
                  setEmployment(e.target.value);
                }}
                disabled={disabled}
              >
                <option value="Retiree">Retiree</option>
                <option value="Student">Student</option>
                <option value="Disability pensioner">
                  Disability pensioner
                </option>
                <option value="Stay-at-home parent">Stay-at-home parent</option>
                <option value="Caregiver">Caregiver</option>
                <option value="Military veteran">Military veteran</option>
                <option value="Unemployed">Unemployed</option>
              </select>
            </div>
          ) : null}
          {windowSize.width > 425 ? <br></br> : null}

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              backgroundColor:
                windowSize.width > 768 ? "rgba(0,0,0,0.25)" : null,
              boxSizing: "border-box",
              padding: 4,
            }}
          >
            {buttonClicked ? (
              <p style={{ fontSize: 14, opacity: 0.75 }}>
                Scroll down to continue.
              </p>
            ) : (
              <Button
                sx={{ minWidth: "300px" }}
                variant="contained"
                onClick={handleSubmit}
              >
                NEXT
              </Button>
            )}
          </div>
        </GenericMain>
        {windowSize.width > 768 ? (
          <GenericSidebar windowSize={windowSize}></GenericSidebar>
        ) : null}
      </DynamicFrame>
    </>
  );
}
