import React, { useContext } from "react";

import ConferenceRoomPortal from "./ConferenceRoomPortal";
import DynamicMap from "../common-components/DynamicMap";
import NominationsPortal from "./NominationsPortal";
import ClickableUsername from "../common-components/ClickableUsername";
import { centsToDollars, timeSince } from "../../functions/GeneralFunctions";

import { useNavigate } from "react-router-dom";

import { GlobalContext } from "../../App";

import "../../App.css";

export default function ChannelOverview({ data, hideMap }) {
  ////  INITS
  const GlobalCtxt = useContext(GlobalContext);
  const navigate = useNavigate();

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flex: 1,
      }}
      className="dark-container"
    >
      <h3 className="color-transition">{data.name}</h3>
      {hideMap || !data?.public ? null : (
        <DynamicMap
          displayOnly={true}
          coords={data.coords}
          channelName={data.name}
          channelId={data._id}
        />
      )}

      <div style={{ width: "100%" }}>
        {data?.public ? (
          <>
            <p>
              <span style={{ fontWeight: 600 }}>Members:</span> {data.users}
            </p>
            <p>
              <span style={{ fontWeight: 600 }}>Voters:</span>{" "}
              {data.t1_members +
                data.t2_members +
                data.t3_members +
                data.t4_members}
            </p>
            <p>
              <span style={{ fontWeight: 600 }}>Investors:</span>{" "}
              {data.t2_members + data.t3_members + data.t4_members}
            </p>
            <p>
              <span style={{ fontWeight: 600 }}>Community Balance:</span>{" "}
              {centsToDollars(data.balance)}
            </p>
          </>
        ) : null}
        {!data?.public && hideMap ? (
          <p>
            <span style={{ fontWeight: 600 }}>Community Balance:</span>{" "}
            {centsToDollars(data.balance)}
          </p>
        ) : null}

        <p style={{ marginBottom: 0 }}>
          <span style={{ fontWeight: 600 }}>Current Secretary:</span>
        </p>
        <ClickableUsername
          userId={data.secretaryId}
          username={data.secretary}
        />
        {data.public ? (
          <>
            {data.last_election ? (
              <p>
                <span style={{ fontWeight: 600 }}>Last election:</span>{" "}
                {timeSince(data.last_election)} ago
              </p>
            ) : null}
            {Date.now() - data.last_election > GlobalCtxt.electoralCycle ? (
              <NominationsPortal data={data} />
            ) : (
              <p>
                <span style={{ fontWeight: 600 }}>Nominations re-open:</span>{" "}
                {new Date(
                  data.last_election + GlobalCtxt.electoralCycle
                ).toLocaleString()}
              </p>
            )}
          </>
        ) : null}
        <hr style={{ width: "100%", opacity: 0.5 }}></hr>
        <ConferenceRoomPortal channelId={data._id} channelName={data.name} />
      </div>
    </div>
  );
}
