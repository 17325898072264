import Axios from "axios";
import authenticatedConfig from "../utils/Token";

const getAffidavitDraft = async ({ prompt, temp }) => {
  try {
    const doc = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/ai/get-affidavit-draft`,
      { prompt, temp }
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const parseHtmlToListItems = (htmlString) => {
  try {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const listItems = doc.querySelectorAll("li");
    const resultArray = Array.from(listItems).map((item) => item.textContent);
    return resultArray;
  } catch (err) {
    console.log(err);
    return null;
  }
};

const checkForVowel = (str) => {
  const vowels = "aeiouAEIOU";
  return vowels.includes(str[0]);
};

const createAffidavitDoc = async (data) => {
  try {
    const doc = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/affidavits/create-affidavit-doc`,
      data
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const submitAffidavit = async (data) => {
  try {
    const doc = await Axios.post(
      `${process.env.REACT_APP_MAILING_SERVER}/email/send-declaration`,
      data
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const sendWitnessedDeclaration = async (data) => {
  try {
    const doc = await Axios.post(
      `${process.env.REACT_APP_MAILING_SERVER}/email/send-witnessed-declaration`,
      data
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getWitnessData = async (witnessId) => {
  try {
    const doc = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/users/get-witness-data?witnessId=${witnessId}`,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getWitnessSessions = async (data) => {
  try {
    const docs = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/video/get-witness-sessions?public=${data.public}`,
      authenticatedConfig
    );
    return docs;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getSingleAffidavit = async (code) => {
  try {
    const doc = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/affidavits/get-single-affidavit?code=${code}`,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const countSubmissions = async () => {
  try {
    const docs = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/affidavits/count-submissions`
    );
    return docs;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const checkForDec = async (decId) => {
  try {
    const exists = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/affidavits/check-for-dec?decId=${decId}`
    );
    return exists;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const createPetitionSignature = async (data) => {
  try {
    const created = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/affidavits/create-petition-signature`,
      data
    );
    return created;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getTestimony = async (testimonyId) => {
  try {
    const doc = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/affidavits/get-testimony?testimonyId=${testimonyId}`
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getTestimoniesByChar = async (char, pw) => {
  try {
    const doc = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/affidavits/get-testimonies-by-char?char=${char}&pw=${pw}`
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

export {
  getAffidavitDraft,
  parseHtmlToListItems,
  checkForVowel,
  submitAffidavit,
  getWitnessData,
  getWitnessSessions,
  createAffidavitDoc,
  getSingleAffidavit,
  sendWitnessedDeclaration,
  countSubmissions,
  checkForDec,
  createPetitionSignature,
  getTestimony,
  getTestimoniesByChar,
};
