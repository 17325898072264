import React, { useContext } from "react";

import GenericModal from "./GenericModal";
import UserPreview from "../home-components/UserPreview";
import { useWindowSize } from "../../utils/Hooks";

import { GlobalContext } from "../../App";

export default function MobileUserModal() {
  const GlobalCtxt = useContext(GlobalContext);
  const windowSize = useWindowSize();

  return (
    <>
      {windowSize.width <= 768 ? (
        <GenericModal
          open={GlobalCtxt?.globalCurrentUser ? true : false}
          handleClose={() => GlobalCtxt.updateCurrentUser(null)}
        >
          <UserPreview data={GlobalCtxt?.globalCurrentUser} hideExit={true} />
        </GenericModal>
      ) : null}
    </>
  );
}
