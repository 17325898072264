import React, { useState, useContext } from "react";

import GenericMain from "../common-components/GenericMain";
import DynamicFrame from "../common-components/DynamicFrame";
import GenericSidebar from "../common-components/GenericSidebar";
import GenericTextField from "../common-components/GenericTextField";
import GenericModal from "../common-components/GenericModal";
import Div3Sidebar from "./Div3Sidebar";

import Button from "@mui/material/Button";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";

import { AffidavitContext } from "../../screens/AffidavitFlow";

export default function AffidavitDiv3({
  windowSize,
  updateCurrentDiv,
  disabled,
}) {
  const AffidavitCtxt = useContext(AffidavitContext);

  ////  STATES
  const [modalVisible, setModalVisible] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleSubmit = async (text) => {
    try {
      await AffidavitCtxt.updateData({ textInput: text });
      updateCurrentDiv(3);
      setTimeout(() => {
        setButtonClicked(true);
      }, 2000);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <GenericModal
        open={modalVisible}
        handleClose={() => setModalVisible(false)}
        styling={{ backgroundColor: "rgba(0,0,0,0.8)" }}
      >
        <Div3Sidebar />
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <button onClick={() => setModalVisible(false)}>EXIT</button>
        </div>
      </GenericModal>
      {windowSize.width > 768 ? (
        <h2 style={{ textAlign: "center" }}>Tell your story</h2>
      ) : (
        <h3 style={{ textAlign: "center" }}>Tell your story</h3>
      )}

      <h4
        style={{
          fontWeight: 400,
          width: "96%",
          maxWidth: 600,
          textAlign: "center",
          margin: "5px 5px 10px 5px",
          fontSize: windowSize.width > 768 ? 16 : 14,
        }}
      >
        In your own words, explain how you were affected by Australia's COVID-19
        response.
      </h4>
      {windowSize.width <= 768 ? (
        <p
          style={{
            fontSize: 10,
            marginTop: 8,
            marginBottom: 8,
            textAlign: "center",
            maxWidth: "96%",
          }}
        >
          NOTE: We are aware of a minor formatting issue that occurs on a subset
          of mobile devices when completing this field. If you encounter this
          issue, consider completing your Declaration on a desktop device.
        </p>
      ) : null}

      {windowSize.width <= 768 ? (
        <Button
          variant="contained"
          size="small"
          startIcon={<TipsAndUpdatesIcon />}
          sx={{ marginTop: "5px", marginBottom: "10px" }}
          onClick={() => setModalVisible(true)}
          color="info"
        >
          SUGGESTIONS
        </Button>
      ) : null}

      <DynamicFrame
        windowSize={windowSize}
        styling={{
          height: "auto",
          backgroundColor: "rgba(0,0,0,0.5)",
          overflowY: "auto",
        }}
      >
        <GenericMain
          styling={{
            padding: 10,
            boxSizing: "border-box",
            backgroundColor: "#f5f5f5",
            //overflowY: "auto",
          }}
        >
          <GenericTextField
            charLimit={2500}
            rows={windowSize.width > 768 ? 16 : 12}
            buttonVariant="contained"
            handleSubmit={handleSubmit}
            value={AffidavitCtxt.data?.textInput}
          />
          {buttonClicked ? (
            <p
              style={{
                opacity: 0.8,
                textAlign: "center",
                fontSize: 14,
              }}
            >
              Scroll down to continue.
            </p>
          ) : null}
        </GenericMain>
        {windowSize.width > 768 ? (
          <GenericSidebar
            windowSize={windowSize}
            styling={{ width: "30vw", maxHeight: "65vh" }}
          >
            <Div3Sidebar />
          </GenericSidebar>
        ) : null}
      </DynamicFrame>
    </>
  );
}
