import React from "react";

const TenReasons = () => {
  return (
    <div>
      <h2>10 Reasons why Australia MUST cancel its treaties with the WHO</h2>
      <p style={{ fontStyle: "italic" }}>
        Download the extended PDF version{" "}
        <a
          href="https://storage.googleapis.com/bucket-775/10-reasons.pdf"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "dodgerblue" }}
        >
          HERE.
        </a>
      </p>
      <ol>
        <li>
          Health Minister Butler’s endorsement of the WHO’s 2024 International
          Health Regulations (IHRs) and his support of the Pandemic Agreement is
          inappropriate because:
          <ul>
            <li>
              Consultation with Australians about these documents has been
              woefully inadequate.
            </li>
            <li>Nevertheless, many Australians objected to the treaties.</li>
            <li>14 members of parliament objected to the treaties.</li>
            <li>
              Australian politicians and agencies were not given 4 months to
              consider the finished wording of the IHRs and their implications
              for Australia, as required under international law.
            </li>
          </ul>
        </li>
        <li>The treaties will cost Australia billions of dollars annually.</li>
        <li>
          The treaties empower the Director of the WHO to declare a pandemic,
          even with no evidence of an actual emergency. This leads to the
          activation of emergency laws in Australia that grant extraordinary
          power to health bureaucrats at the expense of normal democratic
          processes.
        </li>
        <li>
          Emergency powers will enable our politicians and public agencies to
          follow the WHO’s lead and restrict Australians’ freedom by:
          <ul>
            <li>Imposing lockdowns</li>
            <li>Enforcing travel restrictions</li>
            <li>Censoring scientific debate</li>
            <li>Incarcerating Australians</li>
            <li>Imposing medical treatments on Australians</li>
            <li>Implementing any other measure the WHO deems appropriate</li>
          </ul>
        </li>
        <li>
          The WHO’s approach to pandemic management in the Covid-19 epidemic –
          featuring lockdowns, masks, and travel restrictions – was grossly
          flawed.
        </li>
        <li>
          Implementing the WHO’s approach to pandemic management in the Covid-19
          epidemic created an economic and humanitarian crisis in Australia.
        </li>
        <li>
          The WHO is neither an independent nor an impartial body, and as such
          it is unfit to make decisions about health.
        </li>
        <li>
          The WHO is indemnified from liability for harm, further disqualifying
          it from making decisions about health.
        </li>
        <li>
          These treaties have ongoing application and lengthy withdrawal
          periods, making them very difficult to get out of once we are
          committed.
        </li>
        <li>
          The treaties establish a mechanism whereby we would be giving the WHO
          an alarming degree of undue influence over the Australian government
          and its people.
        </li>
      </ol>
    </div>
  );
};

export default TenReasons;
