import React, { useContext } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";

import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import PublishIcon from "@mui/icons-material/Publish";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";

import { GlobalContext } from "../App";

import "../App.css";

export default function NewsChuteLanding() {
  const navigate = useNavigate();
  const GlobalCtxt = useContext(GlobalContext);

  return (
    <ScreenContainer>
      <Navbar />
      <h1
        style={{ textAlign: "center" }}
        className="animate__animated animate__bounceInDown"
      >
        News Chute
      </h1>
      <div
        style={{
          width: "100vw",
          maxWidth: 768,
          border: "0.5px solid black",
          backgroundColor: "whitesmoke",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 10,
          boxSizing: "border-box",
          borderRadius: 8,
          boxShadow: GlobalCtxt.genericBoxShadow,
        }}
        className="animate__animated animate__fadeIn animate__delay-1s"
      >
        <h4 style={{ textAlign: "center" }}>
          Do you want to browse existing documents or submit one of your own?
        </h4>
        <br></br>
        <Button
          size="large"
          variant="contained"
          startIcon={<LocalLibraryIcon />}
          color="success"
          sx={{ minWidth: "200px" }}
          onClick={() => navigate("/news-chute/browse")}
        >
          BROWSE
        </Button>
        <br></br>
        <Button
          size="large"
          variant="contained"
          startIcon={<PublishIcon />}
          sx={{ minWidth: "200px" }}
          onClick={() => navigate("/news-chute/submit")}
        >
          SUBMIT
        </Button>
        <br></br>
        <hr style={{ width: "100%", opacity: 0.5 }}></hr>
        <p style={{ textAlign: "center", width: "96%" }}>
          The News Chute feature enables users to embed external content in the
          PhaseChamber website. This is a great way to share things like news
          articles, scientific studies and press releases.
        </p>
        <p style={{ textAlign: "center", width: "96%" }}>
          If there's an issue that you're passionate about, we encourage you to
          dig up high-quality information and share it via the News Chute!
        </p>
      </div>
    </ScreenContainer>
  );
}
