import React, { useState, useEffect, useContext } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import DynamicFrame from "../components/common-components/DynamicFrame";
import GenericMain from "../components/common-components/GenericMain";
import GenericSidebar from "../components/common-components/GenericSidebar";
import GenericTextField from "../components/common-components/GenericTextField";
import UserPreview from "../components/home-components/UserPreview";
import SignInPrompt from "../components/common-components/SignInPrompt";
import ClickableUsername from "../components/common-components/ClickableUsername";
import Comment from "../components/common-components/Comment";
import InteractionsBar from "../components/common-components/InteractionsBar";
import { useWindowSize } from "../utils/Hooks";
import {
  getPostById,
  getComments,
  createComment,
} from "../functions/ContentFunctions";
import { safelyRenderHtml } from "../functions/GeneralFunctions";

import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

import { GlobalContext } from "../App";

export default function FullPost() {
  ////  INITS
  const windowSize = useWindowSize();
  const navigate = useNavigate();
  const params = useParams();
  const GlobalCtxt = useContext(GlobalContext);

  ////  STATES
  const [postData, setPostData] = useState(null);
  const [fetchError, setFetchError] = useState(false);
  const [sanitizedContent, setSanitizedContent] = useState(null);
  const [comments, setComments] = useState(null);
  const [exhausted, setExhausted] = useState(false);
  const [commenting, setCommenting] = useState(true);

  ////  FUNCTIONS
  const initFromParams = async () => {
    try {
      const doc = await getPostById(params.postId);
      if (doc) {
        setPostData(doc.data);
      } else {
        setFetchError(true);
      }
    } catch (err) {
      console.log(err);
      setFetchError(true);
    }
  };

  const renderSafely = async () => {
    try {
      const res = await safelyRenderHtml(postData.text);
      setSanitizedContent(res);
    } catch (err) {
      console.log(err);
      setFetchError(true);
    }
  };

  const fetchComments = async () => {
    try {
      const docs = await getComments({
        contentId: params.postId,
        skip: 0,
        sortKey: "",
      });
      if (Array.isArray(docs?.data) && docs.data.length > 0) {
        setComments(docs.data);
      }
    } catch (err) {
      console.log(err);
      alert("Error retrieving comments!");
    }
  };

  const fetchMoreComments = async () => {
    try {
      const docs = await getComments({
        contentId: params.postId,
        skip: comments.length,
        sortKey: "",
      });
      if (Array.isArray(docs?.data) && docs.data.length > 0) {
        setComments((prev) => [...prev, ...docs.data]);
      } else {
        setExhausted(true);
      }
    } catch (err) {
      console.log(err);
      alert("Error retrieving comments!");
    }
  };

  const submitComment = async (text) => {
    try {
      setCommenting("uploading");
      await createComment({
        text,
        contentId: postData._id,
        type: "post",
        originalAuthorId: postData.authorId,
      });
      setCommenting("success");
    } catch (err) {
      console.log(err);
      alert("Error creating comment! Please try again later.");
      setCommenting(true);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (GlobalCtxt.globalCurrentPost) {
      setPostData(GlobalCtxt.globalCurrentPost);
    } else {
      initFromParams();
    }
  }, []);

  useEffect(() => {
    if (postData) {
      renderSafely();
    }
  }, [postData]);

  useEffect(() => {
    if (commenting === "success") {
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    }
  }, [commenting]);

  return (
    <ScreenContainer>
      <Navbar />
      <DynamicFrame windowSize={windowSize}>
        <GenericMain styling={{ backgroundColor: "lightgrey" }}>
          <div>
            <IconButton
              onClick={() => {
                if (params.channelId && params.channelName) {
                  navigate(`/home/${params.channelId}/${params.channelName}`);
                } else {
                  navigate("/home");
                }
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <br></br>
          {postData ? (
            <>
              <ClickableUsername
                userId={postData.authorId}
                username={postData.authorName}
                underline={true}
              />
              <br></br>
              {sanitizedContent ? (
                <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
              ) : null}
              {postData?.file ? (
                <>
                  <br></br>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={postData.file}
                      style={{ maxWidth: "90%", maxHeight: "65vh" }}
                    />
                  </div>
                </>
              ) : null}
              <br></br>
              <InteractionsBar
                authorId={postData.authorId}
                contentRef={postData._id}
                commentCount={postData.comments}
                likeCount={postData.likes}
                type="post"
                fontSize={12}
                colors={{ liked: "green" }}
                margin="10px 0px 10px 0px"
              />
              <hr style={{ width: "100%" }}></hr>
              {localStorage.getItem("electorate") ? (
                <>
                  {commenting === true ? (
                    <GenericTextField
                      placeholder="Leave a comment ..."
                      rows={3}
                      charLimit={5000}
                      handleSubmit={submitComment}
                      divStyling={{ backgroundColor: "rgba(255,255,255,0.2)" }}
                    />
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {commenting === "uploading" ? (
                        <CircularProgress />
                      ) : commenting === "success" ? (
                        <div style={{ height: 40, width: 40 }}>
                          <DotLottieReact
                            src="https://lottie.host/ed77a13f-b127-4131-b8cb-b3e7bab6ab25/Fpxtr3MZdz.lottie"
                            autoplay
                          />
                        </div>
                      ) : null}
                    </div>
                  )}
                </>
              ) : (
                <SignInPrompt action="commenting" />
              )}

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {!postData?.comments || postData.comments <= 0 ? (
                  <h4 style={{ opacity: 0.5 }}>No comments yet.</h4>
                ) : postData.comments === 1 ? (
                  <h4 style={{ opacity: 0.5 }}>1 comment</h4>
                ) : (
                  <h4 style={{ opacity: 0.5 }}>{postData.comments} comments</h4>
                )}
                {/* REVISIT: fix conditional */}
                {postData?.comments && postData.comments > 0 && !comments ? (
                  <Button variant="outlined" onClick={fetchComments}>
                    SHOW
                  </Button>
                ) : null}
              </div>
              {comments && Array.isArray(comments) ? (
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div style={{ width: windowSize.width * 0.05 }}></div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      borderLeft: "0.5px solid grey",
                      paddingLeft: 10,
                    }}
                  >
                    {comments.map((el, i) => {
                      return <Comment data={el} key={el._id} />;
                    })}
                    {comments.length % 12 === 0 && !exhausted ? (
                      <Button variant="outlined" onClick={fetchMoreComments}>
                        LOAD MORE
                      </Button>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <>{fetchError ? <></> : null}</>
          )}
        </GenericMain>
        {windowSize.width > 768 ? (
          <GenericSidebar
            windowSize={windowSize}
            styling={{ width: windowSize.width * 0.3 }}
          >
            {GlobalCtxt.globalCurrentUser ? (
              <UserPreview data={GlobalCtxt.globalCurrentUser} />
            ) : null}
          </GenericSidebar>
        ) : null}
      </DynamicFrame>
    </ScreenContainer>
  );
}

/*<div
                  style={{
                    width: "100%",
                    marginLeft: windowSize.width * 0.05,
                    paddingLeft: windowSize.width * 0.025,
                    boxSizing: "border-box",
                    borderLeft: "0.5px solid grey",
                  }}
                >
                  
                  </div>*/
