import React, { useState, useEffect } from "react";

export default function Countdown({ deadline, styling }) {
  const calculateTimeLeft = () => {
    const difference = new Date(deadline) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  const { days, hours, minutes, seconds } = timeLeft;

  // Ensure two-digit format for hours, minutes, and seconds
  const formatTime = (time) => String(time).padStart(2, "0");

  return (
    <div>
      <p style={styling}>
        {days > 0 && `${days}d `}
        {formatTime(hours)}:{formatTime(minutes)}:{formatTime(seconds)}
      </p>
    </div>
  );
}
