import React, { useEffect, useState } from "react";

import {
  getUrlThumbnail,
  timeSince,
  replaceAndSanitize,
} from "../../functions/GeneralFunctions";

import FeedIcon from "@mui/icons-material/Feed";

export default function NcArticle({ data, handleClick }) {
  ////  INITS

  ////  STATES
  const [thumbnailIcon, setThumbnailIcon] = useState(null);

  ////  FUNCTIONS
  const fetchIcon = async () => {
    try {
      const icon = await getUrlThumbnail(data.url);
      if (icon.data) {
        setThumbnailIcon(icon.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    fetchIcon();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "whitesmoke",
        border: "1px solid black",
        fontSize: 14,
        cursor: "pointer",
        marginTop: 5,
      }}
      onClick={() => {
        handleClick(data);
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "lightblue",
          padding: "0px 5px",
          boxSizing: "border-box",
          borderBottom: "1px solid black",
        }}
      >
        <p>{data.username}</p>
        <p>{timeSince(data.createdAt)} ago</p>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ height: 100, width: 100, backgroundColor: "grey" }}>
          {thumbnailIcon ? (
            <img src={thumbnailIcon} style={{ height: 100, width: 100 }} />
          ) : (
            <FeedIcon />
          )}
        </div>
        <div style={{ padding: 4, boxSizing: "border-box" }}>
          <p style={{ fontWeight: 600, margin: "5px 0px" }}>Submission note:</p>
          <p>{replaceAndSanitize(data.description)}</p>
        </div>
      </div>
    </div>
  );
}
