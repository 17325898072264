import React, { useEffect, useState, useContext } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import DynamicFrame from "../components/common-components/DynamicFrame";
import AiSidebar from "../components/newschute-components/AiSidebar";
import GenericSidebar from "../components/common-components/GenericSidebar";
import GenericTextField from "../components/common-components/GenericTextField";
import InteractionsBar from "../components/common-components/InteractionsBar";
import SignInPrompt from "../components/common-components/SignInPrompt";
import GenericMain from "../components/common-components/GenericMain";
import NcArticle from "../components/common-components/NcArticle";
import Comment from "../components/common-components/Comment";
import BookmarkButton from "../components/common-components/BookmarkButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useWindowSize } from "../utils/Hooks";
import { getNCDocs, getBookmarkedDocs } from "../functions/NewsFunctions";
import { getComments, createComment } from "../functions/ContentFunctions";

import CircularProgress from "@mui/material/CircularProgress";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import WidthWideIcon from "@mui/icons-material/WidthWide";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

import { GlobalContext } from "../App";

const tabTextStyles = {
  color: "white",
  textAlign: "center",
  cursor: "pointer",
};

export default function NewsChuteBrowse() {
  ////  INITS
  const windowSize = useWindowSize();
  const GlobalCtxt = useContext(GlobalContext);
  const navigate = useNavigate();

  const tabContainerStyles = {
    width: "48%",
    padding: "10px 0",
    borderRadius: "8px",
    cursor: "pointer",
    textAlign: "center",
    transition: "background-color 0.3s ease",
    fontSize: windowSize.width > 900 ? 15 : 12,
  };

  ////  STATES
  const [index, setIndex] = useState("current-news");
  const [timeIndex, setTimeIndex] = useState("last-week");
  const [ncDocs, setNcDocs] = useState([]);
  const [currentDoc, setCurrentDoc] = useState(null);
  const [sidebarState, setSidebarState] = useState("comments");
  const [comments, setComments] = useState([]);
  const [commenting, setCommenting] = useState(true);
  const [rightSidebarWidth, setRightSidebarWidth] = useState("25vw");
  const [docsExhausted, setDocsExhausted] = useState(false);
  const [commentsExhausted, setCommentsExhausted] = useState(false);

  ////  FUNCTIONS
  const initNCDocs = async () => {
    try {
      let docs;
      if (index === "bookmarks") {
        docs = await getBookmarkedDocs(0, 12);
      } else {
        docs = await getNCDocs({
          sortKey: index,
          skip: 0,
          limit: 12,
          timeFrame: timeIndex,
        });
      }
      if (Array.isArray(docs.data) && docs.data.length > 0) {
        setNcDocs(docs.data);
      }
    } catch (err) {
      console.log(err);
      alert("Error retrieving articles! Please refresh the page to try again.");
    }
  };

  const fetchMoreDocs = async () => {
    try {
      let docs;
      if (index === "bookmarks") {
        docs = await getBookmarkedDocs(ncDocs.length, 12);
      } else {
        docs = await getNCDocs({
          sortKey: index,
          skip: ncDocs.length,
          limit: 12,
          timeFrame: timeIndex,
        });
      }
      if (Array.isArray(docs.data) && docs.data.length > 0) {
        setNcDocs((prev) => [...prev, ...docs.data]);
      } else {
        setDocsExhausted(true);
      }
    } catch (err) {
      console.log(err);
      alert("Error retrieving articles! Please refresh the page to try again.");
    }
  };

  const initComments = async () => {
    try {
      const docs = await getComments({
        contentId: currentDoc._id,
        skip: 0,
      });
      console.log(docs);
      if (Array.isArray(docs?.data)) {
        setComments(docs.data);
      }
    } catch (err) {
      console.log(err);
      //alert("Error retrieving comments!");
    }
  };

  const fetchMoreComments = async () => {
    try {
      const docs = await getComments({
        contentId: currentDoc._id,
        skip: comments.length,
      });
      if (Array.isArray(docs?.data) && docs.data.length > 0) {
        setComments((prev) => [...prev, ...docs.data]);
      } else {
        setCommentsExhausted(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDocumentClick = (doc) => {
    setCurrentDoc(doc);
  };

  const submitComment = async (text) => {
    try {
      setCommenting("uploading");
      await createComment({
        text,
        contentId: currentDoc._id,
        type: "article",
      });
      setCommenting("success");
    } catch (err) {
      console.log(err);
      alert("Error creating comment! Please try again later.");
      setCommenting(true);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (index && timeIndex) {
      setNcDocs([]);
      initNCDocs();
    }
  }, [index, timeIndex]);

  useEffect(() => {
    if (currentDoc) {
      setComments([]);
      initComments();
    }
  }, [currentDoc]);

  useEffect(() => {
    if (commenting === "success") {
      setTimeout(() => {
        setCommenting(true);
      }, 2500);
    }
    if (commenting === true && currentDoc) {
      initComments();
    }
  }, [commenting]);

  return (
    <ScreenContainer>
      <Navbar />
      <div style={{ width: "96vw" }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate("/news-chute")}
          color="info"
          variant="contained"
          sx={{ marginBottom: "5px" }}
          size="small"
        >
          BACK
        </Button>
      </div>
      <DynamicFrame
        windowSize={windowSize}
        styling={{
          height: "90vh",
          flexDirection: windowSize.width > 768 ? "row" : "column",
        }}
      >
        <GenericSidebar
          windowSize={windowSize}
          styling={{
            minWidth: windowSize.width > 768 ? null : "100vw",
            minHeight: windowSize.width > 768 ? null : "50vh",
          }}
        >
          <h4 style={{ color: "white", textAlign: "center" }}>
            Browse documents
          </h4>
          <p
            style={{
              fontSize: 14,
              opacity: 0.5,
              color: "white",
              margin: "10px 0px 5px 0px",
            }}
          >
            Category
          </p>
          <Select
            sx={{ backgroundColor: "whitesmoke" }}
            onChange={(e) => {
              setIndex(e.target.value);
            }}
            value={index}
          >
            <MenuItem value="current-news">Current news</MenuItem>
            <MenuItem value="archived-news">Archived news</MenuItem>
            <MenuItem value="studies">Studies</MenuItem>
            {GlobalCtxt.userInfo?.tier >= 3 ? (
              <MenuItem value="bookmarks">Your bookmarks</MenuItem>
            ) : null}
          </Select>
          {index === "bookmarks" ? null : (
            <>
              <p
                style={{
                  fontSize: 14,
                  opacity: 0.5,
                  color: "white",
                  margin: "10px 0px 5px 0px",
                }}
              >
                Uploaded within
              </p>
              <Select
                sx={{ backgroundColor: "whitesmoke" }}
                onChange={(e) => {
                  setTimeIndex(e.target.value);
                }}
                value={timeIndex}
              >
                <MenuItem value="last-week">Last week</MenuItem>
                <MenuItem value="last-month">Last month</MenuItem>
                <MenuItem value="last-year">Last year</MenuItem>
                <MenuItem value="all-time">All time</MenuItem>
              </Select>
            </>
          )}

          {Array.isArray(ncDocs) && ncDocs.length > 0 ? (
            <p style={{ opacity: 0.5, color: "lightgrey" }}>
              NewsChute documents:
            </p>
          ) : null}
          {Array.isArray(ncDocs) && ncDocs.length > 0 ? (
            <>
              {ncDocs.map((el, i) => {
                return (
                  <NcArticle
                    data={el}
                    handleClick={handleDocumentClick}
                    key={`${i}-${el._id}`}
                  />
                );
              })}
              {ncDocs.length % 12 === 0 && !docsExhausted ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button onClick={fetchMoreDocs}>FETCH MORE</Button>
                </div>
              ) : null}
            </>
          ) : (
            <p style={{ color: "grey", textAlign: "center" }}>
              Nothing to display.
            </p>
          )}
        </GenericSidebar>

        <GenericMain
          styling={{
            backgroundColor: "whitesmoke",
            minWidth: windowSize.width > 768 ? null : "100vw",
            minHeight: windowSize.width > 768 ? null : "90vh",
          }}
        >
          {currentDoc ? (
            <div
              style={{
                width: "100%",
                padding: 5,
                boxSizing: "border-box",
                backgroundColor: "rgba(0,0,0,0.8)",
                marginBottom: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <InteractionsBar
                authorId={currentDoc.userId}
                contentRef={currentDoc._id}
                commentCount={currentDoc.comments}
                likeCount={currentDoc.likes}
                fontSize={14}
                type="doc"
                colors={{ text: "white", liked: "green" }}
                handleComment="hide"
              />
              {currentDoc?._id ? (
                <BookmarkButton
                  contentRef={currentDoc._id}
                  prevBookmarks={currentDoc.bookmarkedBy}
                />
              ) : null}
            </div>
          ) : null}
          {currentDoc?.url ? (
            <iframe
              src={currentDoc.url}
              style={{ width: "100%", height: "100%" }}
            ></iframe>
          ) : (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ textAlign: "center", opacity: 0.5 }}>
                Select a document from the left sidebar.
              </p>
            </div>
          )}
        </GenericMain>
        <GenericSidebar
          windowSize={windowSize}
          styling={{
            width: rightSidebarWidth,
            minWidth: windowSize.width > 768 ? null : "100vw",
            minHeight: windowSize.width > 768 ? null : "90vh",
          }}
        >
          {rightSidebarWidth === "25vw" ? (
            <Button
              startIcon={<WidthWideIcon />}
              onClick={() => setRightSidebarWidth("39vw")}
            >
              EXPAND
            </Button>
          ) : (
            <Button
              startIcon={<WidthWideIcon />}
              onClick={() => setRightSidebarWidth("25vw")}
            >
              COLLAPSE
            </Button>
          )}

          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              marginTop: 5,
            }}
          >
            <div
              style={{
                ...tabContainerStyles,
                backgroundColor: sidebarState === "comments" ? "#333" : "#111",
              }}
              onClick={() => {
                setSidebarState("comments");
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.backgroundColor = "#444")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundColor =
                  sidebarState === "comments" ? "#333" : "#111")
              }
            >
              <p
                style={{
                  ...tabTextStyles,
                  fontWeight: sidebarState === "comments" ? 600 : 300,
                  textDecoration:
                    sidebarState === "comments" ? "underline" : "none",
                  margin: 0,
                  color: "#fff",
                }}
              >
                COMMENTS
              </p>
            </div>
            <div
              style={{
                ...tabContainerStyles,
                backgroundColor: sidebarState === "ai" ? "#333" : "#111",
              }}
              onClick={() => {
                setSidebarState("ai");
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.backgroundColor = "#444")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundColor =
                  sidebarState === "ai" ? "#333" : "#111")
              }
            >
              <p
                style={{
                  ...tabTextStyles,
                  fontWeight: sidebarState === "ai" ? 600 : 300,
                  textDecoration: sidebarState === "ai" ? "underline" : "none",
                  margin: 0,
                  color: "#fff",
                }}
              >
                AI SUMMARY
              </p>
            </div>
          </div>
          {sidebarState === "ai" ? (
            <AiSidebar />
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  overflowY: "auto",
                  margin: "10px 0px",
                }}
              >
                {Array.isArray(comments) && comments.length > 0 ? (
                  <>
                    {comments.map((el, i) => {
                      return (
                        <Comment
                          data={el}
                          key={`${i}-${el._id}`}
                          styling={{
                            backgroundColor: "whitesmoke",
                            paddingLeft: 5,
                            paddingRight: 5,
                          }}
                        />
                      );
                    })}
                    {comments.length % 12 === 0 && !commentsExhausted ? (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button onClick={fetchMoreComments}>FETCH MORE</Button>
                      </div>
                    ) : null}
                  </>
                ) : (
                  <p
                    style={{
                      opacity: 0.5,
                      color: "white",
                      textAlign: "center",
                      fontStyle: "italic",
                    }}
                  >
                    Be the first to comment!
                  </p>
                )}
              </div>
              {localStorage.getItem("electorate") ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {commenting === true ? (
                    <>
                      {currentDoc ? (
                        <GenericTextField
                          rows={4}
                          placeholder="Leave a comment ..."
                          handleSubmit={submitComment}
                          charLimit={1000}
                          divStyling={{ backgroundColor: "whitesmoke" }}
                        />
                      ) : null}
                    </>
                  ) : commenting === "uploading" ? (
                    <CircularProgress />
                  ) : commenting === "success" ? (
                    <div style={{ height: 40, width: 40 }}>
                      <DotLottieReact
                        src="https://lottie.host/ed77a13f-b127-4131-b8cb-b3e7bab6ab25/Fpxtr3MZdz.lottie"
                        autoplay
                      />
                    </div>
                  ) : null}
                </div>
              ) : (
                <SignInPrompt action="commenting" />
              )}
            </>
          )}
        </GenericSidebar>
      </DynamicFrame>
    </ScreenContainer>
  );
}

/*
{windowSize.width <= 768 ? (
        <>
          <div
            style={{
              width: "100%",
              maxHeight: "50vh",
              overflowY: "auto",
              border: "1px solid black",
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
          >
            <div
              style={{
                display: "flex",
                backgroundColor: "rgba(0,0,0,0.8)",
                padding: 5,
                boxSizing: "border-box",
              }}
            >
              <select
                onChange={(e) => {
                  setIndex(e.target.value);
                }}
                value={index}
                style={{ marginRight: "5px" }}
              >
                <option value="current-news">Current news</option>
                <option value="archived-news">Archived news</option>
                <option value="studies">Studies</option>
              </select>
              <select
                onChange={(e) => {
                  setTimeIndex(e.target.value);
                }}
                value={timeIndex}
              >
                <option value="last-week">Last week</option>
                <option value="last-month">Last month</option>
                <option value="last-year">Last year</option>
                <option value="all-time">All time</option>
              </select>
            </div>
            {Array.isArray(ncDocs) && ncDocs.length > 0 ? (
              ncDocs.map((el, i) => {
                return (
                  <NcArticle data={el} handleClick={handleDocumentClick} />
                );
              })
            ) : (
              <p style={{ color: "grey", textAlign: "center" }}>
                Nothing to display.
              </p>
            )}
          </div>
          <br></br>
        </>
      ) : null}
*/
