const electorateList = [
  "Adelaide",
  "Aston",
  "Ballarat",
  "Banks",
  "Barker",
  "Barton",
  "Bass",
  "Bean",
  "Bendigo",
  "Bennelong",
  "Berowra",
  "Blair",
  "Blaxland",
  "Bonner",
  "Boothby",
  "Bowman",
  "Braddon",
  "Bradfield",
  "Brand",
  "Brisbane",
  "Bruce",
  "Burt",
  "Calare",
  "Calwell",
  "Canberra",
  "Canning",
  "Capricornia",
  "Casey",
  "Chifley",
  "Chisholm",
  "Clark",
  "Cook",
  "Cooper",
  "Corangamite",
  "Corio",
  "Cowan",
  "Cowper",
  "Cunningham",
  "Curtin",
  "Dawson",
  "Deakin",
  "Dickson",
  "Dobell",
  "Dunkley",
  "Durack",
  "Eden-Monaro",
  "Fadden",
  "Fairfax",
  "Farrer",
  "Fenner",
  "Fisher",
  "Flinders",
  "Flynn",
  "Forde",
  "Forrest",
  "Fowler",
  "Franklin",
  "Fraser",
  "Fremantle",
  "Gellibrand",
  "Gilmore",
  "Gippsland",
  "Goldstein",
  "Gorton",
  "Grayndler",
  "Greenway",
  "Grey",
  "Griffith",
  "Groom",
  "Hasluck",
  "Hawke",
  "Herbert",
  "Higgins",
  "Hindmarsh",
  "Hinkler",
  "Holt",
  "Hotham",
  "Hughes",
  "Hume",
  "Hunter",
  "Indi",
  "Isaacs",
  "Jagajaga",
  "Kennedy",
  "Kingsford Smith",
  "Kingston",
  "Kooyong",
  "La Trobe",
  "Lalor",
  "Leichhardt",
  "Lilley",
  "Lindsay",
  "Lingiari",
  "Longman",
  "Lyne",
  "Lyons",
  "Macarthur",
  "Mackellar",
  "Macnamara",
  "Macquarie",
  "Makin",
  "Mallee",
  "Maranoa",
  "Maribyrnong",
  "Mayo",
  "McEwen",
  "McMahon",
  "McPherson",
  "Melbourne",
  "Menzies",
  "Mitchell",
  "Monash",
  "Moncrieff",
  "Moore",
  "Moreton",
  "New England",
  "Newcastle",
  "Nicholls",
  "North Sydney",
  "O'Connor",
  "Oxley",
  "Page",
  "Parkes",
  "Parramatta",
  "Paterson",
  "Pearce",
  "Perth",
  "Petrie",
  "Rankin",
  "Reid",
  "Richmond",
  "Riverina",
  "Robertson",
  "Ryan",
  "Scullin",
  "Shortland",
  "Solomon",
  "Spence",
  "Sturt",
  "Swan",
  "Sydney",
  "Tangney",
  "Wannon",
  "Warringah",
  "Watson",
  "Wentworth",
  "Werriwa",
  "Whitlam",
  "Wide Bay",
  "Wills",
  "Wright",
];

const loremIpsum = {
  lorem_25:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tincidunt justo non nisl aliquet, sed scelerisque risus auctor. Aliquam erat volutpat.",
  lorem_50:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus euismod, libero id ultrices suscipit, lectus eros vehicula quam, nec auctor nisl lorem a odio. Nam sit amet turpis id enim ullamcorper varius. Aliquam erat volutpat. Donec tincidunt, nisl sit amet commodo fermentum, urna velit.",
  lorem_75:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus euismod, libero id ultrices suscipit, lectus eros vehicula quam, nec auctor nisl lorem a odio. Nam sit amet turpis id enim ullamcorper varius. Aliquam erat volutpat. Donec tincidunt, nisl sit amet commodo fermentum, urna velit volutpat dolor, quis blandit velit mi eget quam. Cras sollicitudin, mauris a suscipit luctus, justo ligula lacinia eros, ut malesuada velit turpis id risus.",
  lorem_100:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus euismod, libero id ultrices suscipit, lectus eros vehicula quam, nec auctor nisl lorem a odio. Nam sit amet turpis id enim ullamcorper varius. Aliquam erat volutpat. Donec tincidunt, nisl sit amet commodo fermentum, urna velit volutpat dolor, quis blandit velit mi eget quam. Cras sollicitudin, mauris a suscipit luctus, justo ligula lacinia eros, ut malesuada velit turpis id risus. Nullam ut tortor neque. Curabitur faucibus euismod velit, ac tempor justo fermentum sit amet. Quisque ac dolor vel mauris eleifend ultricies.",
};

const acceptableImageFormats = [
  "image/webp",
  "image/svg",
  "image/png",
  "image/jpg",
  "image/jpeg",
];

const acceptableFormats = [
  "image/webp",
  "image/svg",
  "image/png",
  "image/jpg",
  "image/jpeg",
  "application/pdf",
];

const subOptions = [
  {
    label: "Standard",
    price: 690,
    price_str: "6.90",
    lookup_key: "pc_membership_t1",
    tier: 1,
    perks: [
      {
        perk: "Minimal ads",
        explainer: null,
      },
      {
        perk: "Nominate for Channel Secretary",
        explainer: null,
      },
      {
        perk: "Vote for Channel Secretary",
        explainer:
          "Vote for who becomes Secretary of your Channel, the National Channel, and beyond!",
      },
      {
        perk: "Members' Advantage",
        explainer:
          "Automatic tiebreak advantage over lower tier candidates when running for Channel Secretary.",
      },
      {
        perk: "Host and attend video calls",
        explainer: null,
      },
      {
        perk: "Unlimited NewsChute drops",
        explainer: null,
      },
    ],
  },
  {
    label: "Investor",
    price: 1190,
    price_str: "11.90",
    lookup_key: "pc_membership_t2",
    tier: 2,
    perks: [
      {
        perk: "Minimal ads",
        explainer: null,
      },
      {
        perk: "Nominate for Channel Secretary",
        explainer: null,
      },
      {
        perk: "Vote for Channel Secretary",
        explainer:
          "Vote for who becomes Secretary of your Channel, the National Channel, and beyond!",
      },
      {
        perk: "Members' Advantage",
        explainer:
          "Automatic tiebreak advantage over lower tier candidates when running for Channel Secretary.",
      },
      {
        perk: "Host and attend video calls",
        explainer: null,
      },
      {
        perk: "Unlimited NewsChute drops",
        explainer: null,
      },
      {
        perk: "Community Reinvestment",
        explainer:
          "$3 of your monthly subscription fee will be reinvested into your electorate's Community Balance.",
      },
      {
        perk: "Vote on funding proposals",
        explainer:
          "As a community investor, you will have the power to vote for or against funding proposals that are put to your local Channel.",
      },
      {
        perk: "Create your own Channel",
        explainer: null,
      },
      {
        perk: "Host private video calls",
        explainer:
          "Host private video calls through your Channel's own Conference Room feature",
      },
      {
        perk: "Launch fundraisers",
        explainer: null,
      },
      {
        perk: "15% withdrawal fee",
        explainer: "You keep 85% of funds raised through this platform.",
      },
    ],
  },
  {
    label: "Investor Plus",
    price: 1490,
    price_str: "14.90",
    lookup_key: "pc_membership_t3",
    tier: 3,
    perks: [
      {
        perk: "Minimal ads",
        explainer: null,
      },
      {
        perk: "Nominate for Channel Secretary",
        explainer: null,
      },
      {
        perk: "Vote for Channel Secretary",
        explainer:
          "Vote for who becomes Secretary of your Channel, the National Channel, and beyond!",
      },
      {
        perk: "Members' Advantage",
        explainer:
          "Automatic tiebreak advantage over lower tier candidates when running for Channel Secretary.",
      },
      {
        perk: "Host and attend video conferences",
        explainer: null,
      },
      {
        perk: "Unlimited NewsChute drops",
        explainer: null,
      },
      {
        perk: "Community Reinvestment",
        explainer:
          "$3 of your monthly subscription fee will be reinvested into your electorate's Community Balance.",
      },
      {
        perk: "Vote on funding proposals",
        explainer:
          "As a community investor, you will have the power to vote for or against funding proposals that are put to your local Channel.",
      },
      {
        perk: "Create your own Channel",
        explainer: null,
      },
      {
        perk: "Host private video calls",
        explainer:
          "Host private video calls through your Channel's own Conference Room feature.",
      },
      {
        perk: "Launch fundraisers",
        explainer: null,
      },
      {
        perk: "10% withdrawal fee",
        explainer: "You keep 90% of funds raised through this platform.",
      },
      {
        perk: "NewsChute AI summaries",
        explainer:
          "Instantly access AI-generated summaries of embedded NewsChute content.",
      },
    ],
  },
  {
    label: "Community Leader",
    price: 3500,
    price_str: "35.00",
    lookup_key: "pc_membership_t4",
    tier: 4,
    perks: [
      {
        perk: "Zero ads",
        explainer: null,
      },
      {
        perk: "Nominate for Channel Secretary",
        explainer: null,
      },
      {
        perk: "Vote for Channel Secretary",
        explainer:
          "Vote for who becomes Secretary of your Channel, the National Channel, and beyond!",
      },
      {
        perk: "Members' Advantage",
        explainer:
          "Automatic tiebreak advantage over lower tier candidates when running for Channel Secretary.",
      },
      {
        perk: "Host and attend video conferences",
        explainer: null,
      },
      {
        perk: "Unlimited NewsChute drops",
        explainer: null,
      },
      {
        perk: "Community Reinvestment",
        explainer:
          "$3 of your monthly subscription fee will be reinvested into your electorate's Community Balance.",
      },
      {
        perk: "Vote on funding proposals",
        explainer:
          "As a community investor, you will have the power to vote for or against funding proposals that are put to your local Channel.",
      },
      {
        perk: "Create your own Channel",
        explainer: null,
      },
      {
        perk: "Host private video calls",
        explainer:
          "Host private video calls through your Channel's own Conference Room feature.",
      },
      {
        perk: "Launch fundraisers",
        explainer: null,
      },
      {
        perk: "2.5% withdrawal fee",
        explainer: "You keep 97.5% of funds raised through this platform.",
      },
      {
        perk: "NewsChute AI summaries",
        explainer:
          "Instantly access AI-generated summaries of embedded NewsChute content.",
      },
      {
        perk: "Launch your own broadcast",
        explainer: null,
      },
      {
        perk: "Live stream to multiple platforms",
        explainer: null,
      },
      {
        perk: "Download and publish recordings",
        explainer: null,
      },
    ],
  },
];

const occupations = [
  { value: "", label: "" },
  { value: "Architect", label: "Architect" },
  { value: "Chiropractor", label: "Chiropractor" },
  { value: "Dentist", label: "Dentist" },
  { value: "Financial Adviser", label: "Financial Adviser" },
  { value: "Financial Planner", label: "Financial Planner" },
  {
    value: "Legal Practitioner",
    label:
      "Legal Practitioner (including an enrolled practitioner without a practicing certificate)",
  },
  { value: "Medical Practitioner", label: "Medical Practitioner" },
  { value: "Midwife", label: "Midwife" },
  {
    value: "Migration Agent",
    label:
      "Migration Agent (registered under Division 3 of Part 3 of the Migration Act 1958)",
  },
  { value: "Nurse", label: "Nurse" },
  { value: "Occupational Therapist", label: "Occupational Therapist" },
  { value: "Optometrist", label: "Optometrist" },
  { value: "Patent Attorney", label: "Patent Attorney" },
  { value: "Pharmacist", label: "Pharmacist" },
  { value: "Physiotherapist", label: "Physiotherapist" },
  { value: "Psychologist", label: "Psychologist" },
  { value: "Trade Marks Attorney", label: "Trade Marks Attorney" },
  { value: "Veterinary Surgeon", label: "Veterinary Surgeon" },
  { value: "Accountant", label: "Accountant" },
  {
    value: "Agent of the Australian Postal Corporation",
    label: "Agent of the Australian Postal Corporation",
  },
  { value: "APS Employee", label: "APS Employee with 5+ years of service" },
  {
    value: "Australian Consular Officer",
    label: "Australian Consular Officer or Diplomatic Officer",
  },
  { value: "Bailiff", label: "Bailiff" },
  { value: "Bank Officer", label: "Bank Officer with 5+ years of service" },
  {
    value: "Building Society Officer",
    label: "Building Society Officer with 5+ years of service",
  },
  {
    value: "CEO of a Commonwealth Court",
    label: "CEO of a Commonwealth Court",
  },
  { value: "Clerk of a Court", label: "Clerk of a Court" },
  {
    value: "Commissioner for Affidavits",
    label: "Commissioner for Affidavits",
  },
  {
    value: "Commissioner for Declarations",
    label: "Commissioner for Declarations",
  },
  {
    value: "Credit Union Officer",
    label: "Credit Union Officer with 5+ years of service",
  },
  {
    value: "Permanent Employee of a Commonwealth Authority",
    label:
      "Permanent Employee of a Commonwealth Authority with 5+ years of service",
  },
  {
    value: "Employee of the Australian Trade Commission",
    label: "Employee of the Australian Trade and Investment Commission",
  },
  {
    value: "Employee of the Commonwealth Abroad",
    label: "Employee of the Commonwealth abroad",
  },
  { value: "Engineer", label: "Engineer" },
  {
    value: "Finance Company Officer",
    label: "Finance Company Officer with 5+ years of service",
  },
  {
    value: "Holder of a Statutory Office",
    label: "Holder of a Statutory Office",
  },
  { value: "Judge", label: "Judge" },
  { value: "Justice of the Peace", label: "Justice of the Peace" },
  { value: "Magistrate", label: "Magistrate" },
  { value: "Marriage Celebrant", label: "Marriage Celebrant" },
  { value: "Master of a Court", label: "Master of a Court" },
  { value: "ADF Member", label: "Member of the Australian Defence Force" },
  {
    value: "Member of the Australasian Institute of Mining and Metallurgy",
    label: "Member of the Australasian Institute of Mining and Metallurgy",
  },
  {
    value: "Member of the Governance Institute",
    label: "Member of the Governance Institute of Australia Ltd",
  },
  {
    value: "Member of Parliament or Local Government",
    label: "Member of Parliament or a Local Government Authority",
  },
  { value: "Minister of Religion", label: "Minister of Religion" },
  { value: "Notary Public", label: "Notary Public" },
  {
    value: "Permanent Employee of Australia Post",
    label: "Permanent Employee of Australia Post with 5+ years of service",
  },
  {
    value: "Permanent Employee of a State or Territory",
    label:
      "Permanent Employee of a State or Territory with 5+ years of service",
  },
  {
    value: "Permanent Employee of Local Government",
    label: "Permanent Employee of Local Government with 5+ years of service",
  },
  {
    value: "Statutory Declaration Witness",
    label: "Person authorized to witness Statutory Declarations",
  },
  { value: "Police Officer", label: "Police Officer" },
  {
    value: "Registrar of a Court",
    label: "Registrar or Deputy Registrar of a Court",
  },
  {
    value: "Senior Executive of a Commonwealth Authority",
    label: "Senior Executive Employee of a Commonwealth Authority",
  },
  {
    value: "Senior Executive of a State or Territory",
    label: "Senior Executive Employee of a State or Territory",
  },
  { value: "SES Employee", label: "SES Employee of the Commonwealth" },
  { value: "Sheriff", label: "Sheriff" },
  { value: "Sheriff's Officer", label: "Sheriff's Officer" },
  { value: "Teacher", label: "Teacher (full-time or part-time)" },
];

const criteriaMap = {
  Accountant: (
    <div>
      Accountant who is either a:
      <ul>
        <li>fellow of the National Tax Accountants' Association, or</li>
        <li>
          member of any of the following:
          <ul>
            <li>Chartered Accountants Australia and New Zealand</li>
            <li>Association of Taxation and Management Accountants</li>
            <li>CPA Australia</li>
            <li>The Institute of Public Accountants.</li>
          </ul>
        </li>
      </ul>
    </div>
  ),
  "Agent of the Australian Postal Corporation": (
    <div>
      Agent of the Australian Postal Corporation (Australia Post) who is in
      charge of an office that provides postal services to the public.
    </div>
  ),
  "APS Employee": (
    <div>
      APS employee engaged on an ongoing basis with 5 or more years of
      continuous service who is not specified in another item of this part.
    </div>
  ),
  "Australian Consular Officer": (
    <div>
      Australian Consular Officer or Australian Diplomatic Officer (within the
      meaning of the Consular Fees Act 1955).
    </div>
  ),
  "Bank Officer": (
    <div>Bank officer with 5 or more years of continuous service.</div>
  ),
  "Building Society Officer": (
    <div>
      Building society officer with 5 or more years of continuous service.
    </div>
  ),
  "CEO of a Commonwealth Court": (
    <div>Chief executive officer of a Commonwealth court.</div>
  ),
  "Credit Union Officer": (
    <div>Credit union officer with 5 or more years of continuous service.</div>
  ),
  "Permanent Employee of a Commonwealth Authority": (
    <div>
      Permanent employee of a Commonwealth authority with 5 or more years of
      continuous service who is not specified in another item in this Part.
    </div>
  ),
  "Employee of the Australian Trade Commission": (
    <div>
      Employee of the Australian Trade and Investment Commission who is:
      <ul>
        <li>(a) in a country or place outside Australia; and</li>
        <li>
          (b) authorised under paragraph 3 (d) of the Consular Fees Act 1955;
          and
        </li>
        <li>(c) exercising the employee’s function at that place.</li>
      </ul>
    </div>
  ),
  "Employee of the Commonwealth Abroad": (
    <div>
      Employee of the Commonwealth who is:
      <ul>
        <li>(a) at a place outside Australia; and</li>
        <li>
          (b) authorised under paragraph 3 (c) of the Consular Fees Act 1955;
          and
        </li>
        <li>(c) exercising the employee’s function at that place.</li>
      </ul>
    </div>
  ),
  Engineer: (
    <div>
      Engineer who is either:
      <ul>
        <li>member of Engineers Australia but not a student; or</li>
        <li>
          a Registered Professional Engineer of Professionals Australia; or
        </li>
        <li>
          registered as an engineer under a law of the Commonwealth, a state or
          territory; or
        </li>
        <li>
          registered on the National Engineering Register by Engineers
          Australia.
        </li>
      </ul>
    </div>
  ),
  "Finance Company Officer": (
    <div>
      Finance company officer with 5 or more years of continuous service.
    </div>
  ),
  "Holder of a Statutory Office": (
    <div>
      Holder of a statutory office not specified in another item in this list.
    </div>
  ),
  "Marriage Celebrant": (
    <div>
      Marriage celebrant registered under Subdivision C of Division 1 of Part IV
      of the Marriage Act 1961.
    </div>
  ),
  "ADF Member": (
    <div>
      Member of the Australian Defence Force who is either:
      <ul>
        <li>an officer,</li>
        <li>
          a non-commissioned officer within the meaning of the Defence Force
          Discipline Act 1982 with 5 or more years of continuous service,
        </li>
        <li>a warrant officer within the meaning of that Act.</li>
      </ul>
    </div>
  ),
  "Member of the Governance Institute": (
    <div>Member of the Governance Institute of Australia Ltd.</div>
  ),
  "Member of Parliament or Local Government": (
    <div>
      Member of either:
      <ul>
        <li>the Parliament of the Commonwealth,</li>
        <li>the Parliament of a state,</li>
        <li>a territory legislature,</li>
        <li>a local government authority.</li>
      </ul>
    </div>
  ),
  "Minister of Religion": (
    <div>
      Minister of religion registered under Subdivision A of Division 1 of Part
      IV of the Marriage Act 1961.
    </div>
  ),
  "Notary Public": (
    <div>
      Notary public, including a notary public (however described) exercising
      functions at a place outside the:
      <ul>
        <li>Commonwealth,</li>
        <li>external territories of the Commonwealth.</li>
      </ul>
    </div>
  ),
  "Permanent Employee of Australia Post": (
    <div>
      Permanent employee of the Australian Postal Corporation with 5 or more
      years of continuous service who is employed in an office providing postal
      services to the public.
    </div>
  ),
  "Permanent Employee of a State or Territory": (
    <div>
      Permanent employee of a state or territory, or a state or territory
      authority, with 5 or more years of continuous service, other than such an
      employee who is specified in another item of this Part.
    </div>
  ),
  "Permanent Employee of Local Government": (
    <div>
      Permanent employee of a local government authority with 5 or more years of
      continuous service, other than such an employee who is specified in
      another item of this Part.
    </div>
  ),
  "Statutory Declaration Witness": (
    <div>
      Person before whom a statutory declaration may be made under the law of
      the state or territory in which the declaration is made.
    </div>
  ),
  "Registrar of a Court": (
    <div>Registrar, or Deputy Registrar, of a court.</div>
  ),
  Teacher: (
    <div>
      Teacher employed full-time or part-time at a school or tertiary education
      institution.
    </div>
  ),
};

const dummyStreams = [];

export {
  electorateList,
  loremIpsum,
  acceptableFormats,
  acceptableImageFormats,
  subOptions,
  dummyStreams,
  criteriaMap,
  occupations,
};
