import React, { useRef, useEffect, useState } from "react";

import Navbar from "../components/common-components/Navbar";
import ScreenContainer from "../components/common-components/ScreenContainer";
import { useWindowSize } from "../utils/Hooks";
import {
  countSubmissions,
  createPetitionSignature,
} from "../functions/AffidavitFunctions";
import {
  formatNumberWithCommas,
  checkTurnstile,
} from "../functions/GeneralFunctions";
import { validateName } from "../functions/GeneralFunctions";

import { Turnstile } from "@marsidev/react-turnstile";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import * as EmailValidator from "email-validator";

export default function Petition() {
  ////  INITS
  const windowSize = useWindowSize();
  const fNameRef = useRef();
  const lNameRef = useRef();
  const postcodeRef = useRef();
  const emailRef = useRef();
  const turnstileRef = useRef();
  const navigate = useNavigate();

  ////  STATES
  const [signatureCount, setSignatureCount] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState(null);
  const [success, setSuccess] = useState(false);

  ////  FUNCTIONS
  const handleSubmit = async () => {
    try {
      setBtnDisabled(true);
      const fNameValid = validateName(fNameRef.current.value);
      if (fNameValid === false) {
        alert("Invalid first name!");
        setBtnDisabled(false);
        return;
      }
      const lNameValid = validateName(lNameRef.current.value);
      if (lNameValid === false) {
        alert("Invalid last name!");
        setBtnDisabled(false);
        return;
      }
      const pc = postcodeRef.current.value;
      if (isNaN(parseInt(pc))) {
        alert("Invalid postcode!");
        setBtnDisabled(false);
        return;
      } else {
        if (pc.length < 3 || pc.length > 4) {
          alert("Invalid postcode!");
          setBtnDisabled(false);
          return;
        }
      }
      const trimmedEmail = emailRef.current.value.trim();
      const emailValid = EmailValidator.validate(trimmedEmail);
      if (!emailValid) {
        alert("Please enter a valid email address.");
        setBtnDisabled(false);
        return;
      }
      if (!turnstileToken) {
        alert("Please complete the Cloudflare verification box.");
        setBtnDisabled(false);
        return;
      }
      const isTokenValid = await checkTurnstile(turnstileToken);
      if (isTokenValid?.data?.success) {
        await createPetitionSignature({
          fName: fNameRef.current.value,
          lName: lNameRef.current.value,
          email: trimmedEmail,
          postcode: pc,
        });
        setSuccess(true);
      } else {
        alert("Error verifying Cloudflare component. Please try again.");
        handleExpire();
      }
    } catch (err) {
      console.log(err);
      alert(err.message);
      setBtnDisabled(false);
      handleExpire();
    }
  };

  const handleError = () => {
    alert("Cloudflare Turnstile error! Resetting ...");
    handleExpire();
  };

  const handleExpire = () => {
    try {
      turnstileRef.current?.reset();
    } catch (err) {
      console.log(err);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    const init = async () => {
      try {
        const res = await countSubmissions();
        if (res?.data?.signatures) {
          setSignatureCount(res.data.signatures);
        }
      } catch (err) {
        console.log(err);
      }
    };
    init();
  }, []);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        navigate("/declarations");
      }, 2000);
    }
  }, [success]);

  return (
    <ScreenContainer>
      <Navbar />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h4 style={{ opacity: 0.5, marginBottom: 4 }}>PETITION</h4>
        <h2 style={{ textAlign: "center" }}>
          Call a COVID-19 Royal Commission!
        </h2>
        <div
          style={{
            border: "2px solid black",
            borderRadius: 30,
            backgroundColor: "limegreen",
            padding: 10,
            boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
          }}
        >
          {signatureCount ? (
            <h2>{formatNumberWithCommas(signatureCount)} signatures</h2>
          ) : (
            <CircularProgress />
          )}
        </div>
        <br></br>
        <img
          src="https://storage.googleapis.com/bucket-775/convoy.jpeg"
          style={{ width: "100vw", maxWidth: 768 }}
        />
        <div
          style={{
            width: "100vw",
            maxWidth: 768,
            padding: 8,
            backgroundColor: "whitesmoke",
            boxSizing: "border-box",
          }}
        >
          <p>
            <b>
              Australia's response to the Covid-19 pandemic consisted of the
              harshest crackdown on civil liberties in living memory.
            </b>{" "}
            State and territory leaders enforced public health orders that
            suspended our freedom to work, travel, socialise, and even make
            informed decisions regarding our own health.
          </p>
          <p>
            Never before in the history of our nation has so much power been
            concentrated in the hands of so few people. For this reason alone,{" "}
            <b>
              the public deserves a full and transparent inquiry into the
              decisions made and actions taken during the pandemic.
            </b>
          </p>
          <p>
            Moreover, many experts have argued that certain elements of
            Australia's pandemic response were not scientifically justified —{" "}
            <b>
              discriminating against people on the basis of their vaccination
              status, for example, even after the vaccines proved ineffective at
              preventing infection.
            </b>{" "}
            Given the controversy surrounding the utility of these measures, and
            the extreme extent to which they infringed upon rights that would
            ordinarily be considered inalienable, the public is entitled to an
            explanation as to why our political leaders felt they were warranted
            under the circumstances.{" "}
            <b>
              We deserve to know who proposed these measures, what they were
              intended to achieve, and whether they ultimately succeeded in
              their objective.
            </b>
          </p>
          <p>
            To ensure that this inquiry is truly fair and independent, it must
            be conducted by individuals who have no ties to government and the
            pharmaceutical industry. It must also be preceded by a period of
            public consultation that enables interested parties to have their
            say on the terms of reference.
          </p>
          <p>
            Accountability is an indispensable feature of our system of
            government. In the interest of ensuring that the actions of our
            elected officials receive the appropriate level of scrutiny,{" "}
            <b>
              we call on Anthony Albanese to initiate a Royal Commission into
              Australia's handling of the Covid-19 pandemic.
            </b>
          </p>
          <br></br>
          <hr></hr>
          <h3 style={{ textAlign: "center" }}>Make your voice heard!</h3>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
            }}
          >
            <TextField
              sx={{ width: "48%", maxWidth: 300 }}
              placeholder="First name"
              inputRef={fNameRef}
              inputProps={{ style: { textAlign: "center" } }}
            />
            <TextField
              sx={{ width: "48%", maxWidth: 300 }}
              placeholder="Last name"
              inputRef={lNameRef}
              inputProps={{ style: { textAlign: "center" } }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-evenly",
              marginTop: 5,
            }}
          >
            <TextField
              sx={{ width: "48%", maxWidth: 300 }}
              placeholder="Postcode"
              inputProps={{ style: { textAlign: "center" } }}
              inputRef={postcodeRef}
            />
            <TextField
              sx={{ width: "48%", maxWidth: 300 }}
              placeholder="Email"
              inputProps={{ style: { textAlign: "center" } }}
              inputRef={emailRef}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              margin: "10px 0px 5px 0px",
            }}
          >
            <Turnstile
              siteKey={process.env.REACT_APP_CF_PRODUCTION_PUBLIC}
              ref={turnstileRef}
              onSuccess={setTurnstileToken}
              onError={handleError}
              onExpire={handleExpire}
            />
          </div>
          <hr></hr>
          <br></br>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            {success ? (
              <h4 style={{ textAlign: "center" }}>
                Success! Your signature has been counted.
              </h4>
            ) : (
              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={btnDisabled}
              >
                SUBMIT SIGNATURE!
              </Button>
            )}
          </div>
        </div>
      </div>
    </ScreenContainer>
  );
}
