import React from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";

import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import "../App.css";

export default function FlyerSuccess() {
  ////  INITS
  const navigate = useNavigate();

  return (
    <ScreenContainer>
      <Navbar />
      <div
        style={{
          width: "100vw",
          maxWidth: 768,
          backgroundColor: "whitesmoke",
          borderRadius: 4,
          border: "1px solid black",
          padding: 10,
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h2>Success!</h2>
        <p style={{ maxWidth: 650, textAlign: "center" }}>
          <span style={{ fontWeight: "bold" }}>Keep an eye on your inbox.</span>{" "}
          We'll send you an email next Wednesday with a link to the campaign
          launch webinar.{" "}
          <span style={{ fontWeight: "bold" }}>
            We look forward to seeing you there!
          </span>
        </p>
        <br></br>
        <div
          style={{
            width: "100%",
            backgroundColor: "#0f2027",
            padding: 10,
            boxSizing: "border-box",
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          className="dark-container"
        >
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <img
              src="https://storage.googleapis.com/bucket-775/navbar_logo_transparent.png"
              alt="Navbar Logo"
              style={{
                width: "100%",
                maxWidth: 350,
                height: "auto",
              }}
            />
          </div>
          <p style={{ textAlign: "center", maxWidth: 600 }}>
            You're on <span style={{ fontWeight: "bold" }}>PhaseChamber,</span>{" "}
            the first social media platform that is 100% Australian owned and
            operated. Unlike other platforms, we're committed to upholding your{" "}
            <span style={{ fontWeight: "bold" }}>freedom of expression.</span>
          </p>
          <p style={{ textAlign: "center", maxWidth: 600 }}>
            We're currently in the middle of a staged rollout. The next feature
            to come online is our in-house video conferencing software, which is
            on track to be ready by{" "}
            <span style={{ fontWeight: "bold" }}>Saturday September 21.</span>
          </p>
          <p style={{ textAlign: "center", maxWidth: 600 }}>
            If you'd like to watch and participate in video conferences hosted
            by freedom fighters all over Australia, click the button below to
            create a <span style={{ fontWeight: "bold" }}>free account!</span>
          </p>
          <br></br>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              navigate("/register");
            }}
          >
            SIGN UP FOR FREE!
          </Button>
          <br></br>
          <Button
            variant="outlined"
            onClick={() => {
              navigate("/home");
            }}
          >
            CHECK OUT THE HOMEPAGE
          </Button>
        </div>
      </div>
    </ScreenContainer>
  );
}
