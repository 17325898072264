////  REVISIT: rendering of error messages on screen
import React, { useEffect, useState, useContext } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import DynamicFrame from "../components/common-components/DynamicFrame";
import Countdown from "../components/common-components/Countdown";
import SocketCtxtProvider from "../components/conference-components/SocketCtxtProvider";
import QueueCtxtProvider from "../components/conference-components/QueueCtxtProvider";
import ConferenceSidebar from "../components/conference-components/ConferenceSidebar";
import { useWindowSize } from "../utils/Hooks";
import {
  getDailyRoom,
  getMeetingToken,
  getMeetingTokenVisitor,
} from "../functions/ConferenceFunctions";

import { useParams, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Daily from "@daily-co/daily-js";
import { DailyProvider } from "@daily-co/daily-react";
import CircularProgress from "@mui/material/CircularProgress";

import "../App.css";

import { GlobalContext } from "../App";

export default function LiveCall() {
  ////  INITS
  const windowSize = useWindowSize();
  const GlobalCtxt = useContext(GlobalContext);
  const params = useParams();
  const navigate = useNavigate();

  ////  STATES
  const [roomData, setRoomData] = useState(null);
  const [meetingToken, setMeetingToken] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [dailyCall, setDailyCall] = useState(null);
  const [callActive, setCallActive] = useState(false);
  const [callFinished, setCallFinished] = useState(false);
  const [accessError, setAccessError] = useState(null);

  ////  FUNCTIONS
  const initRoom = async () => {
    try {
      const room = await getDailyRoom(params.roomName);
      if (room?.data) {
        setRoomData(room.data);
      }
    } catch (err) {
      console.log(err);
      setAccessError(
        "Unable to fetch the room. Refresh the page to try again."
      );
    }
  };

  const fetchToken = async () => {
    try {
      let tkn;
      if (
        params.code ||
        (params.callType === "witnessing" &&
          !localStorage.getItem("electorate"))
      ) {
        tkn = await getMeetingTokenVisitor(params.roomName, params.code);
      } else {
        tkn = await getMeetingToken(params.roomName);
      }
      if (tkn?.data) {
        const { is_owner, token, start_time } = tkn.data;
        setMeetingToken(token.token);
        setIsOwner(is_owner);
        setStartTime(start_time);
      } else {
        setAccessError("Your access token is invalid or expired.");
      }
    } catch (err) {
      console.log(err);
      setAccessError("Your access token is invalid or expired.");
    }
  };

  const initFrame = () => {
    try {
      const call = Daily.createFrame({
        iframeStyle: {
          height: "100%",
          width: "100%",
          position: "relative",
        },
        showLeaveButton: true,
        showFullscreenButton: true,
      });
      setDailyCall(call);
    } catch (err) {
      console.log(err);
      setAccessError(
        "Unable to initialise the call. Please refresh the page to try again."
      );
    }
  };

  const startCall = () => {
    try {
      setCallActive(true);
    } catch (err) {
      console.log(err);
      setAccessError(
        "Unable to initialise the call. Please refresh the page to try again."
      );
    }
  };

  ////  EFFECTS
  useEffect(() => {
    initRoom();
  }, []);

  useEffect(() => {
    if (roomData) {
      fetchToken();
    }
  }, [roomData]);

  useEffect(() => {
    if (callActive) {
      initFrame();
    }
  }, [callActive]);

  useEffect(() => {
    try {
      // REVISIT: more conditionals?
      if (dailyCall) {
        document
          .getElementById("call-container")
          .appendChild(dailyCall.iframe());

        setTimeout(() => {
          dailyCall.join({ url: roomData.url, token: meetingToken });
        }, 1000);

        dailyCall.on("left-meeting", () => {
          if (windowSize.width <= 768) {
            setTimeout(() => {
              setCallFinished(true);
            }, 3000);
          }
        });
        // REVISIT: cleanup function?
      }
    } catch (err) {
      console.log(err);
      setAccessError(
        "Unable to join call. Please refresh the page to try again."
      );
    }
  }, [dailyCall]);

  useEffect(() => {
    if (callFinished) {
      setCallActive(false);
      setMeetingToken(null);
    }
  }, [callFinished]);

  return (
    <ScreenContainer styling={{ paddingTop: "5px", maxWidth: "100vw" }}>
      <DailyProvider callObject={dailyCall}>
        <DynamicFrame
          windowSize={windowSize}
          styling={{ maxWidth: "99vw", maxHeight: "99vh" }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              backgroundColor: "rgba(0,0,0,0.5)",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              padding: 8,
              boxSizing: "border-box",
            }}
          >
            {callActive ? (
              <div
                id="call-container"
                style={{ height: "100%", width: "100%" }}
              />
            ) : (
              <>
                {meetingToken ? (
                  <>
                    {startTime > Date.now() && !isOwner ? (
                      <>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <p style={{ marginRight: 5, color: "lightgrey" }}>
                            This room opens in
                          </p>
                          <Countdown
                            deadline={startTime}
                            styling={{ color: "lightgrey" }}
                          />
                        </div>
                      </>
                    ) : (
                      <div className="animated-border">
                        <Button variant="text" onClick={startCall}>
                          JOIN CALL
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {accessError ? (
                      <p style={{ color: "white" }}>{accessError}</p>
                    ) : null}
                    {windowSize.width <= 768 && callFinished ? (
                      <Button
                        variant="contained"
                        onClick={() => {
                          navigate(`/home/${GlobalCtxt.nationalId}/National`);
                        }}
                      >
                        HOME
                      </Button>
                    ) : (
                      <CircularProgress />
                    )}
                  </>
                )}
              </>
            )}
          </div>
          {windowSize.width > 768 ? (
            <>
              {params.callType === "broadcast" ||
              params.callType === "webinar" ? (
                <SocketCtxtProvider
                  internal={true}
                  isAdmin={isOwner}
                  callType={params.callType}
                />
              ) : params.callType === "witnessing" && isOwner ? (
                <QueueCtxtProvider isAdmin={true}>
                  <ConferenceSidebar
                    isAdmin={isOwner}
                    callType="witnessing"
                  ></ConferenceSidebar>
                </QueueCtxtProvider>
              ) : (
                <ConferenceSidebar
                  isAdmin={isOwner}
                  callType={params.callType}
                />
              )}
            </>
          ) : null}
        </DynamicFrame>
      </DailyProvider>
    </ScreenContainer>
  );
}
