import React from "react";

const defaultStyling = {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  padding: 10,
  boxSizing: "border-box",
  height: "100%",
  maxWidth: "100vw",
  overflowY: "auto",
};

export default function GenericMain({ styling, children }) {
  return <div style={{ ...defaultStyling, ...styling }}>{children}</div>;
}
