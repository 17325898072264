import React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function Div1Sidebar({ bg }) {
  return (
    <div style={{ height: "100%", width: "100%", overflowY: "auto" }}>
      <h3 style={{ color: "white", textAlign: "center" }}>FAQ</h3>
      <Accordion
        sx={{ backgroundColor: "rgba(0,0,0,0.5)", marginBottom: "5px" }}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon sx={{ color: "white" }} />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ color: "white" }}
        >
          What is a statutory declaration?
        </AccordionSummary>
        <AccordionDetails sx={{ color: "lightgrey", fontWeight: 300 }}>
          A statutory declaration is a written statement that someone makes
          under oath. Once signed and witnessed, the declaration becomes an
          official legal document that can be used in various settings,
          including in court.
          <br></br>
          <br></br>By signing your declaration, you are swearing that what
          you've written is true and correct to the best of your knowledge. You
          do not necessarily need to provide evidence in support of your
          statements — but the more evidence you provide, the stronger your
          declaration will be.<br></br>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ backgroundColor: "rgba(0,0,0,0.5)", marginBottom: "5px" }}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon sx={{ color: "white" }} />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ color: "white" }}
        >
          What's the point of creating a statutory declaration?
        </AccordionSummary>
        <AccordionDetails sx={{ color: "lightgrey", fontWeight: 300 }}>
          Statutory declarations are made under oath, and therefore carry much
          more weight than other types of statements. By presenting your
          testimony in the form of a declaration, you elevate its standing in
          the eyes of the law. This makes your story much harder to dismiss.
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ backgroundColor: "rgba(0,0,0,0.5)", marginBottom: "5px" }}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon sx={{ color: "white" }} />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ color: "white" }}
        >
          What can I write in my Declaration?
        </AccordionSummary>
        <AccordionDetails sx={{ color: "lightgrey", fontWeight: 300 }}>
          Anything, as long as you sincerely believe it to be true.
          <br></br>
          <br></br>The purpose of a statutory declaration is to record your
          experience to the best of your knowledge and understanding. You do not
          need to prove with absolute certainty that your statements are
          factually correct. All that matters is that you're telling the truth
          about what you've experienced and observed.
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ backgroundColor: "rgba(0,0,0,0.5)", marginBottom: "5px" }}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon sx={{ color: "white" }} />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ color: "white" }}
        >
          I was harmed by government policies but don't have hard evidence to
          prove it. Can I still create a Declaration?
        </AccordionSummary>
        <AccordionDetails sx={{ color: "lightgrey", fontWeight: 300 }}>
          Absolutely. Providing evidence is recommended where possible, but not
          compulsory. Users are encouraged to address all the ways in which they
          were affected, even where evidence isn't readily available.<br></br>
          <br></br>Harm comes in various forms, some of which are not always
          observable. Psychological trauma is one example of harm that is very
          real and yet not neccessarily measurable.<br></br>
          <br></br>If it's true, you can write about it.
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ backgroundColor: "rgba(0,0,0,0.5)", marginBottom: "5px" }}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon sx={{ color: "white" }} />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ color: "white" }}
        >
          My evidence is anecdotal. Can I include it?
        </AccordionSummary>
        <AccordionDetails sx={{ color: "lightgrey", fontWeight: 300 }}>
          Yes, as long as you accurately represent what happened. When including
          anecdotal evidence (i.e. second-hand information, general
          observations, etc.), make sure not to embellish the details. Honesty
          is the best policy.
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ backgroundColor: "rgba(0,0,0,0.5)", marginBottom: "5px" }}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon sx={{ color: "white" }} />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ color: "white" }}
        >
          Can creating a Declaration get me in trouble?
        </AccordionSummary>
        <AccordionDetails sx={{ color: "lightgrey", fontWeight: 300 }}>
          No, as long as you do not make any statements that are deliberately
          false and deceptive.
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ backgroundColor: "rgba(0,0,0,0.5)", marginBottom: "5px" }}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon sx={{ color: "white" }} />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ color: "white" }}
        >
          What will happen if I lie on my Declaration?
        </AccordionSummary>
        <AccordionDetails sx={{ color: "lightgrey", fontWeight: 300 }}>
          If you deliberately lie on your statutory declaration, you could be
          charged with perjury if the declaration is later used in a legal
          proceeding. Perjury is a crime that occurs when someone makes a false
          statement under oath, knowing it is false, with the intent to mislead.
          <br></br>
          <br></br>Perjury only applies to statements that are intentionally
          false and deceptive. It does not apply to statements of belief,
          sincere but unprovable claims, or honest mistakes.<br></br>
          <br></br>Please note that this website simply generates the
          declaration; it does not itself initiate a legal proceeding. Perjury
          only becomes a concern if the declaration is submitted in a legal
          context (such as a court case), where the false statement could affect
          the outcome.
          <br></br>
          <br></br>As always, honesty is crucial. When in doubt, we encourage
          you to frame your statements as beliefs or experiences rather than as
          objective facts. This will minimize any risk of future legal issues if
          the declaration is used in a formal proceeding.
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ backgroundColor: "rgba(0,0,0,0.5)", marginBottom: "5px" }}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon sx={{ color: "white" }} />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ color: "white" }}
        >
          Legal documents make me nervous. Can I just submit my story in the
          form of a letter or social media post instead?
        </AccordionSummary>
        <AccordionDetails sx={{ color: "lightgrey", fontWeight: 300 }}>
          A statutory declaration is a big step up from a letter or social media
          post. It is a powerful document in the eyes of the law. When you sign
          your declaration in the presence of an authorised witness, your
          testimony becomes a form of evidence that can be used in court.
          <br></br>
          <br></br>While you are free to tell your story however you want, we
          strongly encourage you to consider creating a declaration. Your
          testimony will be a lot stronger if submitted in this way.
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
