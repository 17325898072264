//  REVISIT: finish formatting when Stream is ready for comparison
//  probably necessary to give the AccessRequest its own component

import React, { useContext, useState } from "react";

import GenericTextField from "../common-components/GenericTextField";
import AccessRequest from "./AccessRequest";
import { timeSince, confirmUser } from "../../functions/GeneralFunctions";

import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";

import { socket } from "../../socket";
import { SocketContext } from "./SocketCtxtProvider";

import "../../App.css";

export default function LiveLobby({ isAdmin }) {
  ////  INITS
  const SocketCtxt = useContext(SocketContext);
  const params = useParams();

  ////  STATES
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [requestSubmitted, setRequestSubmitted] = useState(false);

  ////  FUNCTIONS
  const submitRequest = async (text) => {
    try {
      setBtnDisabled(true);
      const user = await confirmUser();
      socket.emit("request-access", {
        user: user.data,
        text,
        room: SocketCtxt.room,
      });
      setTimeout(() => {
        setBtnDisabled(false);
        setRequestSubmitted(true);
      }, 3000);
    } catch (err) {
      console.log(err);
      alert(
        "We could not verify your credentials. Please log back in to try again."
      );
      setBtnDisabled(false);
    }
  };

  const sendInvitation = (code, user) => {
    try {
      socket.emit("send-access-code", {
        code,
        user,
        room: SocketCtxt.room,
        channelId: params.channelId,
        channelName: decodeURIComponent(params.channelName),
        roomName: params.roomName,
      });
    } catch (err) {
      console.log(err);
      alert("Invitation send failed. Please try again later.");
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        overflowY: "auto",
      }}
      className="dark-container"
    >
      {isAdmin || requestSubmitted ? (
        <>
          <p>Access requests:</p>
          {SocketCtxt.accessRequests && Array.isArray(SocketCtxt.accessRequests)
            ? SocketCtxt.accessRequests.map((el, i) => {
                return (
                  <AccessRequest
                    data={el}
                    key={`${i}-${el.time}`}
                    isAdmin={isAdmin}
                    sendInvitation={sendInvitation}
                  />
                );
              })
            : null}
        </>
      ) : (
        <>
          <p style={{ textAlign: "center", color: "limegreen" }}>
            Got something you want to say? Click the button below to request an
            invitation to speak live on air!
          </p>
          <GenericTextField
            divStyling={{ backgroundColor: "whitesmoke" }}
            rows={4}
            placeholder={`What do you want to talk about?`}
            handleSubmit={submitRequest}
            charLimit={300}
            disabled={btnDisabled}
          />
        </>
      )}
    </div>
  );
}
