import React from "react";

import { replaceAndSanitize } from "../../functions/GeneralFunctions";

import { useWindowSize } from "../../utils/Hooks";

export default function CoverImage({ url, styling, channelName }) {
  const windowSize = useWindowSize();

  const defaultStyling = {
    width: "100%",
    minHeight: "20vh",
    backgroundImage: !url
      ? "url('https://storage.googleapis.com/bucket-775/canva_default_cover_image.png')"
      : `url('${url}')`,
    backgroundSize: "cover", // Ensures the image covers the container
    backgroundPosition: "center", // Centers the image in the div
    backgroundRepeat: "no-repeat", // Prevents the image from repeating
    position: "relative",
  };

  return (
    <div style={{ ...defaultStyling, ...styling }}>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          background:
            "linear-gradient(to right, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.8), rgba(255,255,255,0.7), rgba(255,255,255,0.6), rgba(255,255,255,0)",
          //borderRadius: "8px",
          //boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
          padding:
            windowSize.width > 768 ? "0px 100px 0px 10px" : "0px 5px 0px 10px",
          minWidth: 350,
        }}
      >
        <h2>{replaceAndSanitize(channelName)}</h2>
      </div>
    </div>
  );
}
