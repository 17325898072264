import React, { useState, useEffect, useContext, useRef } from "react";

import Dropzone from "react-dropzone";
import UploadIcon from "@mui/icons-material/Upload";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "@mui/material/Button";
import { Turnstile } from "@marsidev/react-turnstile";

import { acceptableFormats } from "../../utils/Data";
import { checkTurnstile } from "../../functions/GeneralFunctions";
import { AffidavitContext } from "../../screens/AffidavitFlow";

const skinnyText = {
  fontSize: 12,
  marginTop: 2,
  marginBottom: 2,
};

export default function AffidavitDiv4({
  windowSize,
  updateCurrentDiv,
  disabled,
}) {
  ////  INITS
  const AffidavitCtxt = useContext(AffidavitContext);
  const turnstileRef = useRef();

  ////  STATES
  const [attachments, setAttachments] = useState([]);
  const [turnstileToken, setTurnstileToken] = useState(null);
  const [canProceed, setCanProceed] = useState(false);
  const [turnstileResets, setTurnstileResets] = useState(0);
  const [buttonClicked, setButtonClicked] = useState(false);

  ////  FUNCTIONS
  const handleSubmit = async () => {
    try {
      if (Array.isArray(attachments) && attachments.length > 0) {
        for (var el of attachments) {
          if (acceptableFormats.includes(el.type) === false) {
            alert(
              "One or more of your attachments has an invalid file type. You can remove attachments by clicking the cross icon next to the file name."
            );
            return;
          }
          if (el.size > 5000000) {
            alert(
              "One or more of your attachments exceeds 5MB. You can remove attachments by clicking the cross icon next to the file name."
            );
            return;
          }
        }
        await AffidavitCtxt.updateData({
          attachments: attachments,
        });
      } else {
        await AffidavitCtxt.updateData({
          attachments: null,
        });
      }
      updateCurrentDiv(4);
      setTimeout(() => {
        setButtonClicked(true);
      }, 2000);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpload = (files) => {
    try {
      setAttachments([...attachments, ...files]);
    } catch (err) {
      console.log(err);
    }
  };

  const removeFile = (fileName) => {
    try {
      let newArr = [...attachments];
      const index = newArr.findIndex((obj) => obj.name === fileName);
      if (index !== -1) {
        newArr.splice(index, 1);
      }
      setAttachments(newArr);
    } catch (err) {
      console.log(err);
    }
  };

  const truncateText = (txt) => {
    //setModifiedText(txt.slice(0, charLimit) + " ...");
    return txt.slice(0, 30) + "...";
  };

  const handleExpire = () => {
    try {
      if (turnstileResets < 10) {
        turnstileRef.current?.reset();
        setTurnstileResets((prev) => prev + 1);
      } else {
        alert(
          "Error verifying Cloudflare component. If this issue persists, please refresh the page to try again."
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const validateToken = async () => {
    try {
      const check = await checkTurnstile(turnstileToken);
      if (check?.data?.success) {
        setCanProceed(true);
      } else {
        handleExpire();
      }
    } catch (err) {
      console.log(err);
      handleExpire();
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (turnstileToken) {
      validateToken();
    }
  }, [turnstileToken]);

  return (
    <div
      style={{
        height: `calc(${windowSize.height}px - env(safe-area-inset-bottom))`,
        maxHeight: "75vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
        overflowY: "auto",
      }}
    >
      <h4 style={{ textAlign: "center" }}>Attach documentation (optional)</h4>
      <p style={{ textAlign: "center", marginLeft: 4, marginRight: 4 }}>
        Feel free to upload any documents that support your evidence, i.e.
        photos, doctor's notes, scan results, termination letters, etc.
      </p>
      <p
        style={{
          textAlign: "center",
          marginLeft: 4,
          marginRight: 4,
          fontSize: 13,
          opacity: 0.5,
        }}
      >
        (Max 10x attachments per Declaration. Max 5MB per file. Accepted file
        types: JPEG, JPG, PNG, SVG, WEBP, PDF.)
      </p>
      <div>
        <Dropzone
          onDrop={(acceptedFiles) => {
            handleUpload(acceptedFiles);
          }}
          disabled={disabled}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div
                {...getRootProps()}
                style={{
                  border: "1px solid black",
                  cursor: "pointer",
                  padding: 16,
                  display: "flex",
                  flexDirection: "column",
                  minWidth: 315,
                  alignItems: "center",
                  backgroundColor: "rgba(0,0,0,0.5)",
                  boxSizing: "border-box",
                }}
              >
                <UploadIcon fontSize="large" sx={{ color: "white" }} />
                <input {...getInputProps()} />
                <p style={{ color: "lightgrey", fontSize: 14 }}>
                  Drag and drop files, or click to select
                </p>
              </div>
            </section>
          )}
        </Dropzone>
        <div
          style={{
            width: 315,
            maxHeight: "15vh",
            overflowY: "auto",
            backgroundColor: "whitesmoke",
            border: "1px solid black",
            boxSizing: "border-box",
            padding: 2,
          }}
        >
          <p style={{ ...skinnyText, fontWeight: "bold" }}>
            Attachments: {attachments.length}
          </p>
          {attachments.map((el, i) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor:
                    acceptableFormats.includes(el.type) && el.size < 5000000
                      ? null
                      : "pink",
                }}
                key={el.size}
              >
                <p style={skinnyText}>{truncateText(el.name)}</p>
                <IconButton
                  onClick={() => {
                    removeFile(el.name);
                  }}
                  disabled={disabled}
                >
                  <CancelIcon fontSize="small" sx={{ color: "darkgrey" }} />
                </IconButton>
              </div>
            );
          })}
        </div>
      </div>
      <Turnstile
        ref={turnstileRef}
        siteKey={process.env.REACT_APP_CF_PRODUCTION_PUBLIC}
        onSuccess={setTurnstileToken}
        onError={handleExpire}
        onExpire={handleExpire}
      />

      {buttonClicked ? (
        <p style={{ textAlign: "center", fontSize: 14, opacity: 0.75 }}>
          Scroll down to continue.
        </p>
      ) : (
        <Button
          variant="contained"
          onClick={() => {
            if (AffidavitCtxt?.data?.textInput) {
              handleSubmit();
            } else {
              alert("Please enter your testimony in the window above.");
            }
          }}
          disabled={canProceed ? false : true}
        >
          CREATE DECLARATION DRAFT!
        </Button>
      )}
    </div>
  );
}
