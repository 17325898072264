import React, { useState } from "react";

import PrintIcon from "@mui/icons-material/Print";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import HandshakeIcon from "@mui/icons-material/Handshake";
import { useNavigate } from "react-router-dom";

const hoverStyle = {
  transform: "scale(1.05)",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
};

const iconContainerStyle = {
  width: 120,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: 10,
  backgroundColor: "white",
};

const textContainerStyle = {
  display: "flex",
  flex: 1,
  alignItems: "center",
  backgroundColor: "#525854",
  color: "white",
  padding: 10,
  boxSizing: "border-box",
};

const headingStyle = {
  margin: "10px 0px",
};

const containerStyle = {
  width: "96vw",
  maxWidth: 600,
  display: "flex",
  border: "1px solid black",
  margin: "10px 0px 5px 0px",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  cursor: "pointer",
};

export default function FlyerDiv3() {
  ////  INITS
  const navigate = useNavigate();

  ////  STATES
  const [hoveredBuy, setHoveredBuy] = useState(false);
  const [hoveredDIY, setHoveredDIY] = useState(false);
  const [hoveredCash, setHoveredCash] = useState(false);

  return (
    <>
      <h2>Get the flyers!</h2>
      <h4 style={{ textAlign: "center" }}>
        There are three ways that you can access the flyers:
      </h4>
      <div
        style={{ ...containerStyle, ...(hoveredDIY ? hoverStyle : {}) }}
        onMouseEnter={() => setHoveredDIY(true)}
        onMouseLeave={() => setHoveredDIY(false)}
        onClick={() => {
          window.open(
            "https://storage.googleapis.com/bucket-775/flyer_2x.pdf",
            "_blank"
          );
        }}
      >
        <div style={iconContainerStyle}>
          <h4 style={headingStyle}>DIY</h4>
          <PrintIcon fontSize="large" />
        </div>
        <div style={textContainerStyle}>
          <p>Download the artwork and print it yourself.</p>
        </div>
      </div>
      <div
        style={{ ...containerStyle, ...(hoveredBuy ? hoverStyle : {}) }}
        onMouseEnter={() => setHoveredBuy(true)}
        onMouseLeave={() => setHoveredBuy(false)}
        onClick={() => {
          window.open(
            "https://winstonsmithinitiative.store/collections/all",
            "_blank",
            "noopener,noreferrer"
          );
        }}
      >
        <div style={iconContainerStyle}>
          <h4 style={headingStyle}>BUY ONLINE</h4>
          <LocalShippingIcon fontSize="large" />
        </div>
        <div style={textContainerStyle}>
          <p>
            Purchase by the bundle and get them shipped straight to your door.
          </p>
        </div>
      </div>

      <div
        style={{ ...containerStyle, ...(hoveredCash ? hoverStyle : {}) }}
        onMouseEnter={() => setHoveredCash(true)}
        onMouseLeave={() => setHoveredCash(false)}
        onClick={() => {
          navigate("/campaign");
        }}
      >
        <div style={iconContainerStyle}>
          <h4 style={headingStyle}>BUY IN CASH</h4>
          <HandshakeIcon fontSize="large" />
        </div>
        <div style={textContainerStyle}>
          <p>
            Purchase in person from your community distributor{" "}
            <span style={{ fontWeight: "bold" }}>(LIMITED AVAILABILITY).</span>
          </p>
        </div>
      </div>
      <br></br>
      <h4 style={{ textAlign: "center" }}>Thank you for joining the fight!</h4>
    </>
  );
}
