import React, { useState } from "react";

import PaidIcon from "@mui/icons-material/Paid";
import Button from "@mui/material/Button";

import "animate.css";
import "../../App.css";

export default function TipButton({ tipWho, handleClick }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={isHovered ? "animated-border" : ""}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Button
        variant={isHovered ? "text" : "contained"}
        startIcon={isHovered ? null : <PaidIcon />}
        className="custom-button"
        onClick={handleClick}
      >
        {`Tip ${tipWho}`}
      </Button>
    </div>
  );
}
