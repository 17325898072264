import React, { useContext } from "react";

import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";

export default function Div5Sidebar({ windowSize, listItems, recreateDraft }) {
  //const AffidavitCtxt = useContext(AffidavitContext);

  return (
    <>
      <h3
        style={{
          textAlign: "center",
          color: "white",
          fontWeight: 600,
        }}
      >
        READ ME!
      </h3>
      {listItems && Array.isArray(listItems) && listItems.length > 1 ? (
        <p style={{ color: "white", fontSize: 14 }}>
          We've created two separate drafts based on your testimony. The first
          is quite conservative, while the second has been edited more
          extensively. You can toggle between them by clicking the 'DRAFT #1'
          and 'DRAFT #2' tabs. You may proceed with whichever draft you prefer.
        </p>
      ) : (
        <p></p>
      )}
      <hr style={{ width: "100%" }}></hr>
      <p style={{ color: "lightgrey", lineHeight: 2, fontSize: 15 }}>
        If you want to make further changes, you can activate editing mode by
        clicking the <EditIcon /> button on the right of each paragraph. This
        will reveal two additional buttons: <DeleteIcon />, which removes the
        entire paragraph, and <SaveAsIcon />, which saves any changes you've
        made. There is also a <AddCircleIcon /> button below the final paragraph
        that enables you to add another paragraph if you wish.
      </p>
      <hr style={{ width: "100%" }}></hr>
      <p
        style={{
          color: "white",
          fontSize: 14,
          textAlign: "center",
          fontWeight: 600,
        }}
      >
        When you're happy with the draft, click the green 'SUBMIT DRAFT' button
        to finish the process!
      </p>
      <hr style={{ width: "100%" }}></hr>
      <p
        style={{
          color: "lightgrey",
          fontSize: 14,
          textAlign: "center",
          fontWeight: 600,
        }}
      >
        Winnie doesn't always get it right on the first go. If you're not happy
        with either draft, you can create two more by clicking the button below.
      </p>
      <Button
        onClick={recreateDraft}
        variant="contained"
        color="info"
        sx={{ width: "100%" }}
      >
        Recreate drafts
      </Button>
    </>
  );
}
