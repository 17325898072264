import { io } from "socket.io-client";

let socket; // Declare the socket variable

// Dummy conditional for now ...
if (1 === 1) {
  // Only initialize the socket if the user is a paying member
  socket = io(process.env.REACT_APP_STREAMING_SERVER, {
    transports: ["websocket"],
  });

  // Optionally, handle connection events
  socket.on("connect", () => {
    console.log("WebSocket connected:", socket.id);
  });

  socket.on("disconnect", () => {
    console.log("WebSocket disconnected.");
  });
} else {
  console.log("User is not a paying member, WebSocket not initialized.");
}

export { socket };
