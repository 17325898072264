import React, { useState, useEffect, createContext, useContext } from "react";

import {
  getSingleAffidavit,
  getWitnessData,
} from "../../functions/AffidavitFunctions";

import { socket } from "../../socket";

import { GlobalContext } from "../../App";
export const QueueContext = createContext();

export default function QueueCtxtProvider({ isAdmin, children }) {
  ////  INITS
  const GlobalCtxt = useContext(GlobalContext);

  ////  STATES
  const [isConnected, setIsConnected] = useState(
    socket ? socket.connected : false
  );
  const [queues, setQueues] = useState({});
  const [currentQueue, setCurrentQueue] = useState("");
  const [decCode, setDecCode] = useState("");
  const [selectedDec, setSelectedDec] = useState(null);
  const [inviteCountdown, setInviteCountdown] = useState(61);
  const [witnessData, setWitnessData] = useState(null);

  ////  FUNCTIONS
  const handleConnect = () => {
    try {
      if (!isConnected) {
        setIsConnected(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDisconnect = () => {
    try {
      setIsConnected(false);
    } catch (err) {
      console.log(err);
    }
  };

  const activateCountdown = () => {
    try {
      const intervalId = setInterval(() => {
        setInviteCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(intervalId);
            return 0;
          }
          if (prevCountdown >= 999) {
            clearInterval(intervalId);
            return 61;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  };

  const updateDecCode = (code) => {
    try {
      if (code) {
        setDecCode(code);
        setInviteCountdown(60);
      }
    } catch (err) {
      console.log(err);
      alert("Error getting Declaration information.");
    }
  };

  const updateCurrentQueue = (roomName) => {
    setCurrentQueue(roomName);
  };

  const fetchQueueData = () => {
    try {
      socket.emit("fetch-queue-data");
    } catch (err) {
      console.log(err);
    }
  };

  const handleQueueUpdate = (data) => {
    try {
      setQueues(data);
    } catch (err) {
      console.log(err);
    }
  };

  const openQueue = (roomName) => {
    try {
      if (!currentQueue) {
        setCurrentQueue(roomName);
      }
      socket.emit("open-queue", roomName);
    } catch (err) {
      console.log(err);
    }
  };

  const closeQueue = () => {
    try {
      socket.emit("close-queue", currentQueue);
    } catch (err) {
      console.log(err);
    }
  };

  const reactivateQueue = () => {
    try {
      socket.emit("reactivate-queue", currentQueue);
    } catch (err) {
      console.log(err);
    }
  };

  const joinQueue = (roomName, declarationId) => {
    try {
      if (currentQueue) {
        alert("You can only be in one queue at a time.");
        return;
      } else {
        setCurrentQueue(roomName);
      }
      socket.emit("join-queue", roomName, declarationId);
    } catch (err) {
      console.log(err);
    }
  };

  const leaveQueue = () => {
    try {
      socket.emit("leave-queue", currentQueue);
      setCurrentQueue("");
    } catch (err) {
      console.log(err);
    }
  };

  const inviteNext = () => {
    try {
      socket.emit("invite-next", currentQueue);
    } catch (err) {
      console.log(err);
    }
  };

  const acceptInvite = () => {
    try {
      socket.emit("accept-invite", currentQueue);
    } catch (err) {
      console.log(err);
    }
  };

  const messageQueue = () => {
    try {
      //socket.emit("message-queue", currentQueue);
    } catch (err) {
      console.log(err);
    }
  };

  const promoteSecond = () => {
    try {
      socket.emit("promote-second", currentQueue);
    } catch (err) {
      console.log(err);
    }
  };

  const handleError = (err) => {
    console.log(err);
    alert(err);
  };

  const fetchDecData = async () => {
    try {
      const res = await getSingleAffidavit(decCode);
      if (res?.data) {
        setSelectedDec(res.data);
      }
    } catch (err) {
      console.log(err);
      alert("Error accessing Declaration data.");
    }
  };

  const fetchWitnessData = async () => {
    try {
      const doc = await getWitnessData(GlobalCtxt.userInfo.witnessId);
      if (doc?.data) {
        setWitnessData(doc.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleInviteAccepted = () => {
    try {
      setInviteCountdown(61);
    } catch (err) {
      console.log(err);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    try {
      if (!socket) {
        console.log("Socket is not initialized.");
        return;
      }

      socket.on("connect", handleConnect);
      socket.on("disconnect", handleDisconnect);
      socket.on("queue-update", handleQueueUpdate);
      socket.on("error", handleError);
      socket.on("invite-accepted", handleInviteAccepted);

      return () => {
        socket.off("connect", handleConnect);
        socket.off("disconnect", handleDisconnect);
        socket.off("queue-update", handleQueueUpdate);
        socket.off("error", handleError);
        socket.off("invite-accepted", handleInviteAccepted);
      };
    } catch (err) {
      console.log(err);
    }
  }, [socket]);

  useEffect(() => {
    //console.log(queues);
  }, [queues]);

  useEffect(() => {
    if (isConnected) {
      fetchQueueData();
    }
  }, [isConnected]);

  useEffect(() => {
    if (decCode) {
      fetchDecData();
    }
  }, [decCode]);

  useEffect(() => {
    if (isAdmin && GlobalCtxt?.userInfo?.witnessId) {
      fetchWitnessData();
    }
  }, []);

  useEffect(() => {
    if (inviteCountdown === 0) {
      promoteSecond();
      setInviteCountdown(61);
    } else if (inviteCountdown === 60) {
      activateCountdown();
    }
  }, [inviteCountdown]);

  return (
    <QueueContext.Provider
      value={{
        isConnected,
        updateCurrentQueue,
        openQueue,
        closeQueue,
        reactivateQueue,
        joinQueue,
        leaveQueue,
        inviteNext,
        acceptInvite,
        messageQueue,
        promoteSecond,
        updateDecCode,
        selectedDec,
        queues,
        currentQueue,
        witnessData,
        inviteCountdown,
      }}
    >
      {children}
    </QueueContext.Provider>
  );
}
