import React, { useContext } from "react";

import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { GlobalContext } from "../../App";

export default function LandingSidebarTab({
  meetingActive,
  toggleSidebar,
  callType,
  isAdmin,
}) {
  ////  INITS
  const navigate = useNavigate();
  const GlobalCtxt = useContext(GlobalContext);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <>
        {meetingActive ? (
          <Button
            color="info"
            variant="contained"
            size="small"
            onClick={toggleSidebar}
            sx={{ width: "100%" }}
            startIcon={<ArrowRightIcon />}
          >
            CLOSE SIDEBAR
          </Button>
        ) : (
          <div style={{ width: "100%" }}>
            <p style={{ textAlign: "center" }}>
              Click JOIN CALL to get started!
            </p>
            <hr style={{ width: "100%" }}></hr>
          </div>
        )}
      </>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          width: "100%",
          margin: "10px 0px",
          //border: "0.5px solid grey",
        }}
      >
        {callType === "witnessing" ? (
          <>
            {isAdmin ? (
              <>
                <h4>Welcome to your witnessing session!</h4>
                <p>
                  Use the drop-down menu above to navigate this sidebar. The
                  sidebar panels are as follows:
                </p>
                <div
                  style={{
                    width: "100%",
                    padding: 5,
                    boxSizing: "border-box",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    marginBottom: 5,
                  }}
                >
                  <h3>Manage queue</h3>
                  <p
                    style={{
                      fontWeight: 300,
                      margin: "5px 0px",
                    }}
                  >
                    This is where you can see the users who are queueing for
                    your session. To invite a user, simply press the 'INVITE'
                    button on their tab.{" "}
                    <span style={{ fontWeight: "bold" }}>
                      For privacy reasons, please ensure that there is only one
                      user in the call at any given time.
                    </span>
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    padding: 5,
                    boxSizing: "border-box",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    marginBottom: 5,
                  }}
                >
                  <h3>View Declaration</h3>
                  <p style={{ fontWeight: 300, margin: "5px 0px" }}>
                    This is where you can view and electronically sign a PDF
                    version of the user's Declaration. Once you sign the
                    Declaration, it will become a lawful piece of evidence.
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    padding: 5,
                    boxSizing: "border-box",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    marginBottom: 5,
                  }}
                >
                  <h3>Checklist</h3>
                  <p style={{ fontWeight: 300, margin: "5px 0px" }}>
                    This contains a checklist reminder of the steps you must
                    follow while witnessing the Declaration.
                  </p>
                </div>
              </>
            ) : (
              <>
                <h4>Welcome to your witnessing session!</h4>
              </>
            )}
          </>
        ) : null}
      </div>
      <Button
        variant="contained"
        onClick={() => {
          navigate(`/home/${GlobalCtxt.nationalId}/National`);
        }}
      >
        RETURN TO HOMEPAGE
      </Button>
    </div>
  );
}
