import React, { useRef, useEffect, useState } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import AutoReel from "../components/common-components/AutoReel";
import { useWindowSize } from "../utils/Hooks";
import { countSubmissions } from "../functions/AffidavitFunctions";
import Button from "@mui/material/Button";
import MuxPlayer from "@mux/mux-player-react";
import { useNavigate } from "react-router-dom";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import "animate.css";

export default function DecLanding() {
  ////  INITS
  const muxRef = useRef();
  const bottomRef = useRef();
  const windowSize = useWindowSize();
  const navigate = useNavigate();

  ////  STATES
  const [decsCount, setDecsCount] = useState(null);

  ////  FUNCTIONS
  const fetchSubmissionCount = async () => {
    try {
      const res = await countSubmissions();
      if (res?.data?.declarations) {
        setTimeout(() => {
          setDecsCount(res.data.declarations);
        }, 1500);
      }
    } catch (err) {
      console.log(err);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    fetchSubmissionCount();
  }, []);

  useEffect(() => {
    //
  }, [windowSize]);

  const handleLearnMoreClick = () => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <ScreenContainer
      styling={{
        background: "linear-gradient(to bottom, #A2B5BA, #90A7B0)",
        paddingTop: 0,
      }}
    >
      <div
        style={{
          width: "100vw",
          height: "auto",
          backgroundColor: "black",
          position: "fixed",
          top: 0,
          left: 0,
        }}
      >
        <p style={{ textAlign: "center", color: "lightgrey", fontSize: 14 }}>
          A campaign by the <b>Winston Smith Initiative</b>
        </p>
      </div>
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "8vh",
        }}
        className="animate__animated animate__fadeIn"
      >
        <h3 style={{ textAlign: "center", width: "98vw", maxWidth: 768 }}>
          What is the Declaration Project? Watch the video below to find out!
        </h3>
        <div
          style={{
            width: "98vw",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              border: "2px solid black",
              display: "flex",
              backgroundColor: "rgba(0,0,0,0.6)",
            }}
          >
            <div style={{ width: "98vw", maxWidth: 768 }}>
              {(() => {
                try {
                  return (
                    <MuxPlayer
                      ref={muxRef}
                      playbackId="cUkxravp8EMH00khAglcDLESXJnbzSTN4X57x9hBSnuc"
                      metadata={{
                        video_id:
                          "02W184xOpGSZ9wtsiezpKESfEdbswUVKX2zrZzzEl02Ak",
                        video_title: "DP_Promo",
                      }}
                    />
                  );
                } catch (error) {
                  console.error("Error rendering MuxPlayer:", error);
                  return <p>Video player failed to load.</p>;
                }
              })()}
            </div>
            {windowSize.width > 900 ? (
              <div
                style={{
                  width: "20vw",
                  backgroundColor: "rgba(0,0,0,0.6)",
                  padding: "5px",
                  boxSizing: "border-box",
                }}
              >
                <h4
                  style={{ textAlign: "center", color: "white", opacity: 0.75 }}
                >
                  Declarations created:
                </h4>
                {decsCount ? (
                  <AutoReel countTo={decsCount} height={100} />
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
        {windowSize.width < 768 ? <br /> : null}
        <div
          style={{
            width: "98vw",
            maxWidth: 768,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 20,
            backgroundColor: "rgba(0,0,0,0.8)",
            padding: 20,
            boxSizing: "border-box",
            border: "1px solid black",
            borderRadius: 8,
          }}
        >
          <Button
            variant="contained"
            color="success"
            sx={{
              marginBottom: "20px",
              width: "100%",
              padding: "12px",
              fontSize: "1.2rem",
              boxShadow: 3,
              transition: "background-color 0.3s, transform 0.3s",
              "&:hover": {
                backgroundColor: "#005b5b",
                transform: "scale(1.05)",
              },
            }}
            size="large"
            onClick={() => {
              navigate("/create-declaration");
            }}
          >
            CREATE DECLARATION!
          </Button>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              startIcon={<LightbulbIcon />}
              onClick={handleLearnMoreClick}
              sx={{
                marginRight: "10px",
                padding: "10px 20px",
                borderRadius: 4,
                border: "2px solid #87CEEB",
                color: "#87CEEB",
                transition: "background-color 0.3s, transform 0.3s",
                "&:hover": {
                  backgroundColor: "rgba(135, 206, 235, 0.2)",
                  transform: "scale(1.05)",
                },
              }}
              variant="outlined"
            >
              LEARN MORE
            </Button>
            <Button
              startIcon={<VolunteerActivismIcon />}
              variant="outlined"
              color="success"
              sx={{
                padding: "10px 20px",
                borderRadius: 4,
                border: "2px solid #66BB6A",
                color: "#66BB6A",
                transition: "background-color 0.3s, transform 0.3s",
                "&:hover": {
                  backgroundColor: "rgba(102, 187, 106, 0.2)",
                  transform: "scale(1.05)",
                },
              }}
              onClick={() => {
                window.open(
                  "https://www.givesendgo.com/declaration-project",
                  "_blank"
                );
              }}
            >
              DONATE
            </Button>
          </div>
        </div>
      </div>
      <div
        ref={bottomRef}
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "8vh",
        }}
      >
        <h3>Explainer video</h3>
        <div
          style={{
            width: "98vw",
            maxWidth: 768,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {(() => {
            try {
              return (
                <MuxPlayer
                  playbackId="ST02F6ml8NFqmzaD19xd8Onkk01av8tE3e01ByVvGhuhNQ"
                  metadata={{
                    video_id: "GIUwkb4GDZ3Il7I54FCZHyd901caHtFEVWikFH4olwAI",
                    video_title: "DP_Walkthru",
                  }}
                />
              );
            } catch (error) {
              console.error("Error rendering MuxPlayer:", error);
              return <p>Video player failed to load.</p>;
            }
          })()}
        </div>
        <div
          style={{
            width: "98vw",
            maxWidth: 768,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 20,
            backgroundColor: "rgba(0,0,0,0.8)",
            padding: 20,
            boxSizing: "border-box",
            border: "1px solid black",
            borderRadius: 8,
          }}
        >
          <Button
            variant="contained"
            color="success"
            sx={{
              width: "100%",
              padding: "12px",
              fontSize: "1.2rem",
              boxShadow: 3,
              transition: "background-color 0.3s, transform 0.3s",
              "&:hover": {
                backgroundColor: "#005b5b",
                transform: "scale(1.05)",
              },
            }}
            size="large"
            onClick={() => {
              navigate("/create-declaration");
            }}
          >
            CREATE DECLARATION!
          </Button>
        </div>
      </div>
    </ScreenContainer>
  );
}
