//  REVISIT: unfinished. will complete when designated channel balances are set up

import React, { useState, useEffect } from "react";

import Button from "@mui/material/Button";
import PaidIcon from "@mui/icons-material/Paid";
import FavoriteIcon from "@mui/icons-material/Favorite";
import TextField from "@mui/material/TextField";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import CircularProgress from "@mui/material/CircularProgress";

import { checkTipQty } from "../../functions/GeneralFunctions";

import "animate.css";

export default function TipModal({ recipient }) {
  ////  STATES
  const [tipQty, setTipQty] = useState(0);
  const [tipValue, setTipValue] = useState(0);
  const [tipsAvailable, setTipsAvailable] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [dataUnavailable, setDataUnavailable] = useState(false);

  ////  FUNCTIONS
  const getTipsAvailable = async () => {
    try {
      const tips = await checkTipQty();
      console.log(tips.data);
      setLoaded(true);
    } catch (err) {
      console.log(err);
      alert(err.message);
      setDataUnavailable(true);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    getTipsAvailable();
  }, []);

  useEffect(() => {
    setTipValue(tipQty * 50);
  }, [tipQty]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <FavoriteIcon
          fontSize="large"
          sx={{ color: "red" }}
          className="animate__animated animate__backInUp animate__delay-0.5s"
        />
      </div>
      <h4 style={{ textAlign: "center" }}>Thanks for sharing the love!</h4>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {!loaded ? (
          <CircularProgress />
        ) : (
          <>
            {dataUnavailable ? null : (
              <>
                <p style={{ textAlign: "center" }}>
                  How many tips do you want to send to{" "}
                  <span style={{ fontWeight: 600 }}>{recipient.username}?</span>
                </p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    type="number"
                    onChange={(e) => {
                      if (parseInt(e.target.value) > 12) {
                        setError(true);
                        setTipQty(12);
                        return;
                      } else {
                        if (error) {
                          setError(false);
                        }
                      }
                      if (e.target.value === "") {
                        setTipQty(0);
                      } else {
                        setTipQty(parseInt(e.target.value));
                      }
                    }}
                  />
                  <p style={{ marginLeft: 10, fontSize: 20 }}>
                    ${(tipValue / 100).toFixed(2)}
                  </p>
                </div>
                {error ? (
                  <p style={{ fontSize: 12, color: "red", margin: 5 }}>
                    Max tip $6.
                  </p>
                ) : null}
                <br></br>
                <Button
                  startIcon={<VolunteerActivismIcon />}
                  color="success"
                  variant="contained"
                  disabled={tipValue ? false : true}
                >{`Give $${(tipValue / 100).toFixed(2)}`}</Button>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
