import React from "react";

const bgs = {
  meeting: "https://storage.googleapis.com/bucket-775/c_r_nolabel_DND2.png",
  broadcast: "https://storage.googleapis.com/bucket-775/broadcast_bg.png",
  webinar: "https://storage.googleapis.com/bucket-775/webinar_bg.png",
};

const defaultStyling = {
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  height: "100vh",
  width: "100vw",
  position: "absolute",
  top: 0,
  left: 0,
};

export default function LobbyWrapper({ styling, children, mode }) {
  return (
    <div
      style={{
        ...defaultStyling,
        ...styling,
        backgroundImage: `url(${bgs[mode]})`,
      }}
    >
      {children}
    </div>
  );
}
