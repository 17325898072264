import Axios from "axios";
import authenticatedConfig from "../utils/Token";

const getConvos = async (skip, limit) => {
  try {
    const docs = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/chats/get-convos?skip=${skip}&limit=${limit}`,
      authenticatedConfig
    );
    return docs;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getMessages = async (convoId) => {
  try {
    const docs = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/chats/get-messages?convoId=${convoId}`,
      authenticatedConfig
    );
    return docs;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const createConvo = async (data) => {
  try {
    const doc = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/chats/create-convo`,
      data,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

export { getConvos, getMessages, createConvo };
