import React, { useState } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import { resetPassword } from "../functions/GeneralFunctions";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useParams, useNavigate } from "react-router-dom";

export default function FMP() {
  ////  INITS
  const params = useParams();
  const navigate = useNavigate();

  ////  STATES
  const [pw, setPw] = useState("");
  const [pwConfirm, setPwConfirm] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);

  ////  FUNCTIONS
  const handleReset = async () => {
    try {
      setBtnDisabled(true);
      if (pw.length < 8) {
        alert("Password must be 8 characters or more.");
        setBtnDisabled(false);
        return;
      }
      if (pw !== pwConfirm) {
        alert("Passwords don't match.");
        setBtnDisabled(false);
        return;
      }
      await resetPassword({
        userId: params.userId,
        createdAt: params.createdAt,
        newPassword: pw,
      });
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } catch (err) {
      console.log(err);
      alert("Error updating password!");
      setBtnDisabled(false);
    }
  };

  return (
    <ScreenContainer>
      <Navbar />
      <div
        style={{
          height: "80vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: 600,
            padding: 10,
            boxSizing: "border-box",
            backgroundColor: "whitesmoke",
            border: "1px solid black",
          }}
        >
          <h4 style={{ textAlign: "center" }}>Reset password</h4>
          <TextField
            fullWidth={true}
            placeholder="New password"
            type="password"
            onChange={(e) => {
              setPw(e.target.value);
            }}
          />
          <TextField
            fullWidth={true}
            placeholder="Confirm new password"
            type="password"
            onChange={(e) => {
              setPwConfirm(e.target.value);
            }}
          />
          <br></br>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Button onClick={handleReset} disabled={btnDisabled}>
              RESET
            </Button>
          </div>
        </div>
      </div>
    </ScreenContainer>
  );
}
