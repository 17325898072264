import Axios from "axios";
import authenticatedConfig from "../utils/Token";

const createPost = async (data) => {
  try {
    const doc = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/posts/create-post`,
      data,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const createComment = async (data) => {
  try {
    const doc = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/posts/create-comment`,
      data,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getPosts = async (query) => {
  try {
    const { channelId, sortKey, skip, limit, timeframe } = query;
    const docs = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/posts/get-posts?channelId=${channelId}&sortKey=${sortKey}&skip=${skip}&limit=${limit}&timeframe=${timeframe}`
    );
    return docs;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getHiddenPosts = async (query) => {
  try {
    const { channelId, skip, limit } = query;
    const docs = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/posts/get-hidden-posts?channelId=${channelId}&skip=${skip}&limit=${limit}`
    );
    return docs;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getPostById = async (postId) => {
  try {
    const doc = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/posts/get-post-by-id?postId=${postId}`
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getPolls = async (data) => {
  try {
    //
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getProposals = async (data) => {
  try {
    //
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getComments = async (data) => {
  try {
    const { contentId, sortKey, skip, limit } = data;
    const docs = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/posts/get-comments?contentId=${contentId}&sortKey=${sortKey}&skip=${skip}&limit=${limit}`
    );
    return docs;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getLikeCount = async (contentId) => {
  try {
    const doc = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/posts/get-like-count?contentId=${contentId}`
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const submitLike = async (data) => {
  try {
    const doc = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/posts/submit-like`,
      data,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const submitNCDoc = async (data) => {
  try {
    const doc = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/ncDocs/submit-nc-doc`,
      data,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getPastPosts = async ({ skip, limit }) => {
  try {
    const docs = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/posts/get-past-posts?skip=${skip}&limit=${limit}`,
      authenticatedConfig
    );
    return docs;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const hideContent = async (data) => {
  try {
    const doc = await Axios.put(
      `${process.env.REACT_APP_DEV_SERVER}/posts/hide-content`,
      data,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

export {
  createPost,
  createComment,
  getPolls,
  getPosts,
  getProposals,
  getPostById,
  getComments,
  getLikeCount,
  submitLike,
  submitNCDoc,
  getPastPosts,
  hideContent,
  getHiddenPosts,
};
