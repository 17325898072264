import React, { useState, useEffect } from "react";

import { getWitnessSessions } from "../../functions/AffidavitFunctions";
import QueueCtxtProvider from "../conference-components/QueueCtxtProvider";
import SessionLobby from "./SessionLobby";

import Button from "@mui/material/Button";

export default function PublicLanding() {
  ////  STATES
  const [sessions, setSessions] = useState([]);
  const [showWitnessExplainer, setShowWitnessExplainer] = useState(false);

  ////  FUNCTIONS
  const fetchSessions = async () => {
    try {
      const docs = await getWitnessSessions({ public: true });
      if (Array.isArray(docs?.data) && docs.data.length > 0) {
        setSessions(docs.data);
      }
    } catch (err) {
      console.log(err);
      alert("Error fetching sessions!");
    }
  };

  ////  EFFECTS
  useEffect(() => {
    fetchSessions();
  }, []);

  useEffect(() => {
    if (Array.isArray(sessions) && sessions.length > 0) {
      console.log(sessions);
    }
  }, [sessions]);

  return (
    <>
      <div
        style={{
          margin: "10px 0px",
          border: "1px solid black",
          width: "100%",
          padding: 10,
          boxSizing: "border-box",
          backgroundColor: "#e3f2fd",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <h4 style={{ textAlign: "center" }}>Remote witnessing service</h4>
        <p style={{ textAlign: "center" }}>
          You can swear your Declaration remotely with one of our Qualified
          Witnesses! Active and upcoming witnessing sessions are displayed
          below.
        </p>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {showWitnessExplainer ? (
            <Button onClick={() => setShowWitnessExplainer(false)}>Hide</Button>
          ) : (
            <Button onClick={() => setShowWitnessExplainer(true)}>
              How does this work?
            </Button>
          )}
        </div>
        {showWitnessExplainer ? (
          <>
            <p style={{ fontSize: 14, opacity: 0.75 }}>
              We've created our own video-conferencing technology, like Zoom,
              which enables you to connect with one of our volunteer Witnesses
              via video call. All of our Witnesses have been vetted to ensure
              that they possess the required qualifications.
            </p>
            <p style={{ fontSize: 14, opacity: 0.75 }}>
              During your session, your Witness will observe you swearing your
              Declaration. When the session is complete, you will receive an
              email containing a second copy of your Declaration that includes
              your Witness' details and electronic signature, along with further
              instructions.
            </p>
            <p style={{ fontSize: 14, opacity: 0.75 }}>
              To start queuing for a session, simply enter your Declaration ID
              into one of the active fields below. Your ability to access a
              session may depend upon the availability of volunteers.
            </p>
          </>
        ) : null}
      </div>
      {Array.isArray(sessions) && sessions.length > 0 ? (
        <QueueCtxtProvider>
          {sessions.map((el, i) => {
            return <SessionLobby data={el} key={`${el.createdAt}-${i}`} />;
          })}
        </QueueCtxtProvider>
      ) : (
        <p style={{ opacity: 0.75, fontStyle: "italic" }}>
          No active sessions. Please check back later.
        </p>
      )}
    </>
  );
}
