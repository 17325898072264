import {
  electorateList,
  acceptableFormats,
  acceptableImageFormats,
} from "../utils/Data";
import authenticatedConfig from "../utils/Token";
import Cookies from "js-cookie";
import DOMPurify from "dompurify";

import Axios from "axios";

const findElectorateFromPostcode = async (postcode) => {
  try {
    const docs = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/admin/find-electorate-from-postcode?postcode=${postcode}`
    );
    if (Array.isArray(docs.data) && docs.data[0]?.name) {
      if (electorateList.includes(docs.data[0].name)) {
        return docs.data;
      } else {
        throw new Error(
          "Error processing postcode. Please double-check and try again."
        );
      }
    } else {
      throw new Error(
        "Error processing postcode. Please double-check and try again."
      );
    }
  } catch (err) {
    console.log(err);
    throw new Error(
      "Error processing postcode. Please double-check and try again."
    );
  }
};

const findMpFromElectorate = async (electorate) => {
  try {
    const doc = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/admin/find-mp-from-electorate?division=${electorate}`
    );
    return doc;
  } catch (err) {
    console.log(err);
    throw new Error("Error!");
  }
};

const checkTurnstile = async (token) => {
  try {
    const doc = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/admin/check-turnstile`,
      { token }
    );
    return doc;
  } catch (err) {
    console.log(err);
    throw new Error("Turnstile error!");
  }
};

const validateUsername = (username) => {
  try {
    const usernameRegex = /^[a-zA-Z0-9_-]{3,20}$/;
    const usernameValid = usernameRegex.test(username);
    return usernameValid;
  } catch (err) {
    console.log(err);
    throw new Error("Error verifying username.");
  }
};

const validateTitle = (title) => {
  try {
    const titleRegex = /^[a-zA-Z0-9 .,'#!&()-]{3,100}$/;
    return titleRegex.test(title);
  } catch (err) {
    console.log(err);
    throw new Error("Error validating video title.");
  }
};

const validateDescription = (description) => {
  try {
    const descriptionRegex = /^[a-zA-Z0-9 .,'#!&()-]{0,250}$/;
    return descriptionRegex.test(description);
  } catch (err) {
    console.log(err);
    throw new Error("Error validating video description.");
  }
};

const validateLocation = (title) => {
  try {
    const validPattern = /^[\p{L}\p{M}\p{N}\p{Zs}\-.'()&/,]{2,100}$/u;
    return validPattern.test(title);
  } catch (err) {
    console.error(err);
    return false;
  }
};

const validateName = (name) => {
  try {
    const validPattern = /^[\p{L}\p{M}\p{Zs}.'-]{2,30}$/u;
    const isValid = validPattern.test(name);
    return isValid;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const validatePhone = (phoneNumber) => {
  try {
    // Pattern allows digits, spaces, +, -, (, ) with a length between 7 and 15 characters
    const validPattern = /^[\d\s+\-()]{8,16}$/;
    const isValid = validPattern.test(phoneNumber);
    return isValid;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const register = async (data) => {
  try {
    const doc = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/admin/register`,
      data
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const login = async (data) => {
  try {
    const doc = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/admin/login`,
      data
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const updateLocalStorage = async (payload) => {
  try {
    const { token, localData } = payload;

    Cookies.set("session-token", token, { expires: 90 });

    for (const key in localData) {
      if (localData.hasOwnProperty(key)) {
        if (Array.isArray(localData[key])) {
          localStorage.setItem(key, JSON.stringify(localData[key]));
        } else {
          localStorage.setItem(key, localData[key]);
        }
      }
    }

    return Promise.resolve();
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

const resetPassword = async (data) => {
  try {
    const doc = await Axios.put(
      `${process.env.REACT_APP_DEV_SERVER}/admin/reset-password`,
      data
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const clearLocalStorageExcept = (exceptions) => {
  for (let i = localStorage.length - 1; i >= 0; i--) {
    const key = localStorage.key(i);

    if (!exceptions.includes(key)) {
      localStorage.removeItem(key);
    }
  }
};

const logout = () => {
  Cookies.remove("session-token");
  clearLocalStorageExcept(["showChatPrompt"]);
};

const checkImage = (file) => {
  try {
    if (file.size > 10000000) {
      return { success: false, error: "Image attachment cannot exceed 10MB." };
    }
    if (!acceptableImageFormats.includes(file.type)) {
      return {
        success: false,
        error: "Image file format must be either JPG, JPEG, PNG, SVG or WebP.",
      };
    }
    return { success: true, error: null };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      error: "An error occurred while processing your file.",
    };
  }
};

const checkFile = (file) => {
  if (file.size > 10000000) {
    return { success: false, error: "Image attachment cannot exceed 10MB." };
  }
  if (!acceptableFormats.includes(file.type)) {
    return {
      success: false,
      error:
        "Image file format must be either JPG, JPEG, PNG, SVG, WebP or PDF.",
    };
  }
  return { success: true, error: null };
};

const generateRandomKey = (tag) => {
  let key = `${tag}-`;
  for (var i = 0; i < 12; i++) {
    key += Math.floor(Math.random() * 10);
  }
  return key;
};

const prepareImage = async (file, tag) => {
  try {
    let append;
    if (file.type === "application/pdf") {
      append = ".pdf";
    } else {
      append = `.${file.type.slice(6)}`;
    }

    const key = generateRandomKey(tag);
    const blob = file.slice(0, file.size, file.type);
    const newFile = new File([blob], `${key}${append}`, {
      type: file.type,
    });
    let formData = new FormData();
    formData.append("imgFile", newFile);
    formData.append("imgKey", `${key}${append}`);

    const res = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/media/upload-image`,
      formData,
      authenticatedConfig
    );

    return res.data;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const prepareBase64Image = async (str) => {
  try {
    const res = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/media/upload-b64-image`,
      { base64Image: str },
      authenticatedConfig
    );
    return res;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const updateProfilePic = async (url) => {
  try {
    const doc = await Axios.put(
      `${process.env.REACT_APP_DEV_SERVER}/users/update-profile-pic`,
      {
        imgUrl: url,
      },
      authenticatedConfig
    );
    localStorage.setItem("profilePic", url);
    return doc;
  } catch (err) {
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const replaceAndSanitize = (str) => {
  try {
    const cleanStr = DOMPurify.sanitize(str);

    return cleanStr
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/\\\\/g, "\\")
      .replace(/\\/g, "")
      .replace(/\\\$/g, "$")
      .replace(/\\\./g, ".")
      .replace(/\\x00/g, "\x00");
  } catch (err) {
    console.log(err);
  }
};

const replaceAndSanitizeHtml = (str) => {
  try {
    let cleanStr = DOMPurify.sanitize(str, {
      ALLOWED_TAGS: ["a"],
      ALLOWED_ATTR: ["rel", "href", "target"],
    });

    return cleanStr
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/\\\\/g, "\\")
      .replace(/\\/g, "")
      .replace(/\\\$/g, "$")
      .replace(/\\\./g, ".")
      .replace(/\\x00/g, "\x00");
  } catch (err) {
    console.log(err);
  }
};

const checkForThreats = async (urlArr) => {
  let alert = false;

  for (const el of urlArr) {
    try {
      // Encode `el` so that it can be passed to Google's Web Risk API.
      const encodedUrl = encodeURIComponent(el);

      // Make a request to Google's Web Risk API to check for threats.
      const response = await Axios.get(
        `${process.env.REACT_APP_DEV_SERVER}/admin/check-for-threats?encodedUrl=${encodedUrl}`
      );

      // If Google identifies a threat, set `alert` = true;
      if (response.data.threat) {
        console.log(response.data);
        alert = true;
        break;
      }
    } catch (err) {
      console.error(`Error checking URL ${el}:`, err);
      // Handle specific cases, such as network errors, or set `alert` to true if you want to be cautious.
      alert = true; // Optionally, treat an error as a reason to trigger an alert
      break; // Optionally, exit loop early on error
    }
  }

  return alert;
};

const safelyRenderHtml = async (str) => {
  // Convert special characters back to plain text.
  str = replaceAndSanitizeHtml(str);

  // Identify URLs in the given string.
  const urlPattern = /\b((http:\/\/|https:\/\/|www\.)[^\s/$.?#].[^\s]*)/gi;

  // Store URLs in an array so that they can be checked for malware.
  const urlArr = [];
  const strWithLinks = str.replace(urlPattern, (match) => {
    let href = match.startsWith("http") ? match : `http://${match}`;
    urlArr.push(href);
    return `<a href="${href}" target="_blank" rel="noopener noreferrer">${match}</a>`;
  });

  // Initialize alert variable
  let alert = false;

  // Check for threats only if URLs were found
  if (urlArr.length > 0) {
    alert = await checkForThreats(urlArr);
  }

  // Double check that the text is not vulnerable to XSS.
  // If an XSS vulnerability is identified, do not render text.
  const cleaned = replaceAndSanitizeHtml(strWithLinks);

  if (cleaned.length !== strWithLinks.length) {
    alert = true;
  }

  return new Promise((resolve) => {
    if (!alert) {
      resolve(
        `<p style="white-space: pre-wrap; line-height: 1.5">${strWithLinks}</p>`
      );
    } else {
      resolve(
        `<p style="white-space: pre-wrap;">This comment is under review because it contains a link that has been identified as potentially dangerous.</p>`
      );
    }
  });
};

function timeSince(unixTime) {
  try {
    const now = Date.now();
    const elapsed = now - unixTime;

    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerWeek = msPerDay * 7;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;

    if (elapsed < msPerMinute) {
      const seconds = Math.floor(elapsed / 1000);
      return `${seconds}s`;
    } else if (elapsed < msPerHour) {
      const minutes = Math.floor(elapsed / msPerMinute);
      return `${minutes}m`;
    } else if (elapsed < msPerDay) {
      const hours = Math.floor(elapsed / msPerHour);
      return `${hours}h`;
    } else if (elapsed < msPerWeek) {
      const days = Math.floor(elapsed / msPerDay);
      return `${days}d`;
    } else if (elapsed < msPerMonth) {
      const weeks = Math.floor(elapsed / msPerWeek);
      return `${weeks}w`;
    } else if (elapsed < msPerYear) {
      // adjusted
      const months = Math.floor(elapsed / msPerWeek);
      return `${months}w`;
    } else {
      const years = Math.floor(elapsed / msPerYear);
      return `${years}y`;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
}

const checkTipQty = async () => {
  try {
    const doc = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/users/check-tip-qty`,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const confirmUser = async () => {
  try {
    const doc = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/users/confirm-user`,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const unixToStringSeconds = (unix) => {
  try {
    const time = new Date(unix * 1000);
    return time.toLocaleString();
  } catch (err) {
    console.log(err);
    return null;
  }
};

const unixToStringMs = (unix) => {
  try {
    const time = new Date(unix);
    return time.toLocaleString();
  } catch (err) {
    console.log(err);
    return null;
  }
};

function formatDuration(duration) {
  const totalSeconds = Math.round(duration);

  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  if (minutes > 0) {
    return `${minutes}m ${seconds}s`;
  } else {
    return `${seconds}s`;
  }
}

function truncateString(str) {
  if (str.length <= 200) {
    return str;
  } else {
    return str.substring(0, 200) + " ...";
  }
}

function centsToDollars(cents) {
  const dollars = (cents / 100).toFixed(2);
  return `$${Number(dollars).toLocaleString()}`;
}

const getUrlThumbnail = async (url) => {
  try {
    const doc = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/admin/get-url-thumbnail?url=${url}`
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const validateMarker = async (markerId) => {
  try {
    const doc = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/map/validate-marker?markerId=${markerId}`
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

function formatNumberWithCommas(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export {
  findElectorateFromPostcode,
  findMpFromElectorate,
  checkTurnstile,
  validateUsername,
  validateTitle,
  validateDescription,
  validateLocation,
  validatePhone,
  validateName,
  register,
  login,
  updateLocalStorage,
  resetPassword,
  logout,
  checkImage,
  checkFile,
  prepareImage,
  prepareBase64Image,
  updateProfilePic,
  replaceAndSanitize,
  timeSince,
  checkTipQty,
  confirmUser,
  unixToStringMs,
  unixToStringSeconds,
  formatDuration,
  truncateString,
  safelyRenderHtml,
  centsToDollars,
  getUrlThumbnail,
  validateMarker,
  formatNumberWithCommas,
};
