import React from "react";

import CreateIcon from "@mui/icons-material/Create";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import GroupsIcon from "@mui/icons-material/Groups";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import GavelIcon from "@mui/icons-material/Gavel";

export default function APExplainer({ minHeight, jc, windowSize }) {
  const iconStyle = {
    width: windowSize.width <= 768 ? "100%" : "30%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "2px solid black",
    borderRadius: 4,
    margin: 5,
  };
  const parentStyle = {
    display: "flex",
    flexDirection: windowSize.width <= 768 ? "column" : "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    border: "1px solid black",
    backgroundColor: "whitesmoke",
    borderRadius: 4,
    padding: 5,
    boxSizing: "border-box",
  };
  const textStyle = {
    width: windowSize.width <= 768 ? "100%" : "66%",
    textAlign: windowSize.width <= 768 ? "center" : null,
    fontSize: 15,
  };

  return (
    <div
      style={{
        height: minHeight,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: jc,
        alignItems: "center",
        overflowY: "auto",
      }}
    >
      <div style={parentStyle}>
        <div style={{ ...iconStyle, backgroundColor: "#C5D2C0" }}>
          <CreateIcon />
          <h4 style={{ marginBottom: 5, marginTop: 5 }}>WRITE</h4>
        </div>
        <p style={textStyle}>
          Tell us, <b>in your own words,</b> how you were personally affected by
          Australia's response to the COVID-19 pandemic.
        </p>
      </div>
      <ArrowDownwardIcon sx={{ color: "lightgrey" }} />
      <div style={parentStyle}>
        <div style={{ ...iconStyle, backgroundColor: "#B0C2B4" }}>
          <AutoAwesomeIcon />
          <h4 style={{ marginBottom: 5, marginTop: 5 }}>GENERATE</h4>
        </div>
        <p style={textStyle}>
          Our very own AI program, <b>Winnie,</b> will translate your story into
          a <b>Commonwealth Statutory Declaration</b> that you can edit,
          download and print for free.
        </p>
      </div>
      <ArrowDownwardIcon sx={{ color: "lightgrey" }} />
      <div style={parentStyle}>
        <div style={{ ...iconStyle, backgroundColor: "#A2B5BA" }}>
          <GavelIcon />
          <h4 style={{ marginBottom: 5, marginTop: 5 }}>FIND A WITNESS</h4>
        </div>
        <p style={textStyle}>
          Sign your declaration in the presence of a <b>Qualified Witness</b>,
          either in person or remotely via our online witnessing system.
        </p>
      </div>
      <ArrowDownwardIcon sx={{ color: "lightgrey" }} />
      <div style={parentStyle}>
        <div style={{ ...iconStyle, backgroundColor: "#3A7CA5" }}>
          <GroupsIcon />
          <h4 style={{ marginBottom: 5, marginTop: 5, color: "white" }}>
            JOIN THE FIGHT!
          </h4>
        </div>
        <p style={{ ...textStyle, fontWeight: 600 }}>
          Join like-minded freedom fighters all over Australia as we submit our
          Declarations to media organisations and our political representatives!
        </p>
      </div>
    </div>
  );
}
