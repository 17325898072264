//  REVISIT: isAdmin prop drilling. any reason why it can't be passed through context?

import React, { createContext, useContext, useState, useEffect } from "react";

import ConferenceSidebar from "./ConferenceSidebar";
import SocketContainer from "./SocketContainer";
import AdSidebar from "../common-components/AdSidebar";

import { useDaily } from "@daily-co/daily-react";

import { socket } from "../../socket";

export const SocketContext = createContext();

export default function SocketCtxtProvider({
  internal,
  isAdmin,
  stream,
  callType,
}) {
  ////  INITS
  const daily = useDaily();

  ////  STATES
  const [isConnected, setIsConnected] = useState(
    socket ? socket.connected : false
  );
  const [streamData, setStreamData] = useState(null);
  const [room, setRoom] = useState(null);
  const [viewerCount, setViewerCount] = useState(0);
  const [liveMessages, setLiveMessages] = useState([]);
  const [endpoints, setEndpoints] = useState([]);
  const [accessRequests, setAccessRequests] = useState([]);

  ////  FUNCTIONS
  const handleConnect = () => {
    try {
      if (!isConnected) {
        setIsConnected(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDisconnect = () => {
    try {
      setIsConnected(false);
    } catch (err) {
      console.log(err);
    }
  };

  const joinRoom = () => {
    try {
      if (isConnected && streamData && !room) {
        socket.emit("join-room", { room: streamData.id });
        setRoom(streamData.id);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateViewerCount = (int) => {
    setViewerCount(int);
  };

  const updateLiveMessages = (msg) => {
    setLiveMessages((prev) => [...prev, msg]);
  };

  const updateStreamData = (strm, ep) => {
    setStreamData(strm);
    if (ep && Array.isArray(ep)) {
      setEndpoints(ep);
    }
  };

  const updateAccessRequests = (request) => {
    setAccessRequests((prev) => [...prev, request]);
  };

  const startStreaming = async () => {
    try {
      daily.startLiveStreaming({
        rtmpUrl: `rtmp://global-live.mux.com:5222/app/${streamData.stream_key}`,
      });
    } catch (err) {
      console.log(err);
      alert("An unexpected error prevented the stream from starting.");
    }
  };

  const receiveAccessCode = (code) => {
    setAccessRequests((prevAccessRequests) =>
      prevAccessRequests.map((request) =>
        request.user === code.user
          ? {
              ...request,
              code: code.code,
              channelId: code.channelId,
              roomName: code.roomName,
              channelName: code.channelName,
            }
          : request
      )
    );
  };

  ////  EFFECTS
  useEffect(() => {
    try {
      if (!socket) {
        console.log("Socket is not initialized.");
        return;
      }

      socket.on("connect", handleConnect);
      socket.on("disconnect", handleDisconnect);
      socket.on("receive-message", updateLiveMessages);
      socket.on("access-requested", updateAccessRequests);
      socket.on("code-received", receiveAccessCode);
      socket.on("viewer-count-update", updateViewerCount);

      return () => {
        socket.off("connect", handleConnect);
        socket.off("disconnect", handleDisconnect);
        socket.off("receive-message", updateLiveMessages);
        socket.off("access-requested", updateAccessRequests);
        socket.off("code-received", receiveAccessCode);
        socket.off("viewer-count-update", updateViewerCount);
      };
    } catch (err) {
      console.log(err);
    }
  }, [socket]);

  useEffect(() => {
    if (isConnected && streamData) {
      joinRoom();
    }
  }, [isConnected, streamData]);

  useEffect(() => {
    if (stream) {
      setStreamData(stream);
    }
  }, [stream]);

  return (
    <SocketContext.Provider
      value={{
        isConnected,
        room,
        viewerCount,
        liveMessages,
        streamData,
        updateStreamData,
        endpoints,
        startStreaming,
        accessRequests,
        updateAccessRequests,
      }}
    >
      {internal ? (
        <ConferenceSidebar isAdmin={isAdmin} callType={callType} />
      ) : (
        <>{1 === 2 ? <AdSidebar /> : <SocketContainer isAdmin={isAdmin} />}</>
      )}
    </SocketContext.Provider>
  );
}
