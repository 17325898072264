import React, { useState } from "react";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Calendar from "react-calendar";
import HelpIcon from "@mui/icons-material/Help";

export default function GenericScheduler({
  handleSubmit,
  minHrs,
  maxHrs,
  header,
}) {
  ////  STATES
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(null);
  const [duration, setDuration] = useState(null);

  ////  FUNCTIONS
  const onChangeDate = (newDate) => {
    setDate(newDate);
  };

  const onChangeTime = (e) => {
    setTime(e.target.value);
  };

  const createUnix = () => {
    try {
      const [hours, minutes] = time.split(":");
      const appointmentDate = new Date(date);
      appointmentDate.setHours(parseInt(hours, 10));
      appointmentDate.setMinutes(parseInt(minutes, 10));
      appointmentDate.setSeconds(0);
      appointmentDate.setMilliseconds(0);
      return Math.floor(appointmentDate.getTime());
    } catch (err) {
      //alert("Please enter a valid time.");
      return false;
    }
  };

  const processInputs = () => {
    try {
      let parsedEventDuration = duration;
      if (parsedEventDuration) {
        parsedEventDuration = parseFloat(duration);
        if (isNaN(parsedEventDuration)) {
          alert("Invalid duration input.");
          return;
        } else if (
          parsedEventDuration < minHrs ||
          parsedEventDuration > maxHrs
        ) {
          alert(`Duration must be between ${minHrs} and ${maxHrs} hours.`);
          return;
        }
        parsedEventDuration *= 3600000;
      } else {
        parsedEventDuration = 7200000;
      }

      const timestamp = createUnix();
      if (!timestamp) {
        alert("Invalid time.");
        return;
      }

      handleSubmit({ time: timestamp, duration: parsedEventDuration });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255,255,255,0.9)",
        padding: "10px 5px",
        boxSizing: "border-box",
      }}
    >
      <h3 style={{ textAlign: "center" }}>{header}</h3>
      <Calendar onChange={onChangeDate} value={date} />
      <br></br>
      <div style={{ display: "flex", alignItems: "center" }}>
        <p>Select time:</p>
        <input
          aria-label="Time"
          type="time"
          style={{
            minWidth: 150,
            fontSize: 16,
            marginLeft: "10px",
          }}
          onChange={onChangeTime}
        />
        <IconButton
          style={{ marginLeft: "10px" }}
          onClick={() => {
            alert(
              "The value that you input will automatically display in each user's local timezone."
            );
          }}
        >
          <HelpIcon sx={{ color: "grey" }} />
        </IconButton>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <p>Duration:</p>
        <TextField
          type="number"
          placeholder={`Hours (min ${minHrs}, max ${maxHrs})`}
          sx={{
            marginLeft: "5px",
            minWidth: "275px",
            backgroundColor: "white",
            borderRadius: 2,
          }}
          onChange={(e) => {
            setDuration(e.target.value);
          }}
          size="small"
        />
      </div>
      <p style={{ fontSize: 12, textAlign: "center", maxWidth: 350 }}>
        Users will be automatically removed from the call when the duration
        expires. If the field above is left blank, a 2-hour duration will be
        automatically applied.
      </p>
      <br></br>
      <Button
        variant="contained"
        color="success"
        onClick={processInputs}
        sx={{ minWidth: "300px" }}
      >
        NEXT
      </Button>
    </div>
  );
}
