import React from "react";

import { useNavigate } from "react-router-dom";

export default function ConferenceRoomPortal({ channelId, channelName }) {
  ////
  const navigate = useNavigate();

  return (
    <div
      style={{
        width: "100%",
        height: "auto",
        background:
          "url('https://storage.googleapis.com/bucket-775/webinar_bg.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "25vh",
        border: "1px solid black",
        cursor: "pointer",
      }}
      onClick={() => {
        navigate(`/conference-room/${channelId}/${channelName}`);
      }}
    >
      <div
        style={{
          width: "auto",
          backgroundColor: "rgba(255,255,255,0.9)",
          borderRadius: 4,
          border: "1px solid black",
          padding: 5,
          boxSizing: "border-box",
        }}
      >
        <p
          style={{
            fontSize: 14,
            textAlign: "center",
            margin: "10px 0px 0px 0px",
            color: "black",
          }}
        >
          {channelName}
        </p>
        <h4 style={{ textAlign: "center", margin: 10, color: "black" }}>
          Conference Room
        </h4>
      </div>
    </div>
  );
}
