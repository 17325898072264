import React, { useContext, useEffect, useState } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import DynamicMap from "../components/common-components/DynamicMap";
import DynamicFrame from "../components/common-components/DynamicFrame";
import GenericSidebar from "../components/common-components/GenericSidebar";
import GenericModal from "../components/common-components/GenericModal";
import GenericMain from "../components/common-components/GenericMain";
import GenericTextField from "../components/common-components/GenericTextField";
import MeetingAdminPreview from "../components/conference-components/MeetingAdminPreview";
import ChannelOverview from "../components/home-components/ChannelOverview";
import {
  getChannelsByUser,
  createMapClass,
  deleteMapMarker,
} from "../functions/ChannelFunctions";
import { useWindowSize } from "../utils/Hooks";
import { validateTitle } from "../functions/GeneralFunctions";
import { getVideoEvents } from "../functions/ConferenceFunctions";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";
import CancelIcon from "@mui/icons-material/Cancel";
import Checkbox from "@mui/material/Checkbox";
import MapIcon from "@mui/icons-material/Map";

import { GlobalContext } from "../App";

export default function SecretarySuite() {
  ////  INITS
  const GlobalCtxt = useContext(GlobalContext);
  const windowSize = useWindowSize();

  ////  STATES
  const [channelList, setChannelList] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [addingClass, setAddingClass] = useState(false);
  const [publicChecked, setPublicChecked] = useState(true);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [reloadMap, setReloadMap] = useState(false);
  const [upcomingEvents, setUpcomingEvents] = useState(null);

  ////  FUNCTIONS
  const fetchChannels = async () => {
    try {
      const docs = await getChannelsByUser();
      console.log(docs.data);
      if (Array.isArray(docs.data) && docs.data.length > 0) {
        setChannelList(docs.data);
      }
    } catch (err) {
      console.log(err);
      alert("Error retrieving Channels! Please refresh the page to try again.");
    }
  };

  const handleClassSubmit = async (txt) => {
    try {
      const isValid = validateTitle(txt);
      if (!isValid) {
        alert("Invalid title! Avoid using uncommon characters.");
        return;
      }
      await createMapClass({
        name: txt,
        public: publicChecked,
        channelId: selectedChannel._id,
      });
      setAddingClass("success");
    } catch (err) {
      console.log(err);
    }
  };

  const handleMarkerClick = async (mkr) => {
    try {
      setSelectedMarker(mkr);
    } catch (err) {
      console.log(err);
    }
  };

  const handleMarkerDelete = async () => {
    try {
      await deleteMapMarker({
        markerId: selectedMarker._id,
        channelId: selectedMarker.channelId,
        userId: selectedMarker.userId,
      });
      setReloadMap(true);
      setSelectedMarker(null);
      setTimeout(() => {
        setReloadMap(false);
      }, 2000);
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  const fetchEvents = async () => {
    try {
      const events = await getVideoEvents(selectedChannel._id);
      setUpcomingEvents(events.data);
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    fetchChannels();
  }, []);

  useEffect(() => {
    if (addingClass === "success") {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [addingClass]);

  useEffect(() => {
    if (selectedChannel) {
      fetchEvents();
    }
  }, [selectedChannel]);

  return (
    <ScreenContainer>
      <Navbar />
      <GenericModal open={showModal} handleClose={() => setShowModal(false)}>
        <p>
          <span style={{ fontWeight: 600 }}>Markers</span> are the red pins that
          appear on the map.
        </p>
        <p>
          If you wish to categorise your community's markers into distinct
          groups, you can do so using{" "}
          <span style={{ fontWeight: 600 }}>marker classes.</span>
        </p>
        <p>
          For example, you may wish to have two marker classes: one that
          represents supportive businesses in your area, and another that
          represents places to visit. Distinguishing the markers in this way
          makes the map less cluttered and confusing.
        </p>
      </GenericModal>
      <div
        style={{
          width: "100vw",
          maxWidth: 768,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "whitesmoke",
          padding: 10,
          boxSizing: "border-box",
          boxShadow: GlobalCtxt.genericBoxShadow,
          border: "1px solid black",
        }}
      >
        <h3 style={{ fontWeight: 400 }}>Secretary Suite</h3>
        {Array.isArray(channelList) && channelList.length > 0 ? (
          <>
            <div style={{ width: "100%" }}>
              <p style={{ opacity: 0.75 }}>Your Channels:</p>
            </div>
            <div
              style={{ width: "100%", maxHeight: "50vh", overflowY: "auto" }}
            >
              {channelList.map((el, i) => {
                return (
                  <div
                    style={{
                      width: "100%",
                      border: "0.5px solid grey",
                      backgroundColor: i % 2 === 0 ? "lightgrey" : "white",
                      padding: "0px 10px",
                      cursor: "pointer",
                    }}
                    key={i}
                    onClick={() => {
                      setSelectedChannel(el);
                    }}
                  >
                    <p>{el.name}</p>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <p>You are not the Secretary of any Channels.</p>
        )}
      </div>
      <br></br>
      {selectedChannel ? (
        <DynamicFrame
          windowSize={windowSize}
          styling={{ maxWidth: 1024, height: "75vh" }}
        >
          <GenericMain styling={{ backgroundColor: "rgba(0,0,0,0.8)" }}>
            {addingClass === "success" ? (
              <h4 style={{ color: "limegreen" }}>Success!</h4>
            ) : addingClass === true ? (
              <>
                <div style={{ width: "100%" }}>
                  <IconButton onClick={() => setAddingClass(false)}>
                    <CancelIcon sx={{ color: "grey" }} />
                  </IconButton>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Checkbox
                    checked={publicChecked}
                    onChange={(e) => {
                      setPublicChecked(e.target.checked);
                    }}
                  />
                  <p style={{ color: "white" }}>Public?</p>
                </div>
                <p
                  style={{
                    color: "lightgrey",
                    fontSize: 14,
                    textAlign: "center",
                  }}
                >
                  If you do not check the box above, the markers within this
                  marker class will only be visible to paying users of the
                  Channel.
                </p>

                <GenericTextField
                  divStyling={{ backgroundColor: "whitesmoke" }}
                  placeholder="Give it a name ..."
                  charLimit={75}
                  handleSubmit={handleClassSubmit}
                />
              </>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: 5,
                  marginBottom: 5,
                }}
              >
                <>
                  <Button
                    startIcon={<MapIcon />}
                    variant="contained"
                    fullWidth={true}
                    onClick={() => setAddingClass(true)}
                  >
                    Create a Marker Class
                  </Button>
                  <IconButton onClick={() => setShowModal(true)}>
                    <HelpIcon sx={{ color: "grey" }} />
                  </IconButton>
                </>
              </div>
            )}

            <hr style={{ width: "100%" }}></hr>
            <DynamicMap
              coords={selectedChannel.coords}
              channelName={selectedChannel.name}
              channelId={selectedChannel._id}
              displayOnly={true}
              //handleMarkerClick={handleMarkerClick}
              //reloadMap={reloadMap}
              /*markerClasses={
                selectedChannel.marker_classes
                  ? selectedChannel.marker_classes
                  : null
              }*/
            />
          </GenericMain>
          {windowSize.width > 768 ? (
            <GenericSidebar windowSize={windowSize}>
              {selectedMarker ? (
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "#333",
                    display: "flex",
                    justifyContent: "center",
                    padding: 10,
                    boxSizing: "border-box",
                  }}
                >
                  <Button
                    color="warning"
                    variant="contained"
                    onClick={handleMarkerDelete}
                  >
                    DELETE MARKER
                  </Button>
                </div>
              ) : null}
              <ChannelOverview data={selectedChannel} hideMap={true} />
            </GenericSidebar>
          ) : null}
        </DynamicFrame>
      ) : null}
      <br></br>
      {selectedChannel ? (
        <DynamicFrame
          windowSize={windowSize}
          styling={{ maxWidth: 1024, height: "100vh" }}
        >
          <GenericMain styling={{ backgroundColor: "rgba(255,255,255,0.8)" }}>
            <p style={{ opacity: 0.75 }}>Upcoming events:</p>
            {Array.isArray(upcomingEvents) && upcomingEvents.length > 0 ? (
              <>
                {upcomingEvents.map((el, i) => {
                  return (
                    <MeetingAdminPreview data={el} key={`${i}-${el._id}`} />
                  );
                })}
              </>
            ) : (
              <p style={{ fontWeight: 300, fontStyle: "italic" }}>
                Nothing to display.
              </p>
            )}
          </GenericMain>
          {windowSize.width > 768 ? (
            <GenericSidebar windowSize={windowSize}>
              <p style={{ color: "lightgrey", textAlign: "center" }}>
                Recent registrations
              </p>
            </GenericSidebar>
          ) : null}
        </DynamicFrame>
      ) : null}
    </ScreenContainer>
  );
}
