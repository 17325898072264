import React, { useState, useEffect, useContext } from "react";

import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationSingle from "./NotificationSingle";
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import CircularProgress from "@mui/material/CircularProgress";

import {
  getNotifications,
  batchNotifications,
} from "../../functions/UserFunctions";

export default function NotificationBell({ showBadge }) {
  ////  INITS
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  ////  STATES
  const [ding, setDing] = useState(false);
  const [lastFetch, setLastFetch] = useState(null);
  const [nsList, setNsList] = useState([]);
  const [loading, setLoading] = useState(false);

  ////  FUNCTIONS
  const fetchNotifications = async () => {
    try {
      setLoading(true);
      const ns = await getNotifications();
      const list = batchNotifications(ns.data, lastFetch);
      console.log(list);
      setNsList(list);
      const isHighlighted = list.some((item) => item.highlight === true);
      if (isHighlighted) {
        setDing(true);
      }
      localStorage.setItem("lastNsFetch", Date.now());
      setLastFetch(Date.now());
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleClick = (e) => {
    if (nsList.length <= 0) {
      fetchNotifications();
    }
    setAnchorEl(e.currentTarget);
    setDing(false);
  };

  ////  EFFECTS
  useEffect(() => {
    try {
      const lf = localStorage.getItem("lastNsFetch");
      if (!lf) {
        setLastFetch(1);
      } else {
        const lfInt = parseInt(lf);
        if (isNaN(lfInt)) {
          setLastFetch(1);
        } else {
          setLastFetch(lfInt);
        }
      }
    } catch (err) {
      console.log(err);
      alert("Error processing notifications!");
    }
  }, []);

  useEffect(() => {
    if (lastFetch) {
      if (Date.now() - lastFetch > 30000) {
        fetchNotifications();
      }
    }
  }, [lastFetch]);

  useEffect(() => {
    if (ding) {
      console.log("DING");
    }
  }, [ding]);

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{ boxSizing: "border-box", padding: "2px" }}
      >
        {Array.isArray(nsList) && nsList.length > 0 ? (
          <>
            {nsList.map((el, i) => {
              return <NotificationSingle data={el} key={`${i}-${el.type}`} />;
            })}
          </>
        ) : (
          <>
            {loading ? (
              <CircularProgress />
            ) : (
              <p style={{ fontSize: 12, fontStyle: "italic", opacity: 0.5 }}>
                Nothing to display
              </p>
            )}
          </>
        )}
      </Menu>
      <IconButton
        onClick={(e) => {
          handleClick(e);
        }}
      >
        <Badge
          color="error"
          variant="dot"
          invisible={!ding}
          overlap="circular"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <NotificationsIcon
            fontSize="large"
            sx={{ color: ding ? "white" : "grey" }}
          />
        </Badge>
      </IconButton>
    </>
  );
}
