import Axios from "axios";
import Cookies from "js-cookie";

const config = {
  headers: {
    Authorization: `Bearer ${Cookies.get("session-token")}`,
  },
};

//  INITIALISE KEYS WHEN USER REGISTERS
const generateKeys = async (_id) => {
  try {
    // Generate public and private key.
    const keyPair = await crypto.subtle.generateKey(
      {
        name: "RSA-OAEP",
        modulusLength: 2048,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: { name: "SHA-256" },
      },
      true,
      ["encrypt", "decrypt"]
    );

    // Export public and private key.
    const publicKey = await crypto.subtle.exportKey("spki", keyPair.publicKey);
    const privateKey = await crypto.subtle.exportKey(
      "pkcs8",
      keyPair.privateKey
    );

    // Store private key in IndexedDB with a unique identifier.
    try {
      await storePrivateKey(privateKey, _id);
    } catch (storeError) {
      console.error("Error storing private key:", storeError);
      throw storeError;
    }

    // Convert public key to string, then post to database.
    const publicKeyStr = arrayBufferToBase64(publicKey);
    return publicKeyStr;
  } catch (err) {
    console.log(err);
    return false;
  }
};

/*
const getDatabaseVersion = (dbName) => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName);

    request.onsuccess = function (event) {
      const db = event.target.result;
      const version = db.version;
      db.close();
      resolve(version);
    };

    request.onerror = function () {
      reject("Failed to get database version.");
    };
  });
};
*/

const storePrivateKey = (privateKey, _id) => {
  return new Promise((resolve, reject) => {
    const dbName = "cryptoKeysDB";
    const storeName = `keyStore-${_id}`;
    console.log("storeName == " + storeName);
    const uniqueKeyId = `privateKey-${_id}`;
    console.log("uniqueKeyId == " + uniqueKeyId);

    const request = indexedDB.open(dbName, 1);

    request.onupgradeneeded = function (event) {
      console.log("upgrade needed");
      const db = event.target.result;
      if (!db.objectStoreNames.contains(storeName)) {
        console.log("creating new object store");
        db.createObjectStore(storeName, { keyPath: "id" });
      }
    };

    request.onsuccess = function (event) {
      console.log("success");
      const db = event.target.result;
      const transaction = db.transaction(storeName, "readwrite");
      const store = transaction.objectStore(storeName);

      store.put({ id: uniqueKeyId, key: privateKey });

      transaction.oncomplete = function () {
        console.log("Private key stored in IndexedDB.");
        resolve();
      };

      transaction.onerror = function () {
        console.error("Transaction failed:", transaction.error);
        reject(transaction.error);
      };
    };

    request.onerror = function (event) {
      console.error("IndexedDB request failed:", event.target.error);
      reject(event.target.error);
    };
  });
};

const getObjectStoreNames = () => {
  return new Promise((resolve, reject) => {
    const dbName = "cryptoKeysDB";
    const request = indexedDB.open(dbName);

    request.onsuccess = function (event) {
      const db = event.target.result;

      // Get the names of all object stores
      const storeNames = Array.from(db.objectStoreNames);
      db.close();

      if (storeNames.length > 0) {
        resolve(storeNames); // Resolve with the array of store names
      } else {
        resolve(null); // No object stores found
      }
    };

    request.onerror = function (event) {
      console.error("Failed to open the database:", event.target.error);
      reject(event.target.error);
    };

    request.onupgradeneeded = function () {
      // This block will only run if the database doesn't exist and is being created,
      // which means no object stores exist.
      resolve(null); // No object stores exist at this point
    };
  });
};

const resetDatabase = () => {
  return new Promise((resolve, reject) => {
    const dbName = "cryptoKeysDB";

    const deleteRequest = indexedDB.deleteDatabase(dbName);

    deleteRequest.onsuccess = function () {
      console.log("Database deleted successfully.");
      resolve();
    };

    deleteRequest.onerror = function (event) {
      console.error("Failed to delete database:", event.target.error);
      reject(event.target.error);
    };

    deleteRequest.onblocked = function () {
      console.warn("Database deletion blocked. Close all connections.");
      reject("Error!");
    };
  });
};

const sendEncryptedMessage = async (content, recipientId, convoId) => {
  try {
    // Generate session key (returns CryptoKey object).
    const sessionKey = await generateSessionKey();
    // Convert plain text input to binary.
    const encodedMessage = new TextEncoder().encode(content);
    // Generate 12-byte Initialisation Vector.
    const iv = crypto.getRandomValues(new Uint8Array(12));
    // Encrypt content using the session key.
    const encryptedMessage = await crypto.subtle.encrypt(
      {
        name: "AES-GCM",
        iv: iv,
      },
      sessionKey,
      encodedMessage
    );
    // Convert the encrypted content back to a string.
    const encryptedMessageStr = arrayBufferToBase64(encryptedMessage);
    // Convert IV to string.
    const ivStr = arrayBufferToBase64(iv);
    // Convert the session key CryptoKey object to binary (array buffer).
    const sessionKeyAB = await crypto.subtle.exportKey("raw", sessionKey);
    // Now, create two encrypted versions of the session key.
    // First, create one for the sender (returns string).
    const senderKey = await generateSenderKey(sessionKeyAB);
    // Create another for the recipient (returns string).
    const recipientKey = await generateRecipientKey(sessionKeyAB, recipientId);
    // Post the message document with all elements encrypted.
    await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/chats/create-message`,
      {
        content: encryptedMessageStr,
        recipientId,
        senderKey: senderKey,
        recipientKey: recipientKey,
        convoId: convoId,
        iv: ivStr,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("session-token")}`,
        },
      }
    );
  } catch (err) {
    console.log(err);
    alert(err.message);
  }
};

const generateSessionKey = async () => {
  try {
    // Generate a symmetrical session key.
    const key = await crypto.subtle.generateKey(
      {
        name: "AES-GCM",
        length: 128,
      },
      true,
      ["encrypt", "decrypt"]
    );
    // Return CryptoKey object.
    return key;
  } catch (err) {
    console.log(err);
    throw new Error(
      "Error generating encrypted session key. Message send aborted."
    );
  }
};

const generateSenderKey = async (sessionKeyAB) => {
  try {
    // Get the sender's public key from local storage in string format.
    const publicKeyStr = localStorage.getItem("publicKey");
    // Convert public key from string to array buffer.
    const publicKeyAB = base64ToArrayBuffer(publicKeyStr);
    // Convert public key from array buffer to CryptoKey object.
    const publicKey = await crypto.subtle.importKey(
      "spki",
      publicKeyAB,
      {
        name: "RSA-OAEP",
        hash: { name: "SHA-256" },
      },
      false,
      ["encrypt"]
    );
    // Encrypt the session key using the sender's public key.
    const encryptedSenderKey = await crypto.subtle.encrypt(
      {
        name: "RSA-OAEP",
      },
      publicKey,
      sessionKeyAB
    );
    // Convert the encrypted session key to a string and return.
    const encryptedSenderKeyStr = arrayBufferToBase64(encryptedSenderKey);
    return encryptedSenderKeyStr;
  } catch (err) {
    console.log(err);
    alert("Error generating encrypted session key! Message send aborted.");
  }
};

const generateRecipientKey = async (sessionKeyAB, recipientId) => {
  try {
    // Fetch recipient's public key from the server.
    const publicKeyStr = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/users/get-public-key?userId=${recipientId}`
    );
    // Convert recipient's public key from string to array buffer.
    const publicKeyAB = base64ToArrayBuffer(publicKeyStr.data.publicKey);
    // Convert recipient's public key from array buffer to CryptoKey object.
    const publicKey = await crypto.subtle.importKey(
      "spki",
      publicKeyAB,
      {
        name: "RSA-OAEP",
        hash: { name: "SHA-256" },
      },
      false,
      ["encrypt"]
    );
    // Encrypt session key using recipient's public key.
    const encryptedRecipientKey = await crypto.subtle.encrypt(
      {
        name: "RSA-OAEP",
      },
      publicKey,
      sessionKeyAB
    );
    // Convert encrypted session key back to string and return.
    const encryptedRecipientKeyStr = arrayBufferToBase64(encryptedRecipientKey);
    return encryptedRecipientKeyStr;
  } catch (err) {
    console.log(err);
    alert("Error generating encrypted session key! Message send aborted.");
  }
};

const decryptKeyAndMessage = async (iv, sessionKey, content) => {
  try {
    // Convert the session key from string to array buffer.
    const sessionKeyAB = base64ToArrayBuffer(sessionKey);
    // Convert IV from string to array buffer.
    const ivAB = base64ToArrayBuffer(iv);
    // Fetch sender's private key from IndexedDB (returns CryptoKey object).
    const privateKey = await getPrivateKeyFromIndexedDB(
      localStorage.getItem("_id")
    );
    // Decrypt the session key using sender's private key.
    const decryptedSessionKeyAB = await crypto.subtle.decrypt(
      {
        name: "RSA-OAEP",
      },
      privateKey,
      sessionKeyAB
    );
    // Convert the decrypted key from array buffer to CryptoKey object.
    const decryptedSessionKey = await crypto.subtle.importKey(
      "raw",
      decryptedSessionKeyAB,
      {
        name: "AES-GCM",
      },
      true,
      ["decrypt"]
    );
    // Convert the encrypted content from string to array buffer.
    const contentAB = base64ToArrayBuffer(content);
    // Decrypt content using decrypted session key and IV (outputs array buffer).
    const decryptedContent = await crypto.subtle.decrypt(
      {
        name: "AES-GCM",
        iv: ivAB,
      },
      decryptedSessionKey,
      contentAB
    );
    // Convert decrypted content from array buffer to string using TextDecoder.
    const decryptedContentStr = new TextDecoder().decode(decryptedContent);
    return decryptedContentStr; // Return the decrypted text
  } catch (err) {
    console.log(err);
  }
};

async function getPrivateKeyFromIndexedDB(_id) {
  try {
    const dbName = "cryptoKeysDB";
    const storeName = `keyStore-${_id}`;
    const uniqueKeyId = `privateKey-${_id}`; // Use the unique ID to retrieve the key

    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName, 1);

      request.onsuccess = function (event) {
        const db = event.target.result;
        const transaction = db.transaction(storeName, "readonly");
        const store = transaction.objectStore(storeName);

        // Fetch the private key using the unique ID
        const privateKeyRequest = store.get(uniqueKeyId);

        privateKeyRequest.onsuccess = function (event) {
          const privateKeyData = privateKeyRequest.result?.key;
          if (!privateKeyData) {
            reject("Private key not found in IndexedDB!");
            return;
          }

          crypto.subtle
            .importKey(
              "pkcs8",
              privateKeyData,
              {
                name: "RSA-OAEP",
                hash: { name: "SHA-256" },
              },
              true,
              ["decrypt"]
            )
            .then((importedKey) => {
              resolve(importedKey);
            })
            .catch((error) => {
              reject("Error importing private key: " + error);
            });
        };

        privateKeyRequest.onerror = function () {
          reject("Failed to retrieve private key!");
        };
      };

      request.onerror = function (event) {
        reject("IndexedDB request failed: " + event.target.error);
      };
    });
  } catch (err) {
    throw new Error("Encrypted messaging only works on a single device!");
  }
}

function arrayBufferToBase64(buffer) {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

export {
  generateKeys,
  sendEncryptedMessage,
  decryptKeyAndMessage,
  getObjectStoreNames,
  resetDatabase,
};
