import React, { useState, useEffect } from "react";

export default function SingleReel({
  targetNumber,
  tileWidth,
  height,
  leftToRight,
  pos,
  updateAnimation,
}) {
  ////  STATES
  const [reelArr, setReelArr] = useState([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4,
    5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4,
    5, 6, 7, 8, 9, 0,
  ]);
  const [offset, setOffset] = useState(0);

  ////  EFFECTS
  useEffect(() => {
    if (typeof targetNumber === "number") {
      let scrollAmount = targetNumber + 10;
      scrollAmount += pos * 10;
      scrollAmount *= tileWidth;
      let animationDuration = (6 + pos) * 1000;
      setOffset(scrollAmount);
      setTimeout(() => {
        updateAnimation();
      }, animationDuration);
    }
  }, [targetNumber]);

  return (
    <>
      <div
        style={{
          height: tileWidth,
          width: tileWidth,
          position: "relative", // Allow absolute positioning of children
          overflow: "scroll", // Hide the overflowing content
          backgroundColor: "green",
          pointerEvents: "none",
        }}
      >
        <div
          style={{
            height: tileWidth,
            transform: offset ? `translateY(-${offset}px)` : null,
            transition: offset ? `transform ${6 + pos}s ease-in-out` : null,
          }}
        >
          {Array.isArray(reelArr) &&
            reelArr.map((el, i) => {
              return (
                <div
                  key={i}
                  style={{
                    minHeight: tileWidth,
                    minWidth: tileWidth,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3
                    style={{
                      fontSize: tileWidth * 0.4,
                      margin: 0,
                      color: "white",
                    }}
                  >
                    {el}
                  </h3>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}
