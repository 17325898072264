import React, { useState } from "react";
import { timeSince } from "../../functions/GeneralFunctions";

export default function MessageBubble({ sender, content, createdAt }) {
  const [clicked, setClicked] = useState(false);

  // Determine alignment and color based on sender
  const isSender = sender === localStorage.getItem("_id");
  const bubbleColor = isSender ? "#4CAF50" : "#1E90FF"; // Updated colors
  const textAlign = isSender ? "right" : "left";

  return (
    <>
      {clicked && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: textAlign,
          }}
        >
          <p
            style={{ color: "grey", fontSize: 12, margin: "0px 12px 4px 12px" }}
          >
            {timeSince(createdAt)} ago
          </p>
        </div>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: textAlign,
          padding: "0 12px",
        }}
      >
        <div
          style={{
            backgroundColor: bubbleColor,
            maxWidth: "75%",
            padding: "10px 20px",
            borderRadius: 16,
            marginBottom: 10,
            color: "white",
            cursor: "pointer",
            transition: "background-color 0.2s ease",
            wordWrap: "break-word",
          }}
          onClick={() => {
            setClicked((prev) => !prev);
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = isSender
              ? "#388E3C" // Slightly darker green on hover
              : "#1C7ED6"; // Slightly darker blue on hover
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = bubbleColor; // Revert to original color
          }}
        >
          <p style={{ margin: 0, fontSize: "1rem", lineHeight: "1.5" }}>
            {content}
          </p>
        </div>
      </div>
    </>
  );
}
