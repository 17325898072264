import React, { useContext, useState, useEffect } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import AssetWindow from "../components/conference-components/AssetWindow";
import AssetTile from "../components/common-components/AssetTile";
import { getVids } from "../functions/StreamingFunctions";
import { useWindowSize } from "../utils/Hooks";

import { useParams, useNavigate } from "react-router-dom";

import "../App.css";

import { GlobalContext } from "../App";

export default function Assets() {
  ////  INITS
  const GlobalCtxt = useContext(GlobalContext);
  const params = useParams();
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  ////  STATES
  const [otherChannelVids, setOtherChannelVids] = useState([]);
  const [otherVids, setOtherVids] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [querySince, setQuerySince] = useState("");
  const [selectedVid, setSelectedVid] = useState(null);

  ////  FUNCTIONS
  const fetchChannelVids = async () => {
    try {
      const docs = await getVids({
        sortKey: sortBy,
        skip: otherChannelVids.length,
        limit: 8,
        timeframe: querySince,
      });
      if (!docs?.data || docs.data.length === 0) {
        fetchOtherVids();
      } else {
        setOtherChannelVids(docs.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchOtherVids = async () => {
    try {
      const docs = await getVids({
        sortKey: sortBy,
        skip: otherVids.length,
        limit: 8,
        timeframe: querySince,
      });
      setOtherVids(docs.data);
    } catch (err) {
      console.log(err);
    }
  };

  const updateSelectedVid = (vid) => {
    setSelectedVid(vid);
  };

  ////  EFFECTS
  useEffect(() => {
    if (params.channelId) {
      fetchChannelVids();
    } else {
      fetchOtherVids();
    }
  }, []);

  useEffect(() => {
    if (otherChannelVids) {
      fetchOtherVids();
    }
  }, [otherChannelVids]);

  return (
    <ScreenContainer styling={{ background: GlobalCtxt.darkBg }}>
      <Navbar />
      {selectedVid ? <AssetWindow asset={selectedVid} /> : null}
      <div className="dark-container" style={{ width: "98vw", marginTop: 40 }}>
        <h4>More from this channel:</h4>
        {Array.isArray(otherChannelVids) && otherChannelVids.length > 0 ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: windowSize.width <= 768 ? "space-evenly" : null,
            }}
          >
            {otherChannelVids.map((el, i) => {
              return (
                <AssetTile
                  data={el}
                  key={`${i}-${el._id}`}
                  handleClick={updateSelectedVid}
                />
              );
            })}
          </div>
        ) : (
          <p style={{ opacity: 0.5 }}>Nothing to display.</p>
        )}
        <h4>Other videos you might like:</h4>
        {Array.isArray(otherVids) && otherVids.length > 0 ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: windowSize.width <= 768 ? "space-evenly" : null,
            }}
          >
            {otherVids.map((el, i) => {
              return (
                <AssetTile
                  data={el}
                  key={`${i}-${el._id}`}
                  handleClick={updateSelectedVid}
                />
              );
            })}
          </div>
        ) : (
          <p style={{ opacity: 0.5 }}>Nothing to display.</p>
        )}
      </div>
    </ScreenContainer>
  );
}
