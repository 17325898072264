import React, { useState, useEffect, useContext } from "react";

import CircularImgContainer from "./CircularImgContainer";
import { getUserById } from "../../functions/UserFunctions";
import { timeSince } from "../../functions/GeneralFunctions";

import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import Tooltip from "@mui/material/Tooltip";

import { GlobalContext } from "../../App";

export default function ClickableUsername({
  userId,
  username,
  underline,
  createdAt,
}) {
  ////  INITS
  const [user, setUser] = useState(null);
  const GlobalCtxt = useContext(GlobalContext);

  ////  FUNCTIONS
  const fetchUser = async () => {
    try {
      const doc = await getUserById(userId);
      setUser(doc.data);
    } catch (err) {
      console.log(err);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (userId) {
      fetchUser();
    }
  }, [userId]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        borderBottom: underline ? "0.5px solid grey" : null,
        cursor: "pointer",
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (user) {
          GlobalCtxt.updateCurrentUser(user);
        }
      }}
    >
      <CircularImgContainer
        url={user?.profilePic ? user.profilePic : null}
        styling={{ height: "40px", width: "40px" }}
      />
      <p style={{ marginLeft: "10px", fontWeight: 300 }}>{username}</p>
      {user?.tier ? (
        <Tooltip title={`${username} is a paying subscriber!`}>
          <WorkspacePremiumIcon sx={{ color: "green", marginLeft: "10px" }} />
        </Tooltip>
      ) : null}

      {createdAt && !isNaN(createdAt) ? (
        <p style={{ marginLeft: "15px", fontSize: 14, opacity: 0.5 }}>
          ({timeSince(createdAt)} ago)
        </p>
      ) : null}
    </div>
  );
}
