import React from "react";
import MuxPlayer from "@mux/mux-player-react";

export default function Debra() {
  return (
    <>
      <br></br>
      <div style={{ width: "100%" }}>
        <MuxPlayer
          playbackId="2AerKmZc1ZZRDHKy990101MeeWeqkNTKyDugqHgK00Y7Oo"
          metadata={{
            video_id: "ejFZo8puPJOZ6dTG5G8t4IjId701QHYOr9UU0037Ipo008",
            video_title: "Debra's video",
          }}
        />
      </div>
    </>
  );
}
