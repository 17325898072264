import React, { useState, useEffect } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import DynamicFrame from "../components/common-components/DynamicFrame";
import GenericSidebar from "../components/common-components/GenericSidebar";
import GenericMain from "../components/common-components/GenericMain";
import VolunteerTab from "../components/common-components/VolunteerTab";
import { getVolunteers } from "../functions/UserFunctions";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

export default function FlyersAdmin() {
  const [signups, setSignups] = useState([]);
  const [signupsCount, setSignupsCount] = useState(0);
  const [electoratesCount, setElectoratesCount] = useState(0);
  const [password, setPassword] = useState("");

  ////  FUNCTIONS
  const categorizeByElectorate = (data) => {
    const result = [];

    data.forEach((doc) => {
      // Find if the electorate category already exists in the result array
      const category = result.find(
        (item) => item.electorate === doc.electorate
      );

      if (category) {
        // If category exists, push the signup to the existing array
        category.signups.push(doc);
      } else {
        // If category doesn't exist, create a new entry
        result.push({
          electorate: doc.electorate, // can be string or null
          signups: [doc],
        });
      }
    });

    // Sort the result array by the length of signups in descending order
    result.sort((a, b) => b.signups.length - a.signups.length);

    return result;
  };

  const fetchSignups = async () => {
    try {
      const docs = await getVolunteers(password);
      if (Array.isArray(docs.data)) {
        setSignupsCount(docs.data.length);
        const categorizedSignups = categorizeByElectorate(docs.data);
        setSignups(categorizedSignups);
      }
    } catch (err) {
      console.log(err);
      alert("Error retrieving sign ups! Please refresh the page to try again.");
    }
  };

  ////  EFFECTS
  useEffect(() => {
    //fetchSignups();
  }, []);

  useEffect(() => {
    if (Array.isArray(signups) && signups.length > 0) {
      setElectoratesCount(signups.length);
      setPassword("");
    }
  }, [signups]);

  return (
    <ScreenContainer>
      <Navbar />
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            sx={{
              backgroundColor: "whitesmoke",
              borderRadius: "4px",
              marginRight: "10px",
            }}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            value={password}
          />
          <Button
            variant="contained"
            onClick={fetchSignups}
            disabled={password ? false : true}
          >
            SUBMIT
          </Button>
        </div>
      </div>
      <br></br>
      <div
        style={{
          width: "100vw",
          maxWidth: 768,
          backgroundColor: "whitesmoke",
          border: "1px solid black",
          borderRadius: 4,
          padding: 5,
          boxSizing: "border-box",
          maxHeight: "100vh",
          overflowY: "auto",
        }}
      >
        <h4>TOTAL REGISTRATIONS: {signupsCount}</h4>
        <h4>ELECTORATES COVERED: {electoratesCount}</h4>
        {signups.length > 0
          ? signups.map((el, i) => {
              return (
                <VolunteerTab
                  data={el}
                  index={i}
                  key={`${i}-${el.electorate}`}
                />
              );
            })
          : null}
      </div>
    </ScreenContainer>
  );
}
