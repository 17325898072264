import React, { useState, useRef, useEffect, useContext } from "react";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import { login, updateLocalStorage } from "../functions/GeneralFunctions";

import { useParams, useNavigate } from "react-router-dom";

import { GlobalContext } from "../App";

export default function Login() {
  ////  INITS
  const params = useParams();
  const GlobalCtxt = useContext(GlobalContext);
  const idRef = useRef();
  const pwRef = useRef();
  const navigate = useNavigate();

  ////  STATES
  const [btnDisabled, setBtnDisabled] = useState(false);

  ////  FUNCTIONS
  const handleLogin = async () => {
    try {
      if (!idRef.current.value || !pwRef.current.value) {
        alert("Please complete both fields.");
        return;
      }
      setBtnDisabled(true);
      const loggedIn = await login({
        identifier: idRef.current.value.trim(),
        password: pwRef.current.value,
      });
      await updateLocalStorage(loggedIn.data);
      GlobalCtxt.localtoGlobal();
      setTimeout(() => {
        navigate(`/home/${GlobalCtxt.nationalId}/National/reload`);
      }, 500);
    } catch (err) {
      console.log(err);
      alert(err.message);
      setBtnDisabled(false);
    }
  };

  return (
    <ScreenContainer>
      <Navbar />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "98vw",
          maxWidth: 600,
          padding: 5,
          backgroundColor: "whitesmoke",
          border: "2px solid black",
          boxShadow: GlobalCtxt.genericBoxShadow,
        }}
      >
        <h2>
          {params.fromRegister === "true"
            ? "Registration successful!"
            : "Welcome back!"}
        </h2>
        <br></br>
        <TextField
          placeholder="Username or email"
          inputRef={idRef}
          sx={{ minWidth: 350, marginBottom: "10px" }}
        />
        <TextField
          placeholder="Password"
          inputRef={pwRef}
          type="password"
          sx={{ minWidth: 350, marginBottom: "10px" }}
        />
        <br></br>
        <Button
          variant="contained"
          disabled={btnDisabled}
          onClick={handleLogin}
        >
          LOG IN
        </Button>
        {params.fromRegister === "true" ? null : (
          <p style={{ fontSize: 14 }}>
            Don't have an account?{" "}
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                navigate("/register");
              }}
            >
              Click here
            </a>{" "}
            to register!
          </p>
        )}
        <hr style={{ width: "100%", opacity: 0.5 }}></hr>
        <p style={{ fontSize: 14 }}>
          Forgot your password?{" "}
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              navigate("/initiate-reset");
            }}
          >
            Reset.
          </a>
        </p>
      </div>
    </ScreenContainer>
  );
}

/*

import Navbar from "../common-components/Navbar";
import { useNavigate, useParams } from "react-router-dom";

import { login, updateLocalStorage } from "../utils/Functions";
import ScreenContainer from "../common-components/ScreenContainer";

import { GlobalContext } from "../App";

export default function Login() {
  ////  INITS
  
  const params = useParams();
  const GlobalCtxt = useContext(GlobalContext);

  ////  STATES
  const [loading, setLoading] = useState(false);

  ////  FUNCTIONS
  

  return (
    <ScreenContainer>
      <Navbar />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "96vw",
          maxWidth: 600,
          minHeight: 425,
          padding: 5,
          boxSizing: "border-box",
          backgroundColor: "whitesmoke",
          border: "2px solid black",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        
        <div>
          
        </div>
        {loading === true ? (
          <CircularProgress />
        ) : (
          <Button variant="outlined" onClick={handleLogin}>
            Log in
          </Button>
        )}
        
      </div>
    </ScreenContainer>
  );
}
*/
