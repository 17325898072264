import React, { useContext } from "react";

import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import SignInPrompt from "../common-components/SignInPrompt";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import CircularImgContainer from "../common-components/CircularImgContainer";
import { timeSince } from "../../functions/GeneralFunctions";

import { GlobalContext } from "../../App";

export default function UserPreview({ data, hideExit }) {
  ////  INITS
  const GlobalCtxt = useContext(GlobalContext);
  const navigate = useNavigate();

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "whitesmoke",
        borderRadius: 3,
        marginBottom: 5,
      }}
    >
      {hideExit ? null : (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "right" }}
        >
          <IconButton
            onClick={() => {
              GlobalCtxt.updateCurrentUser(null);
            }}
          >
            <CancelIcon />
          </IconButton>
        </div>
      )}

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CircularImgContainer url={data.profilePic} />
        <h3 style={{ textAlign: "center" }}>{data.username}</h3>
        {localStorage.getItem("electorate") ? (
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              navigate(`/chat/${data._id}/${data.username}`);
            }}
            disabled={
              data._id === localStorage.getItem("_id") || !data?.publicKey
                ? true
                : false
            }
          >
            SEND A MESSAGE!
          </Button>
        ) : (
          <SignInPrompt action="messaging" />
        )}

        {data.tier && data.tier >= 1 ? (
          <div
            style={{
              width: "100%",
              padding: 10,
              marginTop: 10,
              boxSizing: "border-box",
              backgroundColor: GlobalCtxt.colourScheme.primaryGreen,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{ padding: 5, backgroundColor: "green", borderRadius: 8 }}
            >
              <WorkspacePremiumIcon fontSize="large" sx={{ color: "white" }} />
            </div>
            <p style={{ textAlign: "center", color: "white", marginBottom: 0 }}>
              {data.username} is a{" "}
              {data.tier === 1
                ? `Community Supporter`
                : data.tier === 2 || data.tier === 3
                ? `Community Investor`
                : `Community Leader`}
              !
            </p>
          </div>
        ) : null}
        <div style={{ width: "100%", padding: 5, boxSizing: "border-box" }}>
          <p>
            <span style={{ fontWeight: 600 }}>Electorate: </span>
            {data.electorate}
          </p>
          <p>
            <span style={{ fontWeight: 600 }}>Affiliation: </span>
            {data.affiliation || "Independent"}
          </p>
          <p>
            <span style={{ fontWeight: 600 }}>Joined: </span>
            {timeSince(data.createdAt)} ago
          </p>
        </div>
      </div>
    </div>
  );
}

/*
import React, { useContext, useEffect } from "react";

import CircularImgContainer from "./CircularImgContainer";
import { timeSince } from "../utils/Functions";
import { useWindowSize } from "../utils/Hooks";
import GenericModal from "./GenericModal";

import StarsIcon from "@mui/icons-material/Stars";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";

import { GlobalContext } from "../App";

export default function UserPreview() {
  ////  INITS
  const GlobalCtxt = useContext(GlobalContext);
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  ////  FUNCTIONS
  const handleChat = () => {
    try {
      navigate(
        `/chat/${GlobalCtxt.globalUserPreview._id}/${GlobalCtxt.globalUserPreview.username}`
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    console.log(GlobalCtxt.globalUserPreview);
  }, [GlobalCtxt.globalUserPreview]);

  return (
    <>
      {GlobalCtxt.globalUserPreview ? (
        <>
          <div
            style={{
              width: "100%",
              backgroundColor: "rgba(255,255,255,0.8)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 5,
              boxSizing: "border-box",
              borderRadius: 4,
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "right",
              }}
            >
              <IconButton
                onClick={() => {
                  GlobalCtxt.updateGlobalUserPreview(null);
                }}
              >
                <CancelIcon />
              </IconButton>
            </div>
            <CircularImgContainer
              url={GlobalCtxt.globalUserPreview.profilePic}
            />
            <h4>{GlobalCtxt.globalUserPreview.username}</h4>
            {GlobalCtxt.globalUserPreview.username !==
            localStorage.getItem("username") ? (
              <>
                <Button
                  variant="contained"
                  onClick={handleChat}
                  sx={{ marginBottom: "2px" }}
                >
                  Chat with {GlobalCtxt.globalUserPreview.username}
                </Button>
                <br></br>
              </>
            ) : null}

            {GlobalCtxt.globalUserPreview?.tier &&
            GlobalCtxt.globalUserPreview.tier > 0 ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backgroundColor: "green",
                  padding: 5,
                  boxSizing: "border-box",
                }}
              >
                <StarsIcon sx={{ color: "gold" }} fontSize="large" />
                <p style={{ color: "white", fontWeight: "bold", margin: 5 }}>
                  {GlobalCtxt.globalUserPreview.tier === 3
                    ? `Community Leader`
                    : `Paying Supporter`}
                </p>
              </div>
            ) : null}
            <div style={{ width: "100%" }}>
              <p>
                <span style={{ fontWeight: 500 }}>Joined:</span>{" "}
                {timeSince(GlobalCtxt.globalUserPreview.createdAt)} ago
              </p>
              <p>
                <span style={{ fontWeight: 500 }}>Electorate:</span>{" "}
                {GlobalCtxt.globalUserPreview.electorate}
              </p>
              {GlobalCtxt.globalUserPreview.affiliation ? (
                <p>
                  <span style={{ fontWeight: 500 }}>Affiliation:</span>{" "}
                  {GlobalCtxt.globalUserPreview.affiliation}
                </p>
              ) : null}
            </div>
          </div>
          <br></br>
        </>
      ) : null}
    </>
  );
}
*/
