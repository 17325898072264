import React from "react";

export default function StreamTab({ initCurrentStream, data }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "10px",
        marginBottom: "8px",
        borderRadius: "8px",
        backgroundColor: "#333", // Dark background
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.3)",
        transition: "background-color 0.3s ease",
        cursor: "pointer",
      }}
      onClick={() => {
        initCurrentStream(data);
      }}
      onMouseEnter={
        (e) => (e.currentTarget.style.backgroundColor = "#444") // Slightly lighter on hover
      }
      onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#333")}
    >
      <div
        style={{
          height: 12,
          width: 12,
          borderRadius: 360,
          backgroundColor: "limegreen",
          marginRight: 8,
          border: "2px solid #333", // Border matches background color
        }}
      />
      <p
        style={{
          margin: 0,
          fontSize: "14px",
          fontWeight: "500",
          color: "#FFF",
          maxWidth: "80%",
        }}
      >
        {data.passthrough}
      </p>
    </div>
  );
}
