import React, { useState, useEffect } from "react";

const LetterTemplate = ({
  address,
  todaysDate,
  recipientName,
  repType,
  senderName,
}) => {
  ////  STATES
  const [localDateString, setLocalDateString] = useState("");

  ////  EFFECTS
  useEffect(() => {
    const today = new Date().toLocaleDateString();
    setLocalDateString(today);
  }, []);

  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    lineHeight: "1.6",
    margin: "0 auto",
    width: "96%",
    padding: "20px",
    boxSizing: "border-box",
    border: "1px solid #ddd",
    borderRadius: "5px",
    backgroundColor: "#f9f9f9",
    fontSize: 14,
  };

  const headerStyle = {
    marginBottom: "20px",
  };

  const boldText = {
    fontWeight: "bold",
  };

  const paragraphStyle = {
    marginBottom: "16px",
  };

  const listStyle = {
    marginLeft: "20px",
    marginBottom: "16px",
  };

  const signatureStyle = {
    marginTop: "40px",
  };

  return (
    <div style={containerStyle}>
      <div style={headerStyle}>
        <p>{address}</p>
        <p>{localDateString}</p>
      </div>
      <p>Dear {recipientName},</p>
      <p style={{ ...paragraphStyle, ...boldText }}>
        International Health Regulations
      </p>
      <p style={paragraphStyle}>
        I am writing to you as my local {repType}. I wish to bring to your
        attention the serious consequences that will result from provisions in
        two treaties—the{" "}
        <span style={boldText}>
          2024 International Health Regulations (IHRs)
        </span>{" "}
        and the <span style={boldText}>Pandemic Agreement</span>—if they come
        into legislative force in June 2025, as is currently planned.
      </p>
      <p style={paragraphStyle}>
        If this happens, it will damage the social, economic, and material
        health of Australians and destroy our democratic freedom. The Federal
        Government considers the WHO to be the global authority on public
        health, and these treaties will cement the WHO’s power and influence.
      </p>
      <p style={paragraphStyle}>
        The WHO will be able to declare an emergency, even where there is not a
        single human death from a disease, with little or no evidence of an
        actual emergency, and even if this is against the advice of its own
        expert committee. The WHO will then issue recommendations for dealing
        with the so-called emergency and expect the world to follow.
      </p>
      <p style={paragraphStyle}>
        In supporting these treaties, the federal government is flagging
        Australia’s intention to willingly follow the WHO’s lead, reducing
        Australian decision-makers to little more than a rubber stamp.
      </p>
      <p style={paragraphStyle}>
        With the WHO warning of more public health emergencies, and its greater
        influence as a result of these treaties, we can expect more lockdowns
        and other extreme measures, resulting in:
      </p>
      <ul style={listStyle}>
        <li>
          <span style={boldText}>Small business closures</span>, leading to job
          losses and financial distress
        </li>
        <li>
          <span style={boldText}>Social isolation</span>, leading to an increase
          in mental health problems such as loneliness, depression, suicide, and
          domestic violence
        </li>
        <li>
          <span style={boldText}>Travel restrictions</span> and increased
          surveillance
        </li>
        <li>
          <span style={boldText}>School closures</span>, resulting in less class
          time, lower academic performance, and social isolation of children
        </li>
        <li>
          <span style={boldText}>
            Imposition of pharmaceutical health interventions
          </span>
          , whether or not they have been successfully trialed, whether or not
          they are effective, whether or not there are better alternatives, and
          whether or not people want them.
        </li>
      </ul>
      <p style={paragraphStyle}>
        I urge you to remember that the WHO, which has developed these two
        treaties, is not an independent health authority, as it receives more
        than 80% of its funding from non-member states, and its sponsors can
        specify the WHO’s use of these funds.
      </p>
      <p style={paragraphStyle}>
        The provisions in these documents benefit the pharmaceutical industry
        far more than they benefit Australians.
      </p>
      <p style={paragraphStyle}>
        I urge you to take urgent action to stop Australia from supporting the
        Pandemic Agreement at an upcoming meeting of the WHO and to reject the
        IHR amendments before the end-of-March-2025 deadline.
      </p>
      <p style={paragraphStyle}>
        Please be advised that I will not be voting for any candidate who does
        not speak up in opposition to these treaties.
      </p>
      <p style={paragraphStyle}>
        Thank you for your attention to this important message. I look forward
        to receiving your response.
      </p>
      <p>Yours faithfully,</p>
      <br />
      <br />
      <p style={signatureStyle}>{senderName}</p>
    </div>
  );
};

export default LetterTemplate;
