import React, { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

export default function StreamingEndpoint({ data, updateArr, status }) {
  ////  STATES
  const [showKey, setShowKey] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);

  ////  FUNCTIONS
  function anonymizeString(input) {
    try {
      return "xxxxxxxxxxxx";
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "whitesmoke",
        padding: 5,
        boxSizing: "border-box",
        border: "2px solid green",
        borderRadius: 2,
        marginBottom: 5,
      }}
      key={data.id}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p style={{ fontWeight: 400, color: "black", margin: "10px 0" }}>
          {data.passthrough}
        </p>
        {status === "pre" ? (
          <IconButton
            onClick={() => {
              setBtnDisabled(true);
              updateArr(data);
              setTimeout(() => {
                setBtnDisabled(false);
              }, 5000);
            }}
          >
            <DeleteIcon />
          </IconButton>
        ) : (
          <div
            style={{
              height: 16,
              width: 16,
              marginRight: 4,
              borderRadius: 360,
              backgroundColor: status === "standby" ? "orange" : "limegreen",
              border: "0.5px solid grey",
            }}
          />
        )}
      </div>
      {status === "pre" && data.url ? (
        <>
          <hr style={{ opacity: 0.25 }}></hr>
          <p style={{ color: "black" }}>
            <span style={{ fontWeight: 600 }}>URL:</span> {data.url}
          </p>
        </>
      ) : null}

      {status === "pre" && data.stream_key ? (
        <>
          {showKey ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <p style={{ color: "black" }}>
                <span style={{ fontWeight: 600 }}>Key:</span> {data.stream_key}
              </p>
              <IconButton
                sx={{ marginLeft: "5px" }}
                onClick={() => {
                  setShowKey(false);
                }}
              >
                <VisibilityOffIcon />
              </IconButton>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <p style={{ color: "black" }}>
                <span style={{ fontWeight: 600 }}>Key:</span>{" "}
                {anonymizeString(data.stream_key)}
              </p>
              <IconButton
                sx={{ marginLeft: "5px" }}
                onClick={() => {
                  setShowKey(true);
                }}
              >
                <VisibilityIcon />
              </IconButton>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
}
