import React, { useState } from "react";

import DynamicFrame from "../common-components/DynamicFrame";
import GenericMain from "../common-components/GenericMain";
import GenericSidebar from "../common-components/GenericSidebar";
import GenericModal from "../common-components/GenericModal";
import APExplainer from "./APExplainer";
import Div1Sidebar from "./Div1Sidebar";

import Button from "@mui/material/Button";
import "../../App.css";
import "animate.css";

export default function AffidavitDiv1({
  windowSize,
  GlobalCtxt,
  updateCurrentDiv,
}) {
  ////  STATES
  const [showFaq, setShowFaq] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  return (
    <>
      <GenericModal
        open={showFaq}
        handleClose={() => setShowFaq(false)}
        styling={{ marginTop: 20, maxHeight: "85vh" }}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.8)",
          }}
        >
          <Div1Sidebar />
        </div>
      </GenericModal>
      {windowSize.width > 768 ? (
        <h1 className="animate__animated animate__fadeInDown">
          The Declaration Project
        </h1>
      ) : (
        <h3 className="animate__animated animate__fadeInDown">
          The Declaration Project
        </h3>
      )}
      {windowSize.width <= 768 ? (
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            setShowFaq((prev) => !prev);
          }}
          style={{ marginBottom: 10 }}
          className="animate__animated animate__fadeIn animate__delay-1s"
        >
          FAQ
        </a>
      ) : null}
      <div className="animate__animated animate__fadeIn animate__delay-1s">
        <DynamicFrame windowSize={windowSize} styling={{ height: "72vh" }}>
          <GenericMain
            styling={{
              backgroundColor: GlobalCtxt.colourScheme.primaryGreen,
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h3 style={{ color: "white" }}>Turn your story into evidence!</h3>
              <APExplainer windowSize={windowSize} />
              {buttonClicked ? (
                <p
                  style={{
                    color: "white",
                    opacity: 0.75,
                    fontSize: 14,
                    textAlign: "center",
                  }}
                >
                  Scroll down to continue.
                </p>
              ) : (
                <Button
                  sx={{ margin: "10px 0px 5px 0px" }}
                  variant="contained"
                  onClick={() => {
                    updateCurrentDiv(1);
                    setTimeout(() => {
                      setButtonClicked(true);
                    }, 2000);
                  }}
                >
                  GET STARTED!
                </Button>
              )}
            </div>
          </GenericMain>
          {windowSize.width > 768 ? (
            <GenericSidebar
              windowSize={windowSize}
              styling={{
                width: windowSize.width * 0.3,
                backgroundColor: "rgba(0,0,0,0.8)",
              }}
            >
              <Div1Sidebar />
            </GenericSidebar>
          ) : null}
        </DynamicFrame>
      </div>
    </>
  );
}
