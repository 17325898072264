//  REVISIT: Explainer text and FAQ box.
//  Payment flow is basically set, but some testing of edge cases might be necessary.
//  Also need to include cancellation functionality in the Profile page.

import React, { useState, useEffect } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import { subOptions } from "../utils/Data";
import UpgradeFAQ from "../components/checkout-components/UpgradeFAQ";
import GenericModal from "../components/common-components/GenericModal";
import ReferralModal from "../components/checkout-components/ReferralModal";
import {
  createStripeSession,
  changeSubscription,
  checkForValidSub,
} from "../functions/PaymentFunctions";
import { updateLocalStorage } from "../functions/GeneralFunctions";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";

import "../App.css";

export default function Upgrade() {
  ////  STATES
  const [explainerModal, setExplainerModal] = useState(null);
  const [currentTier, setCurrentTier] = useState(0);
  const [subPayload, setSubPayload] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);

  ////  FUNCTIONS
  const preSubmit = async (data) => {
    try {
      if (
        typeof data.to_tier !== "number" ||
        typeof currentTier !== "number" ||
        data.to_tier < 0
      ) {
        alert(
          "Error processing your request. Please refresh the page to try again."
        );
        return;
      }

      // Sets lookup_key, to_tier, and price, all imported from Data.
      setSubPayload(data);

      if (currentTier === 0) {
        setExplainerModal("pre-submit");
      } else if (currentTier < data.to_tier) {
        setExplainerModal("pre-submit");
      } else if (currentTier > data.to_tier) {
        setExplainerModal("downgrade");
      } else if (currentTier === data.to_tier) {
        // Shouldn't occur as button is disabled when this condition is met.
      }
    } catch (err) {
      console.error("Error during subscription process:", err);
    }
  };

  const handleSubmission = async (affiliation) => {
    try {
      setBtnDisabled(true);
      const check = await checkForValidSub();
      if (check.data?.tier === 0 || !check.data?.stripeId) {
        const res = await createStripeSession({
          ...subPayload,
          ...affiliation,
          baseURL: window.location.origin,
        });
        if (res.data?.url) {
          window.location.href = res.data.url;
        }
      } else {
        const upgraded = await changeSubscription({
          ...affiliation,
          to_tier: subPayload.to_tier,
        });
        await updateLocalStorage(upgraded.data);
        setExplainerModal(null);
        alert("Your subscription tier was successfully updated!");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.log(err);
      alert(err.message);
      setBtnDisabled(false);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    try {
      if (localStorage.getItem("tier")) {
        setCurrentTier(parseInt(localStorage.getItem("tier")));
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <ScreenContainer>
      <Navbar />
      <GenericModal
        open={explainerModal ? true : false}
        handleClose={() => setExplainerModal(null)}
      >
        {explainerModal === "pre-submit" ? (
          <ReferralModal
            handleSubmission={handleSubmission}
            btnDisabled={btnDisabled}
          />
        ) : explainerModal === "downgrade" ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "center" }}>
              Are you sure you want to downgrade your subscription?
            </h4>
            <p style={{ textAlign: "center" }}>
              Downgrading may result in the immediate loss of some privileges.
            </p>
            <br></br>
            <Button
              variant="contained"
              onClick={() => {
                setExplainerModal(null);
              }}
            >
              Return to paywall
            </Button>
            <br></br>
            <Button
              variant="outlined"
              onClick={() => {
                handleSubmission({ affiliation: null });
              }}
            >
              Confirm downgrade
            </Button>
          </div>
        ) : (
          <p style={{ textAlign: "center" }}>{explainerModal}</p>
        )}
      </GenericModal>
      <h1 style={{ textAlign: "center" }}>Choose what works for you!</h1>
      <p style={{ textAlign: "center" }}>
        All prices are quoted in Australian Dollars. Scroll down for FAQ.
      </p>
      <br></br>
      <div className="options-container">
        {subOptions.map((foo, i) => {
          return (
            <div key={`card-${i}`} className="option-card">
              <h3>{foo.label}</h3>
              <h1>
                {foo.price_str}
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  /month
                </span>
              </h1>
              <hr style={{ width: "100%" }}></hr>
              {foo.perks.map((el, ii) => {
                return (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                    key={`${foo.label}-${ii}`}
                  >
                    <CheckCircleIcon
                      sx={{ marginRight: "5px", color: "green" }}
                    />
                    <p style={{ fontSize: 14 }}>{el.perk}</p>
                    {el.explainer ? (
                      <Tooltip
                        title={el.explainer}
                        onClick={() => setExplainerModal(el.explainer)}
                      >
                        <HelpIcon
                          fontSize="small"
                          sx={{ marginLeft: "5px", color: "grey" }}
                        />
                      </Tooltip>
                    ) : null}
                  </div>
                );
              })}
              <div style={{ flexGrow: 1 }} />
              <hr style={{ width: "100%" }}></hr>
              <div style={{ paddingBottom: 10 }}>
                <Button
                  variant="contained"
                  disabled={
                    currentTier === foo.tier || btnDisabled ? true : false
                  }
                  onClick={() => {
                    preSubmit({
                      lookup_key: foo.lookup_key,
                      to_tier: foo.tier,
                      price: foo.price,
                    });
                  }}
                >
                  {currentTier === 0 || foo.tier === currentTier
                    ? `SELECT`
                    : foo.tier > currentTier
                    ? `UPGRADE`
                    : `DOWNGRADE`}
                </Button>
              </div>
            </div>
          );
        })}
      </div>
      <br></br>
      <hr style={{ width: "98%" }}></hr>
      <h2>Frequently Asked Questions</h2>
      <UpgradeFAQ />
    </ScreenContainer>
  );
}
