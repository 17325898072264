import React, { useState, useEffect, useContext } from "react";

import EditIcon from "@mui/icons-material/Edit";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";

import { AffidavitContext } from "../../screens/AffidavitFlow";

export default function DynamicListItem({ value, index, windowSize }) {
  ////  INITS
  const AffidavitCtxt = useContext(AffidavitContext);

  ////  STATES
  const [editing, setEditing] = useState(false);

  ////  EFFECTS
  useEffect(() => {
    if (value === "") {
      setEditing(true);
    }
  }, []);

  return (
    <div
      style={{
        width: "98%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: 10,
      }}
    >
      {editing === true ? (
        <IconButton
          onClick={() => {
            setEditing(false);
            AffidavitCtxt.removeListItem(index);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ) : (
        <div style={{ width: "10%" }}>
          <h4>{index + 1}.</h4>
        </div>
      )}

      {editing === true ? (
        <TextField
          value={value}
          multiline={true}
          rows={windowSize.width > 768 ? 3 : 4}
          sx={{ width: "80%" }}
          onChange={(e) => {
            console.log("editing");
            AffidavitCtxt.editListItem(e.target.value, index);
          }}
        />
      ) : (
        <div
          style={{
            padding: 10,
            border: "1px solid black",
            width: "80%",
            backgroundColor: "white",
          }}
        >
          <p>{value}</p>
        </div>
      )}
      {editing === true ? (
        <IconButton
          onClick={() => {
            if (value !== "") {
              setEditing(false);
            }
          }}
        >
          <SaveAsIcon />
        </IconButton>
      ) : (
        <IconButton onClick={() => setEditing(true)}>
          <EditIcon fontSize="small" />
        </IconButton>
      )}
    </div>
  );
}
