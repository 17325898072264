import React from "react";

const LetterTips = () => {
  return (
    <div>
      <h2>
        Tips for writing your own letter to your MP, senators, and election
        candidates
      </h2>

      <p>Insert your address and today’s date</p>

      <p>Dear &lt;insert name of your MP/senator/candidate etc&gt;</p>

      <ul>
        <li>Let them know you are a voter in this electorate.</li>
        <li>
          Tell them that you are concerned about the consequences of two
          documents that, if they come into force in Australia, will severely
          affect our health, our economy, and our democratic rights.
        </li>
      </ul>

      <p>These are:</p>
      <ul>
        <li>the 2024 International Health Regulations (2024 IHRs); and</li>
        <li>the Pandemic Agreement.</li>
      </ul>

      <p>
        Explain that the 2024 International Health Regulations were approved by
        Minister Mark Butler and are due to come into effect in June 2025.
      </p>

      <p>
        Say that you are also concerned that Minister Butler may sign The
        Pandemic Agreement in December.
      </p>

      <p>
        Explain that these documents, once implemented in Australia, will create
        enormous hardship for Australians and have the potential to destroy life
        in this country as we know it, whenever the WHO deems a “health”
        emergency or potential health emergency, warrants its intervention.
      </p>

      <p>
        Say that the provisions of these documents do not benefit Australians,
        but they do benefit Big Business.
      </p>

      <p>
        Ask your MP to stop this from happening. Say that you will not be voting
        for any candidate who does not oppose these documents.
      </p>

      <p>
        Consider enclosing a copy of '10 Reasons why Australia MUST cancel its
        treaties with the WHO', which can be downloaded{" "}
        <a
          href="https://storage.googleapis.com/bucket-775/10-reasons.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          HERE.
        </a>
      </p>

      <p>
        Ask for the MP’s office to issue you with a receipt for your letter.
      </p>
    </div>
  );
};

export default LetterTips;
