import React, { useState, useContext, useEffect } from "react";
import { QueueContext } from "../conference-components/QueueCtxtProvider";
import Button from "@mui/material/Button";

export default function QueueInvite({ data, index }) {
  ////  INITS
  const QueueCtxt = useContext(QueueContext);

  ////  STATES
  const [invited, setInvited] = useState(false);

  ////  FUNCTIONS
  ////  EFFECTS
  useEffect(() => {
    if (QueueCtxt.inviteCountdown === 0) {
      setInvited(false);
    } else {
      console.log(QueueCtxt.inviteCountdown);
    }
  }, [QueueCtxt?.inviteCountdown]);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#f8f9fa", // Light off-white color for contrast
        borderRadius: "8px", // Rounded corners for a modern feel
        padding: "10px 15px", // More spacious padding
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "8px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
      }}
    >
      <p style={{ color: "#333", fontSize: "16px", fontWeight: 500 }}>
        ID: <span style={{ fontWeight: 700 }}>{data.decId}</span>
      </p>

      {index === 0 &&
      QueueCtxt?.inviteCountdown &&
      QueueCtxt.inviteCountdown >= 0 &&
      QueueCtxt.inviteCountdown <= 60 &&
      data.invited ? (
        <h4 style={{ color: "#28a745", fontSize: "18px" }}>
          {QueueCtxt.inviteCountdown}
        </h4>
      ) : (
        <>
          {index === 0 ? (
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                QueueCtxt.updateDecCode(data.decId);
                QueueCtxt.inviteNext();
              }}
              sx={{
                backgroundColor: "#28a745",
                color: "white",
                "&:hover": {
                  backgroundColor: "#218838",
                },
              }}
            >
              INVITE
            </Button>
          ) : null}
        </>
      )}
    </div>
  );
}
