import React from "react";

export default function ChecklistTab() {
  return (
    <>
      <h4>Witnessing process:</h4>
      <div
        style={{
          width: "100%",
          padding: 10,
          boxSizing: "border-box",
          border: "1px solid white",
        }}
      >
        <h4 style={{ color: "orange", textAlign: "center" }}>
          IMPORTANT INFORMATION
        </h4>
        <p style={{ color: "orange" }}>
          As a Witness, you are NOT responsible for what the declarant writes in
          their declaration. However, if you are concerned that the declaration
          contains statements that are deliberately false or misleading, you can
          refuse to sign it.
        </p>
        <p style={{ color: "orange" }}>
          You must also refuse to sign if you believe that the declarant is
          mentally impaired or acting under coercion.
        </p>
        <p style={{ color: "orange" }}>
          If you are not willing to sign, please explain your reasoning before
          asking the user to leave the call. If they refuse to leave, you may
          remove them manually through the call interface.
        </p>
      </div>
      <div style={{ width: "100%", color: "lightgrey" }}>
        <p style={{ fontWeight: "bold" }}>SETTING UP</p>
        <ol style={{ lineHeight: 1.6 }}>
          <li>
            Click the colourful 'Join Call' button to activate the video call.
          </li>
          <li>
            In the 'Manage Queue' tab, click 'Activate Session!' to open your
            queue.
          </li>
          <li>
            When a user enters your queue, click 'Invite' to send them an
            invitation to the call.
          </li>
          <li>
            The user will appear automatically upon accepting your invitation.
            If they don't accept within 60 seconds, they will be moved to the
            second place in the queue.
          </li>
        </ol>
        <p style={{ fontWeight: "bold" }}>WITNESSING</p>
        <ol style={{ lineHeight: 1.6 }}>
          <li>
            Before the process begins, instruct the user to read the information
            under the 'Info for Declarants' tab. Confirm that they understand
            that lying on a statutory declaration is a criminal offence.
          </li>
          <li>
            Navigate to the 'View Declaration' tab to view the user's
            declaration.
          </li>
          <li>
            Make sure the user's camera is positioned so that you can see them
            writing on their declaration.
          </li>
          <li>When the user is ready, instruct them to sign Section 3.</li>
          <li>
            Instruct the user to date and initial every page of their
            declaration.
          </li>
          <li>
            Instruct the user to complete Section 4 if they haven't already
            (they must include a valid phone number or email address, but not
            necessarily both).
          </li>
          <li>Instruct the user to complete sections 5, 6 and 7.</li>
          <li>
            Once the above steps have been completed,{" "}
            <strong>it is your turn to sign.</strong>
          </li>
          <li>
            Your personal details should be pre-filled in the 'Witness
            Information' field below the user's declaration. Ensure that these
            details are correct and up-to-date. If they are not, make
            modifications as necessary.
          </li>
          <li>Complete the 'Date' and 'Month/Year' fields.</li>
          <li>
            Double-check the above details, then click 'Sign Declaration'. An
            electronically signed copy will be automatically sent to the user
            via email.
          </li>
          <li>
            Confirm that the user has received the signed copy (it may go to
            their spam folder). This may take a minute or two.
          </li>
          <li>
            Once the user has received the signed copy, the process is complete
            and they may exit the call. Instructions on what to do next will be
            provided in the email they just received.
          </li>
        </ol>
      </div>
    </>
  );
}
