import React, { useState, useEffect, useContext } from "react";

import { useParams, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import DynamicFrame from "../components/common-components/DynamicFrame";
import GenericMain from "../components/common-components/GenericMain";
import GenericSidebar from "../components/common-components/GenericSidebar";
import { countVotes, resolvePollManually } from "../functions/PollFunctions";
import { useWindowSize } from "../utils/Hooks";

import { GlobalContext } from "../App";

const textStyles = { margin: 5 };

export default function ResolvePoll() {
  ////  INITS
  const GlobalCtxt = useContext(GlobalContext);
  const params = useParams();
  const windowSize = useWindowSize();
  const navigate = useNavigate();

  ////  STATES
  const [pollResults, setPollResults] = useState(null);
  const [votingRecord, setVotingRecord] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedCount, setSelectedCount] = useState(null);
  const [winReason, setWinReason] = useState(null);

  ////  FUNCTIONS
  const fetchPollResult = async () => {
    try {
      const result = await countVotes(params.pollId);
      console.log(result.data);
      setPollResults(result.data.poll);
      setVotingRecord(result.data.voteRecord);
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  const resolve = async () => {
    try {
      if (selectedOption && winReason) {
        const toSubmit = {
          pollId: params.pollId,
          incumbentId: pollResults.incumbentId,
          newSecretaryId: selectedId,
          newSecretaryName: selectedOption.text,
          channelId: pollResults.channelId,
          channelName: pollResults.channelName,
          summaryPrompt: winReason,
        };

        await resolvePollManually(toSubmit);
        setTimeout(() => {
          navigate(`/home/${pollResults.channelId}/${pollResults.channelName}`);
        }, 1000);
      } else {
        alert("needs selectedOption and winReason");
      }
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  /*const countMatches = (id) => {
    return votingRecord.reduce((count, item) => {
      return item.forId === id ? count + 1 : count;
    }, 0);
  };*/

  const countMatches = (id) => {
    let filtered = votingRecord.filter((el) => el.forId === id);
    return filtered.length;
  };

  ////  EFFECTS
  useEffect(() => {
    if (params.pollId) {
      fetchPollResult();
    } else {
      //
    }
  }, []);

  useEffect(() => {
    if (selectedId) {
      setSelectedCount(countMatches(selectedId));
    }
  }, [selectedId]);

  useEffect(() => {
    if (selectedOption) {
      setSelectedId(selectedOption.id);
    }
  }, [selectedOption]);

  return (
    <ScreenContainer>
      <Navbar />
      <DynamicFrame windowSize={windowSize}>
        <GenericMain styling={{ backgroundColor: "lightgrey" }}>
          {pollResults ? (
            <>
              <h2>{pollResults.channelName || pollResults.visible_to[1]}</h2>
              {pollResults.options.map((el, i) => {
                return (
                  <div
                    style={{
                      padding: 10,
                      boxSizing: "border-box",
                      width: "100%",
                      border:
                        el?.more?.residence === pollResults.channelName
                          ? "2px solid green"
                          : "1px solid black",
                      borderRadius: 4,
                      marginBottom: 10,
                      backgroundColor:
                        selectedId === el.id ? "gold" : "whitesmoke",
                    }}
                    key={`${i}-${el.id}`}
                    onClick={() => {
                      setSelectedOption(el);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h4 style={textStyles}>{el.text}</h4>
                      <h4>{el.votes}</h4>
                    </div>
                    <p style={textStyles}>{el.id}</p>
                    <p style={textStyles}>{el.more.tier}</p>
                    <p style={textStyles}>{el.more.residence}</p>
                  </div>
                );
              })}
            </>
          ) : null}
          <RadioGroup
            onChange={(e) => {
              setWinReason(e.target.value);
            }}
          >
            <FormControlLabel
              value="popular vote"
              control={<Radio />}
              label="popular vote"
              key={0}
            />
            <FormControlLabel
              value="member advantage"
              control={<Radio />}
              label="member advantage"
              key={1}
            />
            <FormControlLabel
              value="local advantage"
              control={<Radio />}
              label="local advantage"
              key={2}
            />
          </RadioGroup>
          <h4>{winReason}</h4>
          {selectedOption ? (
            <Button
              variant="contained"
              onClick={resolve}
            >{`Award ${selectedOption?.text}`}</Button>
          ) : null}
        </GenericMain>
        <GenericSidebar
          windowSize={windowSize}
          styling={{ width: windowSize.width * 0.4 }}
        >
          <h4 style={{ color: "white" }}>Matches: {selectedCount}</h4>
          {votingRecord
            ? votingRecord.map((el, i) => {
                return (
                  <div
                    style={{
                      width: "100%",
                      backgroundColor:
                        selectedId === el.forId ? "gold" : "whitesmoke",
                      fontSize: 12,
                      marginBottom: 2,
                    }}
                    key={`${i}-${el.forId}`}
                  >
                    <p>{el.forId}</p>
                    <p>{el.for}</p>
                  </div>
                );
              })
            : null}
        </GenericSidebar>
      </DynamicFrame>
    </ScreenContainer>
  );
}
