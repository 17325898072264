import React, { useEffect, useState } from "react";

import MenuItem from "@mui/material/MenuItem";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import CommentIcon from "@mui/icons-material/Comment";
import EmailIcon from "@mui/icons-material/Email";
import PollIcon from "@mui/icons-material/Poll";
import { useNavigate } from "react-router-dom";

import { timeSince } from "../../functions/GeneralFunctions";

const iconStyles = {
  fontSize: 16,
};

export default function NotificationSingle({ data }) {
  ////  INITS
  const navigate = useNavigate();

  ////  STATES
  const [type, setType] = useState("message");

  ////  FUNCTIONS
  const handleClick = () => {
    try {
      if (data.type === "chat") {
        navigate("/chat");
        return;
      }

      if (data?.targetId) {
        if (data.type === "like-post") {
          navigate(`/post/${data.targetId}`);
        } else if (data.type === "comment-post") {
          navigate(`/post/${data.targetId}`);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (data?.type) {
      if (
        data.type === "like-post" ||
        data.type === "like-video" ||
        data.type === "like-doc" ||
        data.type === "like-comment"
      ) {
        setType("like");
      } else if (
        data.type === "new-poll" ||
        data.type === "poll-resolved" ||
        data.type === "nomination"
      ) {
        setType("poll");
      }
    }
  }, []);

  return (
    <MenuItem
      onClick={handleClick}
      sx={{
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.05)",
        },
      }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "nowrap",
          alignItems: "center",
          backgroundColor:
            type === "message"
              ? "#E0F7FA"
              : type === "like"
              ? "#E8F5E9"
              : type === "poll"
              ? "#FFF3E0"
              : null,
          padding: "8px 12px",
          borderRadius: 8,
          width: "100%",
        }}
      >
        {type === "like" ? (
          <ThumbUpIcon sx={iconStyles} />
        ) : data.type === "comment" ||
          data.type === "comment-post" ||
          data.type === "comment-comment" ? (
          <CommentIcon sx={iconStyles} />
        ) : data.type === "chat" ? (
          <EmailIcon sx={iconStyles} />
        ) : type === "poll" ? (
          <PollIcon sx={iconStyles} />
        ) : null}
        <div style={{ marginLeft: 12, flexGrow: 1 }}>
          <p
            style={{
              fontSize: 14,
              margin: "0px",
              fontWeight: data.highlight ? "bold" : "normal",
              lineHeight: 1.5,
            }}
          >
            {data.msg}
          </p>
          <p
            style={{
              fontSize: 12,
              color: "#888",
              margin: "4px 0px 0px 0px",
            }}
          >
            {timeSince(data.createdAt)} ago
          </p>
        </div>
      </div>
    </MenuItem>
  );
}
