import Axios from "axios";
import authenticatedConfig from "../utils/Token";

const getArchivedWebpage = async (url) => {
  try {
    const archived = await Axios.get(
      `https://archive.org/wayback/available?url=${url}`
    );
    console.log(archived);
    if (archived?.data?.archived_snapshots?.closest?.url) {
      let archivedUrl = archived.data.archived_snapshots.closest.url;

      // Check if the URL is using HTTP and convert it to HTTPS if necessary
      if (archivedUrl.startsWith("http://")) {
        archivedUrl = archivedUrl.replace("http://", "https://");
      }

      return archivedUrl;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
    throw new Error("Unable to generate an archived link for this webpage.");
  }
};

const submitNCDoc = async (data) => {
  try {
    const doc = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/ncDocs/submit-nc-doc`,
      data,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getNCDocs = async (data) => {
  try {
    console.log(data);
    const { sortKey, skip, limit, timeframe } = data;
    const docs = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/ncDocs/get-nc-docs?sortKey=${sortKey}&skip=${skip}&limit=${limit}&timeframe=${timeframe}`,
      authenticatedConfig
    );
    return docs;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getAiSummary = async (text, simplified) => {
  try {
    const doc = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/ai/get-text-summary`,
      { text, simplified }
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const bookmarkDoc = async (data) => {
  try {
    const doc = await Axios.put(
      `${process.env.REACT_APP_DEV_SERVER}/ncDocs/bookmark-doc`,
      data,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getBookmarkedDocs = async (skip, limit) => {
  try {
    const docs = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/ncDocs/get-bookmarked-docs?skip=${skip}&limit=${limit}`,
      authenticatedConfig
    );
    return docs;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

export {
  getArchivedWebpage,
  submitNCDoc,
  getNCDocs,
  getAiSummary,
  bookmarkDoc,
  getBookmarkedDocs,
};
