import React, { useState, useEffect } from "react";

import ClickableUsername from "./ClickableUsername";
import { safelyRenderHtml } from "../../functions/GeneralFunctions";
import InteractionsBar from "./InteractionsBar";

export default function Reply({ data }) {
  ////  INITS
  ////  STATES
  const [sanitizedContent, setSanitizedContent] = useState(null);
  const [fetchError, setFetchError] = useState(false);

  ////  FUNCTIONS
  const renderSafely = async () => {
    try {
      const res = await safelyRenderHtml(data.text);
      setSanitizedContent(res);
    } catch (err) {
      console.log(err);
      setFetchError(true);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (data.text) {
      renderSafely();
    }
  }, [data]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        borderRadius: 4,
        padding: 5,
        boxSizing: "border-box",
        marginBottom: 10,
      }}
    >
      <ClickableUsername
        userId={data.authorId}
        username={data.authorName}
        createdAt={data.createdAt}
      />
      {sanitizedContent ? (
        <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
      ) : null}
      {fetchError ? (
        <p style={{ opacity: 0.5 }}>Content could not be retrieved.</p>
      ) : null}
      <InteractionsBar
        authorId={data.authorId}
        contentRef={data._id}
        commentCount={data.comments}
        likeCount={data.likes}
        type="comment"
        fontSize={12}
        colors={{ liked: "green" }}
        margin="0px 0px 15px 0px"
        handleComment="hide"
      />
    </div>
  );
}
