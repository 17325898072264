import React from "react";

import PersonIcon from "@mui/icons-material/Person";

const defaultStyling = {
  width: "200px",
  height: "200px",
  borderRadius: "50%",
  border: "0.5px solid grey",
  backgroundColor: "lightgrey",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export default function CircularImgContainer({ url, styling }) {
  return (
    <div style={{ ...defaultStyling, ...styling }}>
      {url && url !== "undefined" ? (
        <img
          src={url}
          alt=""
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      ) : (
        <PersonIcon sx={{ height: "90%", width: "90%", color: "grey" }} />
      )}
    </div>
  );
}
