import React, { useContext, useState, useRef, useEffect } from "react";

import DynamicFrame from "../components/common-components/DynamicFrame";
import GenericSidebar from "../components/common-components/GenericSidebar";
import GenericMain from "../components/common-components/GenericMain";
import GenericDrawer from "../components/common-components/GenericDrawer";
import GenericModal from "../components/common-components/GenericModal";
import ConvoPreview from "../components/chat-components/ConvoPreview";
//import GenericTextField from "../components/common-components/GenericTextField";
import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import MessageBubble from "../components/chat-components/MessageBubble";
import { useWindowSize } from "../utils/Hooks";
import {
  sendEncryptedMessage,
  decryptKeyAndMessage,
  getObjectStoreNames,
} from "../functions/EncryptionFunctions";
import {
  getConvos,
  getMessages,
  createConvo,
} from "../functions/ChatFunctions";
import { timeSince } from "../functions/GeneralFunctions";

import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import MailLockIcon from "@mui/icons-material/MailLock";
import { useParams, useNavigate } from "react-router-dom";
import { ObjectId } from "bson";
import DOMPurify from "dompurify";

import { GlobalContext } from "../App";

export default function Chat() {
  ////    INITS
  const windowSize = useWindowSize();
  const params = useParams();
  const messageContainerRef = useRef();
  const navigate = useNavigate();
  const GlobalCtxt = useContext(GlobalContext);

  ////  STATES
  const [convoPreviews, setConvoPreviews] = useState([]);
  const [loadState, setLoadState] = useState("ready");
  const [modalVisible, setModalVisible] = useState(false);
  const [currentConvo, setCurrentConvo] = useState(null);
  const [displayedMessages, setDisplayedMessages] = useState([]);
  const [isNewConvo, setIsNewConvo] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [messageInput, setMessageInput] = useState("");

  ////  FUNCTIONS
  const doesConvoExist = (objectsArray) => {
    return objectsArray.find(
      (object) =>
        object.participants && object.participants.includes(params.recipientId)
    );
  };

  const scrollDown = () => {
    try {
      const { scrollHeight, clientHeight } = messageContainerRef.current;
      messageContainerRef.current.scrollTop = scrollHeight - clientHeight;
    } catch (err) {
      console.log(err);
    }
  };

  const decryptMessagesBatch = async (data) => {
    try {
      console.log(data);
      const decryptedMessages = await Promise.all(
        data.map(async (message) => {
          let sessionKey;
          if (message.senderId === localStorage.getItem("_id")) {
            sessionKey = message.senderKey;
          } else {
            sessionKey = message.recipientKey;
          }
          const decryptedContent = await decryptKeyAndMessage(
            message.iv,
            sessionKey,
            message.content
          );
          return {
            ...message,
            decryptedContent: decryptedContent,
          };
        })
      );
      return decryptedMessages;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const renderConvo = async (convo) => {
    try {
      if (loadState === "error") {
        return;
      }
      if (drawerOpen) {
        setDrawerOpen(false);
      }
      setCurrentConvo({
        convoId: convo._id,
        recipientId:
          convo.participants[0] === localStorage.getItem("_id")
            ? convo.participants[1]
            : convo.participants[0],
        recipientName:
          convo.participantNames[0] === localStorage.getItem("username")
            ? convo.participantNames[1]
            : convo.participantNames[0],
      });
      const messages = await getMessages(convo._id);
      console.log(messages);
      const decryptedMessages = await decryptMessagesBatch(messages.data);
      setDisplayedMessages(decryptedMessages);
    } catch (err) {
      console.log(err);
    }
  };

  const handleMessageSend = async () => {
    try {
      if (!currentConvo) {
        alert("Select a message recipient!");
        return;
      }
      setBtnDisabled(true);
      if (isNewConvo === true) {
        await createConvo({
          recipientId: currentConvo.recipientId,
          recipientName: currentConvo.recipientName,
          convoId: currentConvo.convoId,
        });
        setIsNewConvo(false);
      }
      const cleanMessage = DOMPurify.sanitize(messageInput);
      const sent = await sendEncryptedMessage(
        cleanMessage,
        currentConvo.recipientId,
        currentConvo.convoId
      );
      setDisplayedMessages([
        ...displayedMessages,
        {
          senderId: localStorage.getItem("_id"),
          decryptedContent: cleanMessage,
        },
      ]);
      setBtnDisabled(false);
      setMessageInput("");
    } catch (err) {
      console.log(err);
      alert(err.message);
      setBtnDisabled(false);
    }
  };

  const extractAppendedIds = (existingStores) => {
    return existingStores.map((storeName) => {
      const parts = storeName.split("-");
      return parts.length > 1 ? parts[1] : null;
    });
  };

  const checkForMatchingId = (ids) => {
    const localId = localStorage.getItem("_id");
    return ids.includes(localId);
  };

  const checkStoreExists = async () => {
    try {
      const storeArr = await getObjectStoreNames();
      return new Promise((resolve, reject) => {
        if (!Array.isArray(storeArr)) {
          resolve(false);
        }
        const ids = extractAppendedIds(storeArr);
        if (!Array.isArray(ids)) {
          resolve(false);
        }
        const isMatch = checkForMatchingId(ids);
        resolve(isMatch);
      });
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const init = async () => {
    try {
      if (!localStorage.getItem("electorate")) {
        setLoadState("unregistered");
        return;
      }
      const exists = await checkStoreExists();
      if (!exists) {
        setLoadState("error");
        return;
      }
      setLoadState("fetching");
      const convos = await getConvos(convoPreviews.length, 16);
      if (Array.isArray(convos.data)) {
        setConvoPreviews(convos.data);
      } else {
        return;
      }
      if (!params.recipientId || !params.recipientName) {
        if (convos.data.length <= 0) {
          setLoadState("no-convos");
        }
        return;
      }
      const existing = doesConvoExist(convos.data);
      if (existing) {
        renderConvo(existing);
      } else {
        const newId = new ObjectId();
        setCurrentConvo({
          convoId: newId.toString(),
          recipientId: params.recipientId,
          recipientName: params.recipientName,
        });
        setIsNewConvo(true);
      }
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (currentConvo && loadState !== "ready") {
      setLoadState("show-animation");
    }
  }, [currentConvo]);

  useEffect(() => {
    if (loadState === "show-animation") {
      setTimeout(() => {
        setLoadState("ready");
      }, 2700);
    } else {
      setTimeout(() => {
        scrollDown();
      }, 250);
    }
  }, [loadState]);

  useEffect(() => {
    setTimeout(() => {
      scrollDown();
    }, 250);
  }, [displayedMessages]);

  return (
    <ScreenContainer styling={{ background: GlobalCtxt.darkBg }}>
      <Navbar />
      {windowSize.width <= 768 ? (
        <GenericDrawer
          open={drawerOpen}
          handleClose={() => setDrawerOpen(false)}
        >
          <h4 style={{ textAlign: "center" }}>Chat history</h4>
          {Array.isArray(convoPreviews)
            ? convoPreviews.map((el, i) => {
                return (
                  <ConvoPreview
                    data={el}
                    key={`${i}-${el.createdAt}`}
                    handleClick={renderConvo}
                  />
                );
              })
            : null}
        </GenericDrawer>
      ) : null}

      <DynamicFrame windowSize={windowSize} styling={{ height: "90vh" }}>
        {windowSize.width > 768 ? (
          <GenericSidebar
            windowSize={windowSize}
            styling={{ backgroundColor: "rgba(255,255,255,0.8)" }}
          >
            <h4 style={{ textAlign: "center" }}>Chat history</h4>
            {Array.isArray(convoPreviews)
              ? convoPreviews.map((el, i) => {
                  return (
                    <ConvoPreview
                      data={el}
                      key={`${i}-${el.createdAt}`}
                      handleClick={renderConvo}
                    />
                  );
                })
              : null}
          </GenericSidebar>
        ) : null}
        <GenericMain styling={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
          {loadState === "ready" ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {windowSize.width <= 768 ? (
                  <Button onClick={() => setDrawerOpen(true)}>
                    SHOW CONVERSATIONS
                  </Button>
                ) : null}
                {params.recipientName ? (
                  <h4
                    style={{
                      textAlign: "center",
                      color: "limegreen",
                      margin: 8,
                    }}
                  >
                    Chatting with {params.recipientName}
                  </h4>
                ) : (
                  <div style={{ width: "100%" }} />
                )}
              </div>
              <hr style={{ width: "100%", color: "grey", opacity: 0.2 }}></hr>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  overflowY: "auto",
                }}
                ref={messageContainerRef}
              >
                {Array.isArray(displayedMessages)
                  ? displayedMessages.map((el, i) => {
                      return (
                        <MessageBubble
                          sender={el.senderId}
                          content={el.decryptedContent}
                          createdAt={el.createdAt}
                          key={`${i}-${el.createdAt}`}
                        />
                      );
                    })
                  : null}
              </div>
              {windowSize.width > 768 ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px 5px 5px 5px",
                    backgroundColor: "rgba(0,0,0,0.1)",
                  }}
                >
                  <div />
                  <div
                    style={{
                      width: "100%",
                      maxWidth: 768,
                    }}
                  >
                    <TextField
                      sx={{
                        border: `0.5px solid white`,
                        borderRadius: "4px",
                      }}
                      inputProps={{ style: { color: "white" } }}
                      fullWidth={true}
                      multiline={true}
                      rows={3}
                      onChange={(e) => setMessageInput(e.target.value)}
                      disabled={btnDisabled}
                      value={messageInput}
                    />
                    <div style={{ display: "flex", justifyContent: "right" }}>
                      <IconButton
                        sx={{
                          margin: "4px 4px 2px 2px",
                          color: "dodgerblue",
                        }}
                        onClick={handleMessageSend}
                        disabled={btnDisabled}
                      >
                        <SendIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{ width: "100%" }}>
                  <TextField
                    sx={{ border: `0.5px solid white`, borderRadius: "4px" }}
                    inputProps={{ style: { color: "white" } }}
                    fullWidth={true}
                    multiline={true}
                    rows={3}
                    onChange={(e) => setMessageInput(e.target.value)}
                    value={messageInput}
                    disabled={btnDisabled}
                  />
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <IconButton
                      sx={{ margin: "4px 4px 2px 2px", color: "dodgerblue" }}
                      onClick={handleMessageSend}
                      disabled={btnDisabled}
                    >
                      <SendIcon />
                    </IconButton>
                  </div>
                </div>
              )}
            </div>
          ) : loadState === "show-animation" ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid white",
              }}
            >
              <div
                style={{
                  height: 300,
                  width: 300,
                }}
              >
                <DotLottieReact
                  src="https://lottie.host/00f50a21-84d2-43c7-8191-24e47c5f06b7/ukzhklJIsE.lottie"
                  autoplay
                />
              </div>
            </div>
          ) : loadState === "unregistered" ? (
            <p style={{ color: "white", textAlign: "center" }}>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/register");
                }}
                style={{ color: "lightblue" }}
              >
                Sign up for free
              </a>{" "}
              to start using the encrypted messaging feature!
            </p>
          ) : loadState === "fetching" ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h4 style={{ color: "lightgrey" }}>
                Select a conversation to start chatting!
              </h4>
              {windowSize.width <= 768 ? (
                <Button onClick={() => setDrawerOpen(true)}>
                  VIEW PAST CONVERSATIONS
                </Button>
              ) : null}
            </div>
          ) : loadState === "no-convos" ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h4 style={{ color: "lightgrey" }}>No chat history.</h4>
            </div>
          ) : loadState === "error" ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                color: "lightgrey",
              }}
            >
              <h4>Unable to access your encryption key.</h4>
              <p>
                You are unable to send and receive messages as your encryption
                key cannot be verified.
              </p>
              <p>This is likely due to one of the following reasons:</p>
              <ol>
                <li>You haven't created your key yet.</li>
                <li>
                  You are using a different device to that which you registered
                  on.
                </li>
                <li>
                  You are using a different browser to that which you registered
                  on.
                </li>
                <li>
                  You generated your encryption key while in private browsing
                  mode.
                </li>
                <li>
                  You have created a second account on the same device as your
                  first.
                </li>
              </ol>
              <p>
                Encryption keys can be created and reset on your{" "}
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/profile");
                  }}
                  sx={{ color: "skyblue" }}
                >
                  Profile
                </a>
                .
              </p>
            </div>
          ) : null}
        </GenericMain>
      </DynamicFrame>
    </ScreenContainer>
  );
}
