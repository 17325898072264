import React, { useEffect, useState, useContext } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import ConfettiExplosion from "react-confetti-explosion";
import "animate.css";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import { confirmPurchaseCode } from "../functions/PaymentFunctions";
import { updateLocalStorage } from "../functions/GeneralFunctions";

import { GlobalContext } from "../App";

export default function SubSuccess() {
  ////  INITS
  const params = useParams();
  const navigate = useNavigate();
  const GlobalCtxt = useContext(GlobalContext);

  ////  STATES
  const [success, setSuccess] = useState(null);
  const [confetti, setConfetti] = useState(false);

  ////  FUNCTIONS
  const processUpgrade = async () => {
    try {
      const res = await confirmPurchaseCode(params.confirmationCode);
      await updateLocalStorage(res.data);
      setConfetti(true);
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    processUpgrade();
  }, []);

  useEffect(() => {
    if (confetti) {
      setSuccess("hidden");
      setTimeout(() => {
        setSuccess(true);
      }, 3000);
    }
  }, [confetti]);

  return (
    <ScreenContainer>
      <Navbar />
      {confetti ? (
        <ConfettiExplosion particleCount={200} duration={2500} />
      ) : null}
      {success === null ? (
        <CircularProgress />
      ) : success === true ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 10,
            boxSizing: "border-box",
            backgroundColor: "whitesmoke",
            width: "100vw",
            maxWidth: 768,
            borderRadius: 4,
            boxShadow: GlobalCtxt.genericBoxShadow,
          }}
          className="animate__animated animate__fadeIn"
        >
          <h4 style={{ textAlign: "center" }}>
            Congratulations on becoming a paid member of the PhaseChamber
            community!
          </h4>
          <Button
            variant="contained"
            onClick={() => {
              navigate(`/home/${GlobalCtxt.nationalId}/National/reload`);
            }}
          >
            Back to homepage
          </Button>
        </div>
      ) : success === "hidden" ? null : null}
    </ScreenContainer>
  );
}

/*
import React, { useEffect, useState, useContext } from "react";


import Cookies from "js-cookie";

import {
  confirmPurchaseCode,
  updateLocalStorage,
  logout,
  upgrade,
} from "../utils/Functions";
import ScreenContainer from "../common-components/ScreenContainer";
import Navbar from "../common-components/Navbar";

import { GlobalContext } from "../App";

const displayStyle = {
  width: "98%",
  maxWidth: 600,
  border: "1px solid black",
  borderRadius: 8,
  padding: 8,
  boxSizing: "border-box",
  marginBottom: 10,
};

export default function SubSuccess() {
  ////  INITS
  const navigate = useNavigate();
  const params = useParams();
  const GlobalCtxt = useContext(GlobalContext);

  ////  STATES
  
  const [showNavbar, setShowNavbar] = useState(false);

  const init = async () => {
    try {
      const upgradeDetails = await confirmPurchaseCode(params.code);
      const upgraded = await upgrade(upgradeDetails.data);
      Cookies.set("session-token", upgraded.data.token, { expires: 90 });
      await updateLocalStorage(upgraded.data.userInfo);
      setTimeout(() => {
        setSuccess(true);
      }, 250);
    } catch (err) {
      console.log(err);
      setSuccess(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setConfetti(true);
      }, 2000);
    }
  }, [success]);

  useEffect(() => {
    if (confetti) {
      setTimeout(() => {
        setShowNavbar(true);
      }, 3000);
    }
  }, [confetti]);

  return (
    <ScreenContainer>
      {showNavbar ? <Navbar fadeIn={true} /> : null}
      
      {success === true ? (
        <>
          <h1
            style={{ textAlign: "center" }}
            className="animate__animated animate__backInDown"
          >
            Upgrade successful!
          </h1>
          <br></br>
          {showNavbar ? (
            
          ) : null}
        </>
      ) : success === false ? (
        <div
          style={{
            width: "98vw",
            maxWidth: 768,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h3>Something went wrong.</h3>
          <p style={{ textAlign: "center" }}>
            We encountered an unexpected error while upgrading your account.{" "}
            <span style={{ fontWeight: "bold" }}>
              Be advised that this error has NOT exposed or compromised your
              financial information in any way.
            </span>
          </p>
          <p style={{ textAlign: "center" }}>
            Our tech team has been automatically notified and will be in touch
            with you within 24 hours. In the unlikely event that you do not hear
            from us within that timeframe, feel free to leave a post in the
            Troubleshooting channel.
          </p>
          <p>Your reference code is:</p>
          <h4>{params.code}</h4>
          <hr style={{ width: 375 }}></hr>
          <p style={{ textAlign: "center" }}>
            In the meantime, try logging out and logging back in again. Your
            account may have updated despite the error.
          </p>
          <Button
            variant="contained"
            onClick={() => {
              navigate(`/home/${GlobalCtxt.troubleshootingId}/troubleshooting`);
            }}
          >
            Troubleshoot
          </Button>
        </div>
      ) : (
        <CircularProgress />
      )}
    </ScreenContainer>
  );
}
*/
