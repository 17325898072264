import React, { useState, useEffect } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import { testimonyUnsubscribe } from "../functions/UserFunctions";

import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";

export default function Unsub() {
  ////  INITS
  const params = useParams();

  ////  STATES
  const [ready, setReady] = useState(false);

  ////  FUNCTIONS
  const handleUnsub = async () => {
    try {
      await testimonyUnsubscribe(params.id);
      setReady(true);
    } catch (err) {
      console.log(err);
      setReady("error");
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (params?.id) {
      handleUnsub();
    } else {
      setReady("error");
    }
  }, []);

  return (
    <ScreenContainer>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {ready ? (
          <>
            <h4 style={{ textAlign: "center" }}>
              You have unsubscribed from the mailing list!
            </h4>
          </>
        ) : (
          <CircularProgress />
        )}
      </div>
    </ScreenContainer>
  );
}
