import React, { useState, useEffect, useContext } from "react";

import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

import Countdown from "../common-components/Countdown";
import ClickableUsername from "../common-components/ClickableUsername";
import {
  withdrawSecretaryNomination,
  createElection,
} from "../../functions/PollFunctions";

import { GlobalContext } from "../../App";

export default function NominationsPortal({ data }) {
  ////  INITS
  const GlobalCtxt = useContext(GlobalContext);
  const navigate = useNavigate();

  ////  STATES
  const [isIncumbent, setIsIncumbent] = useState(false);
  const [isCandidate, setIsCandidate] = useState(false);
  const [canNominate, setCanNominate] = useState(false);
  const [votingClosed, setVotingClosed] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [canSpill, setCanSpill] = useState(false);

  ////  FUNCTIONS
  const checkStatus = () => {
    try {
      if (GlobalCtxt.userInfo?.tier > 0) {
        setCanNominate(true);
      }
      if (data.secretaryId === localStorage.getItem("_id")) {
        setIsIncumbent(true);
      }
      if (data.noms_close) {
        if (isNaN(data.noms_close)) {
          if (data.noms_close === "voting") {
            setVotingClosed("voting");
          }
        } else if (Date.now() - data.noms_close > 0) {
          setVotingClosed("closed");
        }
      }
      if (Array.isArray(data.candidates) && data.candidates.length > 0) {
        let latestSubmission = 1;
        for (var el of data.candidates) {
          if (el.userId === localStorage.getItem("_id")) {
            setIsCandidate(true);
          }
          if (el.createdAt > latestSubmission) {
            latestSubmission = el.createdAt;
          }
        }
        if (Date.now() - latestSubmission > 172800000) {
          setCanSpill(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleWithdraw = async () => {
    try {
      setBtnDisabled(true);
      await withdrawSecretaryNomination({ channelId: data._id });
      window.location.reload();
    } catch (err) {
      console.log(err);
      alert(
        "Error processing withdrawal. Please refresh the page to try again."
      );
      setBtnDisabled(false);
    }
  };

  const handleActivatePoll = async () => {
    try {
      setBtnDisabled(true);
      await createElection({ channelId: data._id });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (err) {
      console.log(err);
      alert(
        "Error activating poll. Please refresh the page to try again. If this issue persists, please notify us on the Troubleshooting channel."
      );
      setBtnDisabled(false);
    }
  };

  const resetPortal = () => {
    setCanNominate(false);
    setIsIncumbent(false);
    setVotingClosed(false);
    setIsCandidate(false);
    setCanSpill(false);
  };

  ////  EFFECTS
  useEffect(() => {
    if (data) {
      resetPortal();
      setTimeout(() => {
        checkStatus();
      });
    }
  }, [data]);

  return (
    <>
      <hr style={{ width: "100%" }}></hr>
      {votingClosed === "closed" ? (
        <>
          <p style={{ textAlign: "center" }}>
            Nominations have closed. A poll will be published shortly.
          </p>
          {GlobalCtxt.userInfo?.admin ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                disabled={btnDisabled}
                onClick={handleActivatePoll}
              >
                ACTIVATE POLL
              </Button>
            </div>
          ) : null}
        </>
      ) : votingClosed === "voting" ? (
        <p style={{ textAlign: "center" }}>Voting is underway.</p>
      ) : (
        <>
          {isIncumbent &&
          Array.isArray(data.candidates) &&
          data.candidates.length === 0 ? (
            <>
              {/* REVISIT: make sure href links to something */}
              <p style={{ textAlign: "center", color: "orange" }}>
                The nominations window has opened, which means that other paying
                users can now challenge you for the position of Channel
                Secretary.
              </p>
              <p style={{ textAlign: "center", color: "orange" }}>
                For more information on how elections are conducted,{" "}
                <a
                  href="/"
                  style={{ color: "skyblue" }}
                  onClick={(e) => e.preventDefault()}
                >
                  click here.
                </a>
              </p>
            </>
          ) : (
            <>
              <p
                style={{
                  fontWeight: 600,
                  textAlign: "center",
                  color: "limegreen",
                  width: "90%",
                  margin: "0 auto",
                }}
              >
                Nominations for Channel Secretary are now open!
              </p>
              {data.noms_close ? (
                <div
                  style={{
                    width: "100%",
                    padding: 5,
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p style={{ marginRight: 5 }}>Nominations close:</p>
                  <Countdown
                    deadline={data.noms_close}
                    styling={{ color: "orange" }}
                  />
                </div>
              ) : null}
              {!canNominate ? (
                <>
                  <p style={{ textAlign: "center", fontSize: 14 }}>
                    Only paying users can nominate for the role of Channel
                    Secretary.
                  </p>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      onClick={() => {
                        navigate("/upgrade");
                      }}
                    >
                      UPGRADE MEMBERSHIP
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  {isCandidate ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="error"
                        disabled={btnDisabled}
                        onClick={handleWithdraw}
                      >
                        WITHDRAW NOMINATION
                      </Button>
                    </div>
                  ) : (
                    <>
                      <p style={{ textAlign: "center", fontSize: 14 }}>
                        You are eligible to nominate. For more information on
                        the role of Channel Secretary,{" "}
                        <a
                          href="/"
                          style={{ color: "skyblue" }}
                          onClick={(e) => e.preventDefault()}
                        >
                          click here.
                        </a>
                      </p>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          onClick={() => {
                            //navigate(`/nomination/${data._id}/${data.name}`);
                            alert("Secretary elections begin next week!");
                          }}
                          variant="outlined"
                        >
                          NOMINATE!
                        </Button>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      {canSpill &&
      isIncumbent &&
      votingClosed !== "voting" &&
      votingClosed !== "closed" ? (
        <>
          <p style={{ textAlign: "center", color: "orange" }}>
            More than 48 hours have passed since the last person nominated. As
            the incumbent Secretary, you may now activate the poll.
            Alternatively you can wait for the poll to be activated
            automatically when the nominations window expires.
          </p>
          <p style={{ textAlign: "center", color: "orange", fontWeight: 600 }}>
            NOTE: If you want to re-contest the Secretary position, make sure to
            nominate before activating the poll!
          </p>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Button
              variant="contained"
              color="warning"
              disabled={btnDisabled}
              onClick={handleActivatePoll}
            >
              ACTIVATE POLL
            </Button>
          </div>
        </>
      ) : null}
      {Array.isArray(data.candidates) && data.candidates.length > 0 ? (
        <>
          <p style={{ marginBottom: 5, opacity: 0.5 }}>Nominations:</p>
          <div
            style={{
              width: "100%",
              backgroundColor: "black",
              borderRadius: 8,
              padding: 5,
              boxSizing: "border-box",
              color: "black",
            }}
          >
            {data.candidates.map((el, i) => {
              return (
                <ClickableUsername
                  userId={el.userId}
                  username={el.username}
                  underline={true}
                  key={`${i}-${el.userId}`}
                />
              );
            })}
          </div>
        </>
      ) : null}
    </>
  );
}
