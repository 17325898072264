import React, { useEffect, useRef, useState } from "react";

const AddressAutoComplete = ({ grabAddress, placeholder }) => {
  ////  STATES
  const autocompleteRef = useRef(null);
  const [address, setAddress] = useState("");

  ////  EFFECTS
  useEffect(() => {
    if (window.google) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        autocompleteRef.current,
        {
          types: ["address"],
          componentRestrictions: { country: "au" },
        }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        setAddress(place.formatted_address);
      });
    }
  }, []);

  useEffect(() => {
    if (address) {
      grabAddress(address);
    }
  }, [address]);

  return (
    <div style={{ display: "flex", width: "100%", maxWidth: 805 }}>
      <input
        ref={autocompleteRef}
        type="text"
        placeholder={placeholder}
        style={{ width: "100%", padding: 10, fontSize: 16 }}
      />
    </div>
  );
};

export default AddressAutoComplete;
