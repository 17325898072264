import React, { useState, useEffect, useContext } from "react";

import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import IconButton from "@mui/material/IconButton";

import { bookmarkDoc } from "../../functions/NewsFunctions";

import { GlobalContext } from "../../App";

export default function BookmarkButton({ contentRef, prevBookmarks }) {
  ////  INITS
  const GlobalCtxt = useContext(GlobalContext);

  ////  STATES
  const [clicked, setClicked] = useState(false);

  ////  FUNCTIONS
  const handleClick = async () => {
    try {
      if (GlobalCtxt.userInfo.tier >= 3) {
        if (!clicked) {
          await bookmarkDoc({ docId: contentRef });
          setClicked(true);
        }
      } else {
        alert("Upgrade your membership to start bookmarking documents!");
      }
    } catch (err) {
      console.log(err);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (prevBookmarks && Array.isArray(prevBookmarks)) {
      if (prevBookmarks.includes(localStorage.getItem("_id"))) {
        setClicked(true);
      }
    }
  }, []);

  return (
    <IconButton onClick={handleClick}>
      <BookmarkAddIcon sx={{ color: clicked ? "orange" : "grey" }} />
    </IconButton>
  );
}
