import React, { useEffect } from "react";

export default function AdSidebar() {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        padding: 2,
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
      }}
    >
      <div style={{ width: "100%" }}>
        <p>
          This is some dummy text that will eventually serve as an upgrade
          prompt.
        </p>
      </div>
      <ins
        class="adsbygoogle"
        style={{
          display: "inline-block",
          width: "300px",
          height: "250px",
          border: "1px solid black",
        }}
        data-ad-client="ca-pub-4156613766325791"
        data-ad-slot="3100384281"
      ></ins>
      <ins
        class="adsbygoogle"
        style={{
          display: "inline-block",
          width: "300px",
          height: "250px",
          border: "1px solid black",
        }}
        data-ad-client="ca-pub-4156613766325791"
        data-ad-slot="3100384281"
      ></ins>
    </div>
  );
}

/*

*/
