import React, { useState, useEffect } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import DynamicFrame from "../components/common-components/DynamicFrame";
import GenericMain from "../components/common-components/GenericMain";
import GenericSidebar from "../components/common-components/GenericSidebar";
import WitnessLanding from "../components/affidavit-components/WitnessLanding";
import PublicLanding from "../components/affidavit-components/PublicLanding";
import SponsorSidebar from "../components/affidavit-components/SponsorSidebar";
import { useWindowSize } from "../utils/Hooks";
import AutoReel from "../components/common-components/AutoReel";
import { countSubmissions } from "../functions/AffidavitFunctions";

import { useNavigate, useParams } from "react-router-dom";

const counterContainers = {
  background:
    "linear-gradient(to bottom, rgba(0,0,0,0.25), rgba(0,0,0,0.5), rgba(0,0,0,0.75)",
  marginBottom: 10,
  width: "100%",
  padding: 4,
  boxSizing: "border-box",
  borderRadius: 4,
};

export default function DeclarationHub() {
  ////  INITS
  const windowSize = useWindowSize();
  const params = useParams();
  const navigate = useNavigate();

  ////  STATES
  const [publicState, setPublicState] = useState(true);
  const [counts, setCounts] = useState(null);
  const [sponsors, setSponsors] = useState(null);

  ////  FUNCTIONS
  const fetchSubmissionCount = async () => {
    try {
      const res = await countSubmissions();
      if (res?.data) {
        setCounts({
          declarations: res.data.declarations,
          signatures: res.data.signatures,
        });
        setSponsors(res.data.sponsors);
      }
    } catch (err) {
      console.log(err);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchSubmissionCount();
  }, []);

  useEffect(() => {
    if (params?.pageState) {
      if (params.pageState === "witness") {
        setPublicState(false);
      }
    }
  }, []);

  return (
    <ScreenContainer>
      <Navbar />
      <div
        style={{
          width: "96%",
          maxWidth: 600,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 10,
          boxSizing: "border-box",
          border: "8px solid red",
          borderRadius: 8,
          backgroundColor: "rgba(0,0,0,0.6)",
          marginBottom: 20,
        }}
        className="dark-container"
      >
        <h2 style={{ color: "white" }}>Time is running out!</h2>
        <p style={{ textAlign: "center" }}>
          The Australian government wants to impose new censorship laws before
          the end of the year.
        </p>
        <p style={{ textAlign: "center", marginTop: 0 }}>
          <b>Don't let them silence you.</b> Join our fight for the truth by
          creating a <b>Commonwealth Statutory Declaration</b> today!
        </p>
        <div
          className="animated-border"
          style={{ cursor: "pointer", border: "1px solid black" }}
          onClick={() => {
            navigate("/create-declaration");
          }}
        >
          <h4 style={{ color: "white" }}>Create your Declaration!</h4>
        </div>
      </div>
      {windowSize.width <= 768 ? (
        <>
          <br></br>
          <div
            style={{
              ...counterContainers,
              backgroundColor: "rgba(0,0,0,0.8)",
              width: "66%",
            }}
          >
            <p style={{ textAlign: "center", opacity: 0.5, color: "white" }}>
              Declarations created:
            </p>
            {counts?.declarations ? (
              <AutoReel countTo={counts.declarations} />
            ) : null}
          </div>
        </>
      ) : null}
      <DynamicFrame
        windowSize={windowSize}
        styling={{
          maxWidth: windowSize.width > 768 ? windowSize.width * 0.98 : null,
        }}
      >
        {windowSize.width > 768 ? (
          <GenericSidebar
            windowSize={windowSize}
            styling={{ width: windowSize.width * 0.24 }}
          >
            <div
              style={{
                width: "100%",
                padding: 5,
                boxSizing: "border-box",
                backgroundColor: "black",
                display: "flex",
                alignItems: "center",
                borderRadius: 8,
              }}
            >
              <p
                style={{
                  color: "grey",
                  marginRight: 8,
                  fontSize: 14,
                }}
              >
                Display:
              </p>
              <select
                onChange={(e) => {
                  if (e.target.value === "Public") {
                    setPublicState(true);
                    navigate("/declarations/public");
                  } else {
                    setPublicState(false);
                    navigate("/declarations/witness");
                  }
                }}
                value={publicState ? "Public" : "Witness"}
              >
                <option value="Public">Public</option>
                <option value="Witness">Witness</option>
              </select>
              <h3>l</h3>
            </div>
            <SponsorSidebar sponsors={sponsors} />
          </GenericSidebar>
        ) : null}

        <GenericMain
          styling={{
            backgroundColor: "whitesmoke",
          }}
        >
          {windowSize.width <= 768 ? (
            <p style={{ fontSize: 14, fontWeight: 600 }}>
              The Declaration Hub is designed for desktop devices. For the best
              experience, consider using a laptop or computer.
            </p>
          ) : null}
          {windowSize.width <= 768 ? (
            <div
              style={{ width: "100%", display: "flex", alignItems: "center" }}
            >
              <p
                style={{
                  color: "grey",
                  marginRight: 8,
                  fontSize: 14,
                }}
              >
                Display:
              </p>
              <select
                onChange={(e) => {
                  if (e.target.value === "Public") {
                    setPublicState(true);
                    navigate("/declarations/public");
                  } else {
                    setPublicState(false);
                    navigate("/declarations/witness");
                  }
                }}
                value={publicState ? "Public" : "Witness"}
              >
                <option value="Public">Public</option>
                <option value="Witness">Witness</option>
              </select>
            </div>
          ) : null}
          <div
            style={{
              width: "100%",
              backgroundColor: "black",
              display: "flex",
              justifyContent: "center",
              padding: 5,
              boxSizing: "border-box",
              borderRadius: 8,
            }}
          >
            <h3 style={{ color: "white" }}>
              {publicState ? `Declaration Hub` : `Witness Hub`}
            </h3>
          </div>
          {publicState ? (
            <PublicLanding />
          ) : (
            <WitnessLanding windowSize={windowSize} />
          )}
        </GenericMain>
        {windowSize.width > 768 ? (
          <GenericSidebar
            windowSize={windowSize}
            styling={{ width: windowSize.width * 0.24 }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              className="dark-container"
            >
              {counts ? (
                <>
                  <div style={counterContainers}>
                    <p style={{ textAlign: "center", opacity: 0.5 }}>
                      Declarations created:
                    </p>
                    <AutoReel countTo={counts.declarations} height={100} />
                  </div>
                  <div style={counterContainers}>
                    <p style={{ textAlign: "center", opacity: 0.5 }}>
                      Petition signatures:
                    </p>
                    <AutoReel
                      countTo={counts.signatures}
                      height={100}
                      handleClick={{
                        label: "View petition",
                        func: () => {
                          navigate("/petition");
                        },
                      }}
                    />
                  </div>
                  <hr style={{ width: "100%", opacity: 0.5 }}></hr>
                  <p style={{ textAlign: "center", opacity: 0.5 }}>
                    Other activities:
                  </p>
                  <div style={counterContainers}>
                    <p
                      style={{
                        fontSize: 14,
                        textAlign: "center",
                        fontWeight: 600,
                      }}
                    >
                      MAD MUST GO petition
                    </p>
                    <img
                      src="https://storage.googleapis.com/bucket-775/mad_must_go.png"
                      style={{
                        width: "100%",
                        height: "auto",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.open("https://madmustgo.com.au/", "_blank");
                      }}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </GenericSidebar>
        ) : null}
      </DynamicFrame>
      {windowSize.width <= 768 ? (
        <div
          style={{
            width: "100%",
            background: "rgba(0,0,0,0.5)",
            padding: 10,
            boxSizing: "border-box",
          }}
        >
          <SponsorSidebar sponsors={sponsors} />
        </div>
      ) : null}
    </ScreenContainer>
  );
}

/**/
