import React, { useRef } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";

export default function Explainers() {
  ////  INITS
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();

  ////  FUNCTIONS
  const scrollToRef = (ref) => {
    const navbarHeight = window.innerHeight * 0.1; // Calculate 10vh
    const elementPosition = ref.current.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - navbarHeight;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  return (
    <ScreenContainer>
      <Navbar />
      <div
        style={{
          width: "100vw",
          maxWidth: 900,
          backgroundColor: "whitesmoke",
          border: "0.5px solid black",
          borderRadius: 4,
          padding: 10,
          boxSizing: "border-box",
        }}
      >
        <h2 style={{ textAlign: "center" }}>PhaseChamber Explained</h2>
        <h4 style={{ textAlign: "center" }}>
          This page contains expanded information on PhaseChamber's various
          features.
        </h4>
        <br></br>
        <h4>Contents:</h4>
        <ul>
          <li>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                scrollToRef(ref1);
              }}
            >
              What is a Channel?
            </a>
          </li>
          <li>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                scrollToRef(ref2);
              }}
            >
              What is a Community Balance?
            </a>
          </li>
          <li>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                scrollToRef(ref3);
              }}
            >
              What is a Channel Secretary?
            </a>
          </li>
          <li>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                scrollToRef(ref4);
              }}
            >
              Who can become Channel Secretary?
            </a>
          </li>
          <li>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                scrollToRef(ref5);
              }}
            >
              Who can vote for Channel Secretary?
            </a>
          </li>
          <li>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                scrollToRef(ref6);
              }}
            >
              How do elections work?
            </a>
          </li>
        </ul>
        <br></br>
        <hr style={{ width: "100%", opacity: 0.5 }}></hr>
        <br></br>
        <h3 ref={ref1}>What is a Channel?</h3>
        <p>
          A Channel is essentially a message board for a particular community.
        </p>
        <p>
          PhaseChamber has one Channel for each federal electorate in Australia.
          When you sign up to the platform, you will be assigned to your
          electorate's Channel based on your postcode.
        </p>
        <div
          style={{
            width: "100%",
            backgroundColor: "lightgrey",
            padding: 10,
            boxSizing: "border-box",
          }}
        >
          <p>
            In addition to electorate-based Channels, there are also Channels
            that have been created by users (otherwise known as{" "}
            <span style={{ fontWeight: "bold" }}>Community Groups</span>). Any
            user paying $11.90/month or more can create their own Community
            Group, which other users can then interact with.
          </p>
        </div>
        <p>
          Each Channel (and Community Group) has its own{" "}
          <span style={{ fontWeight: "bold" }}>video conference room.</span>{" "}
          This feature enables paying members to schedule and promote video
          calls for up to 200 participants. These calls are similar to Zoom
          calls, but they utilise PhaseChamber's built-in video-conferencing
          technology.
        </p>
        <hr style={{ width: "100%", opacity: 0.5 }}></hr>
        <br></br>
        <h3 ref={ref2}>What is a Community Balance?</h3>
        <p>
          If you're paying $11.90/month or more, $3 of your monthly subscription
          payment will be reinvested into your electorate's Channel. The money
          is tracked by the Channel's Community Balance, which is visible to
          everyone.
        </p>
        <div
          style={{
            width: "100%",
            backgroundColor: "lightgrey",
            padding: 10,
            boxSizing: "border-box",
          }}
        >
          <p>
            <span style={{ fontWeight: "bold" }}>NOTE: </span>Community
            reinvestments are calculated at the end of every month. For example,
            if you subscribe on the 15th, your $3 reinvestment will not be
            processed until the beginning of the following month.
          </p>
        </div>
        <p>There are two ways that the Community Balance can be spent:</p>
        <ul>
          <li>
            <span style={{ fontWeight: "bold" }}>Withdrawal:</span> When the
            balance exceeds $50, the Channel Secretary may submit a withdrawal
            request. The other paying members of the Channel will then be
            prompted to vote on whether the withdrawal should be allowed. If the
            majority vote to approve, the money is transferred to a bank account
            provided by the Secretary.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>Transfer:</span> The money can
            also be transferred to a different Community Balance. As with
            withdrawals, this requires the approval of a majority of the
            Channel's paying members. Community Balance transfers will be
            facilitated by the PhaseChamber fundraising system (coming soon).
          </li>
        </ul>
        <hr style={{ width: "100%", opacity: 0.5 }}></hr>
        <br></br>
        <h3 ref={ref3}>What is a Channel Secretary?</h3>
        <p>
          In each Channel, there is one user who has special privileges. That
          user is known as the{" "}
          <span style={{ fontWeight: 600 }}>Channel Secretary.</span>
        </p>
        <p>
          The Channel Secretary is appointed{" "}
          <span style={{ fontWeight: 600 }}>democratically.</span> For more
          information on how Secretary elections work,{" "}
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              scrollToRef(ref1);
            }}
          >
            see below.
          </a>
        </p>
        <p>
          The Secretary's primary responsibility is{" "}
          <span style={{ fontWeight: 600 }}>content moderation.</span> If
          somebody posts something on the Channel that could be deemed
          inappropriate, it's up to the Secretary to decide whether the content
          should be removed.
        </p>
        <p>
          We believe that some degree of moderation is necessary for the
          platform to run optimally. We encourage Channel Secretaries to remove
          spam, as well as posts that are deliberately and needlessly offensive.
        </p>
        <div
          style={{
            width: "100%",
            backgroundColor: "lightgrey",
            padding: 10,
            boxSizing: "border-box",
          }}
        >
          <p>
            <span style={{ fontWeight: 600 }}>NOTE: </span>In electorate-based
            Channels, PhaseChamber reserves the right to remove content without
            the Secretary's approval if the content contains:
          </p>
          <ul>
            <li>Pornography or sexually suggestive imagery</li>
            <li>Graphic depictions of death or extreme violence</li>
            <li>
              Clear incitement of violence against a particular group or
              individual
            </li>
          </ul>
        </div>
        <p>The Secretary has other privileges as well. They can:</p>
        <ul>
          <li>
            Create free access links to video meetings hosted by the Channel
          </li>
          <li>Set video meetings to private</li>
          <li>Remove markers from the Channel's interactive map</li>
          <li>
            Submit{" "}
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                scrollToRef(ref1);
              }}
            >
              withdrawal requests
            </a>
          </li>
          <li>
            Access a list of users who have recently registered to the Channel
            (this list contains{" "}
            <span style={{ fontWeight: 600 }}>usernames only.</span> Other
            details, such as email addresses and postcodes, are not displayed.)
          </li>
        </ul>
        <hr style={{ width: "100%", opacity: 0.5 }}></hr>
        <br></br>
        <h3 ref={ref4}>Who can become a Channel Secretary?</h3>
        <p>
          You are eligible to become a Channel Secretary if you're a subscriber
          paying <span style={{ fontWeight: 600 }}>$6.90/month or higher.</span>
        </p>
        <p>
          You are free to nominate for Secretary in any electorate-based
          Channel, however your best chance of winning is in your{" "}
          <span style={{ fontWeight: 600 }}>own electorate.</span>{" "}
          PhaseChamber's polling feature is designed to favour local candidates.
        </p>
        <hr style={{ width: "100%", opacity: 0.5 }}></hr>
        <br></br>
        <h3 ref={ref5}>Who can vote for Channel Secretary?</h3>
        <p>
          You are eligible to vote in Secretary elections if you're a subscriber
          paying <span style={{ fontWeight: 600 }}>$6.90/month or higher.</span>
        </p>
        <div
          style={{
            width: "100%",
            backgroundColor: "lightgrey",
            padding: 10,
            boxSizing: "border-box",
          }}
        >
          <p>
            <span style={{ fontWeight: 600 }}>NOTE: </span>When determining who
            can vote in a Poll, the eligibility criteria depends on how many
            paying users are registered in the Channel.
            <ul>
              <li>
                If the Channel has{" "}
                <span style={{ fontWeight: 600 }}>8 paying users or less,</span>{" "}
                the Poll is open to all PhaseChamber users.
              </li>
              <li>
                If the Channel has{" "}
                <span style={{ fontWeight: 600 }}>9 paying users or more,</span>{" "}
                the Poll is open to local users only.
              </li>
            </ul>
            <p>
              <span style={{ fontWeight: 600 }}>EXAMPLE A:</span> The Adelaide
              Channel contains 20 registered users, 7 of whom are paying
              $6.90/month or more. When a Poll is launched,{" "}
              <span style={{ fontWeight: 600 }}>
                all paying PhaseChamber users are eligible to vote.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 600 }}>EXAMPLE B:</span> The Aston
              Channel Channel contains 30 registered users, 9 of whom are paying
              $6.90/month or more. When a Poll is launched,{" "}
              <span style={{ fontWeight: 600 }}>
                only the paying users based in Aston are eligible to vote.
              </span>
            </p>
          </p>
        </div>
        <hr style={{ width: "100%", opacity: 0.5 }}></hr>
        <br></br>
        <h3 ref={ref6}>How do elections work?</h3>
        <p>
          After winning an election, the new Channel Secretary begins their{" "}
          <span style={{ fontWeight: 600 }}>tenure.</span> This is a fixed
          window of time during which the Secretary cannot be challenged.
        </p>
        <p>
          To begin with the tenure will only last for{" "}
          <span style={{ fontWeight: 600 }}>two weeks,</span> however this will
          be gradually extend as the platform matures. We're aiming to
          eventually extend tenures to{" "}
          <span style={{ fontWeight: "bold" }}>three months or more,</span>{" "}
          enough time for the Secretary to plan and execute localised campaigns.
        </p>
        <p>
          When the tenure expires, other paying users have the ability to
          challenge for the Secretary role. The election process begins when the
          first user challenges.
        </p>
        <p>The process is as follows:</p>
        <ol>
          <li>
            A user challenges for the Secretary role by submitting a nomination.
          </li>
          <li>
            A 48-hour nominations window is activated. During this time, other
            users may also nominate.
          </li>
          <li>After 48 hours, a Poll is activated.</li>
          <li>
            Paying users are invited to participate in the Poll by voting for
            their preferred Secretary.
          </li>
          <li>After 48 hours, the Poll closes.</li>
          <li>
            The winner of the Poll is announced, and Secretary privileges are
            transferred if necessary.
          </li>
        </ol>
        <p>
          The polling system is designed to favour local candidates (users who
          reside in the electorate).{" "}
          <span style={{ fontWeight: "bold" }}>
            If there is only one local candidate, the local candidate will win
            automatically.
          </span>
        </p>
        <p>Tiebreaks are resolved as follows:</p>
        <ul>
          <li>
            If the tied candidates are on different subscription tiers, the
            candidate on the higher subscription tier will be declared the
            winner.
          </li>
          <li>
            If the tied candidates are on the same subscription tier and one of
            the candidates is the incumbent Secretary, the incumbent will be
            declared the winner.
          </li>
          <li>
            If the tied candidates are on the same subscription tier and neither
            of them is the incumbent Secretary, a run-off Poll will be created.
          </li>
        </ul>
      </div>
    </ScreenContainer>
  );
}
