import React, { useState, useEffect, useContext } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import GenericTextField from "../components/common-components/GenericTextField";
import Navbar from "../components/common-components/Navbar";
import { submitSecretaryNomination } from "../functions/PollFunctions";

import Slider from "@mui/material/Slider";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";

import { GlobalContext } from "../App";

const moderationText = [
  "I will allow absolute freedom of speech.",
  "I favour free speech with minimal restrictions.",
  "I will seek a sensible balance between free speech and quality control.",
  "When in doubt, I will prioritise quality control over free speech.",
  "Tackling misinformation is my highest priority.",
];

export default function Nomination() {
  ////  INITS
  const params = useParams();
  const navigate = useNavigate();
  const GlobalCtxt = useContext(GlobalContext);

  ////  STATES
  const [moderationRating, setModerationRating] = useState(2);
  const [pitch, setPitch] = useState("");
  const [skillsAndExperience, setSkillsAndExperience] = useState("");
  const [moderationPolicy, setModerationPolicy] = useState(moderationText[2]);
  const [btnDisabled, setBtnDisabled] = useState(false);

  ////  FUNCTIONS
  const handleSubmit = async () => {
    try {
      if (!pitch || !skillsAndExperience) {
        alert("Please complete all fields.");
        return;
      }
      setBtnDisabled(true);
      await submitSecretaryNomination({
        pitch,
        skillsAndExperience,
        modPolicy: moderationPolicy,
        channelId: params.channelId,
      });
      GlobalCtxt.updateCurrentChannel(null);
      setTimeout(() => {
        navigate(`/home/${params.channelId}/${params.channelName}`);
      }, 1000);
    } catch (err) {
      console.log(err);
      alert(err.message);
      setBtnDisabled(false);
    }
  };

  const updatePitch = (text) => {
    setPitch(text);
  };

  const updateSkillsAndExperience = (text) => {
    setSkillsAndExperience(text);
  };

  ////  EFFECTS
  useEffect(() => {
    setModerationPolicy(moderationText[moderationRating]);
  }, [moderationRating]);

  return (
    <ScreenContainer>
      <Navbar />
      <div
        style={{
          width: "100vw",
          maxWidth: 768,
          maxHeight: "100vh",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "whitesmoke",
          border: "0.5px solid black",
          borderRadius: 4,
          padding: 10,
          boxSizing: "border-box",
        }}
      >
        <h3 style={{ textAlign: "center" }}>Thank you for nominating!</h3>
        <p style={{ textAlign: "center", fontStyle: "italic" }}>
          {`Please answer a few quick questions so that members of ${decodeURIComponent(
            params.channelName
          )} can learn more about you.`}
        </p>
        <hr style={{ width: "100%" }}></hr>
        <p style={{ textAlign: "center" }}>
          In approximately 100 words or less, what do you hope to achieve as
          Channel Secretary?
        </p>
        <GenericTextField
          charLimit={650}
          rows={6}
          buttonVariant="hidden"
          divStyling={{ marginBottom: "10px" }}
          handleChange={updatePitch}
        />
        <p style={{ textAlign: "center" }}>
          In approximately 100 words or less, describe the skills and experience
          that you bring to the table.
        </p>
        <GenericTextField
          charLimit={650}
          rows={6}
          buttonVariant="hidden"
          divStyling={{ marginBottom: "10px" }}
          handleChange={updateSkillsAndExperience}
        />
        <p style={{ textAlign: "center" }}>
          How will you approach content moderation?
        </p>
        <p
          style={{
            textAlign: "center",
            fontSize: 14,
            opacity: 0.75,
            marginTop: 0,
          }}
        >
          (move the slider to adjust your policy)
        </p>
        <Slider
          value={moderationRating}
          step={1}
          marks
          min={0}
          max={4}
          onChange={(e) => {
            setModerationRating(e.target.value);
          }}
          sx={{ width: "96%" }}
        />
        <h4 style={{ textAlign: "center" }}>{moderationPolicy}</h4>
        <hr style={{ width: "100%" }}></hr>
        <br></br>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: 10,
            boxSizing: "border-box",
            backgroundColor: "grey",
          }}
        >
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={btnDisabled}
          >
            SUBMIT NOMINATION
          </Button>
        </div>
        <br></br>
      </div>
    </ScreenContainer>
  );
}
