import React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useNavigate } from "react-router-dom";

export default function UpgradeFAQ() {
  const navigate = useNavigate();

  return (
    <div style={{ width: "98%", maxWidth: 768 }}>
      <Accordion
        sx={{ backgroundColor: "rgba(0,0,0,0.5)", marginBottom: "2px" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ color: "white" }}
        >
          Are my banking details secure?
        </AccordionSummary>
        <AccordionDetails sx={{ color: "lightgrey", fontWeight: 300 }}>
          Yes. We process your subscription using Stripe, a globally trusted
          third-party payment platform. Your banking details will never be
          visible to us or anyone else, and you can cancel your subscription at
          any time.
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ backgroundColor: "rgba(0,0,0,0.5)", marginBottom: "2px" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ color: "white" }}
        >
          Do you accept alternative methods of payment?
        </AccordionSummary>
        <AccordionDetails sx={{ color: "lightgrey", fontWeight: 300 }}>
          If you'd prefer to pay via direct transfer, email{" "}
          <span style={{ fontWeight: 600 }}>
            joel@winstonsmithinitiative.com
          </span>
          . Please note that this arrangement requires payment on annual basis.
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ backgroundColor: "rgba(0,0,0,0.5)", marginBottom: "2px" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ color: "white" }}
        >
          What is the Community Fund?
        </AccordionSummary>
        <AccordionDetails sx={{ color: "lightgrey", fontWeight: 300 }}>
          Each Channel has a dedicated fund designed the raise money for local
          initiatives. If you're subscribed to the Standard tier or higher, we
          will re-invest a portion of your monthly subscription revenue into
          your Channel's fund.<br></br>
          <br></br>Once your Channel surpasses the withdrawal threshold, all
          paying members are entitled to a vote on how that money should be
          spent. For more information on how that works,{" "}
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              navigate("/explainers");
            }}
            style={{ color: "lightblue" }}
          >
            click here.
          </a>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ backgroundColor: "rgba(0,0,0,0.5)", marginBottom: "2px" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ color: "white" }}
        >
          What is the Members' Advantage?
        </AccordionSummary>
        <AccordionDetails sx={{ color: "lightgrey", fontWeight: 300 }}>
          As a paying member, you will have an inherent advantage over
          non-paying members when you run for the role of Channel Secretary. To
          learn more about how the Members' Advantage works in practice,{" "}
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              navigate("/explainers");
            }}
            style={{ color: "lightblue" }}
          >
            click here.
          </a>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
