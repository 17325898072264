import React, { useContext, useState } from "react";

import DynamicFrame from "../common-components/DynamicFrame";
import GenericMain from "../common-components/GenericMain";
import GenericSidebar from "../common-components/GenericSidebar";
import GenericModal from "../common-components/GenericModal";
import DynamicListItem from "./DynamicListItem";
import Div5Sidebar from "./Div5Sidebar";

import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";

import "animate.css";

import { AffidavitContext } from "../../screens/AffidavitFlow";

export default function AffidavitDiv5({
  windowSize,
  draftState,
  draftIndex,
  updateDraftIndex,
  updateCurrentDiv,
  recreateDraft,
}) {
  ////  INITS
  const AffidavitCtxt = useContext(AffidavitContext);
  const navigate = useNavigate();

  ////  STATES
  const [showHelper, setShowHelper] = useState(false);

  return (
    <>
      <GenericModal open={showHelper} handleClose={() => setShowHelper(false)}>
        <div
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.8)",
            padding: 5,
            boxSizing: "border-box",
          }}
        >
          <Div5Sidebar
            windowSize={windowSize}
            listItems={AffidavitCtxt.listItems}
            recreateDraft={recreateDraft}
          />
        </div>
      </GenericModal>
      {!draftState ? (
        <div
          style={{
            height: "85%",
            width: "85%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ opacity: 0.5, textAlign: "center" }}>
            Complete the fields above to create your Declaration draft.
          </p>
          <Button onClick={() => window.location.reload()}>RESET</Button>
        </div>
      ) : draftState === "loading" ? (
        <>
          <h3 style={{ textAlign: "center" }}>
            Winnie is generating your Declaration ...
          </h3>
          <div
            style={{
              width: 400,
              height: 400,
              maxWidth: "96vw",
              maxHeight: "96vw",
            }}
          >
            <DotLottieReact
              src="https://lottie.host/907b877e-c3b8-4608-bd01-7e7748dcc06e/nEiMW9l1gV.lottie"
              autoplay
              loop
            />
          </div>
        </>
      ) : draftState === "error" ? (
        <>
          <h4 style={{ width: "96%", maxWidth: 600, textAlign: "center" }}>
            An error occurred while processing your Declaration. Press the
            button below to attempt again.
          </h4>
          <Button onClick={recreateDraft}>RECREATE DRAFT</Button>
        </>
      ) : draftState === "ready" ? (
        <>
          {windowSize.width <= 768 ? (
            <h3>Your Declaration is ready!</h3>
          ) : (
            <h2>Your Declaration is ready!</h2>
          )}
          {windowSize.width <= 768 ? (
            <Button
              sx={{ margin: "0px 0px 10px 0px" }}
              startIcon={<HelpIcon />}
              color="info"
              variant="contained"
              size="small"
              onClick={() => setShowHelper(true)}
            >
              INFO
            </Button>
          ) : null}
          <DynamicFrame windowSize={windowSize} styling={{ height: "75vh" }}>
            <GenericMain styling={{ backgroundColor: "lightgrey" }}>
              {AffidavitCtxt.listItems &&
              Array.isArray(AffidavitCtxt.listItems) ? (
                <>
                  <div
                    style={{
                      width: "100%",
                      padding: "10px 15px",
                      boxSizing: "border-box",
                      backgroundColor: "#2E3B4E",
                      borderRadius: "4px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        {AffidavitCtxt.listItems.map((el, i) => (
                          <div
                            key={i}
                            style={{
                              padding: "8px 16px",
                              boxSizing: "border-box",
                              backgroundColor: "#3C4A60",
                              color: "white",
                              marginRight: "10px",
                              borderRadius: "4px",
                              cursor: "pointer",
                              transition: "background-color 0.3s",
                              border:
                                i === draftIndex
                                  ? "2px solid white"
                                  : "0.5px solid white",
                            }}
                            onClick={() => {
                              updateDraftIndex(i);
                            }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.backgroundColor = "#52637A";
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.backgroundColor = "#3C4A60";
                            }}
                          >
                            <p
                              style={{
                                margin: 0,
                                fontWeight: i === draftIndex ? 500 : 300,
                                fontSize: 15,
                              }}
                            >
                              {AffidavitCtxt.listItems.length > 2 &&
                              windowSize.width <= 768
                                ? `#${i + 1}`
                                : `Draft #${i + 1}`}
                            </p>{" "}
                          </div>
                        ))}
                      </div>
                      <Button
                        variant="contained"
                        color="success"
                        size={windowSize.width > 600 ? "medium" : "small"}
                        onClick={() => {
                          updateCurrentDiv(99);
                        }}
                      >
                        SUBMIT DRAFT #{draftIndex + 1}
                      </Button>
                    </div>

                    {AffidavitCtxt.listItems.length > 0 ? (
                      <p style={{ fontSize: 14, color: "lightgrey" }}>
                        {draftIndex === 0
                          ? `This draft is fairly conservative. The grammar and formatting have been tidied up, with minimal changes to the wording of your testimony.`
                          : draftIndex === 1
                          ? `This draft has been edited more heavily. You may notice that parts of your testimony have been rewritten, as if by a lawyer.`
                          : null}
                      </p>
                    ) : null}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      overflowY: "auto",
                      borderTop: "0.1px solid black",
                      marginTop: 5,
                    }}
                  >
                    {AffidavitCtxt.listItems[draftIndex] ? (
                      <>
                        {AffidavitCtxt.listItems[draftIndex].map((el, i) => {
                          return (
                            <DynamicListItem
                              value={el}
                              key={i}
                              index={i}
                              windowSize={windowSize}
                            />
                          );
                        })}
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            startIcon={<AddCircleIcon />}
                            variant="contained"
                            color="info"
                            onClick={() => {
                              AffidavitCtxt.addListItem();
                            }}
                          >
                            ADD PARAGRAPH
                          </Button>
                        </div>
                      </>
                    ) : (
                      <h4>This draft cannot be displayed.</h4>
                    )}
                  </div>
                </>
              ) : null}
            </GenericMain>
            {windowSize.width > 768 ? (
              <GenericSidebar
                windowSize={windowSize}
                styling={{ width: "30vw" }}
              >
                <Div5Sidebar
                  windowSize={windowSize}
                  listItems={AffidavitCtxt.listItems}
                  recreateDraft={recreateDraft}
                />
              </GenericSidebar>
            ) : null}
          </DynamicFrame>
        </>
      ) : draftState === "success" ? (
        <>
          {windowSize.width > 768 ? (
            <h2
              className="animate__animated animate__fadeInDown animate__delay-1s"
              style={{ marginTop: 10 }}
            >
              {draftState.toUpperCase()}!
            </h2>
          ) : (
            <h3
              className="animate__animated animate__fadeInDown animate__delay-1s"
              style={{ marginTop: 10 }}
            >
              {draftState.toUpperCase()}!
            </h3>
          )}

          <p
            className="animate__animated animate__fadeInDown animate__delay-1s"
            style={{
              textAlign: "center",
              width: "96%",
              maxWidth: 768,
              fontSize: windowSize.width > 768 ? 16 : 14,
            }}
          >
            Your fully formatted Statutory Declaration should arrive via email
            within the next minute!
          </p>
          <div
            className="animate__animated animate__fadeInDown animate__delay-1s"
            style={{
              width: "96%",
              maxWidth: 768,
              padding: 10,
              boxSizing: "border-box",
              backgroundColor: "rgba(0,0,0,0.6)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "white",
              fontWeight: 300,
              fontSize: 14,
            }}
          >
            <h2>Next steps:</h2>
            <p style={{ textAlign: "center" }}>
              Your Declaration does not become an official legal document until
              it is sworn in the presence of a Qualified Witness.{" "}
              <b>This is a crucial step in the process!</b>
            </p>
            <p style={{ textAlign: "center" }}>
              Declarations can be witnessed either <b>in person</b> or{" "}
              <b>remotely.</b> Both methods are explained in the email you just
              received. For those who prefer to swear remotely, we provide a{" "}
              <b>free online service</b> that can connect you with a Qualified
              Witness via video call.
            </p>
            <hr style={{ width: "100%", opacity: 0.5 }}></hr>
            <p style={{ textAlign: "center" }}>
              Once your Declaration is appropriately signed, it's time to put it
              work! Further information on how we intend to use our Declarations
              can be found at the <b>Declaration Hub,</b> which you can visit by
              clicking the button below.
            </p>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                navigate("/declarations");
              }}
            >
              VISIT THE DECLARATION HUB!
            </Button>
          </div>
        </>
      ) : draftState === "submitting" ? (
        <>
          <h2>Submitting ...</h2>
          <LinearProgress sx={{ width: "96%", maxWidth: 768 }} />
          <p style={{ width: "96%", maxWidth: 768, textAlign: "center" }}>
            Hold tight, this usually takes between 15 and 30 seconds.
          </p>
        </>
      ) : null}
    </>
  );
}
