//  REVISIT: 'show more' functionality. same for Reply

import React, { useState, useEffect } from "react";

import ClickableUsername from "./ClickableUsername";
import InteractionsBar from "./InteractionsBar";
import GenericTextField from "./GenericTextField";
import SignInPrompt from "./SignInPrompt";
import Reply from "./Reply";
import { safelyRenderHtml } from "../../functions/GeneralFunctions";
import {
  createComment,
  getComments,
  hideContent,
} from "../../functions/ContentFunctions";
import { useWindowSize } from "../../utils/Hooks";

import CircularProgress from "@mui/material/CircularProgress";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import HideSourceIcon from "@mui/icons-material/HideSource";
import Tooltip from "@mui/material/Tooltip";
import FlagIcon from "@mui/icons-material/Flag";

const defaultStyling = {
  display: "flex",
  flexDirection: "column",
  //flex: 1,
  borderRadius: 4,
  padding: 5,
  boxSizing: "border-box",
  marginBottom: 10,
};

export default function Comment({ data, styling }) {
  ////  INITS
  const windowSize = useWindowSize();

  ////  STATES
  const [sanitizedContent, setSanitizedContent] = useState(null);
  const [fetchError, setFetchError] = useState(false);
  const [replying, setReplying] = useState(false);
  const [replies, setReplies] = useState([]);
  const [exhausted, setExhausted] = useState(false);
  const [canHide, setCanHide] = useState(false);

  ////  FUNCTIONS
  const renderSafely = async () => {
    try {
      const res = await safelyRenderHtml(data.text);
      setSanitizedContent(res);
    } catch (err) {
      console.log(err);
      setFetchError(true);
    }
  };

  const fetchReplies = async () => {
    try {
      const docs = await getComments({
        contentId: data._id,
        skip: 0,
        sortKey: "",
      });
      if (Array.isArray(docs?.data) && docs.data.length > 0) {
        setReplies(docs.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchMoreReplies = async () => {
    try {
      const docs = await getComments({
        contentId: data._id,
        skip: replies.length,
        sortKey: "",
      });
      if (Array.isArray(docs?.data) && docs.data.length > 0) {
        setReplies((prev) => [...prev, ...docs.data]);
      } else {
        setExhausted(true);
      }
    } catch (err) {
      console.log(err);
      alert("Error retrieving comments!");
    }
  };

  const submitReply = async (text) => {
    try {
      setReplying("uploading");
      await createComment({
        text,
        contentId: data._id,
        type: "comment",
        originalAuthorId: data.authorId,
      });
      setReplying("success");
    } catch (err) {
      console.log(err);
      alert("Error submitting reply!");
      setReplying(true);
    }
  };

  const commentClick = async () => {
    try {
      setReplying((prev) => !prev);
    } catch (err) {
      console.log(err);
    }
  };

  const handleHide = async () => {
    try {
      await hideContent({
        contentId: data._id,
        type: "comment",
      });
      setCanHide("hidden");
    } catch (err) {
      console.log(err);
      alert(
        "Unable to hide content. If this becomes a persistent issue, please notify us via the Troubleshooting Channel."
      );
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (data.text) {
      renderSafely();
    }
    /*
    setCanHide(false);
    if (data?.authorId) {
      if (data.authorId === localStorage.getItem("_id")) {
        setCanHide(true);
      }
      if (Array.isArray(GlobalCtxt.userInfo?.secretaryOf)) {
        if (GlobalCtxt.userInfo.secretaryOf.includes(data.channelName)) {
          setCanHide(true);
        }
      }
    }*/
  }, [data]);

  useEffect(() => {
    if (replying === true) {
      fetchReplies();
    } else if (replying === "success") {
      setTimeout(() => {
        setReplying(true);
      }, 2500);
    }
  }, [replying]);

  return (
    <div style={{ ...defaultStyling, ...styling }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <ClickableUsername
          userId={data.authorId}
          username={data.authorName}
          createdAt={data.createdAt}
        />
        {canHide === true ? (
          <Tooltip title="Hide post">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleHide();
              }}
            >
              <FlagIcon />
            </IconButton>
          </Tooltip>
        ) : canHide === "hidden" ? (
          <p style={{ fontSize: 14, opacity: 0.5 }}>HIDDEN</p>
        ) : null}
      </div>

      {sanitizedContent ? (
        <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
      ) : null}
      <InteractionsBar
        authorId={data.authorId}
        contentRef={data._id}
        commentCount={data.comments}
        likeCount={data.likes}
        type="comment"
        fontSize={12}
        colors={{ liked: "green" }}
        margin="0px 0px 12px 0px"
        handleComment={commentClick}
      />

      {replying === true ? (
        <>
          {localStorage.getItem("electorate") ? (
            <GenericTextField
              rows={3}
              charLimit={1000}
              placeholder="Write a reply ..."
              handleSubmit={submitReply}
            />
          ) : null}
        </>
      ) : (
        <div
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          {replying === "uploading" ? (
            <CircularProgress />
          ) : replying === "success" ? (
            <div style={{ height: 40, width: 40 }}>
              <DotLottieReact
                src="https://lottie.host/ed77a13f-b127-4131-b8cb-b3e7bab6ab25/Fpxtr3MZdz.lottie"
                autoplay
              />
            </div>
          ) : null}
        </div>
      )}
      {replying === true && Array.isArray(replies) && replies.length > 0 ? (
        <div
          style={{
            display: "flex",
          }}
        >
          <div style={{ width: windowSize.width * 0.04 }}></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              borderLeft: "0.5px solid grey",
              paddingLeft: 8,
            }}
          >
            {replies.map((el, i) => {
              return <Reply data={el} key={el._id} />;
            })}
            {replies.length % 12 === 0 && !exhausted ? (
              <Button variant="outlined" onClick={fetchMoreReplies}>
                LOAD MORE
              </Button>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
}
