import React, { useState } from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import validator from "email-validator";
import { useNavigate } from "react-router-dom";

const hoverStyle = {
  transform: "scale(1.05)",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
};

const iconContainerStyle = {
  width: 120,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: 10,
  backgroundColor: "whitesmoke",
};

const textContainerStyle = {
  display: "flex",
  flex: 1,
  backgroundColor: "rgba(255,255,255,0.5)",
  padding: 10,
  boxSizing: "border-box",
};

const headingStyle = {
  margin: "10px 0px",
};

export default function FlyerDiv1({
  updateDiv1Modal,
  updateLinkedEmail,
  linkedEmail,
}) {
  ////  INITS
  const containerStyle = {
    width: "96vw",
    maxWidth: 600,
    display: "flex",
    border: "1px solid black",
    margin: "10px 0px 5px 0px",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    cursor: "pointer",
    opacity: linkedEmail ? 0.25 : 1,
  };
  const navigate = useNavigate();

  ////  STATES
  const [hoveredEmail, setHoveredEmail] = useState(false);
  const [hoveredSignUp, setHoveredSignUp] = useState(false);
  const [emailSelected, setEmailSelected] = useState(false);
  const [localLinkedEmail, setLocalLinkedEmail] = useState("");

  return (
    <>
      <h4 style={{ textAlign: "center", width: "96%", maxWidth: 600 }}>
        In a moment we'll ask you set your intended delivery route using our
        Interactive Map. But before we do that, we need to authenticate your
        submission.{" "}
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            updateDiv1Modal(true);
          }}
        >
          Why is this important?
        </a>
      </h4>
      <br></br>
      <h2 style={{ textAlign: "center" }}>Choose an authentication method</h2>
      <div
        style={{ ...containerStyle, ...(hoveredEmail ? hoverStyle : {}) }}
        onMouseEnter={() => setHoveredEmail(true)}
        onMouseLeave={() => setHoveredEmail(false)}
        onClick={() => setEmailSelected((prev) => !prev)}
      >
        <div style={iconContainerStyle}>
          <h4 style={headingStyle}>EMAIL</h4>
          <MarkEmailReadIcon fontSize="large" />
        </div>
        <div style={textContainerStyle}>
          <p>
            We'll send you an email containing a verification link and further
            instructions.
          </p>
        </div>
      </div>
      {emailSelected ? (
        <div
          style={{
            width: "96vw",
            maxWidth: 600,
            display: "flex",
            alignItems: "center",
          }}
        >
          <TextField
            fullWidth={true}
            placeholder="Enter your email ..."
            sx={{ backgroundColor: "whitesmoke", borderRadius: "4px" }}
            value={localLinkedEmail}
            onChange={(e) => {
              setLocalLinkedEmail(e.target.value);
            }}
          />
          <Button
            sx={{ marginLeft: "5px" }}
            variant="outlined"
            disabled={localLinkedEmail ? false : true}
            onClick={() => {
              const emailValid = validator.validate(localLinkedEmail.trim());
              if (emailValid) {
                updateLinkedEmail(localLinkedEmail.trim());
              } else {
                alert("Please enter a valid email address.");
              }
            }}
          >
            SUBMIT
          </Button>
        </div>
      ) : null}
      <div
        style={{ ...containerStyle, ...(hoveredSignUp ? hoverStyle : {}) }}
        onMouseEnter={() => setHoveredSignUp(true)}
        onMouseLeave={() => setHoveredSignUp(false)}
        onClick={() => {
          navigate("/register");
        }}
      >
        <div style={iconContainerStyle}>
          <h4 style={headingStyle}>SIGN UP</h4>
          <HowToRegIcon fontSize="large" />
        </div>
        <div style={textContainerStyle}>
          <p>
            Create a free PhaseChamber account. It takes about 30 seconds, no
            links required!
          </p>
        </div>
      </div>
    </>
  );
}
