import React, { useState, useEffect } from "react";

import { login, updateLocalStorage } from "../../functions/GeneralFunctions";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

export default function WitnessLogin({ resetRW }) {
  ////  STATES
  const [password, setPassword] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [loading, setLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);

  ////  FUNCTIONS
  const handleLogin = async () => {
    try {
      setBtnDisabled(true);
      const loggedIn = await login({ identifier, password });
      await updateLocalStorage(loggedIn.data);
      if (loggedIn?.data?.localData?.witnessId) {
        window.location.reload();
      } else {
        alert("No Witness profile found for this account.");
        setBtnDisabled(false);
      }
    } catch (err) {
      console.log(err);
      alert(err.message);
      setBtnDisabled(false);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (localStorage.getItem("email")) {
      setIdentifier(localStorage.getItem("email"));
    }
  }, []);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "rgba(0,0,0,0.2)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "10px 0px",
        padding: 10,
        boxSizing: "border-box",
      }}
    >
      <TextField
        placeholder="Enter PhaseChamber username or email"
        sx={{
          width: "100%",
          maxWidth: "350px",
          backgroundColor: "whitesmoke",
          borderRadius: "4px",
          marginBottom: "5px",
        }}
        value={identifier}
        onChange={(e) => {
          setIdentifier(e.target.value);
        }}
      />
      <TextField
        placeholder="Enter PhaseChamber password"
        sx={{
          width: "100%",
          maxWidth: "350px",
          backgroundColor: "whitesmoke",
          borderRadius: "4px",
          marginBottom: "5px",
        }}
        type="password"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
      />
      <br></br>
      {loading ? (
        <CircularProgress />
      ) : (
        <Button
          variant="contained"
          sx={{ minWidth: "200px" }}
          disabled={btnDisabled}
          onClick={handleLogin}
        >
          LOG IN
        </Button>
      )}
      <Button onClick={resetRW} sx={{ marginTop: "10px" }}>
        BACK
      </Button>
    </div>
  );
}
