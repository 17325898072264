import React from "react";

import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";

const defaultStyling = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "96vw",
  height: "auto",
  maxWidth: 768,
  maxHeight: "80vh",
  backgroundColor: "whitesmoke",
  borderRadius: "8px",
  padding: 10,
  boxSizing: "border-box",
  overflowY: "auto",
};

export default function GenericModal({ open, handleClose, children, styling }) {
  return (
    <Modal open={open} onClose={handleClose}>
      <div style={{ ...defaultStyling, ...styling }}>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "right" }}
        >
          <IconButton onClick={handleClose}>
            <CancelIcon />
          </IconButton>
        </div>
        {children}
      </div>
    </Modal>
  );
}
