//  REVISIT: assigning ids to elements in electorateList

import React, { useContext } from "react";

import { electorateList } from "../../utils/Data";

import "../../App.css";

import { GlobalContext } from "../../App";
import { useNavigate } from "react-router-dom";

export default function ChannelBrowseSidebar() {
  ////  INITS
  const GlobalCtxt = useContext(GlobalContext);
  const navigate = useNavigate();

  return (
    <div
      className="dark-container"
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ maxHeight: "40vh", overflowY: "auto", flexShrink: 0 }}>
        <p
          style={{ cursor: "pointer" }}
          onClick={() => {
            GlobalCtxt.updateCurrentChannel("National");
          }}
        >
          National
        </p>
        <p
          style={{ cursor: "pointer" }}
          onClick={() => {
            GlobalCtxt.updateCurrentChannel("Troubleshooting");
          }}
        >
          Troubleshooting
        </p>
        {localStorage.getItem("electorate") ? (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              GlobalCtxt.updateCurrentChannel(
                localStorage.getItem("electorate")
              );
            }}
          >
            {localStorage.getItem("electorate")}
          </p>
        ) : null}
        {localStorage.getItem("affiliation") &&
        localStorage.getItem("affiliation") !== "null" &&
        localStorage.getItem("affiliation") !== "undefined" ? (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              GlobalCtxt.updateCurrentChannel(
                localStorage.getItem("affiliation")
              );
            }}
          >
            {localStorage.getItem("affiliation")}
          </p>
        ) : null}
      </div>
      <hr style={{ width: "100%" }}></hr>
      <select
        style={{ margin: "8px 0px" }}
        onChange={(e) => {
          GlobalCtxt.updateChannelIndex(e.target.value);
        }}
      >
        <option value="aus_federal_electorates">Federal electorates</option>
        <option value="community_groups">Community groups</option>
      </select>
      <div
        style={{
          overflowY: "auto",
          flexGrow: 1,
        }}
      >
        {GlobalCtxt.globalChannelLonglist &&
        Array.isArray(GlobalCtxt.globalChannelLonglist)
          ? GlobalCtxt.globalChannelLonglist.map((el, i) => {
              return (
                <p
                  key={`${i}-${el}`}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    GlobalCtxt.updateCurrentChannel(el);
                  }}
                >
                  {el}
                </p>
              );
            })
          : null}
      </div>
    </div>
  );
}

/*
import React, { useContext } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

import { GlobalContext } from "../App";

export default function ChannelSidebar({ windowSize }) {
  ////  INITS
  const GlobalCtxt = useContext(GlobalContext);

  ////  FUNCTIONS

  return (
    <>
      <p style={{ color: "grey" }}>Select a channel</p>
      <div
        style={{ width: "100%", height: 0.5, backgroundColor: "white" }}
      ></div>
      {GlobalCtxt.globalChannelShortlist ? (
        <>
          <div style={{ maxHeight: "35vh", overflowY: "auto" }}>
            {GlobalCtxt.globalChannelShortlist.map((el, i) => {
              return (
                <p
                  style={{
                    color: windowSize.width <= 900 ? "black" : "lightgrey",
                    cursor: "pointer",
                  }}
                  key={`${el.name}-${i}`}
                  onClick={() => {
                    GlobalCtxt.updateGlobalCurrentChannel(el);
                  }}
                >
                  {el.name}
                </p>
              );
            })}
          </div>
          <div
            style={{
              width: "100%",
              height: 0.5,
              backgroundColor: "white",
              marginBottom: 8,
            }}
          ></div>
        </>
      ) : (
        <CircularProgress />
      )}
      <select
        onChange={(e) => {
          GlobalCtxt.updateChannelIndex(e.target.value);
        }}
      >
        <option value="electorates">Electorates</option>
        <option value="groups">Community groups</option>
      </select>
      {GlobalCtxt.globalChannelLonglist ? (
        <>
          <div
            style={{
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            {GlobalCtxt.globalChannelLonglist.map((el, i) => {
              return (
                <p
                  style={{
                    color: windowSize.width <= 900 ? "black" : "lightgrey",
                    cursor: "pointer",
                  }}
                  key={`${el.name}-${i}`}
                  onClick={() => {
                    GlobalCtxt.updateGlobalCurrentChannel(el);
                  }}
                >
                  {el.name}
                </p>
              );
            })}
            {GlobalCtxt.globalChannelLonglist.length % 50 === 0 &&
            GlobalCtxt.globalChannelLonglist.length !== 0 ? (
              <Button
                onClick={GlobalCtxt.fetchMoreChannels}
                variant="outlined"
                sx={{ maxWidth: "96%" }}
              >
                VIEW MORE
              </Button>
            ) : null}
          </div>
        </>
      ) : null}
    </>
  );
}
*/
