import React, { useState, useEffect } from "react";

import Button from "@mui/material/Button";

import { getReferralOptions } from "../../functions/PaymentFunctions";

export default function ReferralModal({ handleSubmission, btnDisabled }) {
  ////  STATES
  const [affiliateOptions, setAffiliateOptions] = useState([]);
  const [selectedAffiliate, setSelectedAffiliate] = useState("Independent");

  ////  FUNCTIONS
  const handleClick = () => {
    try {
      if (!selectedAffiliate || selectedAffiliate === "Independent") {
        handleSubmission({
          affiliation: null,
        });
      } else {
        handleSubmission({ affiliation: selectedAffiliate });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAffiliateOptions = async () => {
    try {
      const options = await getReferralOptions();
      const namesArray = options.data.map((option) => option.name);
      if (Array.isArray(namesArray)) {
        setAffiliateOptions(namesArray);
      }
    } catch (err) {
      console.log(err);
      alert("Error fetching referral options!");
    }
  };

  ////  EFFECTS
  useEffect(() => {
    fetchAffiliateOptions();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 10,
        boxSizing: "border-box",
      }}
    >
      <h3>Confirm affiliation</h3>
      <p style={{ textAlign: "center" }}>
        When you upgrade to a paid membership, we pay a bonus to the
        organisation that you're affiliated with.
      </p>
      <p style={{ textAlign: "center" }}>
        You don't have to declare any affiliation. If you select 'Independent',
        your bonus will be paid into the National Channel's Community Balance.
      </p>
      <hr style={{ width: "100%" }}></hr>
      <h4 style={{ textAlign: "center" }}>Who are you affiliated with?</h4>
      <select
        onChange={(e) => {
          setSelectedAffiliate(e.target.value);
        }}
      >
        <option value="Independent">Independent</option>
        {Array.isArray(affiliateOptions) && affiliateOptions.length > 0
          ? affiliateOptions.map((el, i) => {
              return (
                <option key={`${i}-${el}`} value={el}>
                  {el}
                </option>
              );
            })
          : null}
      </select>
      <br></br>
      <br></br>
      <Button
        variant="contained"
        color="success"
        onClick={handleClick}
        disabled={btnDisabled}
        sx={{ marginTop: "5px" }}
      >
        PROCEED TO CHECKOUT
      </Button>
    </div>
  );
}
