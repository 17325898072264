import React, { useState, useEffect } from "react";

import Button from "@mui/material/Button";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";

export default function SponsorSidebar({ sponsors }) {
  ////  INITS
  //const navigate = useNavigate();

  ////  STATES
  const [displayIndex, setDisplayIndex] = useState(0);

  ////  EFFECTS
  useEffect(() => {
    if (Array.isArray(sponsors) && sponsors.length > 0) {
      const interval = setInterval(() => {
        setDisplayIndex((prevIndex) => (prevIndex + 1) % sponsors.length);
      }, 5000);

      // Clear interval on component unmount
      return () => clearInterval(interval);
    }
  }, [sponsors]);

  return (
    <>
      <div style={{ width: "100%" }} className="dark-container">
        <p style={{ textAlign: "center", fontSize: 15, fontWeight: "bold" }}>
          Thank you to our sponsors!
        </p>
        <p style={{ textAlign: "center", fontSize: 13, fontWeight: 300 }}>
          If you want to join our team of sponsors, please reach out at{" "}
          <span style={{ fontWeight: 600 }}>
            admin@winstonsmithinitiative.com.
          </span>
        </p>
        {Array.isArray(sponsors) ? (
          <>
            <img
              src={sponsors[displayIndex].logo}
              style={{ width: "100%", height: "auto" }}
            />
            <p
              style={{
                textAlign: "center",
                fontSize: 14,
                opacity: 0.75,
                margin: 10,
              }}
            >
              {sponsors[displayIndex].name}
            </p>
          </>
        ) : null}
        <hr style={{ opacity: 0.5, width: "100%" }}></hr>
        <p style={{ textAlign: "center", fontSize: 14 }}>
          We've provided our services for free so that this campaign can have
          the biggest possible impact. Your support will enable us to cover our
          costs and reach as many disaffected Australians as possible!
        </p>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Button
            startIcon={<VolunteerActivismIcon />}
            variant="outlined"
            color="success"
            sx={{
              padding: "10px 20px",
              borderRadius: 4,
              border: "2px solid #66BB6A",
              color: "#66BB6A",
              transition: "background-color 0.3s, transform 0.3s",
              "&:hover": {
                backgroundColor: "rgba(102, 187, 106, 0.2)",
                transform: "scale(1.05)",
              },
              width: "98%",
              marginBottom: "10px",
            }}
            onClick={() => {
              window.open(
                "https://www.givesendgo.com/declaration-project",
                "_blank"
              );
            }}
          >
            DONATE
          </Button>
        </div>
        <div
          style={{
            width: "100%",
            backgroundColor: "#C8E6C9",
            padding: 4,
            boxSizing: "border-box",
            borderRadius: 8,
          }}
        >
          <h4
            style={{
              textAlign: "center",
              fontSize: 14,
              color: "black",
              opacity: 0.75,
            }}
          >
            Other ways to show support:
          </h4>
          <h3 style={{ color: "black" }}>1. Substack</h3>
          <img
            src="https://storage.googleapis.com/bucket-775/substack_eric.jpg"
            style={{ width: "100%", height: "auto", cursor: "pointer" }}
            onClick={() => {
              window.open(
                "https://open.substack.com/pub/thewinstonsmithinitiative/p/unvaxxed-and-unbeaten-the-story-of?r=2sjif5&utm_campaign=post&utm_medium=web",
                "_blank"
              );
            }}
          />
          <p style={{ fontSize: 14, color: "black" }}>
            When I'm not creating and running campaigns, I'm writing on
            Substack. Click the image above to read my report on Eric Velvick, a
            retiree from Brisbane who was imprisoned for trying to return home
            while unvaccinated.
          </p>
          <p style={{ fontSize: 14, color: "black" }}>
            Exclusive access is just $7/month. Every subscription boosts the
            sustainability of our movement!
          </p>
        </div>
      </div>
      <div></div>
    </>
  );
}
