//  REVISIT: ensure that only tier 4 can create a broadcast

import React, { useState, useEffect, useContext } from "react";

import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HelpIcon from "@mui/icons-material/Help";
import EditIcon from "@mui/icons-material/Edit";

import GenericTextField from "../common-components/GenericTextField";

import { useDaily } from "@daily-co/daily-react";

import {
  createNewStream,
  getStreamKey,
  listStreams,
  createEndpoint,
  deleteEndpoint,
  updatePassthrough,
} from "../../functions/StreamingFunctions";
import StreamingEndpoint from "./StreamingEndpoint";

import "../../App.css";

import { GlobalContext } from "../../App";
import { SocketContext } from "./SocketCtxtProvider";

export default function StreamingSidebarTab({
  meetingActive,
  streamingActive,
  stopStreaming,
  toggleTab,
}) {
  ////  INITS
  const GlobalCtxt = useContext(GlobalContext);
  const SocketCtxt = useContext(SocketContext);
  const daily = useDaily();
  const params = useParams();

  ////  STATES
  const [streamKey, setStreamKey] = useState("");
  const [showKey, setShowKey] = useState(false);
  const [streamDataDraft, setStreamDataDraft] = useState(null);
  const [endpoints, setEndpoints] = useState([
    {
      passthrough: "PhaseChamber live feed",
      url: "",
      stream_key: "",
    },
  ]);
  const [endpointDraft, setEndpointDraft] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [passthrough, setPassthrough] = useState("");

  ////  FUNCTIONS
  const fetchKey = async () => {
    try {
      setStreamKey("fetching");
      const key = await getStreamKey();
      if (key) {
        const strm = await listStreams(key);
        if (Array.isArray(strm?.data?.data)) {
          setStreamDataDraft(strm.data.data[0]);
        } else {
          createKey();
        }
      } else {
        setTimeout(() => {
          createKey();
        }, 1000);
      }
    } catch (err) {
      console.log(err);
      setStreamKey("error");
    }
  };

  const createKey = async () => {
    try {
      setStreamKey("creating");
      const strm = await createNewStream();
      if (strm?.data?.data?.stream_key) {
        setStreamDataDraft(strm.data.data);
      } else {
        setStreamKey("error");
      }
    } catch (err) {
      console.log(err);
      setStreamKey("error");
    }
  };

  const refreshList = async () => {
    try {
      const strm = await listStreams(streamKey);
      if (Array.isArray(strm?.data?.data)) {
        setStreamDataDraft(strm.data.data[0]);
      }
    } catch (err) {
      console.log(err);
      alert("An unexpected error occurred!");
    }
  };

  const removeEndpoint = async (obj) => {
    try {
      if (obj.id) {
        const deleted = await deleteEndpoint({
          targetId: obj.id,
          streamId: streamDataDraft.id,
        });
      }
      setEndpoints((prev) =>
        prev.filter((endpoint) => endpoint.url !== obj.url)
      );
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  const mergeEndpoints = async (arr) => {
    try {
      setEndpoints((prev) => [...prev, ...arr]);
    } catch (err) {
      console.log(err);
    }
  };

  const updateEndpointDraft = (key, value) => {
    setEndpointDraft({ ...endpointDraft, [key]: value });
  };

  const initialiseEndpointDraft = (cue) => {
    try {
      if (cue === "cancel") {
        setEndpointDraft(null);
      } else {
        setEndpointDraft({
          passthrough: "",
          url: "",
          stream_key: "",
          streamId: streamDataDraft.id,
          parentKey: streamKey,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const submitEndpoint = async () => {
    try {
      setBtnDisabled(true);
      for (var key in endpointDraft) {
        if (!endpointDraft[key]) {
          alert("Complete all fields!");
          return;
        }
      }
      const res = await createEndpoint(endpointDraft);
      await refreshList();
      setEndpointDraft(null);
      setBtnDisabled(false);
    } catch (err) {
      console.log(err);
      alert(err.message);
      setBtnDisabled(false);
    }
  };

  const handleStartStreaming = () => {
    try {
      setBtnDisabled(true);
      SocketCtxt.startStreaming();
    } catch (err) {
      console.log(err);
      setBtnDisabled(false);
    }
  };

  const handleStopStreaming = () => {
    try {
      setBtnDisabled(true);
      stopStreaming();
    } catch (err) {
      console.log(err);
      setBtnDisabled(false);
    }
  };

  const handlePassthroughSubmit = (text) => {
    setPassthrough(text);
  };

  const submitPassthrough = async () => {
    try {
      await updatePassthrough({
        passthrough,
        streamId: SocketCtxt.streamData.id,
      });
    } catch (err) {
      console.log(err);
      alert("Error submitting broadcast name. Default will be used.");
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (streamDataDraft) {
      console.log(streamDataDraft);
      setStreamKey(streamDataDraft.stream_key);
      if (
        streamDataDraft?.simulcast_targets &&
        Array.isArray(streamDataDraft.simulcast_targets)
      ) {
        mergeEndpoints(streamDataDraft.simulcast_targets);
      }
    }
  }, [streamDataDraft]);

  useEffect(() => {
    setBtnDisabled(false);
  }, [streamingActive]);

  useEffect(() => {
    if (passthrough) {
      submitPassthrough();
    }
  }, [passthrough]);

  useEffect(() => {
    if (meetingActive && !passthrough) {
      handlePassthroughSubmit(`${params.channelName} Channel Broadcast`);
    }
  }, [meetingActive]);

  return (
    <>
      {SocketCtxt.streamData ? (
        <>
          {streamingActive ? (
            <p>
              Stream status:{" "}
              <span style={{ color: "limegreen", fontWeight: 600 }}>LIVE</span>
            </p>
          ) : (
            <p>
              Stream status:{" "}
              <span style={{ color: "orange", fontWeight: 600 }}>STANDBY</span>
            </p>
          )}
          {meetingActive ? (
            <>
              {streamingActive ? (
                <Button
                  variant="outlined"
                  color="warning"
                  sx={{ marginBottom: "10px" }}
                  onClick={handleStopStreaming}
                  disabled={btnDisabled}
                >
                  STOP STREAMING
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="success"
                  sx={{ marginBottom: "10px" }}
                  onClick={handleStartStreaming}
                  disabled={btnDisabled}
                >
                  START STREAMING
                </Button>
              )}
            </>
          ) : (
            <p style={{ fontWeight: 300, fontSize: 14, textAlign: "center" }}>
              A 'Start Streaming' button will appear when you join the room.
            </p>
          )}
          {!passthrough ? (
            <>
              <hr style={{ width: "100%" }}></hr>
              <p
                style={{ textAlign: "center" }}
              >{`Give your broadcast a unique title and description! If the title field is left blank, your broadcast will be called '${params.channelName} Channel Broadcast'.`}</p>
              <GenericTextField
                placeholder="Title"
                divStyling={{
                  backgroundColor: "whitesmoke",
                }}
                charLimit={100}
                buttonVariant="outlined"
                handleSubmit={handlePassthroughSubmit}
              />
            </>
          ) : null}

          <hr style={{ width: "100%" }}></hr>
          <div style={{ width: "100%" }}>
            <p style={{ opacity: 0.5 }}>Endpoints:</p>
          </div>
          {SocketCtxt.endpoints
            ? SocketCtxt.endpoints.map((el, i) => {
                return (
                  <StreamingEndpoint
                    data={el}
                    status={streamingActive ? "streaming" : "standby"}
                  />
                );
              })
            : null}
          <hr style={{ width: "100%" }}></hr>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              backgroundColor: "black",
              alignItems: "center",
              borderRadius: 8,
              padding: 5,
              boxSizing: "border-box",
            }}
          >
            {passthrough ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <h4
                    style={{
                      margin: 5,
                      fontWeight: 300,
                      color: streamingActive ? "limegreen" : "orange",
                    }}
                  >
                    {passthrough}
                  </h4>
                  {!streamingActive ? (
                    <IconButton onClick={() => setPassthrough("")}>
                      <EditIcon sx={{ color: "grey" }} fontSize="small" />
                    </IconButton>
                  ) : null}
                </div>
                <hr style={{ opacity: 0.1, width: "100%" }}></hr>
              </>
            ) : null}
            <h4>
              Viewers:{" "}
              {SocketCtxt.viewerCount === 1
                ? `1 (just you)`
                : SocketCtxt.viewerCount}
            </h4>
            <Button
              onClick={() => {
                toggleTab("socket");
              }}
            >
              VIEW LIVE LOBBY
            </Button>
          </div>
        </>
      ) : (
        <>
          {!streamKey ? (
            <>
              <h4 style={{ textAlign: "center" }}>CONFIGURE STREAMING</h4>
              <hr style={{ width: "100%" }}></hr>
              <br></br>
              <Button variant="contained" onClick={fetchKey}>
                FETCH STREAM KEY
              </Button>
            </>
          ) : streamKey === "fetching" ? (
            <h4 className="color-transition">FETCHING KEY ...</h4>
          ) : streamKey === "creating" ? (
            <p className="color-transition" style={{ textAlign: "center" }}>
              Key not found. Generating a new one ...
            </p>
          ) : streamKey === "error" ? (
            <p style={{ textAlign: "center" }}>
              Unable to fetch or generate stream key.
            </p>
          ) : (
            <>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backgroundColor: "#2F4F4F",
                  padding: 8,
                  borderRadius: "8px",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.25)",
                  boxSizing: "border-box",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h4
                    style={{
                      color: "#32CD32",
                      textAlign: "center",
                      margin: "10px 0px 10px 0px",
                    }}
                  >
                    SUCCESS!
                  </h4>
                  <Button
                    onClick={() => {
                      setShowKey((prev) => !prev);
                    }}
                  >
                    {showKey ? "HIDE KEY" : "SHOW KEY"}
                  </Button>
                </div>
                {showKey ? (
                  <p
                    style={{
                      textAlign: "center",
                      color: "#90EE90",
                      marginTop: "15px",
                      backgroundColor: "black",
                      padding: "10px",
                      borderRadius: "5px",
                      wordWrap: "break-word",
                      fontFamily: "monospace",
                    }}
                  >{`rtmp://global-live.mux.com:5222/app/${streamKey}`}</p>
                ) : null}
              </div>
              <div style={{ width: "100%", marginBottom: 5 }}>
                <p>Streaming endpoints:</p>
                {Array.isArray(endpoints) && endpoints.length > 0 ? (
                  endpoints.map((el, i) => {
                    return (
                      <StreamingEndpoint
                        key={`${i}-${el.passthrough}`}
                        data={el}
                        updateArr={removeEndpoint}
                        status="pre"
                      />
                    );
                  })
                ) : (
                  <p style={{ opacity: 0.5 }}>Nothing to display.</p>
                )}
              </div>
              {endpointDraft ? (
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "whitesmoke",
                    border: "0.5px solid black",
                    borderRadius: 4,
                    padding: 5,
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 10,
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TextField
                      placeholder="Endpoint name"
                      sx={{ marginBottom: "2px" }}
                      fullWidth={true}
                      onChange={(e) => {
                        updateEndpointDraft("passthrough", e.target.value);
                      }}
                    />
                    <IconButton
                      onClick={() => {
                        alert(
                          "This field is an arbitrary reference to the platform you're streaming to. You can call it whatever you want (i.e. 'Facebook', FB, 'youtube', etc.)"
                        );
                      }}
                    >
                      <HelpIcon />
                    </IconButton>
                  </div>

                  <TextField
                    placeholder="URL"
                    sx={{ marginBottom: "2px" }}
                    fullWidth={true}
                    onChange={(e) => {
                      updateEndpointDraft("url", e.target.value);
                    }}
                  />
                  <TextField
                    placeholder="Key"
                    sx={{ marginBottom: "2px" }}
                    fullWidth={true}
                    onChange={(e) => {
                      updateEndpointDraft("stream_key", e.target.value);
                    }}
                  />
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    sx={{ marginTop: "10px" }}
                    onClick={submitEndpoint}
                    disabled={btnDisabled}
                  >
                    SUBMIT
                  </Button>
                </div>
              ) : null}
              {endpointDraft ? (
                <Button
                  onClick={() => {
                    initialiseEndpointDraft("cancel");
                  }}
                  variant="outlined"
                  size="small"
                >
                  Cancel endpoint
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    initialiseEndpointDraft("create");
                  }}
                  variant="outlined"
                  startIcon={<AddCircleIcon />}
                  role={undefined}
                >
                  Add endpoint
                </Button>
              )}
              <br></br>
              <hr style={{ width: "100%" }}></hr>
              <p style={{ textAlign: "center" }}>
                When you're ready, click the button below to proceed to the
                standby phase.
              </p>
              <Button
                variant="contained"
                color="info"
                onClick={() => {
                  SocketCtxt.updateStreamData(
                    streamDataDraft,
                    endpoints,
                    streamKey
                  );
                }}
              >
                SAVE CONFIGURATION
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
}

/*
import React, { useState, useEffect, useContext } from "react";

import { checkSec } from "../utils/Functions";
import {
  createStream,
  listStreams,
  addStreamKey,
  labelStream,
} from "../utils/StreamingFunctions";
import GenericModal from "../common-components/GenericModal";
import HelperModalKey from "./HelperModalKey";

import "../App.css";

import { SocketContext } from "../common-components/SocketCtxtProvider";

export default function StreamingSidebar({
  callObject,
  streamState,
  updateStreamState,
  endpoints,
  submitEndpoint,
  deleteEndpoint,
  initialiseEndpoints,
}) {
  ////  INITS
  const params = useParams();
  const SocketCtxt = useContext(SocketContext);

  ////  STATES
  const [accessDenied, setAccessDenied] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showKey, setShowKey] = useState(false);
  const [endpointDraft, setEndpointDraft] = useState(null);
  const [isLive, setIsLive] = useState(null);
  const [isStreaming, setIsStreaming] = useState(null);
  const [customEndpoint, setCustomEndpoint] = useState(false);

  ////  FUNCTIONS
  const configureStream = async () => {
    try {
      setLoading(true);
      if (SocketCtxt.isAdmin === false) {
        setAccessDenied(true);
        return;
      }
      const getCredentials = await checkSec(params.channelId);
      if (parseInt(getCredentials.data?.tier) < 3) {
        setAccessDenied(true);
        return;
      }
      let stream;
      if (getCredentials.data.streamKey) {
        stream = await listStreams(getCredentials.data.streamKey);
        if (Array.isArray(stream.data) && stream.data[0]) {
          SocketCtxt.updateStreamData(stream.data[0]);
          initialiseEndpoints(stream.data[0].simulcast_targets);
          setLoading(false);
          updateStreamState("configured");
          return;
        }
      }
      stream = await createStream();
      SocketCtxt.updateStreamData(stream.data);
      setLoading(false);
      updateStreamState("configured");
      await addStreamKey(stream.data.stream_key);
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  const initialiseEndpointDraft = () => {
    setEndpointDraft({
      url: "",
      stream_key: "",
      passthrough: "",
      streamId: SocketCtxt.streamData.id,
    });
  };

  

  function anonymizeString(input) {
    return input
      .split("")
      .map(() => "x")
      .join("");
  }

  const handleStartLiveStreaming = async () => {
    try {
      setLoading(true);
      const streaming = await callObject.startLiveStreaming({
        rtmpUrl: `rtmp://global-live.mux.com:5222/app/${SocketCtxt.streamData.stream_key}`,
        layout: {
          preset: "custom", // required
          composition_params: {
            mode: "dominant",
            showTextOverlay: true,
            "text.content": "Hello",
            "text.align_horizontal": "right",
            "text.align_vertical": "bottom",
            "text.fontFamily": "Exo",
            "text.fontWeight": "500",
            "videoSettings.roundedCorners": true,
            "videoSettings.showParticipantLabels": true,
          },
        },
      });
      setTimeout(() => {
        setLoading(false);
      }, 3500);
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (endpointDraft) {
      if (endpointDraft.passthrough === "Other") {
        setCustomEndpoint(true);
      }
    }
  }, [endpointDraft]);

  useEffect(() => {
    if (streamState === "streaming") {
      setLoading(false);
    }
  }, [streamState]);

  useEffect(() => {
    if (accessDenied) {
      setLoading(false);
    }
  }, [accessDenied]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {streamState ? (
            <>
              {streamState === "configured" || streamState === "joined" ? (
                <>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      backgroundColor: "#2F4F4F", // Dark Slate Gray with a greenish tint
                      padding: 10,
                      borderRadius: "8px",
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.25)",
                      boxSizing: "border-box",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h4
                        style={{
                          color: "#32CD32", // LimeGreen
                          textAlign: "center",
                          margin: "10px 0px 10px 0px",
                        }}
                      >
                        SUCCESS!
                      </h4>
                      <Button
                        onClick={() => {
                          setShowKey((prev) => !prev);
                        }}
                      >
                        {showKey ? "HIDE KEY" : "SHOW KEY"}
                      </Button>
                    </div>

                    {showKey && SocketCtxt.streamData?.stream_key ? (
                      <p
                        style={{
                          textAlign: "center",
                          color: "#90EE90", // LightGreen
                          marginTop: "15px",
                          backgroundColor: "#3CB371", // Medium Sea Green
                          padding: "10px",
                          borderRadius: "5px",
                          wordWrap: "break-word",
                          fontFamily: "monospace",
                        }}
                      >{`rtmp://global-live.mux.com:5222/app/${SocketCtxt.streamData.stream_key}`}</p>
                    ) : null}
                  </div>
                  <hr style={{ width: "100%" }}></hr>
                  <p style={{ color: "white" }}>Streaming endpoints:</p>
                  {Array.isArray(endpoints) && endpoints[0] ? (
                    <>
                      {endpoints.map((el, i) => {
                        return (
                          <div
                            style={{
                              width: "100%",
                              backgroundColor: "whitesmoke",
                              padding: 5,
                              boxSizing: "border-box",
                              border: "2px solid green",
                              borderRadius: 2,
                              marginBottom: 8,
                            }}
                            key={el.id}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <p style={{ fontWeight: 600 }}>
                                {el.passthrough}
                              </p>
                              {el.url !== "Default" ? (
                                <IconButton
                                  onClick={() => {
                                    deleteEndpoint(el.id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              ) : null}
                            </div>
                            <p>
                              <span style={{ fontWeight: 600 }}>URL:</span>{" "}
                              {el.url}
                            </p>
                            {showKey === el.stream_key ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <p>
                                  <span style={{ fontWeight: 600 }}>Key:</span>{" "}
                                  {el.stream_key}
                                </p>
                                <IconButton
                                  sx={{ marginLeft: "5px" }}
                                  onClick={() => {
                                    setShowKey("");
                                  }}
                                >
                                  <VisibilityOffIcon />
                                </IconButton>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <p>
                                  <span style={{ fontWeight: 600 }}>Key:</span>{" "}
                                  {anonymizeString(el.stream_key)}
                                </p>
                                <IconButton
                                  sx={{ marginLeft: "5px" }}
                                  onClick={() => {
                                    setShowKey(el.stream_key);
                                  }}
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </div>
                            )}
                          </div>
                        );
                      })}
                      {endpointDraft ? (
                        <>
                          <div
                            style={{
                              width: "100%",
                              backgroundColor: "whitesmoke",
                              border: "0.5px solid black",
                              borderRadius: 4,
                              padding: 5,
                              boxSizing: "border-box",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {customEndpoint ? (
                              <TextField
                                placeholder="Endpoint name"
                                sx={{ marginBottom: "2px" }}
                                fullWidth={true}
                                onChange={(e) => {
                                  updateEndpointDraft(
                                    "passthrough",
                                    e.target.value
                                  );
                                }}
                              />
                            ) : (
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "2px",
                                }}
                              >
                                <p style={{ marginRight: 5 }}>Endpoint: </p>
                                <select
                                  style={{ fontSize: 16 }}
                                  onChange={(e) => {
                                    updateEndpointDraft(
                                      "passthrough",
                                      e.target.value
                                    );
                                  }}
                                >
                                  <option></option>
                                  <option>Facebook</option>
                                  <option>YouTube</option>
                                  <option>Rumble</option>
                                  <option>Other</option>
                                </select>
                              </div>
                            )}
                            <TextField
                              placeholder="URL"
                              sx={{ marginBottom: "2px" }}
                              fullWidth={true}
                              onChange={(e) => {
                                updateEndpointDraft("url", e.target.value);
                              }}
                            />
                            <TextField
                              placeholder="Stream key"
                              sx={{ marginBottom: "2px" }}
                              fullWidth={true}
                              onChange={(e) => {
                                updateEndpointDraft(
                                  "stream_key",
                                  e.target.value
                                );
                              }}
                            />

                            <br></br>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Button
                                variant="contained"
                                color="success"
                                onClick={() => {
                                  submitEndpoint(endpointDraft);
                                  setEndpointDraft(null);
                                }}
                                fullWidth={true}
                              >
                                Connect endpoint
                              </Button>
                              <Button
                                variant="outlined"
                                sx={{ marginLeft: "5px" }}
                                onClick={() => {
                                  setEndpointDraft(null);
                                }}
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                          <br></br>
                        </>
                      ) : null}
                      {endpointDraft ? null : (
                        <Button
                          onClick={initialiseEndpointDraft}
                          variant="outlined"
                          startIcon={<AddCircleIcon />}
                          role={undefined}
                        >
                          Add endpoint
                        </Button>
                      )}
                      <br></br>
                      <hr style={{ width: "100%" }}></hr>
                      <p style={{ textAlign: "center", color: "white" }}>
                        You're all set! Start the video call when you're ready,
                        and then click the button that appears below to start
                        broadcasting to your specified endpoints.
                      </p>
                      <br></br>
                      <Button
                        color="success"
                        variant="contained"
                        disabled={streamState === "joined" ? false : true}
                        onClick={handleStartLiveStreaming}
                      >
                        START STREAMING
                      </Button>
                    </>
                  ) : null}
                </>
              ) : streamState === "streaming" ? (
                <>
                  <h4
                    className="slow-flash"
                    style={{ color: "limegreen", fontWeight: 400 }}
                  >
                    STREAMING ACTIVE
                  </h4>
                  {Array.isArray(endpoints) && endpoints[0] ? (
                    <>
                      <div style={{ width: "100%" }}>
                        <p style={{ color: "white" }}>External endpoints:</p>
                        <ul style={{ color: "white", fontSize: 14 }}>
                          {endpoints.map((el, i) => {
                            return <li>{el.passthrough}</li>;
                          })}
                        </ul>
                        <br></br>
                        <p style={{ color: "white" }}>
                          <span style={{ fontWeight: 600 }}>NOTE: </span>You may
                          need to manually publish the stream in order for it to
                          be seen on your other platforms.
                        </p>
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}
            </>
          ) : (
            <>
              {accessDenied ? (
                <p style={{ color: "white", textAlign: "center" }}>
                  Live streaming is only available to users who are subscribed
                  to the Community Leader membership tier.
                </p>
              ) : (
                <>
                  <p style={{ textAlign: "center", color: "white" }}>
                    Click the button below to fetch your stream key (if you've
                    never streamed from this website before, a new key will be
                    automatically generated for you).
                  </p>
                  <Button onClick={configureStream}>FETCH KEY</Button>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
*/
