import React, { useRef, useState } from "react";

import SignatureCanvas from "react-signature-canvas";

const SignaturePad = ({
  handleClearSignature,
  grabSignature,
  height,
  width,
}) => {
  ////  INITS
  const sigCanvas = useRef(null);

  ////  STATES
  const [saved, setSaved] = useState(false);

  ////  FUNCTIONS
  const clearSignature = () => {
    sigCanvas.current.clear();
    setSaved(false);
    handleClearSignature();
  };

  const saveSignature = () => {
    try {
      const signatureDataURL = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      grabSignature(signatureDataURL);
      setSaved(true);
    } catch (err) {
      console.log(err);
      alert("Error saving signature! Please clear and try again.");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxHeight: height + 50,
        maxWidth: width,
      }}
    >
      <SignatureCanvas
        ref={sigCanvas}
        penColor="black"
        canvasProps={{
          width,
          height,
          className: "sigCanvas",
          style: {
            backgroundColor: "white",
            border: saved ? "2px solid green" : "1px solid #000",
          },
        }}
      />
      <div style={{ display: "flex", padding: "5px 0px" }}>
        <button style={{ marginRight: 5 }} onClick={clearSignature}>
          Clear
        </button>
        <button onClick={saveSignature} disabled={saved ? true : false}>
          Save
        </button>
      </div>
    </div>
  );
};

export default SignaturePad;
