import React, { useContext, useState, useEffect } from "react";

import DynamicFrame from "../common-components/DynamicFrame";
import GenericSidebar from "../common-components/GenericSidebar";
import GenericMain from "../common-components/GenericMain";
import GenericModal from "../common-components/GenericModal";
import GenericDrawer from "../common-components/GenericDrawer";
import StreamTab from "./StreamTab";
import SocketCtxtProvider from "./SocketCtxtProvider";
import { useWindowSize } from "../../utils/Hooks";
import { getUserByStreamKey } from "../../functions/StreamingFunctions";
import TipModal from "../common-components/TipModal";

import MuxPlayer from "@mux/mux-player-react";
import Button from "@mui/material/Button";
import PaidIcon from "@mui/icons-material/Paid";

import "../../App.css";

export default function StreamWindow({ streams }) {
  ////  INITS
  const windowSize = useWindowSize();

  ////  STATES
  const [currentStream, setCurrentStream] = useState(null);
  const [currentBroadcaster, setCurrentBroadcaster] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [pulsing, setPulsing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [tipping, setTipping] = useState(false);

  ////  FUNCTIONS
  const initCurrentStream = async (strm) => {
    try {
      if (sidebarOpen) {
        setSidebarOpen(false);
      }
      const user = await getUserByStreamKey(strm.stream_key);
      setCurrentBroadcaster(user.data);
      setCurrentStream(strm);
    } catch (err) {
      console.log(err);
      alert(
        "Error initialising streams! Please refresh the page to try again."
      );
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (streams) {
      console.log(streams);
    }
  }, [streams]);

  useEffect(() => {
    if (currentStream) {
      const intervalId = setInterval(() => {
        setPulsing(true);
        setTimeout(() => {
          setPulsing(false);
        }, 5000);
      }, 1000 * 60 * 5);

      return () => clearInterval(intervalId);
    }
  }, [currentStream]);

  return (
    <>
      <GenericModal open={tipping} handleClose={() => setTipping(false)}>
        <TipModal recipient={currentBroadcaster} />
      </GenericModal>
      {windowSize.width <= 768 ? (
        <GenericDrawer
          open={sidebarOpen}
          handleClose={() => setSidebarOpen(false)}
        >
          {streams && Array.isArray(streams)
            ? streams.map((el, i) => {
                return (
                  <StreamTab initCurrentStream={initCurrentStream} data={el} />
                );
              })
            : null}
        </GenericDrawer>
      ) : null}
      {streams && windowSize.width <= 768 ? (
        <Button onClick={() => setSidebarOpen(true)}>View sidebar</Button>
      ) : null}
      <DynamicFrame
        windowSize={windowSize}
        styling={{
          maxWidth: "100vw",
          maxHeight: "90vh",
        }}
      >
        {windowSize.width > 768 ? (
          <GenericSidebar
            windowSize={windowSize}
            styling={{
              width: "auto",
              maxWidth: windowSize.width * 0.15,
            }}
          >
            {streams && Array.isArray(streams)
              ? streams.map((el, i) => {
                  return (
                    <StreamTab
                      initCurrentStream={initCurrentStream}
                      data={el}
                    />
                  );
                })
              : null}
          </GenericSidebar>
        ) : null}
        <GenericMain styling={{ padding: 0 }}>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "black",
            }}
          >
            {Array.isArray(streams) && streams.length > 0 ? (
              <>
                {/* REVISIT: valid metadata */}
                {currentStream ? (
                  <MuxPlayer
                    playbackId={currentStream.playback_ids[0].id}
                    metadata={{
                      video_id: "video-id-54321",
                      video_title: "Test video title",
                      viewer_user_id: "user-id-007",
                    }}
                  />
                ) : (
                  <p style={{ textAlign: "center", color: "grey" }}>
                    Select a stream from the sidebar on the left.
                  </p>
                )}
              </>
            ) : (
              <p style={{ textAlign: "center", color: "grey" }}>
                Nobody is currently streaming. Please check back later.
              </p>
            )}
          </div>
          {currentStream ? (
            <div
              style={{
                width: "100%",
                backgroundColor: "black",
                padding: "10px 20px",
                boxSizing: "border-box",
                borderTop: "0.5px solid grey",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ maxWidth: "66%" }}>
                <h3 style={{ color: "white", margin: "10px 0px" }}>
                  {currentStream.passthrough}
                </h3>
                <h4
                  style={{
                    color: "lightgrey",
                    fontWeight: 400,
                    margin: "5px 0px",
                  }}
                >
                  {currentBroadcaster.username}
                </h4>
              </div>
              {/* <div
                className={`${
                  pulsing
                    ? "animate__animated animate__tada animate__duration-4s"
                    : ""
                } ${isHovered ? "animated-border" : ""}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <Button
                  variant="contained"
                  startIcon={isHovered ? null : <PaidIcon />}
                  className="custom-button"
                  onClick={() => setTipping(true)}
                >
                  {`Tip ${currentBroadcaster.username}`}
                </Button>
              </div>*/}
              <div />
            </div>
          ) : null}
        </GenericMain>
        {windowSize.width > 768 ? (
          <GenericSidebar windowSize={windowSize}>
            {currentStream ? (
              <SocketCtxtProvider
                internal={false}
                isAdmin={false}
                stream={currentStream}
              />
            ) : null}
          </GenericSidebar>
        ) : null}
      </DynamicFrame>
    </>
  );
}
