import React, { useState, useEffect } from "react";

import { timeSince } from "../../functions/GeneralFunctions";
import { createMeetingCode } from "../../functions/ConferenceFunctions";
import { useParams, useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";

export default function AccessRequest({ data, isAdmin, sendInvitation }) {
  ////  INITS
  const params = useParams();
  const navigate = useNavigate();

  ////  STATES
  const [accessGranted, setAccessGranted] = useState(false);
  const [invitationSent, setInvitationSent] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);

  ////  FUNCTIONS
  const getAndSendCode = async (user) => {
    try {
      // REVISIT: do we need to decodeURIComponent for roomName? */
      setBtnDisabled(true);
      if (params.channelId && params.roomName && params.channelName) {
        const code = await createMeetingCode({
          channelId: params.channelId,
          roomName: params.roomName,
          channelName: decodeURIComponent(params.channelName),
        });
        sendInvitation(code.data, data.user);
        setBtnDisabled(false);
        setInvitationSent(true);
      }
    } catch (err) {
      console.log(err);
      alert("Failed to generate code. Please try again later.");
      setBtnDisabled(false);
    }
  };

  const enterCall = () => {
    try {
      if (data?.channelId && data?.channelName && data?.roomName) {
        navigate(
          `/live-call/${data.roomName}/${data.channelId}/${data.channelName}/broadcast/${data.code}`
        );
      }
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (data?.code && data.user === localStorage.getItem("username")) {
      setAccessGranted(true);
    }
  }, [data]);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "whitesmoke",
        padding: 5,
        marginBottom: 5,
        boxSizing: "border-box",
        border: "1px solid black",
        borderRadius: 8,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p style={{ color: "black" }}>
          {data.user} ({`${timeSince(data.time)} ago`})
        </p>
        {isAdmin ? (
          <Button onClick={() => setExpanded((prev) => !prev)}>
            {expanded ? `SHOW LESS` : `VIEW`}
          </Button>
        ) : (
          <>
            {accessGranted ? <Button onClick={enterCall}>JOIN</Button> : null}
          </>
        )}
      </div>
      {expanded ? (
        <>
          <p style={{ color: "black", fontWeight: 300, fontSize: 14 }}>
            {data.text}
          </p>
          <hr style={{ width: "100%", opacity: 0.5 }}></hr>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            {invitationSent ? (
              <p>Invitation sent!</p>
            ) : (
              <Button variant="contained" size="small" onClick={getAndSendCode}>
                SEND INVITATION
              </Button>
            )}
          </div>
        </>
      ) : null}
    </div>
  );
}
