import React, { useState, useContext } from "react";

import WitnessRegister from "./WitnessRegister";
import WitnessLogin from "./WitnessLogin";
import WitnessDashboard from "./WitnessDashboard";
import { occupations } from "../../utils/Data";

import Button from "@mui/material/Button";

import { GlobalContext } from "../../App";

export default function WitnessLanding({ windowSize }) {
  ////  INITS
  const GlobalCtxt = useContext(GlobalContext);

  ////  STATES
  const [renderWhat, setRenderWhat] = useState("");

  ////  FUNCTIONS
  const resetRW = () => {
    setRenderWhat("");
  };

  return (
    <>
      {GlobalCtxt.userInfo?.witnessId ? (
        <WitnessDashboard witnessId={GlobalCtxt.userInfo.witnessId} />
      ) : (
        <>
          {renderWhat === "login" ? (
            <WitnessLogin resetRW={resetRW} />
          ) : renderWhat === "register" ? (
            <WitnessRegister resetRW={resetRW} />
          ) : (
            <>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    backgroundColor: "rgba(0,0,0,0.8)",
                    alignItems: "center",
                    width: "98%",
                    maxWidth: 600,
                    padding: 10,
                    boxSizing: "border-box",
                    borderRadius: 4,
                    boxShadow: GlobalCtxt.genericBoxShadow,
                    margin: "20px 0px 20px 0px",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      setRenderWhat("login");
                    }}
                  >
                    Log in
                  </Button>
                  <h2 style={{ color: "white", fontWeight: 400 }}>/</h2>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setRenderWhat("register");
                    }}
                  >
                    Register
                  </Button>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <h2>Become a Witness!</h2>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: windowSize.width > 900 ? "row" : "column",
                  justifyContent: "space-between",
                  height: "auto",
                  border: "1px solid black",
                  backgroundColor: "white",
                }}
              >
                <div
                  style={{
                    width: windowSize.width > 900 ? "48%" : "100%",
                    padding: 5,
                    boxSizing: "border-box",
                  }}
                >
                  <p>
                    Statutory declarations are not legally valid until they've
                    been signed in the presence of a qualified witness.
                  </p>
                  <p>
                    To maximise participation in the Declaration Project, we're
                    compiling a database of everyday Australians who are able
                    and willing to become a volunteer Witness. If your
                    occupation appears in the attached list, you may be eligible
                    to register!
                  </p>
                  <p>
                    All registered Witnesses will gain access to our very own
                    video-conferencing technology, which enables you to witness
                    Declarations from the comfort of your own home.
                  </p>
                  <p>
                    If you're suitably qualified and keen to get involved, hit
                    the Register button above to begin the registration process!
                    Everyone who registers will be contacted by a member of our
                    team before their Witness profile is approved, so you'll
                    have ample opportunity to ask questions before witnessing
                    your first Declaration.
                  </p>
                </div>
                <div
                  style={{
                    height: "auto",
                    width: windowSize.width > 900 ? "48%" : "100%",
                    overflowY: "auto",
                    backgroundColor: "lightgrey",
                    padding: 5,
                    boxSizing: "border-box",
                  }}
                >
                  <h4>Qualifying occupations:</h4>
                  <ul>
                    {occupations.map((el, i) => {
                      return (
                        <li
                          key={i}
                          style={{ display: el.value ? "list-item" : "none" }}
                        >
                          {el.value}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

/*
<div
                    style={{
                      width: "100%",
                      height: "auto",
                      backgroundColor: "lightgrey",
                      padding: 5,
                      boxSizing: "border-box",
                      overflowY: "auto",
                    }}
                  >
                    
                  </div>
*/
