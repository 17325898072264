import Axios from "axios";
import authenticatedConfig from "../utils/Token";

const submitSecretaryNomination = async (data) => {
  try {
    console.log(data);
    const doc = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/polls/submit-secretary-nomination`,
      data,
      authenticatedConfig
    );
    console.log(doc);
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const withdrawSecretaryNomination = async (data) => {
  try {
    const doc = await Axios.put(
      `${process.env.REACT_APP_DEV_SERVER}/polls/withdraw-secretary-nomination`,
      data,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const createElection = async (data) => {
  try {
    const doc = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/polls/create-election`,
      data,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getPolls = async ({ channelId, channelName, skip, limit }) => {
  try {
    const docs = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/polls/get-polls?channelId=${channelId}&channelName=${channelName}&skip=${skip}&limit=${limit}`
    );
    return docs;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getSinglePoll = async (pollId) => {
  try {
    const doc = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/polls/get-single-poll?pollId=${pollId}`
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const countVotes = async (pollId) => {
  try {
    const docs = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/polls/count-votes?pollId=${pollId}`,
      authenticatedConfig
    );
    return docs;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const castVote = async (data) => {
  try {
    const doc = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/polls/cast-vote`,
      data,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const resolvePollManually = async (data) => {
  try {
    const doc = await Axios.put(
      `${process.env.REACT_APP_DEV_SERVER}/polls/resolve-poll-manually`,
      data,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getNominations = async (channelId) => {
  try {
    const docs = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/polls/get-nominations?channelId=${channelId}`
    );
    return docs;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getPastVotes = async ({ skip, limit }) => {
  try {
    const docs = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/polls/get-past-votes?skip=${skip}&limit=${limit}`,
      authenticatedConfig
    );
    return docs;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

export {
  submitSecretaryNomination,
  withdrawSecretaryNomination,
  createElection,
  getPolls,
  countVotes,
  castVote,
  resolvePollManually,
  getNominations,
  getSinglePoll,
  getPastVotes,
};
