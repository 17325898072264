import React from "react";

export default function GenericSidebar({ styling, children, windowSize }) {
  const defaultStyling = {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgba(0,0,0,0.6)",
    width: windowSize.width * 0.25,
    height: "100%",
    padding: 10,
    boxSizing: "border-box",
    overflowY: "auto",
  };

  return <div style={{ ...defaultStyling, ...styling }}>{children}</div>;
}
