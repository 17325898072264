import Axios from "axios";
import authenticatedConfig from "../utils/Token";

const sendMarkerAuthenticationEmail = async (data) => {
  try {
    const doc = await Axios.post(
      `${process.env.REACT_APP_MAILING_SERVER}/email/verify-marker`,
      data
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const sendCustomisedLetter = async (data) => {
  try {
    const doc = await Axios.post(
      `${process.env.REACT_APP_MAILING_SERVER}/email/send-customised-letter`,
      data
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const initiateReset = async (email) => {
  try {
    const res = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/admin/initiate-reset?email=${email}`
    );
    if (res?.data) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const bulkSendColdEmails = async (arr) => {
  try {
    await Axios.post(
      `${process.env.REACT_APP_MAILING_SERVER}/email/bulk-send-cold-emails`,
      { testimonies: arr }
    );
    return;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

export {
  sendMarkerAuthenticationEmail,
  sendCustomisedLetter,
  initiateReset,
  bulkSendColdEmails,
};
