import React from "react";

const colors = [
  "green",
  "limegreen",
  "orange",
  "red",
  "yellow",
  "gold",
  "lightblue",
  "dodgerblue",
  "violet",
  "pink",
];

export default function ColorPalette({ handleClick }) {
  return (
    <div
      style={{
        width: "100%",
        maxWidth: 250,
        height: "100%",
        display: "flex",
        padding: 4,
        boxSizing: "border-box",
        overflowX: "auto",
      }}
    >
      {colors.map((el, i) => {
        return (
          <div
            style={{
              height: "100%",
              aspectRatio: "1/1",
              backgroundColor: el,
              marginLeft: 2,
              cursor: "pointer",
            }}
            key={`${i}-${el}`}
            onClick={() => {
              handleClick(el);
            }}
          />
        );
      })}
    </div>
  );
}
