import React from "react";

import { deleteVideoEvent } from "../../functions/ConferenceFunctions";

import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";

export default function SessionPreview({ data }) {
  ////  INITS
  const navigate = useNavigate();

  ////  FUNCTIONS
  const deleteSession = async (id) => {
    try {
      await deleteVideoEvent(id);
      window.location.reload();
    } catch (err) {
      console.log(err);
      alert("Error deleting session! Please try again.");
    }
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        border: "2px solid skyblue",
        borderRadius: 4,
        padding: 10,
        boxSizing: "border-box",
        margin: "5px 0px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            padding: 5,
            backgroundColor:
              data.time > Date.now()
                ? "orange"
                : data.time < Date.now() &&
                  data.time + data.duration > Date.now()
                ? "green"
                : "grey",
            borderRadius: 8,
            marginRight: 10,
          }}
        >
          <h4
            style={{
              color: "white",
              margin: 2,
            }}
          >
            {data.time > Date.now() ? (
              `UPCOMING`
            ) : (
              <>{Date.now() < data.time + data.duration ? `ACTIVE` : `PAST`}</>
            )}
          </h4>
        </div>
        <p style={{ opacity: 0.75 }}>{new Date(data.time).toLocaleString()}</p>
        <p style={{ opacity: 0.75, marginLeft: 10 }}>
          {data.duration === 3600000
            ? `(1hr)`
            : `(${data.duration / 3600000}hrs)`}
        </p>
      </div>
      <hr style={{ opacity: 0.25, width: "100%" }}></hr>
      <div
        style={{
          display: "flex",
          backgroundColor: "rgba(0,0,0,0.1)",
          padding: 8,
          boxSizing: "border-box",
          borderRadius: 4,
        }}
      >
        <Button
          variant="contained"
          color="success"
          size="small"
          startIcon={<PlayCircleOutlineIcon />}
          onClick={() => {
            if (localStorage.getItem("_id")) {
              navigate(
                `/live-call/${data.roomName}/${data.channelId}/${data.channelName}/${data.callType}`
              );
            } else {
              alert("Log back in to start your witnessing session.");
            }
          }}
        >
          JOIN
        </Button>
        <div style={{ width: 10 }}></div>
        <Button
          variant="outlined"
          color="error"
          size="small"
          startIcon={<DeleteIcon />}
          onClick={() => {
            deleteSession(data._id);
          }}
        >
          CANCEL
        </Button>
      </div>
    </div>
  );
}
