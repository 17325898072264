import Axios from "axios";
import authenticatedConfig from "../utils/Token";

const getStreamKey = async () => {
  try {
    const doc = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/stream/get-stream-key`,
      authenticatedConfig
    );
    if (doc.data) {
      return doc.data;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const createNewStream = async () => {
  try {
    const doc = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/stream/create-new-stream`,
      {},
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const listStreams = async (streamKey) => {
  try {
    let docs;
    if (streamKey) {
      docs = await Axios.get(
        `${process.env.REACT_APP_DEV_SERVER}/stream/list-streams-by-key?streamKey=${streamKey}`
      );
    } else {
      docs = await Axios.get(
        `${process.env.REACT_APP_DEV_SERVER}/stream/list-streams`
      );
    }
    return docs;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const createEndpoint = async (data) => {
  try {
    let doc = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/stream/create-endpoint`,
      data,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const deleteEndpoint = async (data) => {
  try {
    let doc = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/stream/delete-endpoint`,
      data,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getAsset = async (assetId) => {
  try {
    const doc = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/stream/get-asset?assetId=${assetId}`
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const deleteAsset = async (assetId) => {
  try {
    const doc = await Axios.put(
      `${process.env.REACT_APP_DEV_SERVER}/stream/delete-asset`,
      {
        assetId,
      },
      authenticatedConfig
    );
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const createAsset = async (data) => {
  try {
    const doc = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/video/create-asset`,
      data,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const updatePassthrough = async (data) => {
  try {
    const doc = await Axios.put(
      `${process.env.REACT_APP_DEV_SERVER}/stream/update-passthrough`,
      data,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getUserByStreamKey = async (key) => {
  try {
    const doc = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/stream/get-user-by-stream-key?streamKey=${key}`
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const checkIsPublished = async (assetId) => {
  try {
    const doc = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/video/check-is-published?assetId=${assetId}`
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getVids = async (data) => {
  try {
    const { key, value, sortKey, skip, limit, timeframe } = data;
    let docs;
    if (!key) {
      docs = await Axios.get(
        `${process.env.REACT_APP_DEV_SERVER}/video/get-videos?sortKey=${sortKey}&skip=${skip}&limit=${limit}&timeframe=${timeframe}`
      );
    } else if (key === "channel") {
      docs = await Axios.get(
        `${process.env.REACT_APP_DEV_SERVER}/video/get-videos-by-channel?sort=${sortKey}&skip=${skip}&limit=${limit}&timeframe=${timeframe}&channelId=${value}`
      );
    }
    return docs;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

export {
  getStreamKey,
  createNewStream,
  listStreams,
  createEndpoint,
  deleteEndpoint,
  getAsset,
  updatePassthrough,
  getUserByStreamKey,
  deleteAsset,
  createAsset,
  checkIsPublished,
  getVids,
};
