import React, { useState, useEffect, useContext } from "react";

import {
  getWitnessData,
  checkForDec,
} from "../../functions/AffidavitFunctions";
import Countdown from "../common-components/Countdown";
import { useWindowSize } from "../../utils/Hooks";

import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";

import { QueueContext } from "../conference-components/QueueCtxtProvider";
import { GlobalContext } from "../../App";

export default function SessionLobby({ data }) {
  ////  INITS
  const windowSize = useWindowSize();
  const QueueCtxt = useContext(QueueContext);
  const GlobalCtxt = useContext(GlobalContext);
  const navigate = useNavigate();

  ////  STATES
  const [witnessInfo, setWitnessInfo] = useState(null);
  const [declarationId, setDeclarationId] = useState("");
  const [queueing, setQueueing] = useState(false);
  const [queuePosition, setQueuePosition] = useState(null);
  const [invited, setInvited] = useState(false);

  ////  FUNCTIONS
  const fetchWitnessInfo = async () => {
    try {
      const doc = await getWitnessData(data.witnessId);
      if (doc && doc?.data) {
        setWitnessInfo(doc.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleJoinQueue = async () => {
    try {
      const exists = await checkForDec(declarationId);
      if (exists?.data) {
        QueueCtxt.joinQueue(data.roomName, declarationId);
      } else {
        alert("Declaration not found! Please double-check your ID.");
      }
    } catch (err) {
      console.log(err);
      alert("Declaration not found! Please double-check your ID.");
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (data?.witnessId) {
      fetchWitnessInfo();
    }
  }, [data]);

  useEffect(() => {
    if (
      QueueCtxt.queues?.[data?.roomName] &&
      Array.isArray(QueueCtxt.queues[data.roomName]?.waiting)
    ) {
      const user = QueueCtxt.queues[data.roomName].waiting.find(
        (el) => el.decId === declarationId
      );

      if (user) {
        setQueueing(true);
        setQueuePosition(
          QueueCtxt.queues[data.roomName].waiting.indexOf(user) + 1
        );
        if (user.invited) {
          setInvited(true);
        } else {
          setInvited(false);
        }
      } else {
        setQueueing(false);
      }
    }
  }, [QueueCtxt?.queues]);

  ////  STYLES
  const styles = {
    container: {
      width: "100%",
      flexDirection: windowSize.width >= 600 ? "row" : "column",
      alignItems: "center",
      justifyContent: windowSize.width >= 600 ? "space-between" : "center",
      backgroundColor: "#f7f9fc", // Softer background
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Subtle shadow
      padding: 16,
      boxSizing: "border-box",
      borderRadius: 10, // Rounded corners for a smoother look
      margin: "10px 0px",
      display: data.time + data.duration < Date.now() ? "none" : "flex",
      transition: "all 0.3s ease-in-out", // Smooth transitions for resizing
    },
    witnessInfo: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      fontSize: windowSize.width >= 600 ? 16 : 14,
      color: "#333",
    },
    button: {
      marginTop: 10,
      backgroundColor: "#007BFF",
      color: "#fff",
      borderRadius: 8,
      padding: "5px 10px",
      fontWeight: "bold",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      transition: "background-color 0.3s ease, box-shadow 0.3s ease",
      cursor: "pointer",
    },
    divider: {
      width: "98%",
      opacity: 0.5,
      margin: "10px 0",
    },
  };

  return (
    <div style={styles.container}>
      {windowSize.width >= 600 ? (
        <>
          <div style={{ width: "49%", height: "100%" }}>
            {witnessInfo ? (
              <>
                {data.time && data.duration && data.time < Date.now() ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p
                      style={{
                        fontSize: 14,
                        opacity: 0.5,
                        marginRight: 5,
                      }}
                    >
                      Session ends in
                    </p>
                    <Countdown
                      deadline={data.time + data.duration}
                      styling={{ fontSize: 14, opacity: 0.5 }}
                    />
                  </div>
                ) : (
                  <p style={{ opacity: 0.5, fontSize: 14 }}>
                    Opens {new Date(data.time).toLocaleString()}
                  </p>
                )}
                <hr style={styles.divider}></hr>
                {witnessInfo.fName &&
                witnessInfo.lName &&
                witnessInfo.occupation ? (
                  <>
                    <h4>
                      {witnessInfo.fName} {witnessInfo.lName.charAt(0)}
                    </h4>
                    <p style={{ marginTop: 0 }}>
                      <span style={{ fontWeight: 600 }}>Occupation:</span>{" "}
                      {witnessInfo.occupation}
                    </p>
                  </>
                ) : null}
              </>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "49%",
              height: "100%",
              backgroundColor: "#e3f2fd",
            }}
          >
            {QueueCtxt?.queues?.[data?.roomName] ? (
              <>
                <p style={{ color: "green" }}>ACTIVE</p>
                {Array.isArray(QueueCtxt.queues[data.roomName]?.waiting) ? (
                  <p style={{ fontSize: 14, fontWeight: "bold" }}>
                    Queue: {QueueCtxt.queues[data.roomName].waiting.length}
                  </p>
                ) : null}
              </>
            ) : (
              <p style={{ color: "grey" }}>INACTIVE</p>
            )}
            {queueing ? (
              <>
                {invited ? (
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ marginTop: "5px" }}
                    onClick={() => {
                      QueueCtxt.acceptInvite();
                      navigate(
                        `/live-call/${data.roomName}/${GlobalCtxt.nationalId}/National/witnessing`
                      );
                    }}
                    style={styles.button}
                  >
                    JOIN SESSION!
                  </Button>
                ) : (
                  <>
                    <p style={{ fontSize: 14, textAlign: "center" }}>
                      You are #{queuePosition} in the queue
                    </p>
                    <hr style={styles.divider}></hr>
                    <Button
                      color="error"
                      variant="outlined"
                      size="small"
                      sx={{ margin: "5px 0px 10px 0px" }}
                      startIcon={<LogoutIcon />}
                      onClick={() => {
                        QueueCtxt.leaveQueue();
                      }}
                    >
                      LEAVE
                    </Button>
                  </>
                )}
              </>
            ) : (
              <>
                {QueueCtxt?.queues?.[data?.roomName]?.open ? (
                  <>
                    <p style={{ textAlign: "center", fontSize: 14 }}>
                      Enter your{" "}
                      <span style={{ fontWeight: 600 }}>Declaration ID</span> to
                      join the queue for this session. Your ID can be found in
                      the email that you received when you created your
                      Declaration.
                    </p>
                    <TextField
                      value={declarationId}
                      onChange={(e) => {
                        setDeclarationId(e.target.value);
                      }}
                    />
                    <Button
                      sx={{ margin: "10px 0px 10px 0px", cursor: "pointer" }}
                      variant="contained"
                      size="small"
                      color="info"
                      disabled={declarationId.length === 8 ? false : true}
                      onClick={handleJoinQueue}
                    >
                      JOIN QUEUE
                    </Button>
                  </>
                ) : (
                  <p style={{ textAlign: "center", fontSize: 14 }}>
                    Queue currently unavailable.
                  </p>
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <p style={{ opacity: 0.75, fontSize: 14 }}>
          Remote witnessing only available on larger devices.
        </p>
      )}
    </div>
  );
}
