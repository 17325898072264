import React, { useState, useContext } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import DynamicFrame from "../components/common-components/DynamicFrame";
import GenericSidebar from "../components/common-components/GenericSidebar";
import GenericMain from "../components/common-components/GenericMain";
import DynamicMap from "../components/common-components/DynamicMap";
import { useWindowSize } from "../utils/Hooks";

import "../App.css";

import { GlobalContext } from "../App";

export default function CurrentCampaign() {
  ////  INITS
  const GlobalCtxt = useContext(GlobalContext);
  const windowSize = useWindowSize();

  return (
    <ScreenContainer styling={{ background: GlobalCtxt.darkBg }}>
      <Navbar />
      <DynamicFrame windowSize={windowSize} styling={{ maxHeight: "80vh" }}>
        {windowSize.width > 768 ? (
          <GenericSidebar windowSize={windowSize}>
            <div
              className="dark-container"
              style={{ height: "100%", width: "100%" }}
            >
              <p style={{ textAlign: "center" }}>
                Each marker on the map represents a delivery zone set by one of
                our legendary volunteers!
              </p>
              <hr style={{ width: "100%" }}></hr>
            </div>
          </GenericSidebar>
        ) : null}

        <GenericMain styling={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
          <DynamicMap
            displayOnly={true}
            containerStyling={{ height: "100%" }}
            mapStyling={{ maxHeight: "100vh" }}
            channelId={GlobalCtxt.nationalId}
            fixedClass="we-say-no"
          />
        </GenericMain>
      </DynamicFrame>
    </ScreenContainer>
  );
}
