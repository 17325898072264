import React, { useState } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import { initiateReset } from "../functions/MailingFunctions";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import * as EmailValidator from "email-validator";

export default function PasswordReboot() {
  ////  INITS

  ////  STATES
  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);

  ////  FUNCTIONS
  const handleReset = async () => {
    try {
      const trimmedEmail = email.trim();
      const emailValid = EmailValidator.validate(trimmedEmail);
      if (!emailValid) {
        alert("Please enter a valid email address.");
        return;
      }
      const res = await initiateReset(trimmedEmail);
      if (res) {
        setSent(true);
      } else {
        alert("Error locating account!");
      }
    } catch (err) {
      console.log(err);
      alert("Error locating account!");
    }
  };
  return (
    <ScreenContainer>
      <Navbar />
      <div
        style={{
          height: "80vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: 600,
            padding: 10,
            boxSizing: "border-box",
            backgroundColor: "whitesmoke",
            border: "1px solid black",
          }}
        >
          {sent ? (
            <h4 style={{ textAlign: "center" }}>
              A reset link has been sent to you via email.
            </h4>
          ) : (
            <>
              <h4 style={{ textAlign: "center" }}>
                Don't worry, we'll send you a reset clink.
              </h4>
              <TextField
                fullWidth={true}
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <br></br>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button onClick={handleReset}>RESET</Button>
              </div>
            </>
          )}
        </div>
      </div>
    </ScreenContainer>
  );
}
