import React, {
  useContext,
  useRef,
  useState,
  useEffect,
  createContext,
} from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import AffidavitDiv1 from "../components/affidavit-components/AffidavitDiv1";
import AffidavitDiv2 from "../components/affidavit-components/AffidavitDiv2";
import AffidavitDiv3 from "../components/affidavit-components/AffidavitDiv3";
import AffidavitDiv4 from "../components/affidavit-components/AffidavitDiv4";
import AffidavitDiv5 from "../components/affidavit-components/AffidavitDiv5";
import GenericModal from "../components/common-components/GenericModal";
import { useWindowSize } from "../utils/Hooks";
import {
  getAffidavitDraft,
  parseHtmlToListItems,
  submitAffidavit,
  createAffidavitDoc,
  getTestimony,
} from "../functions/AffidavitFunctions";
import {
  prepareImage,
  replaceAndSanitize,
} from "../functions/GeneralFunctions";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import * as EmailValidator from "email-validator";
import { useParams } from "react-router-dom";

import { GlobalContext } from "../App";

import "animate.css";

export const AffidavitContext = createContext(null);

export default function AffidavitFlow() {
  ////  INITS
  const div0Ref = useRef();
  const div1Ref = useRef();
  const div2Ref = useRef();
  const div3Ref = useRef();
  const div4Ref = useRef();
  const windowSize = useWindowSize();
  const GlobalCtxt = useContext(GlobalContext);
  const params = useParams();

  ////  STYLES
  const divStyle = {
    height: `calc(${windowSize.height}px - env(safe-area-inset-bottom))`,
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "12vh",
    position: "relative",
  };

  ////  STATES
  const [currentDiv, setCurrentDiv] = useState(0);
  const [data, setData] = useState({});
  const [draftState, setDraftState] = useState(null);
  const [draftIndex, setDraftIndex] = useState(0);
  const [listItems, setListItems] = useState([]);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [mailingConsent, setMailingConsent] = useState(true);
  const [docoConsent, setDocoConsent] = useState(false);
  const [useAddress, setUseAddress] = useState(true);
  const [email, setEmail] = useState("");
  const [aiTemp, setAiTemp] = useState(0.25);

  ////  FUNCTIONS
  const scrollToRef = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  };

  const updateCurrentDiv = (index) => {
    try {
      if (index === 99) {
        setShowEmailModal(true);
      } else {
        setCurrentDiv(index);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateData = (input) => {
    try {
      return new Promise((resolve, reject) => {
        setData({ ...data, ...input });
        resolve();
      });
    } catch (err) {
      console.log(err);
    }
  };

  const createDraft = async () => {
    try {
      let doc;
      let temp = aiTemp;
      let liArr = [];
      for (var i = 0; i < 2; i++) {
        doc = await getAffidavitDraft({
          prompt: data.textInput,
          temp,
        });
        if (Array.isArray(doc?.data?.choices) && doc.data.choices.length > 0) {
          if (doc.data.choices[0]?.message?.content) {
            let parsed = parseHtmlToListItems(
              doc.data.choices[0].message.content
            );
            if (!parsed) {
              if (i === 0) {
                continue;
              } else {
                setDraftState("error");
                return;
              }
            }
            let genericLine = addInitialLine();
            liArr[i] = [...genericLine, ...parsed];
            temp += 0.5;
          } else {
            if (i === 0) {
              continue;
            } else {
              setDraftState("error");
              return;
            }
          }
        } else {
          if (i === 0) {
            continue;
          } else {
            setDraftState("error");
            return;
          }
        }
      }
      setListItems((prev) => [...prev, ...liArr]);
      setAiTemp((prev) => prev + 0.25);
      setTimeout(() => {
        setDraftState("ready");
      }, 500);
    } catch (err) {
      console.log(err);
      setTimeout(() => {
        setDraftState("error");
      }, 1000);
    }
  };

  const addInitialLine = () => {
    try {
      let line =
        "I am providing this statement to document how I was personally affected by the COVID-19 public health response in Australia.";
      return [line];
    } catch (err) {
      console.log(err);
    }
  };

  const addListItem = () => {
    try {
      const newArr = [...listItems];
      newArr[draftIndex].push("");
      setListItems(newArr);
    } catch (err) {
      console.log(err);
    }
  };

  const removeListItem = (index) => {
    try {
      const newArr = [...listItems];
      newArr[draftIndex].splice(index, 1);
      setListItems(newArr);
    } catch (err) {
      console.log(err);
    }
  };

  const editListItem = (text, index) => {
    try {
      const newArr = [...listItems];
      newArr[draftIndex][index] = text;
      setListItems(newArr);
    } catch (err) {
      console.log(err);
    }
  };

  const updateDraftIndex = (index) => {
    try {
      setDraftIndex(index);
    } catch (err) {
      console.log(err);
    }
  };

  const sendAffidavit = async () => {
    try {
      if (!email) {
        alert("Please enter a valid email address.");
        return;
      }
      const trimmedEmail = email.trim();
      const emailValid = EmailValidator.validate(trimmedEmail);
      if (!emailValid) {
        alert("Please enter a valid email address.");
        return;
      }
      setShowEmailModal(false);
      setDraftState("submitting");
      const selectedDraft = listItems[draftIndex];
      let finalStr = "";
      for (var el of selectedDraft) {
        let taggedItem = `<li>${el}</li>`;
        finalStr += taggedItem;
      }
      finalStr = `<ol>${finalStr}</ol>`;
      let attachmentURLs = null;
      try {
        if (data?.attachments && Array.isArray(data.attachments)) {
          attachmentURLs = [];
          for (var foo of data.attachments) {
            let url = await prepareImage(foo, data.fName.slice(0, 4));
            attachmentURLs.push(url.path);
          }
        }
      } catch (err) {
        console.log(err);
        alert(
          "Error uploading one or more attachments! Some files may not be included in your document."
        );
      }
      const res = await createAffidavitDoc({
        fName: data.fName,
        lName: data.lName,
        employment: data.employment,
        address: data.address,
        phone: data.phone,
        affPoints: finalStr,
        urls: attachmentURLs ? attachmentURLs : [],
        email: trimmedEmail,
        mailingConsent,
        docoConsent,
        useAddress,
        temp: aiTemp,
      });
      await submitAffidavit({
        fName: data.fName,
        lName: data.lName,
        employment: data.employment,
        address: data.address,
        phone: data.phone || "",
        affPoints: finalStr,
        urls: attachmentURLs || [],
        email: trimmedEmail,
        useAddress,
        code: res.data.code,
        timestamp: res.data.timestamp,
      });
      setDraftState("success");
    } catch (err) {
      console.log(err);
      alert("Error submitting affidavit! Please wait a moment and try again.");
      setDraftState("ready");
    }
  };

  const recreateDraft = () => {
    try {
      if (aiTemp === 0.5) {
        setDraftState("loading");
      } else {
        alert("Drafts can only be recreated once.");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchTestimony = async () => {
    try {
      const doc = await getTestimony(params.testimonyId);
      if (doc?.data?.text) {
        updateData({ textInput: replaceAndSanitize(doc.data.text) });
      }
    } catch (err) {
      console.log(err);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    // Scroll to the correct div based on currentDiv
    if (currentDiv === 0) scrollToRef(div0Ref);
    if (currentDiv === 1) scrollToRef(div1Ref);
    if (currentDiv === 2) scrollToRef(div2Ref);
    if (currentDiv === 3) scrollToRef(div3Ref);
    if (currentDiv === 4) {
      scrollToRef(div4Ref);
      setTimeout(() => {
        setDraftState("loading");
      }, 1000);
    }
  }, [currentDiv]);

  useEffect(() => {
    if (draftState) {
      if (draftState === "loading") {
        createDraft();
      }
    }
  }, [draftState]);

  useEffect(() => {
    if (params?.testimonyId) {
      fetchTestimony();
    }
  }, []);

  useEffect(() => {
    if (data) {
      console.log(data);
    }
  }, [data]);

  return (
    <AffidavitContext.Provider
      value={{
        data,
        updateData,
        listItems,
        addListItem,
        removeListItem,
        editListItem,
      }}
    >
      <GenericModal
        open={showEmailModal}
        handleClose={() => {
          setShowEmailModal(false);
        }}
      >
        <h3 style={{ textAlign: "center" }}>
          Where should we send your free Declaration?
        </h3>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <TextField
            fullWidth={true}
            sx={{ maxWidth: "300px" }}
            placeholder="Your email address ..."
            inputProps={{ style: { textAlign: "center" } }}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <p style={{ textAlign: "center", maxWidth: 370, fontSize: 14 }}>
            You will receive your statutory declaration in PDF format, along
            with instructions on what to do next.
          </p>
        </div>
        {!mailingConsent ? (
          <h4 style={{ textAlign: "center", color: "red" }}>
            If you do not consent to email updates, we will be unable to notify
            you of follow-up activities.
          </h4>
        ) : null}
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div
            style={{
              width: "auto",
              backgroundColor: "rgba(0,0,0,0.8)",
              padding: "5px 10px",
              boxSizing: "border-box",
              borderRadius: 4,
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={useAddress}
                onChange={(e) => {
                  setUseAddress(e.target.checked);
                }}
              />
              <p style={{ fontSize: 14, color: "lightgrey" }}>
                Use the above email address as a contact detail on my
                Declaration.
              </p>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={mailingConsent}
                onChange={(e) => {
                  setMailingConsent(e.target.checked);
                }}
              />
              <p style={{ fontSize: 14, color: "lightgrey" }}>
                I consent to receiving occasional email updates from the
                organisers of this campaign.
              </p>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={docoConsent}
                onChange={(e) => {
                  setDocoConsent(e.target.checked);
                }}
              />
              <p style={{ fontSize: 14, color: "lightgrey" }}>
                I consent to being contacted in relation to an upcoming
                documentary.
              </p>
            </div>
          </div>
        </div>
        <br></br>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Button variant="contained" color="success" onClick={sendAffidavit}>
            SEND ME MY DECLARATION!
          </Button>
        </div>
      </GenericModal>
      <ScreenContainer
        styling={{
          paddingTop: 0,
          background:
            "linear-gradient(to bottom, #C5D2C0, #B0C2B4, #A2B5BA, #90A7B0)",
          overflowY: "auto",
        }}
      >
        <Navbar />
        <div ref={div0Ref} style={divStyle}>
          <AffidavitDiv1
            windowSize={windowSize}
            GlobalCtxt={GlobalCtxt}
            updateCurrentDiv={updateCurrentDiv}
          />
        </div>
        <div ref={div1Ref} style={divStyle}>
          <AffidavitDiv2
            windowSize={windowSize}
            updateCurrentDiv={updateCurrentDiv}
          />
        </div>
        <div ref={div2Ref} style={divStyle}>
          <AffidavitDiv3
            windowSize={windowSize}
            updateCurrentDiv={updateCurrentDiv}
          />
        </div>
        <div ref={div3Ref} style={divStyle}>
          <AffidavitDiv4
            windowSize={windowSize}
            updateCurrentDiv={updateCurrentDiv}
          />
        </div>
        <div ref={div4Ref} style={divStyle}>
          <AffidavitDiv5
            windowSize={windowSize}
            draftState={draftState}
            draftIndex={draftIndex}
            updateDraftIndex={updateDraftIndex}
            updateCurrentDiv={updateCurrentDiv}
            recreateDraft={recreateDraft}
          />
        </div>
      </ScreenContainer>
    </AffidavitContext.Provider>
  );
}
