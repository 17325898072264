import React, { useState, useEffect, useContext } from "react";

import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";

import Countdown from "./Countdown";
import { useWindowSize } from "../../utils/Hooks";
import { GlobalContext } from "../../App";
import { castVote } from "../../functions/PollFunctions";

const subHeaderText = { margin: "0px 4px 0px 0px", color: "lightgrey" };

export default function PollComponent({ data }) {
  ////  INITS
  const windowSize = useWindowSize();
  const navigate = useNavigate();
  const GlobalCtxt = useContext(GlobalContext);

  ////  STATES
  const [selectedId, setSelectedId] = useState("");
  const [selectedText, setSelectedText] = useState("");
  const [pollStatus, setPollStatus] = useState(null);
  const [canVote, setCanVote] = useState(false);
  const [showResults, setShowResults] = useState(false);

  ////  FUNCTIONS
  const handleRadioChange = (e) => {
    const [id, text] = e.target.value.split("|");

    setSelectedId(id);
    setSelectedText(text);
  };

  const checkCanVote = (threshold) => {
    try {
      for (var el of data.meta_votes) {
        if (el.voterId === localStorage.getItem("_id")) {
          setCanVote("voted");
          return;
        }
      }

      if (!data.premium) {
        setCanVote(true);
      } else {
        if (
          GlobalCtxt.userInfo?.tier &&
          GlobalCtxt.userInfo.tier >= threshold
        ) {
          if (data.open_voting) {
            setCanVote(true);
          } else {
            if (
              data.channelName === localStorage.getItem("electorate") ||
              data.channelName === localStorage.getItem("affiliation")
            ) {
              setCanVote(true);
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const submitVote = async () => {
    try {
      if (data.closes < Date.now()) {
        alert("Poll has closed.");
      } else {
        setCanVote("voted");
        await castVote({
          pollId: data._id,
          optionId: selectedId,
          for_option: selectedText,
        });
      }
    } catch (err) {
      console.log(err);
      alert(
        "Error processing your vote! Please refresh the page and try again. If this issue persists, please let us know via the Troubleshooting Channel."
      );
      setCanVote(true);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (data?.closes) {
      if (data.closes === "closed") {
        setPollStatus("closed");
      } else if (typeof data.closes === "number" && data.closes > Date.now()) {
        setPollStatus("active");
      } else {
        setPollStatus("expired");
      }
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      if (data.type === "election") {
        checkCanVote(1);
      } else if (data.type === "proposal") {
        checkCanVote(2);
      }
    }
  }, []);

  return (
    <>
      {pollStatus ? (
        <div
          style={{
            width: "98%",
            border: "2px solid black",
            backgroundColor: "whitesmoke",
            marginBottom: 10,
          }}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "#FA6800",
              padding: 10,
              boxSizing: "border-box",
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
              flexDirection: windowSize.width > 768 ? "column" : "row",
            }}
          >
            <h3 style={{ color: "white", margin: 4, textAlign: "center" }}>
              POLL
            </h3>
            <div style={{ display: "flex", alignItems: "center" }}>
              {pollStatus === "closed" ? (
                <p style={subHeaderText}>RESOLVED</p>
              ) : pollStatus === "expired" ? (
                <p style={subHeaderText}>VOTING CLOSED</p>
              ) : pollStatus === "active" ? (
                <>
                  <p style={subHeaderText}>Closes: </p>
                  <Countdown
                    deadline={data.closes}
                    styling={{ color: "white", margin: 4 }}
                  />
                </>
              ) : null}
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 5,
              boxSizing: "border-box",
            }}
          >
            {pollStatus === "active" ? (
              <Button
                onClick={() => {
                  navigate(`/poll/${data._id}`);
                }}
              >
                VIEW CANDIDATES
              </Button>
            ) : null}

            <p style={{ textAlign: "center" }}>{data.question}</p>
            {pollStatus === "closed" ? (
              <>
                {data?.options.map((el, i) => {
                  return (
                    <div
                      style={{
                        width: "100%",
                        backgroundColor:
                          data.winner && data.winner === el?.id
                            ? "gold"
                            : i % 2 === 0
                            ? "lightgrey"
                            : "whitesmoke",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "2px 10px",
                        boxSizing: "border-box",
                      }}
                      key={`${i}-${el?.id}`}
                    >
                      <p style={{ fontSize: 14 }}>{el?.text || el?.username}</p>
                      {data.meta_votes.length > 0 ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            width: windowSize.width > 768 ? "66%" : "50%",
                          }}
                        >
                          <p
                            style={{
                              margin: 4,
                              fontSize: 12,
                              textAlign: "center",
                            }}
                          >
                            {el.votes === 1 ? `1 vote` : `${el.votes} votes`}
                          </p>
                          <LinearProgress
                            variant="determinate"
                            value={(el.votes / data.meta_votes.length) * 100}
                            sx={{
                              height: 10,
                              border: "1px solid black",
                              borderRadius: 4,
                              width: "100%",
                            }}
                          />
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </>
            ) : (
              <RadioGroup
                defaultValue=""
                name="radio-buttons-group"
                onChange={handleRadioChange}
              >
                {data?.options.map((el, i) => (
                  <FormControlLabel
                    value={`${el.id}|${el.text}`}
                    control={<Radio />}
                    label={el.text}
                    key={el.createdAt}
                  />
                ))}
              </RadioGroup>
            )}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 10,
              boxSizing: "border-box",
              backgroundColor: "lightgrey",
            }}
          >
            {pollStatus === "closed" ? (
              <>
                <p style={{ textAlign: "center", fontWeight: 600 }}>
                  {data.summary}
                </p>
                {Array.isArray(data.meta_votes) &&
                data.meta_votes.length > 0 ? (
                  <>
                    <Button
                      onClick={() => {
                        setShowResults((prev) => !prev);
                      }}
                    >
                      {showResults ? `HIDE RESULTS` : `SHOW RESULTS`}
                    </Button>
                    {showResults ? (
                      <div
                        style={{
                          width: "100%",
                          overflowX: "auto",
                          maxHeight: "50vh",
                          overflowY: "auto",
                        }}
                      >
                        <table
                          style={{
                            width: "100%",
                            borderCollapse: "collapse",
                            fontSize: 12,
                          }}
                        >
                          <thead
                            style={{
                              backgroundColor: "rgba(0,0,0,0.65)",
                              color: "white",
                            }}
                          >
                            <tr>
                              <th
                                style={{
                                  border: "1px solid black",
                                  padding: "5px",
                                }}
                              >
                                Candidate
                              </th>
                              <th
                                style={{
                                  border: "1px solid black",
                                  padding: "5px",
                                }}
                              >
                                Time cast
                              </th>
                              <th
                                style={{
                                  border: "1px solid black",
                                  padding: "5px",
                                }}
                              >
                                Reference
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.meta_votes.map((el, i) => (
                              <tr
                                key={i}
                                style={{
                                  backgroundColor:
                                    el.voterId === localStorage.getItem("_id")
                                      ? "lightgreen"
                                      : i % 2 === 0
                                      ? "rgba(255,255,255,0.05)"
                                      : "rgba(255,255,255,0.15)",
                                }}
                              >
                                <td
                                  style={{
                                    border: "1px solid black",
                                    padding: "5px",
                                    textAlign: "center",
                                  }}
                                >
                                  {el.for}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    padding: "5px",
                                    textAlign: "center",
                                  }}
                                >
                                  {new Date(el.createdAt).toLocaleString()}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    padding: "5px",
                                    textAlign: "center",
                                  }}
                                >
                                  {el._id}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : null}
                  </>
                ) : null}
              </>
            ) : pollStatus === "expired" ? (
              <>
                <p style={{ textAlign: "center", opacity: 0.65 }}>
                  Results will be published shortly.
                </p>
                {GlobalCtxt.userInfo?.admin ? (
                  <Button
                    onClick={() => {
                      navigate(`/resolve-poll/${data._id}`);
                    }}
                  >
                    RESOLVE
                  </Button>
                ) : null}
              </>
            ) : pollStatus === "active" ? (
              <>
                {canVote === "voted" ? (
                  <p style={{ textAlign: "center", opacity: 0.65 }}>
                    Your vote has been counted! Check your Profile page for a
                    private receipt.
                  </p>
                ) : canVote === true ? (
                  <Button
                    variant="contained"
                    size="small"
                    disabled={selectedId ? false : true}
                    onClick={submitVote}
                  >
                    CAST VOTE
                  </Button>
                ) : (
                  <>
                    {data.open_voting ? (
                      <>
                        {data.type === "election" ? (
                          <p>
                            You must be a paying subscriber to participate in
                            this Poll.
                          </p>
                        ) : data.type === "proposal" ? (
                          <p>
                            You must be subscribed to the Community Investor
                            tier or higher to participate in this Poll.
                          </p>
                        ) : (
                          <p>
                            You must be a paying subscriber to participate in
                            this poll.
                          </p>
                        )}
                        <Button onClick={() => navigate("/upgrade")}>
                          UPGRADE
                        </Button>
                      </>
                    ) : (
                      <>
                        {data.type === "election" ? (
                          <p>
                            You must be a paying subscriber of the{" "}
                            {data.channelName}
                            Channel to participate in this Poll.
                          </p>
                        ) : data.type === "proposal" ? (
                          <p>
                            You must be a Community Investor in the{" "}
                            {data.channelName}
                            Channel to participate in this Poll.
                          </p>
                        ) : (
                          <p>
                            You must be a paying subscriber to participate in
                            this poll.
                          </p>
                        )}
                        <Button onClick={() => navigate("/upgrade")}>
                          UPGRADE
                        </Button>
                      </>
                    )}
                  </>
                )}
              </>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
}
