//  REVISIT: clean up editing, downloads, general formatting, published sidebar

import React, { useState, useEffect, useContext, useRef } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import DynamicFrame from "../components/common-components/DynamicFrame";
import GenericSidebar from "../components/common-components/GenericSidebar";
import GenericMain from "../components/common-components/GenericMain";
import GenericModal from "../components/common-components/GenericModal";
import GenericTextField from "../components/common-components/GenericTextField";
import RecordingPreview from "../components/common-components/RecordingPreview";
import AssetTile from "../components/common-components/AssetTile";
import { useWindowSize } from "../utils/Hooks";
import {
  getStreamKey,
  listStreams,
  deleteAsset,
  createAsset,
  checkIsPublished,
} from "../functions/StreamingFunctions";
import {
  unixToStringSeconds,
  formatDuration,
  validateTitle,
} from "../functions/GeneralFunctions";

import MuxPlayer from "@mux/mux-player-react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import VideocamIcon from "@mui/icons-material/Videocam";
import ErrorIcon from "@mui/icons-material/Error";
import DOMPurify from "dompurify";

import "../App.css";

import { GlobalContext } from "../App";

export default function Studio() {
  ////  INITS
  const windowSize = useWindowSize();
  const GlobalCtxt = useContext(GlobalContext);
  const pStyles = {
    margin: "10px 0px",
    fontSize: 14,
  };
  const editorStyles = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "45%",
    opacity: 0.5,
    cursor: "pointer",
  };
  const playerRef = useRef();

  ////  STATES
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [recentRecordings, setRecentRecordings] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [editing, setEditing] = useState(false);
  const [noContent, setNoContent] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [assetTitle, setAssetTitle] = useState("");
  const [publishedVersion, setPublishedVersion] = useState(null);

  ////  FUNCTIONS
  const fetchRecordings = async () => {
    try {
      const key = await getStreamKey();
      if (!key) {
        setNoContent(true);
        return;
      }
      const stream = await listStreams(key);
      if (
        Array.isArray(stream?.data?.data) &&
        stream.data.data[0].recent_asset_ids
      ) {
        setRecentRecordings(stream.data.data[0].recent_asset_ids);
      } else {
        setNoContent(true);
      }
    } catch (err) {
      console.log(err);
      alert("Error fetching recordings! Please refresh the page to try again.");
      setNoContent(true);
    }
  };

  const updateTitle = (e) => {
    setAssetTitle(e);
  };

  const updateSelectedAsset = (asset) => {
    console.log(asset);
    setSelectedAsset(asset);
  };

  const confirmDelete = async () => {
    try {
      setBtnDisabled(true);
      await deleteAsset(selectedAsset.id);
      window.location.reload();
    } catch (err) {
      console.log(err);
      alert("Unexpected error while deleting asset. Please try again!");
      setBtnDisabled(false);
    }
  };

  const handleTimeUpdate = () => {
    try {
      console.log(editing);
      if (editing === "start") {
        setStartTime(playerRef.current.currentTime);
      } else if (editing === "end") {
        setEndTime(playerRef.current.currentTime);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const quickPublish = async () => {
    try {
      // REVISIT: ensure that channelName is dynamic, selected from secretaryOf array */
      if (!assetTitle) {
        alert("Video title is required.");
        return;
      }
      const titleValid = validateTitle(assetTitle);
      if (!titleValid) {
        alert("Invalid video title.");
        return;
      }
      if (selectedAsset?.playback_ids[0].id) {
        setBtnDisabled(true);
        await createAsset({
          aspect_ratio: selectedAsset.aspect_ratio,
          duration: selectedAsset.duration,
          originalId: selectedAsset.id,
          live_stream_id: selectedAsset.live_stream_id,
          playbackId: selectedAsset.playback_ids[0].id,
          title: DOMPurify.sanitize(assetTitle),
          channelName: "National",
        });
        alert("Upload successful! Refreshing ...");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        alert("Error publishing video! Please refresh the page to try again.");
      }
    } catch (err) {
      console.log(err);
      alert("Error publishing video! Please refresh the page to try again.");
      setBtnDisabled(false);
    }
  };

  const checkForExisting = async () => {
    try {
      const published = await checkIsPublished(selectedAsset.id);
      setPublishedVersion(published.data);
    } catch (err) {
      console.log(err);
      setPublishedVersion(null);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    fetchRecordings();
  }, []);

  useEffect(() => {
    const player = playerRef.current;

    if (player) {
      player.addEventListener("timeupdate", handleTimeUpdate);

      return () => {
        player.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }
  }, [editing]);

  useEffect(() => {
    console.log(editing);
  }, [editing]);

  useEffect(() => {
    if (selectedAsset) {
      setEditing(false);
      setStartTime(0);
      setEndTime(0);
      checkForExisting();
    }
  }, [selectedAsset]);

  return (
    <ScreenContainer styling={{ background: GlobalCtxt.darkBg }}>
      <Navbar />
      {deleting ? (
        <GenericModal open={deleting} handleClose={() => setDeleting(false)}>
          <h4 style={{ textAlign: "center" }}>
            Are you sure you want to delete this recording?
          </h4>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Button
              color="warning"
              variant="contained"
              onClick={confirmDelete}
              disabled={btnDisabled}
            >
              CONFIRM
            </Button>
          </div>
        </GenericModal>
      ) : null}
      <DynamicFrame windowSize={windowSize} styling={{ height: "85vh" }}>
        <GenericSidebar
          windowSize={windowSize}
          styling={{
            width: windowSize.width * 0.2,
          }}
        >
          <h4 style={{ color: "white", marginBottom: "15px" }}>
            Recent recordings:
          </h4>
          {Array.isArray(recentRecordings) && recentRecordings.length > 0
            ? recentRecordings.map((el, i) => {
                return (
                  <RecordingPreview
                    data={el}
                    handleClick={updateSelectedAsset}
                    selectedAsset={selectedAsset}
                    key={el}
                  />
                );
              })
            : null}
        </GenericSidebar>
        <GenericMain styling={{ backgroundColor: "rgba(0,0,0,0.8)" }}>
          {selectedAsset ? (
            <>
              <MuxPlayer
                playbackId={selectedAsset.playback_ids[0].id}
                metadata={{
                  video_id: "video-id-54321",
                  video_title: "Test video title",
                  viewer_user_id: "user-id-007",
                }}
                ref={playerRef}
              />
              {editing ? (
                <>
                  <br></br>
                  <p style={{ textAlign: "center", color: "white" }}>
                    Start playing the video ... the trim times will update
                    automatically!
                  </p>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      padding: 20,
                      boxSizing: "border-box",
                      backgroundColor: "rgba(255,255,255,0.5)",
                      borderRadius: 4,
                    }}
                  >
                    <div
                      style={{
                        ...editorStyles,
                        ...(editing === "start"
                          ? { borderBottom: "1px solid black", opacity: 1 }
                          : null),
                      }}
                      onClick={() => setEditing("start")}
                    >
                      <p style={{ margin: 0, fontWeight: 600 }}>START</p>
                      <h2>{formatDuration(startTime)}</h2>
                    </div>
                    <div
                      style={{
                        ...editorStyles,
                        ...(editing === "end"
                          ? { borderBottom: "1px solid black", opacity: 1 }
                          : null),
                      }}
                      onClick={() => setEditing("end")}
                    >
                      <p style={{ margin: 0, fontWeight: 600 }}>END</p>
                      <h2>{formatDuration(endTime)}</h2>
                    </div>
                  </div>
                  {startTime > endTime ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "orange",
                      }}
                    >
                      <ErrorIcon sx={{ marginRight: "10px" }} />
                      <p>Start time must be earlier than end time.</p>
                    </div>
                  ) : null}
                </>
              ) : null}
            </>
          ) : null}
        </GenericMain>
        <GenericSidebar windowSize={windowSize}>
          {selectedAsset ? (
            <div
              className="dark-container"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    padding: "0px 20px",
                    borderRadius: 4,
                    backgroundColor: publishedVersion ? "green" : "grey",
                    border: "1px solid white",
                  }}
                >
                  <p style={{ fontSize: 14, margin: 10 }}>
                    {publishedVersion ? `PUBLISHED` : `DRAFT`}
                  </p>
                </div>
                {publishedVersion ? null : (
                  <IconButton onClick={() => setDeleting(true)}>
                    <DeleteIcon sx={{ color: "grey" }} />
                  </IconButton>
                )}
              </div>
              {publishedVersion ? (
                <>
                  <br></br>
                  <p style={{ textAlign: "center" }}>YOUR VIDEO:</p>
                  <AssetTile data={publishedVersion} />
                </>
              ) : (
                <>
                  <p style={{ marginBottom: 10, fontSize: 14 }}>
                    <span style={{ fontWeight: 600 }}>From:</span>{" "}
                    {selectedAsset.passthrough}
                  </p>
                  <p style={pStyles}>
                    <span style={{ fontWeight: 600 }}>Created:</span>{" "}
                    {unixToStringSeconds(selectedAsset.created_at)}
                  </p>
                  <p style={pStyles}>
                    <span style={{ fontWeight: 600 }}>Duration:</span>{" "}
                    {formatDuration(selectedAsset.duration)}
                  </p>
                  <hr style={{ width: "100%" }} />
                  {editing ? (
                    <>
                      <GenericTextField
                        charLimit={100}
                        buttonVariant="hidden"
                        divStyling={{ backgroundColor: "whitesmoke" }}
                        placeholder="Title"
                        handleChange={updateTitle}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 10,
                        }}
                      >
                        <Button variant="contained" color="success">
                          PUBLISH TRIMMED VIDEO
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <GenericTextField
                        charLimit={100}
                        buttonVariant="hidden"
                        divStyling={{ backgroundColor: "whitesmoke" }}
                        placeholder="Title"
                        handleChange={updateTitle}
                      />
                      <p style={{ fontSize: 14, textAlign: "center" }}>
                        Click the button below to publish your video immediately
                        to PhaseChamber!
                      </p>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          variant="contained"
                          color="success"
                          onClick={quickPublish}
                        >
                          QUICK PUBLISH!
                        </Button>
                      </div>
                    </>
                  )}

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      flexGrow: 1,
                      justifyContent: "center",
                      marginTop: 36,
                      marginBottom: 12,
                      //backgroundColor: GlobalCtxt.colourScheme.primaryGreen,
                      backgroundColor: "rgba(0,0,0,0.3)",
                      border: "0.5px solid grey",
                      borderRadius: 4,
                    }}
                  >
                    {editing ? (
                      <Button
                        color="warning"
                        variant="contained"
                        onClick={() => setEditing(false)}
                      >
                        CANCEL EDIT
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="info"
                        startIcon={<ContentCutIcon />}
                        onClick={() => setEditing("start")}
                      >
                        EDIT
                      </Button>
                    )}

                    <br></br>
                    <Button
                      variant="contained"
                      color="info"
                      startIcon={<AudiotrackIcon />}
                    >
                      DOWNLOAD AUDIO
                    </Button>
                    <br></br>
                    <Button
                      variant="contained"
                      color="info"
                      startIcon={<VideocamIcon />}
                    >
                      DOWNLOAD VIDEO
                    </Button>
                  </div>
                </>
              )}
            </div>
          ) : null}
        </GenericSidebar>
      </DynamicFrame>
    </ScreenContainer>
  );
}
