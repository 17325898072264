import React, { useContext, useState, useEffect } from "react";

import ClickableUsername from "../common-components/ClickableUsername";
import InteractionsBar from "../common-components/InteractionsBar";
import {
  timeSince,
  replaceAndSanitize,
  truncateString,
} from "../../functions/GeneralFunctions";
import { hideContent } from "../../functions/ContentFunctions";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HideSourceIcon from "@mui/icons-material/HideSource";

import { GlobalContext } from "../../App";

export default function PostPreview({ data, handleClick }) {
  ////  INITS
  const GlobalCtxt = useContext(GlobalContext);

  ////  STATES
  const [canHide, setCanHide] = useState(false);

  ////  FUNCTIONS
  const handleHide = async () => {
    try {
      await hideContent({
        contentId: data._id,
        type: "post",
      });
      setCanHide("hidden");
    } catch (err) {
      console.log(err);
      alert(
        "Unable to hide content. If this becomes a persistent issue, please notify us via the Troubleshooting Channel."
      );
    }
  };

  ////  EFFECTS
  useEffect(() => {
    setCanHide(false);
    if (data?.authorId) {
      if (data.authorId === localStorage.getItem("_id")) {
        setCanHide(true);
      }
      if (Array.isArray(GlobalCtxt.userInfo?.secretaryOf)) {
        if (GlobalCtxt.userInfo.secretaryOf.includes(data.channelName)) {
          setCanHide(true);
        }
      }
    }
  }, [data]);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "whitesmoke",
        border: "0.5px solid black",
        borderRadius: 4,
        padding: 8,
        boxSizing: "border-box",
        marginBottom: 10,
        cursor: "pointer",
      }}
      onClick={() => {
        handleClick(data);
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "0.5px solid grey",
        }}
      >
        <ClickableUsername
          userId={data.authorId}
          username={data.authorName}
          underline={false}
        />
        {canHide === true ? (
          <Tooltip title="Hide post">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleHide();
              }}
            >
              <HideSourceIcon />
            </IconButton>
          </Tooltip>
        ) : canHide === "hidden" ? (
          <p style={{ fontSize: 14, opacity: 0.5 }}>HIDDEN</p>
        ) : (
          <p style={{ opacity: 0.5, fontSize: 14 }}>
            {timeSince(data.createdAt)} ago
          </p>
        )}
      </div>
      <p>{truncateString(replaceAndSanitize(data.text))}</p>
      <InteractionsBar
        authorId={data.authorId}
        contentRef={data._id}
        commentCount={data.comments}
        likeCount={data.likes}
        type="post"
        fontSize={12}
        colors={{ liked: "green" }}
        margin="10px 0px 0px 0px"
      />
    </div>
  );
}
