import React from "react";

export default function VoteReceipt({ data }) {
  return (
    <div
      style={{
        width: "100%",
        border: "1px solid black",
        borderLeft: "10px solid green",
        padding: 10,
        boxSizing: "border-box",
      }}
    >
      <p>
        <span style={{ fontWeight: 600 }}>Vote cast:</span>{" "}
        {new Date(data.createdAt).toLocaleString()}
      </p>
      <p>
        <span style={{ fontWeight: 600 }}>For:</span> {data.for}
      </p>
      <p>
        <span style={{ fontWeight: 600 }}>Reference:</span> {data._id}
      </p>
    </div>
  );
}
