import React from "react";
import Drawer from "@mui/material/Drawer";

const defaultStyling = {
  width: "75vw",
  height: "100%",
  maxWidth: 425,
  padding: 10,
};

export default function GenericDrawer({
  children,
  open,
  handleClose,
  styling,
}) {
  return (
    <Drawer
      anchor="left"
      open={open}
      transitionDuration={500}
      onClose={handleClose}
    >
      <div style={{ ...defaultStyling, ...styling }}>{children}</div>
    </Drawer>
  );
}
