import React, { useState, useEffect, useContext } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import ClickableUsername from "../components/common-components/ClickableUsername";
import DynamicFrame from "../components/common-components/DynamicFrame";
import GenericSidebar from "../components/common-components/GenericSidebar";
import UserPreview from "../components/home-components/UserPreview";
import GenericMain from "../components/common-components/GenericMain";
import PollComponent from "../components/common-components/PollComponent";
import { useWindowSize } from "../utils/Hooks";
import { getNominations, getSinglePoll } from "../functions/PollFunctions";
import { replaceAndSanitize } from "../functions/GeneralFunctions";

import { useParams, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { GlobalContext } from "../App";

import "../App.css";

export default function FullPoll() {
  ////  INITS
  const params = useParams();
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const GlobalCtxt = useContext(GlobalContext);

  ////  STATES
  const [poll, setPoll] = useState(null);
  const [nominations, setNominations] = useState([]);

  ////  FUNCTIONS
  const initPage = async () => {
    try {
      const res = await getSinglePoll(params.pollId);
      if (res) {
        setPoll(res.data);
        const noms = await getNominations(res.data.channelId);
        if (noms && Array.isArray(noms.data)) {
          setNominations(noms.data);
        }
      }
    } catch (err) {
      console.log(err);
      alert("Error loading page! Please try again later.");
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (params.pollId) {
      initPage();
    }
  }, []);

  useEffect(() => {
    if (poll) {
      console.log(poll);
    }
  }, [poll]);

  useEffect(() => {
    if (nominations) {
      console.log(nominations);
    }
  }, [nominations]);

  return (
    <ScreenContainer>
      <Navbar />
      {!poll ? (
        <CircularProgress />
      ) : (
        <>
          <IconButton
            onClick={() =>
              navigate(`/home/${poll.channelId}/${poll.channelName}`)
            }
          >
            <ArrowBackIcon />
          </IconButton>
          <h3 style={{ fontWeight: 400, textAlign: "center" }}>
            Candidates (poll: {poll._id})
          </h3>
        </>
      )}
      <DynamicFrame windowSize={windowSize}>
        <GenericMain styling={{ backgroundColor: "rgba(0,0,0,0.25)" }}>
          <div className="options-container">
            {nominations && nominations.length > 0
              ? nominations.map((el, i) => {
                  return (
                    <div
                      style={{
                        backgroundColor: "whitesmoke",
                        border: "1px solid black",
                        borderRadius: 8,
                        padding: 10,
                        boxSizing: "border-box",
                        width: 370,
                        maxHeight: "80vh",
                        overflowY: "auto",
                        marginBottom: 10,
                      }}
                      key={el._id}
                    >
                      <ClickableUsername
                        userId={el.userId}
                        username={el.username}
                        underline={true}
                      />
                      <h4>What do you hope to achieve as Channel Secretary?</h4>
                      <p>{replaceAndSanitize(el.pitch)}</p>
                      <h4>
                        What skills and experience will you bring to the role?
                      </h4>
                      <p>{replaceAndSanitize(el.sAE)}</p>
                      <h4>What is your moderation policy?</h4>
                      <p>{replaceAndSanitize(el.modPolicy)}</p>
                    </div>
                  );
                })
              : null}
          </div>
        </GenericMain>

        {windowSize.width > 768 ? (
          <GenericSidebar
            windowSize={windowSize}
            styling={{ width: windowSize.width * 0.3 }}
          >
            {GlobalCtxt.globalCurrentUser ? (
              <UserPreview data={GlobalCtxt.globalCurrentUser} />
            ) : null}
            {poll ? <PollComponent data={poll} /> : null}
          </GenericSidebar>
        ) : null}
      </DynamicFrame>
      <br></br>
      {windowSize.width <= 768 ? <PollComponent data={poll} /> : null}
    </ScreenContainer>
  );
}
