import React from "react";

export default function DisclaimerTab() {
  return (
    <>
      <h4>ALL DECLARANTS MUST READ BEFORE SIGNING!</h4>
      <p>
        Statutory declarants are made under oath, which means you could face
        legal consequences for including statements that are{" "}
        <span style={{ fontWeight: "bold" }}>
          deliberately false or deceptive.
        </span>
      </p>
      <p>
        If you deliberately lie on your statutory declaration, you could be
        charged with perjury if the declaration is later used in a legal
        proceeding. Perjury is a crime that occurs when someone makes a false
        statement under oath, knowing it is false, with the intent to mislead.
      </p>
      <p>
        Perjury only applies to statements that are intentionally false and
        deceptive. It does not apply to statements of belief, sincere but
        unprovable claims, or honest mistakes.
      </p>
    </>
  );
}
