import React, { useState, useEffect, useContext } from "react";

import AddressAutoComplete from "../common-components/AddressAutocomplete";
import SignaturePad from "../common-components/SignaturePad";
import {
  prepareBase64Image,
  validateName,
  validatePhone,
} from "../../functions/GeneralFunctions";
import { submitWitnessRegistration } from "../../functions/UserFunctions";
import { criteriaMap, occupations } from "../../utils/Data";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import * as EmailValidator from "email-validator";
import DOMPurify from "dompurify";
import CircularProgress from "@mui/material/CircularProgress";

import { GlobalContext } from "../../App";

export default function WitnessRegister() {
  ////  INITS
  const GlobalCtxt = useContext(GlobalContext);

  ////  STATES
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [occupation, setOccupation] = useState({ value: "", label: "" });
  const [signature, setSignature] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [pageState, setPageState] = useState("ready");

  ////  FUNCTIONS
  const handleChange = (e) => {
    const selectedValue = e.target.value;
    const selectedOccupation = occupations.find(
      (occupation) => occupation.value === selectedValue
    );

    if (selectedOccupation) {
      setOccupation(selectedOccupation);
    }
  };

  const handleAddressChange = (add) => {
    try {
      setAddress(add);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSignature = (sig) => {
    try {
      setSignature(sig);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClearSignature = () => {
    setSignature(null);
  };

  const handleSubmit = async () => {
    try {
      let fNameValid = validateName(fName);
      if (!fName || !fNameValid) {
        alert("Please enter a valid first name.");
        return;
      }
      let lNameValid = validateName(lName);
      if (!lName || !lNameValid) {
        alert("Please enter your last name.");
        return;
      }
      let phoneValid = validatePhone(phone);
      if (!phoneValid) {
        alert("Invalid phone number.");
        return;
      }
      if (!email) {
        alert("Please enter your email.");
        return;
      }
      const trimmedEmail = email.trim();
      const emailValid = EmailValidator.validate(trimmedEmail);
      if (!emailValid) {
        alert("Please enter a valid email address.");
        return;
      }
      if (!address) {
        alert("Please enter your address.");
        return;
      }
      if (!occupation || !occupation.value) {
        alert("Please select your occupation.");
        return;
      }
      if (!signature) {
        alert(
          "Please provide an electronic signature. Once your signature is entered, press SAVE."
        );
        return;
      }
      setPageState("submitting");
      const res = await prepareBase64Image(signature);
      if (!res?.data?.path) {
        alert("Error processing signature. Please try again.");
        return;
      }
      const updated = await submitWitnessRegistration({
        fName: DOMPurify.sanitize(fName),
        lName: DOMPurify.sanitize(lName),
        phone: phone,
        email: trimmedEmail,
        address: DOMPurify.sanitize(address),
        occupation: DOMPurify.sanitize(occupation.value),
        signature: res.data.path,
      });
      setPageState("success");
      setTimeout(() => {
        GlobalCtxt.updateWitnessId(updated.data.witnessId);
      }, 3000);
    } catch (err) {
      console.log(err);
      alert("Unexpected error while submitting registration!");
      setPageState("ready");
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (localStorage.getItem("email")) {
      setEmail(localStorage.getItem("email"));
    }
  }, []);

  return (
    <>
      {localStorage.getItem("electorate") ? (
        <>
          {pageState === "success" ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h2>Success!</h2>
              <h4 style={{ textAlign: "center" }}>
                This page will automatically update in moment ...
              </h4>
            </div>
          ) : (
            <>
              <br></br>
              <div
                style={{
                  width: "100%",
                  padding: 5,
                  boxSizing: "border-box",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-evenly",
                    paddingBottom: 20,
                  }}
                >
                  <TextField
                    fullWidth={true}
                    sx={{ maxWidth: "48%", backgroundColor: "white" }}
                    placeholder="First name"
                    onChange={(e) => {
                      setFName(e.target.value);
                    }}
                    value={fName}
                  />
                  <TextField
                    fullWidth={true}
                    sx={{ maxWidth: "48%", backgroundColor: "white" }}
                    placeholder="Last name"
                    onChange={(e) => {
                      setLName(e.target.value);
                    }}
                    value={lName}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-evenly",
                    paddingBottom: 20,
                  }}
                >
                  <TextField
                    fullWidth={true}
                    sx={{ maxWidth: "48%", backgroundColor: "white" }}
                    placeholder="Phone number"
                    type="number"
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                    value={phone}
                  />
                  <TextField
                    fullWidth={true}
                    sx={{ maxWidth: "48%", backgroundColor: "white" }}
                    placeholder="Email address"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    value={email}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    paddingBottom: 10,
                  }}
                >
                  <p style={{ marginRight: 5, fontWeight: 600 }}>
                    Work or home address:
                  </p>
                  <AddressAutoComplete grabAddress={handleAddressChange} />
                </div>
                <p style={{ fontWeight: 600 }}>Occupation:</p>
                <select
                  name="occupations"
                  id="occupations"
                  style={{
                    fontSize: 16,
                    marginBottom: 10,
                    width: "100%",
                    maxWidth: 600,
                  }}
                  onChange={handleChange}
                >
                  {occupations.map((occupation) => (
                    <option key={occupation.value} value={occupation.value}>
                      {occupation.label}
                    </option>
                  ))}
                </select>
                {occupation.value && criteriaMap[occupation.value] ? (
                  <div
                    style={{
                      width: "100%",
                      padding: 10,
                      boxSizing: "border-box",
                      border: "3px solid red",
                      marginTop: 10,
                      backgroundColor: "white",
                    }}
                  >
                    <p style={{ marginTop: 0, fontWeight: "bold" }}>
                      Please ensure you meet the following criteria:
                    </p>
                    {occupation.value && criteriaMap[occupation.value]
                      ? criteriaMap[occupation.value]
                      : null}
                  </div>
                ) : null}
                <p style={{ fontWeight: 600 }}>Electronic signature:</p>
                <p style={{ fontSize: 14, opacity: 0.75 }}>
                  Draw your signature in the field below, then press the 'SAVE'
                  button.
                </p>
                <p style={{ fontSize: 14, opacity: 0.75 }}>
                  If you make a mistake, press the 'CLEAR' button to start
                  again.
                </p>
                <SignaturePad
                  grabSignature={handleSignature}
                  height={370}
                  width={600}
                  handleClearSignature={handleClearSignature}
                />
                <div
                  style={{
                    border: "1px solid orange",
                    borderRadius: "5px",
                    padding: "15px",
                    backgroundColor: "rgba(0,0,0,0.8)",
                    color: "orange",
                    fontSize: "14px",
                    marginTop: 80,
                  }}
                >
                  <strong>IMPORTANT:</strong>
                  <p style={{ color: "white" }}>
                    By clicking 'SUBMIT REGISTRATION', you confirm that you
                    agree to the following statements:
                  </p>
                  <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                    <li>
                      I confirm that the information provided in this
                      registration is accurate and truthful to the best of my
                      knowledge.
                    </li>
                    <li>
                      I recognize that I may need to provide additional
                      documentation to verify my identity and qualifications as
                      a witness.
                    </li>
                    <li>
                      I commit to complying with all applicable laws and
                      regulations regarding the witnessing of Commonwealth
                      statutory declarations.
                    </li>
                  </ul>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      padding: "20px 0px",
                      boxSizing: "border-box",
                    }}
                  >
                    {pageState === "submitting" ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        variant="contained"
                        color="success"
                        onClick={handleSubmit}
                      >
                        SUBMIT REGISTRATION!
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <p>
          To get started, create a free PhaseChamber account by clicking the LOG
          IN button on the top right corner of the screen. It takes less than a
          minute!
        </p>
      )}
    </>
  );
}
