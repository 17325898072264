import React, { useState, useContext, useEffect } from "react";

import {
  getWitnessData,
  getWitnessSessions,
} from "../../functions/AffidavitFunctions";
import {
  createVideoEvent,
  createDailyRoom,
} from "../../functions/ConferenceFunctions";
import GenericScheduler from "../common-components/GenericScheduler";
import SessionPreview from "./SessionPreview";

import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";

import { GlobalContext } from "../../App";

const confirmationDivStyles = {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "lightgrey",
  padding: "0px 5px",
  boxSizing: "border-box",
  fontSize: 14,
};

export default function WitnessDashboard({ witnessId }) {
  ////  INITS
  const GlobalCtxt = useContext(GlobalContext);

  ////  STATES
  const [data, setData] = useState(null);
  const [hideInfo, setHideInfo] = useState(false);
  const [scheduling, setScheduling] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [sessionData, setSessionData] = useState(null);
  const [scheduledSessions, setScheduledSessions] = useState([]);

  ////  FUNCTIONS
  const fetchWitnessData = async () => {
    try {
      setHideInfo("loading");
      const docs = await getWitnessData(witnessId);
      if (docs?.data) {
        setData(docs.data);
      } else {
        alert("Error retrieving data! Please refresh the page to try again.");
      }
      setHideInfo(false);
    } catch (err) {
      console.log(err);
      alert("Error retrieving data! Please refresh the page to try again.");
      setHideInfo(false);
    }
  };

  const getDTD = (data) => {
    setSessionData({ time: data.time, duration: data.duration });
  };

  const createSession = async () => {
    try {
      setSubmitting(true);
      const roomName = await createVideoEvent({
        channelId: GlobalCtxt.nationalId,
        channelName: "National",
        time: sessionData.time,
        duration: sessionData.duration,
        callType: "witnessing",
        moderators: [
          {
            username: localStorage.getItem("username"),
            _id: localStorage.getItem("_id"),
          },
        ],
        eventName: "Declaration Witnessing Session",
        witnessId: GlobalCtxt.userInfo?.witnessId || null,
      });

      await createDailyRoom({
        roomName: roomName.data,
        callType: "witnessing",
        duration: sessionData.duration / 1000,
        startTime: sessionData.time / 1000,
      });

      setSubmitting("success");
    } catch (err) {
      console.log(err);
      alert("Error creating session! Please try again.");
      setSubmitting(false);
    }
  };

  const fetchSessions = async () => {
    try {
      const docs = await getWitnessSessions({ public: false });
      if (Array.isArray(docs?.data) && docs.data.length > 0) {
        setScheduledSessions(docs.data);
      }
    } catch (err) {
      console.log(err);
      alert("Error fetching sessions!");
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (data) {
      fetchSessions();
    }
  }, [data]);

  useEffect(() => {
    if (scheduling === true) {
      setHideInfo(true);
    } else if (scheduling === false) {
      setSessionData({ time: null, duration: null });
      setSubmitting(false);
    }
  }, [scheduling]);

  useEffect(() => {
    if (submitting === "success") {
      fetchSessions();
      setTimeout(() => {
        setScheduling(false);
      }, 2000);
    }
  }, [submitting]);

  return (
    <>
      <p style={{ fontWeight: "bold" }}>
        Witness ID: <span style={{ fontWeight: 400 }}>{witnessId}</span>
      </p>
      {data && data?.status && data.status === "pending" ? (
        <div
          style={{
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.7)",
            padding: 5,
            boxSizing: "border-box",
            color: "white",
          }}
        >
          <p>
            Thanks for putting your hand up to become a volunteer Witness! Your
            application has been received and will be reviewed by a member of
            our team as soon as possible.
          </p>
          <p></p>
        </div>
      ) : null}
      <div
        style={{
          width: "100%",
          backgroundColor: "rgba(0,0,0,0.8)",
          padding: 5,
          boxSizing: "border-box",
          boxShadow: GlobalCtxt.genericBoxShadow,
        }}
      >
        {hideInfo === "loading" ? (
          <CircularProgress />
        ) : hideInfo === false ? (
          <>
            {data ? (
              <>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    color: "white",
                  }}
                >
                  <div>
                    <h4>
                      Status:{" "}
                      <span
                        style={{
                          fontWeight: 400,
                          color:
                            data.status === "pending" ? "orange" : "limegreen",
                        }}
                      >
                        {data.status.toUpperCase()}
                      </span>
                    </h4>
                    <p style={{ fontWeight: 600 }}>
                      Name:{" "}
                      <span style={{ fontWeight: 300 }}>
                        {data.lName}, {data.fName}
                      </span>
                    </p>
                    <p style={{ fontWeight: 600 }}>
                      Phone:{" "}
                      <span style={{ fontWeight: 300 }}>{data.phone}</span>
                    </p>
                    <p style={{ fontWeight: 600 }}>
                      Email:{" "}
                      <span style={{ fontWeight: 300 }}>{data.email}</span>
                    </p>
                    <p style={{ fontWeight: 600 }}>
                      Address:{" "}
                      <span style={{ fontWeight: 300 }}>{data.address}</span>
                    </p>
                    <p style={{ fontWeight: 600 }}>
                      Occupation:{" "}
                      <span style={{ fontWeight: 300 }}>{data.occupation}</span>
                    </p>
                  </div>
                  <div style={{ padding: 5, boxSizing: "border-box" }}>
                    <p
                      style={{
                        width: "100%",
                        textAlign: "center",
                        color: "lightgrey",
                      }}
                    >
                      Your signature:
                    </p>
                    <img
                      src={data.signature}
                      style={{
                        backgroundColor: "white",
                        height: "auto",
                        maxWidth: 300,
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    color="info"
                    sx={{ marginTop: "10px", minWidth: "200px" }}
                    onClick={() => setHideInfo(true)}
                  >
                    CLOSE
                  </Button>
                </div>
              </>
            ) : (
              <Button
                variant="contained"
                size="small"
                onClick={fetchWitnessData}
              >
                LOAD PROFILE
              </Button>
            )}
          </>
        ) : hideInfo === true ? (
          <Button
            variant="contained"
            size="small"
            onClick={() => setHideInfo(false)}
          >
            SHOW PROFILE
          </Button>
        ) : null}
      </div>
      <br></br>
      {data && data?.status === "active" ? (
        <>
          {scheduling ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <IconButton onClick={() => setScheduling(false)}>
                <CancelIcon />
              </IconButton>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundImage:
                  "url('https://storage.googleapis.com/bucket-775/canva_gold_bg.png')",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                padding: 20,
                boxSizing: "border-box",
                borderRadius: 4,
                boxShadow: GlobalCtxt.genericBoxShadow,
              }}
            >
              <h3
                style={{
                  textAlign: "center",
                  margin: "0px 2px 15px 2px",
                }}
              >
                Schedule a remote session!
              </h3>
              <Button
                variant="contained"
                color="success"
                onClick={() => setScheduling(true)}
                size="small"
                sx={{ minWidth: "200px" }}
              >
                GET STARTED
              </Button>
            </div>
          )}
          {scheduling ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#DFF0D8",
                padding: 5,
                boxSizing: "border-box",
              }}
            >
              {sessionData?.time && sessionData?.duration ? (
                <>
                  <h4>Confirm session</h4>
                  <div
                    style={{
                      width: "auto",
                      maxWidth: 600,
                      border: "0.1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        ...confirmationDivStyles,
                        backgroundColor: "lightgrey",
                        justifyContent: "center",
                      }}
                    >
                      <p>
                        {new Date(sessionData.time).toLocaleString("en-US", {
                          weekday: "long",
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                          timeZoneName: "long",
                        })}
                      </p>
                    </div>
                    <div
                      style={{
                        ...confirmationDivStyles,
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      <p style={{ fontWeight: 600 }}>Duration:</p>
                      {sessionData.duration === 3600000 ? (
                        <p>1 hour</p>
                      ) : (
                        <p>{sessionData.duration / 3600000} hours</p>
                      )}
                    </div>
                  </div>
                  <p
                    style={{
                      textAlign: "center",
                      width: "98%",
                      maxWidth: 600,
                      fontSize: 14,
                    }}
                  >
                    Users will be able to queue for your session at the
                    specified time. They will not be able to access the video
                    call until you invite them. You can cancel this session at
                    any time.
                  </p>
                  {submitting === true ? (
                    <CircularProgress />
                  ) : submitting === "success" ? (
                    <h4>Success!</h4>
                  ) : (
                    <Button
                      variant="contained"
                      color="success"
                      sx={{ minWidth: "300px" }}
                      onClick={createSession}
                    >
                      CONFIRM!
                    </Button>
                  )}
                </>
              ) : (
                <GenericScheduler
                  handleSubmit={getDTD}
                  minHrs={0.5}
                  maxHrs={6}
                  header="Schedule your session"
                />
              )}
            </div>
          ) : null}
          <br></br>
          <div
            style={{
              width: "100%",
              display: "flex",
              border: "0.5px solid lightgrey",
              padding: 4,
              boxSizing: "border-box",
              backgroundColor: "lightgrey",
            }}
          >
            <div style={{ width: "98%", maxWidth: 600 }}>
              {scheduledSessions &&
              Array.isArray(scheduledSessions) &&
              scheduledSessions.length > 0 ? (
                <>
                  <h4>Your sessions:</h4>
                  {scheduledSessions.map((el, i) => {
                    return <SessionPreview data={el} key={el.createdAt} />;
                  })}
                </>
              ) : null}
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
              }}
            ></div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
