import React, { useContext, useState, useEffect } from "react";

import DynamicFrame from "../common-components/DynamicFrame";
import GenericSidebar from "../common-components/GenericSidebar";
import GenericMain from "../common-components/GenericMain";
import GenericTextField from "../common-components/GenericTextField";
import GenericModal from "../common-components/GenericModal";
import SocketCtxtProvider from "./SocketCtxtProvider";
import { useWindowSize } from "../../utils/Hooks";
import { getUserByStreamKey } from "../../functions/StreamingFunctions";
import TipModal from "../common-components/TipModal";
import InteractionsBar from "../common-components/InteractionsBar";
import TipButton from "../common-components/TipButton";

import MuxPlayer from "@mux/mux-player-react";
import Button from "@mui/material/Button";
import PaidIcon from "@mui/icons-material/Paid";

import "../../App.css";

import { GlobalContext } from "../../App";

export default function AssetWindow({ asset }) {
  ////  INITS
  const windowSize = useWindowSize();
  const GlobalCtxt = useContext(GlobalContext);

  ////  STATES
  const [isHovered, setIsHovered] = useState(false);

  ////  FUNCTIONS
  const initCurrentAsset = async () => {
    //
  };

  ////  EFFECTS
  useEffect(() => {
    if (asset) {
      initCurrentAsset();
    }
  }, [asset]);

  return (
    <>
      <DynamicFrame windowSize={windowSize} styling={{ maxHeight: "80vh" }}>
        <GenericMain styling={{ padding: 0 }}>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "black",
            }}
          >
            <MuxPlayer
              playbackId={asset.playbackId}
              metadata={{
                video_id: asset.originalId,
                video_title: asset.title,
                viewer_user_id: "user-id-007",
              }}
            />
          </div>
          <div
            style={{
              width: "100%",
              backgroundColor: "black",
              padding: "10px 20px",
              boxSizing: "border-box",
              borderTop: "0.5px solid grey",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ maxWidth: "66%" }}>
              <h3 style={{ color: "white", margin: "10px 0px" }}>
                {asset?.title}
              </h3>
              <h4
                style={{
                  color: "lightgrey",
                  fontWeight: 400,
                  margin: "5px 0px",
                }}
              >
                {asset?.uploaderName}
              </h4>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <InteractionsBar
                authorId={asset?.uploaderId}
                contentRef="abc"
                type="video"
                likeCount={asset?.likes}
                commentCount={asset?.comments}
                fontSize={18}
                margin="0px 20px 0px 0px"
                colors={{
                  text: "white",
                  liked: "mediumseagreen",
                }}
              />
            </div>
          </div>
        </GenericMain>
        {windowSize.width > 768 ? (
          <GenericSidebar windowSize={windowSize}>
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              className="dark-container"
            >
              {!GlobalCtxt.userInfo?.tier || GlobalCtxt.userInfo.tier <= 0 ? (
                <div
                  style={{ width: 300, height: 250, backgroundColor: "white" }}
                />
              ) : null}
              <h4 style={{ opacity: 0.5 }}>Comments ({asset.comments})</h4>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  overflowY: "auto",
                  border: "1px solid white",
                }}
              ></div>
              <GenericTextField
                placeholder="Leave a comment ..."
                divStyling={{ backgroundColor: "whitesmoke" }}
                rows={3}
                charLimit={280}
              />
            </div>
          </GenericSidebar>
        ) : null}
      </DynamicFrame>
    </>
  );
}
