//  REVISIT: meeting previews, scrollable parent div

import React, { useState, useEffect, useContext } from "react";

import LobbyWrapper from "../components/conference-components/LobbyWrapper";
import LobbyDiv from "../components/conference-components/LobbyDiv";
import MeetingPreview from "../components/conference-components/MeetingPreview";
import { getVideoEvents } from "../functions/ConferenceFunctions";

import { useParams, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

import { GlobalContext } from "../App";

export default function ConferenceLobby() {
  ////  INITS
  const params = useParams();
  const navigate = useNavigate();
  const GlobalCtxt = useContext(GlobalContext);

  ////  STATES
  const [scheduledEvents, setScheduledEvents] = useState([]);
  const [canSchedule, setCanSchedule] = useState(false);

  ////  FUNCTIONS
  const fetchEvents = async () => {
    try {
      if (params.channelId) {
        const docs = await getVideoEvents(params.channelId);
        if (Array.isArray(docs.data)) {
          setScheduledEvents(docs.data);
        }
      }
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  const checkCanSchedule = async () => {
    try {
      if (!GlobalCtxt.userInfo?.tier || GlobalCtxt.userInfo.tier <= 0) {
        return;
      } else {
        if (
          localStorage.getItem("electorate") ===
          decodeURIComponent(params.channelName)
        ) {
          setCanSchedule(true);
        } else if (
          Array.isArray(GlobalCtxt.userInfo?.secretaryOf) &&
          GlobalCtxt.userInfo.secretaryOf.includes(
            decodeURIComponent(params.channelName)
          )
        ) {
          setCanSchedule(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    fetchEvents();
    checkCanSchedule();
  }, []);

  return (
    <LobbyWrapper mode="webinar">
      <LobbyDiv>
        <h4 style={{ margin: 8 }}>{decodeURIComponent(params.channelName)}</h4>
        <h2 style={{ textAlign: "center", margin: 8 }}>Conference Room</h2>
        <hr style={{ width: "100%" }}></hr>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            flex: 1,
            overflowY: "auto",
            maxHeight: "70vh",
          }}
        >
          <p>Upcoming events:</p>
          {Array.isArray(scheduledEvents) && scheduledEvents.length > 0 ? (
            <div>
              {scheduledEvents.map((el, i) => {
                return <MeetingPreview key={el._id} data={el} />;
              })}
            </div>
          ) : (
            <p style={{ fontStyle: "italic", opacity: 0.5 }}>
              Nothing scheduled.
            </p>
          )}
        </div>
        {canSchedule ? (
          <div
            style={{
              width: "100%",
              backgroundColor: "rgba(0,0,0,0.8)",
              display: "flex",
              justifyContent: "center",
              padding: 10,
              boxSizing: "border-box",
            }}
          >
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                navigate(
                  `/conference-schedule/${
                    params.channelId
                  }/${decodeURIComponent(params.channelName)}`
                );
              }}
            >
              SCHEDULE MEETING
            </Button>
          </div>
        ) : null}
      </LobbyDiv>
    </LobbyWrapper>
  );
}
