import React, { useState, useRef, useEffect } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import DynamicFrame from "../components/common-components/DynamicFrame";
import GenericMain from "../components/common-components/GenericMain";
import GenericSidebar from "../components/common-components/GenericSidebar";
import InformationOverview from "../components/flyer-components/InformationOverview";
import TenReasons from "../components/flyer-components/TenReasons";
import EightWays from "../components/flyer-components/EightWays";
import LetterTips from "../components/flyer-components/LetterTips";
import Debra from "../components/flyer-components/Debra";
//import htmlContent from "../components/flyer-components/AusExitsPacks";
//import LetterGenerator from "../components/flyer-components/LetterGenerator";
import { useWindowSize } from "../utils/Hooks";

import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import "../App.css";
import "animate.css";

const divStyle = {
  height: "100vh",
  width: "100vw",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: "10vh",
};

export default function WeSayNo() {
  ////  INITS
  const windowSize = useWindowSize();
  const div0Ref = useRef();
  const div1Ref = useRef();
  const navigate = useNavigate();

  ////  STATES
  const [currentDocument, setCurrentDocument] = useState(null);
  const [currentDiv, setCurrentDiv] = useState(0);

  ////  FUNCTIONS
  const scrollToRef = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  };

  const handleToggle = (index) => {
    try {
      setCurrentDocument(index);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // Scroll to the correct div based on currentDiv
    if (currentDiv === 0) {
      //scrollToRef(div0Ref);
    }
    if (currentDiv === 1) scrollToRef(div1Ref);
  }, [currentDiv]);

  return (
    <ScreenContainer
      styling={{
        background: "linear-gradient(to bottom, #C5D2C0, #B0C2B4, #A2B5BA)",
        paddingTop: 0,
      }}
    >
      <Navbar />
      <div style={divStyle} ref={div0Ref}>
        <h2 className="animate__animated animate__fadeInDown">
          Australia Needs You!
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          className="animate__animated animate__fadeInDown animate__delay-1s"
        >
          <p style={{ width: "96%", maxWidth: 660, textAlign: "center" }}>
            The Australian government is on the verge of signing two
            international agreements which threaten to undermine our most
            fundamental rights and freedoms.
          </p>
          <h4 style={{ width: "96%", maxWidth: 660, textAlign: "center" }}>
            The more you read about these agreements, the more you'll agree that
            they must be stopped at all costs. Click the button below to learn
            more.
          </h4>
          <Button
            variant="contained"
            onClick={() => setCurrentDiv(1)}
            size="small"
          >
            LEARN MORE
          </Button>
        </div>

        <div
          style={{
            width: "100vw",
            maxWidth: 660,
            padding: 8,
            boxSizing: "border-box",
            backgroundColor: "whitesmoke",
            border: "1px solid black",
            borderRadius: 4,
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          className="animate__animated animate__fadeInUp animate__delay-2s"
        >
          <h3 style={{ textAlign: "center" }}>Time is running out.</h3>
          <p
            style={{
              textAlign: "center",
              fontSize: windowSize.width > 768 ? 16 : 14,
            }}
          >
            With a federal election imminent, we need to make it clear to the
            political class that ordinary Australians{" "}
            <span style={{ fontWeight: "bold" }}>do not</span> support these
            proposals. The best way to do that is by contacting your MP{" "}
            <span style={{ fontWeight: "bold" }}>directly.</span>
          </p>
          <p
            style={{
              textAlign: "center",
              fontSize: windowSize.width > 768 ? 16 : 14,
            }}
          >
            We've worked with lawyers and medical professionals to draft a
            letter that clearly and concisely outlines our concerns. To acquire
            a personalised version of that letter that you can use in your own
            correspondence, click the button below.
          </p>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              navigate("/letter-writer");
            }}
          >
            CREATE MY LETTER!
          </Button>
        </div>
      </div>
      <div style={divStyle} ref={div1Ref}>
        <DynamicFrame windowSize={windowSize} styling={{ maxHeight: "90vh" }}>
          <GenericMain styling={{ backgroundColor: "whitesmoke" }}>
            {currentDocument ? (
              <Button
                onClick={() => setCurrentDocument(null)}
                variant="contained"
              >
                BACK TO OVERVIEW
              </Button>
            ) : null}
            {currentDocument === null ? (
              <InformationOverview handleToggle={handleToggle} />
            ) : currentDocument === "ten-reasons" ? (
              <TenReasons />
            ) : currentDocument === "eight-ways" ? (
              <EightWays handleToggle={handleToggle} />
            ) : currentDocument === "letter-tips" ? (
              <LetterTips handleToggle={handleToggle} />
            ) : currentDocument === "debra-vid" ? (
              <Debra handleToggle={handleToggle} />
            ) : (
              <InformationOverview />
            )}
          </GenericMain>
          {windowSize.width > 768 ? (
            <GenericSidebar
              windowSize={windowSize}
              styling={{
                width: windowSize.width * 0.3,
                backgroundColor: "rgba(0,0,0,0.75)",
              }}
            >
              <div
                className="dark-container"
                style={{ height: "100%", width: "100%" }}
              >
                <TenReasons />
              </div>
            </GenericSidebar>
          ) : null}
        </DynamicFrame>
      </div>
    </ScreenContainer>
  );
}
