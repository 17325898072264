import React, { useState, useRef, useContext } from "react";

import GenericTextField from "./GenericTextField";
import CoverImage from "./CoverImage";
import {
  validateTitle,
  validateDescription,
  checkImage,
  prepareImage,
} from "../../functions/GeneralFunctions";
import { createChannel } from "../../functions/ChannelFunctions";

import Button from "@mui/material/Button";
import DOMPurify from "dompurify";

import { GlobalContext } from "../../App";

export default function CreateChannelModal() {
  ////  INITS
  const fileRef = useRef();
  const GlobalCtxt = useContext(GlobalContext);

  ////  STATES
  const [channelName, setChannelName] = useState("");
  const [channelExplainer, setChannelExplainer] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);

  ////  FUNCTIONS
  const handleNameChange = (text) => {
    setChannelName(text);
  };

  const handleExplainerChange = (text) => {
    setChannelExplainer(text);
  };

  const handleFileChange = (event) => {
    if (previewUrl) {
      URL.revokeObjectURL(previewUrl);
    }
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
      setFileToUpload(file);
    }
  };

  const handleFileCancel = () => {
    URL.revokeObjectURL(previewUrl);
    setPreviewUrl(null);
    setFileToUpload(null);
  };

  const handleSubmit = async () => {
    try {
      setBtnDisabled(true);
      const titleValid = validateTitle(channelName);
      if (!titleValid) {
        alert(
          "Invalid title! Please refrain from using any unusual characters."
        );
        setBtnDisabled(false);
        return;
      }
      const explainerValid = validateDescription(channelExplainer);
      if (!explainerValid) {
        alert(
          "Invalid description! Please refrain from using any unusual characters."
        );
        setBtnDisabled(false);
        return;
      }
      let uploaded = null;
      if (fileToUpload) {
        const check = checkImage(fileToUpload);
        if (check.success) {
          uploaded = await prepareImage(
            fileToUpload,
            localStorage.getItem("username").slice(0, 4)
          );
        } else {
          alert("We are unable to upload that image. Please choose another.");
          setBtnDisabled(false);
          return;
        }
      }
      await createChannel({
        name: DOMPurify.sanitize(channelName),
        explainer: DOMPurify.sanitize(channelExplainer),
        coverPhoto: uploaded?.path ? uploaded.path : null,
      });
      await GlobalCtxt.addChannelToSecretaryList(
        DOMPurify.sanitize(channelName)
      );
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (err) {
      console.log(err);
      alert(err.message);
      setBtnDisabled(false);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h3 style={{ textAlign: "center" }}>Let's make a Channel!</h3>
      <p style={{ textAlign: "center" }}>
        When you create a Channel as a paid member, you're Secretary for life!
      </p>
      <div style={{ width: "100%", maxWidth: 600 }}>
        <GenericTextField
          placeholder="Give your Channel a name ..."
          charLimit={50}
          buttonVariant="hidden"
          handleChange={handleNameChange}
          divStyling={{ marginBottom: "2px" }}
        />
      </div>
      <GenericTextField
        placeholder="What is your Channel's purpose?"
        charLimit={249}
        rows={3}
        buttonVariant="hidden"
        handleChange={handleExplainerChange}
        divStyling={{ marginBottom: "2px" }}
      />

      {previewUrl ? (
        <CoverImage
          url={previewUrl}
          channelName={channelName}
          styling={{ margin: "10px 0px" }}
        />
      ) : null}
      {previewUrl ? (
        <Button onClick={handleFileCancel}>CHOOSE A DIFFERENT IMAGE</Button>
      ) : (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            margin: "10px 5px",
          }}
        >
          <p style={{ fontSize: 14, marginRight: "10px" }}>
            Add a cover photo (optional):
          </p>
          <input
            type="file"
            style={{
              border: "1px solid black",
              padding: 5,
              boxSizing: "border-box",
              backgroundColor: "rgba(0,0,0,0.5)",
              color: "white",
            }}
            ref={fileRef}
            onChange={handleFileChange}
          />
        </div>
      )}
      <br></br>
      <Button
        color="success"
        variant="contained"
        onClick={handleSubmit}
        disabled={btnDisabled}
      >
        CREATE CHANNEL!
      </Button>
    </div>
  );
}
