import Axios from "axios";
import authenticatedConfig from "../utils/Token";
import Cookies from "js-cookie";

const createStripeSession = async (data) => {
  try {
    const auth = {
      headers: {
        Authorization: `Bearer ${Cookies.get("session-token")}`,
      },
    };

    console.log("!!!");
    console.log(auth);
    const sesh = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/payments/create-stripe-session`,
      data,
      auth
    );
    return sesh;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const confirmPurchaseCode = async (code) => {
  try {
    const doc = await Axios.put(
      `${process.env.REACT_APP_DEV_SERVER}/payments/confirm-purchase-code`,
      { code },
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const changeSubscription = async (data) => {
  try {
    const doc = await Axios.put(
      `${process.env.REACT_APP_DEV_SERVER}/payments/change-subscription`,
      data,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const cancelSubscription = async () => {
  try {
    const doc = await Axios.put(
      `${process.env.REACT_APP_DEV_SERVER}/payments/cancel-subscription`,
      {},
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const checkForValidSub = async () => {
  try {
    const doc = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/payments/check-for-valid-sub`,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getReferralOptions = async () => {
  try {
    const docs = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/channels/get-referral-options`
    );
    return docs;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

export {
  createStripeSession,
  confirmPurchaseCode,
  changeSubscription,
  cancelSubscription,
  checkForValidSub,
  getReferralOptions,
};
