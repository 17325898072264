import React, { useState, useEffect, useContext, useRef } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import CircularImgContainer from "../components/common-components/CircularImgContainer";
import {
  checkImage,
  prepareImage,
  updateProfilePic,
  logout,
} from "../functions/GeneralFunctions";
import { cancelSubscription } from "../functions/PaymentFunctions";
import { getPastVotes } from "../functions/PollFunctions";
import { getPastPosts } from "../functions/ContentFunctions";
import {
  generateKeys,
  getObjectStoreNames,
  resetDatabase,
} from "../functions/EncryptionFunctions";
import { postPublicKey } from "../functions/UserFunctions";
import GenericModal from "../components/common-components/GenericModal";
import CreateChannelModal from "../components/common-components/CreateChannelModal";
import VoteReceipt from "../components/common-components/VoteReceipt";
import PostPreview from "../components/home-components/PostPreview";

import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

import { GlobalContext } from "../App";

export default function Profile() {
  ////  INITS
  const navigate = useNavigate();
  const GlobalCtxt = useContext(GlobalContext);
  const fileRef = useRef();

  ////  STATES
  const [previewUrl, setPreviewUrl] = useState(null);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [showSubOptions, setShowSubOptions] = useState(false);
  const [cancelModal, setCancelModal] = useState(null);
  const [pastVotes, setPastVotes] = useState([]);
  const [pastPosts, setPastPosts] = useState([]);
  const [channelModalVisible, setChannelModalVisible] = useState(false);
  const [keySuccess, setKeySuccess] = useState(null);
  const [existingStores, setExistingStores] = useState(null);

  ////  FUNCTIONS
  const handleUpload = async () => {
    try {
      setUploading(true);
      const check = checkImage(fileToUpload);
      if (check.success) {
        const uploaded = await prepareImage(
          fileToUpload,
          localStorage.getItem("username").slice(0, 4)
        );
        await updateProfilePic(uploaded.path);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        alert(check.error);
        setUploading(false);
      }
    } catch (err) {
      console.log(err);
      alert(err.message);
      setUploading(false);
    }
  };

  const resetKeys = async () => {
    try {
      const reset = await resetDatabase();
      setTimeout(() => {
        createAndStoreKeys();
      }, 250);
    } catch (err) {
      console.log(err);
      setKeySuccess(false);
    }
  };

  const createAndStoreKeys = async () => {
    try {
      const publicKey = await generateKeys(localStorage.getItem("_id"));
      if (publicKey) {
        const posted = await postPublicKey(publicKey);
        if (posted) {
          setKeySuccess(true);
          localStorage.setItem("publicKey", publicKey);
        }
      } else {
        setKeySuccess(false);
      }
    } catch (err) {
      console.log(err);
      setKeySuccess(false);
    }
  };

  const newKeyHandler = async () => {
    try {
      const alreadyExists = await getObjectStoreNames();
      if (alreadyExists) {
        setExistingStores(alreadyExists);
        return;
      }
      await resetDatabase();
      setTimeout(() => {
        createAndStoreKeys();
      }, 250);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancel = () => {
    URL.revokeObjectURL(previewUrl);
    setPreviewUrl(null);
  };

  const handleClick = () => {
    fileRef.current.click();
  };

  const handleFileChange = (event) => {
    if (previewUrl) {
      URL.revokeObjectURL(previewUrl);
    }
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
      setFileToUpload(file);
    }
  };

  const handleCancellation = async () => {
    try {
      setUploading(true);
      const cancelled = await cancelSubscription();
      alert("Subscription cancelled.");
      setTimeout(() => {
        logout();
        navigate("/login");
      }, 2000);
    } catch (err) {
      console.log(err);
      alert(err.message);
      setUploading(false);
    }
  };

  const fetchPastVotes = async () => {
    try {
      const docs = await getPastVotes({ skip: pastVotes.length, limit: 8 });
      if (docs?.data && Array.isArray(docs.data)) {
        setPastVotes(docs.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchPastPosts = async () => {
    try {
      const docs = await getPastPosts({ skip: pastPosts.length, limit: 8 });
      console.log(docs);
      if (docs?.data && Array.isArray(docs.data)) {
        setPastPosts(docs.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePostClick = (post) => {
    navigate(`/post/${post._id}/${post.channelId}/${post.channelName}`);
  };

  ////  EFFECTS
  useEffect(() => {
    fetchPastVotes();
    fetchPastPosts();
  }, []);

  useEffect(() => {
    if (Array.isArray(pastVotes) && pastVotes.length > 0) {
      console.log(pastVotes);
    }
  }, [pastVotes]);

  useEffect(() => {
    if (existingStores) {
      /*const extractAppendedIds = (existingStores) => {
        return existingStores.map((storeName) => {
          const parts = storeName.split("-");
          return parts.length > 1 ? parts[1] : null;
        });
      };

      const checkForMatchingId = (ids) => {
        const localId = localStorage.getItem("_id");
        return ids.includes(localId);
      };

      const ids = extractAppendedIds(existingStores);
      const isMatch = checkForMatchingId(ids);*/
      setKeySuccess("exists");
    }
  }, [existingStores]);

  useEffect(() => {
    if (keySuccess === true) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, [keySuccess]);

  return (
    <ScreenContainer>
      <Navbar />
      <GenericModal
        open={cancelModal ? true : false}
        handleClose={() => setCancelModal(null)}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h4 style={{ textAlign: "center" }}>
            Are you sure you want to cancel your subscription?
          </h4>
          <p style={{ textAlign: "center" }}>
            Cancellation will result in the immediate loss of your subscription
            privileges.
          </p>
          <br></br>
          <Button
            variant="contained"
            onClick={() => {
              setCancelModal(null);
            }}
            disabled={uploading}
          >
            RETURN TO PROFILE
          </Button>
          <br></br>
          <Button
            variant="outlined"
            color="error"
            onClick={handleCancellation}
            disabled={uploading}
          >
            CONFIRM CANCELLATION
          </Button>
        </div>
      </GenericModal>
      <GenericModal
        open={channelModalVisible}
        handleClose={() => setChannelModalVisible(false)}
      >
        <CreateChannelModal />
      </GenericModal>
      <div
        style={{
          width: "100vw",
          maxWidth: 768,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "whitesmoke",
          padding: 10,
          boxSizing: "border-box",
          boxShadow: GlobalCtxt.genericBoxShadow,
          border: "1px solid black",
        }}
      >
        <div
          style={{
            width: "100%",
            padding: 10,
            boxSizing: "border-box",
            backgroundColor: "lightgrey",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {localStorage.getItem("publicKey") && keySuccess !== "exists" ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <CheckCircleIcon sx={{ color: "green" }} />
                <p
                  style={{
                    margin: "0px 0px 0px 5px",
                    opacity: 0.5,
                    fontSize: 14,
                  }}
                >
                  Encryption key saved
                </p>
              </div>
              <Button
                size="small"
                variant="outlined"
                onClick={() => setKeySuccess("exists")}
              >
                RESET KEY
              </Button>
            </div>
          ) : (
            <>
              {keySuccess === null ? (
                <>
                  <h4 style={{ textAlign: "center" }}>
                    Create an encryption key to start messaging!
                  </h4>
                  <Button variant="outlined" onClick={newKeyHandler}>
                    GENERATE ENCRYPTION KEY
                  </Button>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: 14,
                      maxWidth: 600,
                      margin: "16px 0px 0px 0px",
                    }}
                  >
                    <span style={{ fontWeight: 600 }}>IMPORTANT: </span>Key
                    generation may not work properly if you're using a private
                    browser (i.e. Incognito mode). Please ensure that private
                    browsing is disabled prior to generating your key.
                  </p>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: 14,
                      maxWidth: 600,
                      margin: "16px 0px 0px 0px",
                    }}
                  >
                    <span style={{ fontWeight: 600 }}>
                      The above warning only applies to the process of
                      generating your key.
                    </span>{" "}
                    After the key has been generated, feel free to return to
                    private browsing if you wish.
                  </p>
                </>
              ) : keySuccess === true ? (
                <CircularProgress />
              ) : keySuccess === false ? (
                <>
                  <p style={{ textAlign: "center", maxWidth: 600 }}>
                    An error occurred while generating your encryption key.
                    Please reload the page to try again. If this issue persists,
                    please let us know via the Troubleshooting Channel.
                  </p>
                </>
              ) : keySuccess === "exists" ? (
                <>
                  <h4>Key already exists!</h4>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: 14,
                      margin: "8px 0px 8px 0px",
                      maxWidth: 600,
                    }}
                  >
                    An encryption key has been generated for this device. This
                    is likely because you've already created another account.
                  </p>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: 14,
                      margin: "8px 0px 8px 0px",
                      maxWidth: 600,
                    }}
                  >
                    If you want to send encrypted messages from multiple
                    accounts, you will need to create the accounts using{" "}
                    <span style={{ fontWeight: 600 }}>different browsers.</span>{" "}
                    For example, if you set up your first account on Google
                    Chrome, you will need to set up your second account on
                    another browser such as Firefox, Safari, etc.
                  </p>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: 14,
                      margin: "8px 0px 16px 0px",
                      maxWidth: 600,
                    }}
                  >
                    <span style={{ fontWeight: 600 }}>Alternatively, </span> you
                    can click the button below to reset your key. This will
                    result in you losing access to all previous messages.
                  </p>
                  <Button
                    onClick={resetKeys}
                    variant="contained"
                    color="warning"
                    size="small"
                  >
                    RESET KEY
                  </Button>
                  <br></br>
                  <Button onClick={() => setKeySuccess(null)}>CANCEL</Button>
                </>
              ) : null}
            </>
          )}
        </div>
        <h3 style={{ margin: "20px 0px 10px 0px" }}>
          {localStorage.getItem("username")}
        </h3>
        <h4 style={{ fontWeight: 500, marginTop: 0 }}>
          {localStorage.getItem("electorate")}
        </h4>
        {previewUrl ? (
          <CircularImgContainer url={previewUrl} />
        ) : (
          <CircularImgContainer url={localStorage.getItem("profilePic")} />
        )}
        <br></br>
        {previewUrl ? (
          <>
            <Button
              variant="contained"
              color="info"
              onClick={handleUpload}
              disabled={uploading}
              sx={{ margin: "2px" }}
            >
              Confirm profile picture
            </Button>
            <Button size="small" onClick={handleCancel}>
              Cancel
            </Button>
          </>
        ) : (
          <Button
            variant="outlined"
            color="info"
            onClick={handleClick}
            sx={{ margin: "2px" }}
          >
            Update profile picture
            <input
              type="file"
              hidden
              ref={fileRef}
              onChange={handleFileChange}
            />
          </Button>
        )}
        <br></br>
        {GlobalCtxt?.userInfo?.tier >= 2 ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundImage:
                "url('https://storage.googleapis.com/bucket-775/canva_blue_bg.png')",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              padding: 10,
              boxSizing: "border-box",
            }}
          >
            <h4 style={{ textAlign: "center", margin: 0, color: "white" }}>
              Create your own Channel!
            </h4>
            <p style={{ textAlign: "center", color: "white" }}>
              Launch fundraising campaigns and unlimited private video calls
              with your very own Channel!
            </p>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                setChannelModalVisible(true);
              }}
            >
              CREATE!
            </Button>
          </div>
        ) : null}

        <hr style={{ width: "100%" }}></hr>
        <div style={{ width: "100%", maxHeight: "80vh", overflowY: "auto" }}>
          <p>Your recent posts:</p>
          {Array.isArray(pastPosts) && pastPosts.length > 0 ? (
            <>
              {pastPosts.map((el, i) => {
                return (
                  <PostPreview
                    data={el}
                    key={`${i}-${el._id}`}
                    handleClick={handlePostClick}
                  />
                );
              })}
            </>
          ) : (
            <p style={{ fontStyle: "italic", opacity: 0.5 }}>
              Nothing to display.
            </p>
          )}
        </div>
        <hr style={{ width: "100%" }}></hr>
        <div style={{ width: "100%", maxHeight: "80vh", overflowY: "auto" }}>
          <p>Your past votes:</p>
          {Array.isArray(pastVotes) && pastVotes.length > 0 ? (
            <>
              {pastVotes.map((el, i) => {
                return <VoteReceipt data={el} key={`${i}-${el._id}`} />;
              })}
            </>
          ) : (
            <p style={{ fontStyle: "italic", opacity: 0.5 }}>
              Nothing to display.
            </p>
          )}
        </div>
        <hr style={{ width: "100%" }}></hr>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => {
              setShowSubOptions((prev) => !prev);
            }}
          >
            {showSubOptions
              ? `HIDE SUBSCRIPTION OPTIONS`
              : `SHOW SUBSCRIPTION OPTIONS`}
          </Button>
        </div>
        {showSubOptions ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "lightgrey",
              padding: 5,
              boxSizing: "border-box",
              margin: 5,
            }}
          >
            {GlobalCtxt.userInfo.tier === 0 ? (
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  navigate("/upgrade");
                }}
              >
                UPGRADE!
              </Button>
            ) : null}
            {GlobalCtxt.userInfo.tier !== 0 ? (
              <>
                <Button
                  variant="contained"
                  sx={{ margin: "10px" }}
                  onClick={() => {
                    navigate("/upgrade");
                  }}
                >
                  MODIFY SUBSCRIPTION
                </Button>
                <Button
                  color="error"
                  onClick={() => {
                    setCancelModal(true);
                  }}
                >
                  CANCEL SUBSCRIPTION
                </Button>
              </>
            ) : null}
          </div>
        ) : null}
      </div>
    </ScreenContainer>
  );
}
