import React, { useState } from "react";

export default function VolunteerTab({ data, index }) {
  ////  STATES
  const [unpacked, setUnpacked] = useState(false);

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          width: "100%",
          backgroundColor: index % 2 === 0 ? "lightgrey" : "white",
          display: "flex",
          justifyContent: "space-between",
          padding: 5,
          boxSizing: "border-box",
          cursor: "pointer",
        }}
        onClick={() => {
          setUnpacked((prev) => !prev);
        }}
      >
        <p>{data.electorate ? data.electorate : `No electorate provided`}</p>
        <p>{data.signups.length}</p>
      </div>
      {unpacked ? (
        <>
          {data.signups.map((el, i) => {
            return (
              <div
                style={{
                  width: "100%",
                  borderTop: "0.5px solid grey",
                }}
              >
                <p
                  style={{ marginLeft: 10 }}
                >{`${el.fName} ${el.lName} | ${el.email} | ${el.phone}`}</p>
              </div>
            );
          })}
        </>
      ) : null}
    </div>
  );
}
