//  REVISIT: everything. formatting of display screen, precise data being passed to Socket Context,
//  rendering of other streams ... schedule, maybe?

import React, { useContext, useState, useEffect } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import StreamWindow from "../components/conference-components/StreamWindow";
import { useWindowSize } from "../utils/Hooks";
import { listStreams, getAsset } from "../functions/StreamingFunctions";

import { GlobalContext } from "../App";

export default function Streams() {
  ////  INITS
  const GlobalCtxt = useContext(GlobalContext);
  const windowSize = useWindowSize();

  ////  STATES
  const [streams, setStreams] = useState(null);

  ////  FUNCTIONS
  const getStreams = async () => {
    try {
      const docs = await listStreams();
      if (docs?.data?.data && Array.isArray(docs.data.data)) {
        setStreams(docs.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getDummyAsset = async () => {
    try {
      const doc = await getAsset(
        "6nctoMfg0002Q7AaaRA01YL8iMnx988tNQ3v01CcxVJvk3U"
      );
      console.log(doc);
    } catch (err) {
      console.log(err);
      alert("Error retrieving content!");
    }
  };

  ////  EFFECTS
  useEffect(() => {
    getStreams();
  }, []);

  return (
    <ScreenContainer styling={{ background: GlobalCtxt.darkBg }}>
      <Navbar />
      <StreamWindow streams={streams} />
    </ScreenContainer>
  );
}
