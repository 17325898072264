import React, { createContext, useState, useEffect } from "react";

import Login from "./screens/Login";
import Register from "./screens/Register";
//import Landing from "./screens/Landing";
import Home from "./screens/Home";
import SecretarySuite from "./screens/SecretarySuite";
import Chat from "./screens/Chat";
//import LGAs from "./screens/LGAs";
import DeclarationHub from "./screens/DeclarationHub";
import Profile from "./screens/Profile";
import Upgrade from "./screens/Upgrade";
import Explainers from "./screens/Explainers";
import DecLanding from "./screens/DecLanding";
import SubSuccess from "./screens/SubSuccess";
import FMP from "./screens/FMP";
import Petition from "./screens/Petition";
import MarkerConfirmation from "./screens/MarkerConfirmation";
import FlyerSuccess from "./screens/FlyerSuccess";
import Streams from "./screens/Streams";
import Assets from "./screens/Assets";
import HowTo from "./screens/HowTo";
import Nomination from "./screens/Nomination";
//import PricingTest from "./screens/PricingTest";
import FlyerFlow from "./screens/FlyerFlow";
import LetterFlow from "./screens/LetterFlow";
import Studio from "./screens/Studio";
import CurrentCampaign from "./screens/CurrentCampaign";
//import FlyerLanding from "./screens/FlyerLanding";
import FlyersAdmin from "./screens/FlyersAdmin";
import NewsChuteSubmit from "./screens/NewsChuteSubmit";
import NewsChuteBrowse from "./screens/NewsChuteBrowse";
import NewsChuteLanding from "./screens/NewsChuteLanding";
import FullPost from "./screens/FullPost";
import Unsub from "./screens/Unsub";
import FullPoll from "./screens/FullPoll";
import ResolvePoll from "./screens/ResolvePoll";
//import LiveCall from "./screens/LiveCallJS";
import LiveCall from "./screens/LiveCallPrebuilt";
import ConferenceLobby from "./screens/ConferenceLobby";
import ConferenceSchedule from "./screens/ConferenceSchedule";
import WeSayNo from "./screens/WeSayNo";
import AffidavitFlow from "./screens/AffidavitFlow";
import PasswordReboot from "./screens/PasswordReboot";
import TestimonyAdmin from "./screens/TestimonyAdmin";

import { electorateList } from "./utils/Data";
import { getChannel, getChannelList } from "./functions/ChannelFunctions";
import {
  getPosts,
  getProposals,
  getHiddenPosts,
} from "./functions/ContentFunctions";
import { getPolls } from "./functions/PollFunctions";

import { LoadScript } from "@react-google-maps/api";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

export const GlobalContext = createContext(null);

////  GLOBAL VARS
const lib = ["places"];
const darkBg = "linear-gradient(to bottom, #0f2027, #203a43, #2c5364, #7b517a)";
const nationalId = "66e89ebb303f98e89f227a07";
const genericBoxShadow = "0 4px 8px rgba(0, 0, 0, 0.2)";
const colourScheme = {
  primaryGreen: "#525854",
};
const electoralCycle = 900000; // REVISIT: update value
const votingWindow = 300000; // REVISIT: update value

////  ROUTER
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/home/:channelId?/:channelName?/:reload?",
    element: <Home />,
  },
  {
    path: "/streams",
    element: <Streams />,
  },
  {
    path: "/assets/:channelId?/:playbackId?",
    element: <Assets />,
  },
  {
    path: "/initiate-reset",
    element: <PasswordReboot />,
  },
  {
    path: "/fmp/:userId?/:createdAt?",
    element: <FMP />,
  },
  {
    path: "/unsub/:id?",
    element: <Unsub />,
  },
  {
    path: "/login/:fromRegister?",
    element: <Login />,
  },
  {
    path: "/declarations/:pageState?",
    element: <DeclarationHub />,
  },
  {
    path: "/petition",
    element: <Petition />,
  },
  {
    path: "/letter-writer",
    element: <LetterFlow />,
  },
  {
    path: "/campaign/:campaignId?",
    element: <CurrentCampaign />,
  },
  {
    path: "/declarationproject",
    element: <DecLanding />,
  },
  {
    path: "/create-declaration/:testimonyId?",
    element: <AffidavitFlow />,
  },
  {
    path: "/testimony-admin",
    element: <TestimonyAdmin />,
  },
  {
    path: "/flyer-flow",
    element: <FlyerFlow />,
  },
  {
    path: "we-say-no",
    element: <WeSayNo />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/how-to",
    element: <HowTo />,
  },
  {
    path: "/marker-confirmation/:markerId",
    element: <MarkerConfirmation />,
  },
  {
    path: "/secretary",
    element: <SecretarySuite />,
  },
  {
    path: "/chat/:recipientId?/:recipientName?",
    element: <Chat />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/upgrade/:error?",
    element: <Upgrade />,
  },
  {
    path: "/flyer-success",
    element: <FlyerSuccess />,
  },
  {
    path: "/flyers-admin",
    element: <FlyersAdmin />,
  },
  {
    path: "/subscription-success/:confirmationCode",
    element: <SubSuccess />,
  },
  {
    path: "/conference-room/:channelId/:channelName",
    element: <ConferenceLobby />,
  },
  {
    path: "/conference-schedule/:channelId/:channelName",
    element: <ConferenceSchedule />,
  },
  {
    path: "/live-call/:roomName/:channelId/:channelName/:callType/:code?",
    element: <LiveCall />,
  },
  {
    path: "/studio",
    element: <Studio />,
  },
  {
    path: "/post/:postId/:fromChannelId?/:fromChannelName?",
    element: <FullPost />,
  },
  {
    path: "/news-chute",
    element: <NewsChuteLanding />,
  },
  {
    path: "/news-chute/submit",
    element: <NewsChuteSubmit />,
  },
  {
    path: "/news-chute/browse",
    element: <NewsChuteBrowse />,
  },
  {
    path: "/nomination/:channelId/:channelName",
    element: <Nomination />,
  },
  {
    path: "/explainers",
    element: <Explainers />,
  },
  {
    path: "/resolve-poll/:pollId?",
    element: <ResolvePoll />,
  },
  {
    path: "/poll/:pollId",
    element: <FullPoll />,
  },
]);

////    ////    ////    ////
export default function App() {
  ////  STATES
  const [userInfo, setUserInfo] = useState(null);
  const [globalCurrentChannel, setGlobalCurrentChannel] = useState(null);
  const [globalFeed, setGlobalFeed] = useState(null);
  const [globalChannelShortlist, setGlobalChannelShortlist] = useState([]);
  const [globalChannelLonglist, setGlobalChannelLonglist] = useState([]);
  const [globalFeedIndex, setGlobalFeedIndex] = useState("posts");
  const [globalChannelIndex, setGlobalChannelIndex] = useState(
    "aus_federal_electorates"
  );
  const [globalQueryIndexRanking, setGlobalQueryIndexRanking] =
    useState("most-recent");
  const [globalQueryIndexTime, setGlobalQueryIndexTime] = useState("90-days");
  const [globalCurrentUser, setGlobalCurrentUser] = useState(null);
  const [globalCurrentPost, setGlobalCurrentPost] = useState(null);
  //const [witnessId, setWitnessId] = useState("671349824a3f3798adecf823");

  ////  FUNCTIONS
  const localtoGlobal = () => {
    try {
      const token = Cookies.get("session-token");
      if (!token) {
        return;
      }
      const decodedToken = jwtDecode(token);

      let witnessId = localStorage.getItem("witnessId");
      if (witnessId) {
        try {
          witnessId = JSON.parse(witnessId);
        } catch (err) {
          console.error("Error parsing witnessId from local storage:", err);
          witnessId = "";
        }

        if (witnessId && typeof witnessId === "object" && witnessId?.expires) {
          const now = Date.now();
          const expires = parseInt(witnessId.expires, 10);
          if (isNaN(expires) || now > expires) {
            witnessId = "";
            localStorage.removeItem("witnessId");
          } else {
            witnessId = witnessId.id || "";
          }
        } else {
          witnessId = "";
        }
      } else {
        witnessId = "";
      }

      setUserInfo({
        tier: decodedToken.tier,
        admin: decodedToken.admin,
        // forging your token won't get you far; all requests to the server are authenticated
        likedIds: JSON.parse(localStorage.getItem("likedIds")),
        secretaryOf: JSON.parse(localStorage.getItem("secretaryOf")),
        blockList: JSON.parse(localStorage.getItem("blockList")),
        witnessId: witnessId,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const addChannelToSecretaryList = (channelName) => {
    return new Promise((resolve) => {
      const updatedChannels = [...userInfo.secretaryOf, channelName];

      setUserInfo((prevState) => {
        const newUserInfo = {
          ...prevState,
          secretaryOf: updatedChannels,
        };

        localStorage.setItem("secretaryOf", JSON.stringify(updatedChannels));

        resolve(newUserInfo);

        return newUserInfo;
      });
    });
  };

  const updateCurrentChannel = async (channelName) => {
    try {
      const res = await getChannel(channelName);
      setGlobalCurrentChannel(res.data);
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  const updateFeed = async () => {
    try {
      if (globalCurrentChannel?._id) {
        let docs;
        if (globalQueryIndexRanking === "hidden") {
          docs = await getHiddenPosts({
            channelId: globalCurrentChannel._id,
            skip: 0,
            limit: 12,
          });
        } else {
          if (globalFeedIndex === "posts") {
            docs = await getPosts({
              channelId: globalCurrentChannel._id,
              sortKey: globalQueryIndexRanking,
              timeframe: globalQueryIndexTime,
              skip: 0,
              limit: 12,
            });
          } else if (globalFeedIndex === "polls") {
            docs = await getPolls({
              channelId: globalCurrentChannel._id,
              channelName: globalCurrentChannel.name,
              skip: 0,
              limit: 12,
            });
          } else if (globalFeedIndex === "proposals") {
            //
          }
        }
        if (docs?.data) {
          setGlobalFeed(docs.data);
        } else {
          setGlobalFeed(null);
        }
      }
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  const expandFeed = async () => {
    try {
      if (globalCurrentChannel?._id) {
        let docs;
        if (globalQueryIndexRanking === "hidden") {
          docs = await getHiddenPosts({
            channelId: globalCurrentChannel._id,
            skip: globalFeed.length,
            limit: 12,
          });
        } else {
          if (globalFeedIndex === "posts") {
            docs = await getPosts({
              channelId: globalCurrentChannel._id,
              sortKey: globalQueryIndexRanking,
              timeframe: globalQueryIndexTime,
              skip: globalFeed.length,
              limit: 12,
            });
          } else if (globalFeedIndex === "polls") {
            docs = await getPolls({
              channelId: globalCurrentChannel._id,
              channelName: globalCurrentChannel.name,
              skip: globalFeed.length,
              limit: 12,
            });
          } else if (globalFeedIndex === "proposals") {
            //
          }
        }
        if (docs?.data) {
          setGlobalFeed((prev) => [...prev, ...docs.data]);
        }
      }
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  const initChannelLonglist = async () => {
    try {
      const docs = await getChannelList({
        skip: 0,
        limit: 30,
        type: globalChannelIndex,
      });

      if (Array.isArray(docs?.data)) {
        const channelNames = docs.data.map((item) => Object.values(item)[0]);
        setGlobalChannelLonglist(channelNames);
      }
    } catch (err) {
      console.log(err);
      alert("Error retrieving Channels! Please refresh the page to try again.");
    }
  };

  const updateFeedIndex = (index) => {
    try {
      setGlobalFeedIndex(index);
    } catch (err) {
      console.log(err);
    }
  };

  const updateChannelIndex = (index) => {
    try {
      setGlobalChannelIndex(index);
    } catch (err) {
      console.log(err);
    }
  };

  const updateQueryIndexRanking = (index) => {
    setGlobalQueryIndexRanking(index);
  };

  const updateQueryIndexTime = (index) => {
    setGlobalQueryIndexTime(index);
  };

  const updateCurrentUser = (user) => {
    setGlobalCurrentUser(user);
  };

  const updateCurrentPost = (post) => {
    setGlobalCurrentPost(post);
  };

  const updateWitnessId = (id) => {
    setUserInfo({ ...userInfo, witnessId: id });
    let localWitnessId = { id, expires: Date.now() + 604800000 };
    localStorage.setItem("witnessId", JSON.stringify(localWitnessId));
  };

  const handleNewLike = (id) => {
    let newArr = [...(userInfo.likedIds || [])];
    newArr.push(id);
    setUserInfo({ ...userInfo, likedIds: newArr });
    localStorage.setItem("likedIds", JSON.stringify(newArr));
  };

  const fetchGoogleKeys = async () => {
    try {
      //
    } catch (err) {
      console.log(err);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    localtoGlobal();
  }, []);

  useEffect(() => {
    if (globalChannelIndex) {
      if (globalChannelIndex === "aus_federal_electorates") {
        setGlobalChannelLonglist(electorateList);
      } else {
        initChannelLonglist();
      }
    }
  }, [globalChannelIndex]);

  useEffect(() => {
    if (globalQueryIndexRanking) {
      updateFeed();
    }
  }, [globalQueryIndexRanking]);

  useEffect(() => {
    if (globalQueryIndexTime) {
      updateFeed();
    }
  }, [globalQueryIndexTime]);

  useEffect(() => {
    if (globalFeedIndex) {
      updateFeed();
    }
  }, [globalFeedIndex]);

  useEffect(() => {
    if (globalFeed) {
      console.log(globalFeed);
    }
  }, [globalFeed]);

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_MAPS_KEY}
      libraries={lib}
    >
      <GlobalContext.Provider
        value={{
          localtoGlobal,
          darkBg,
          nationalId,
          genericBoxShadow,
          userInfo,
          colourScheme,
          electoralCycle,
          votingWindow,
          globalCurrentChannel,
          updateCurrentChannel,
          globalFeed,
          updateFeed,
          expandFeed,
          globalFeedIndex,
          updateFeedIndex,
          globalChannelIndex,
          updateChannelIndex,
          globalChannelShortlist,
          globalChannelLonglist,
          globalQueryIndexRanking,
          updateQueryIndexRanking,
          globalQueryIndexTime,
          updateQueryIndexTime,
          globalCurrentUser,
          updateCurrentUser,
          globalCurrentPost,
          updateCurrentPost,
          updateWitnessId,
          handleNewLike,
          addChannelToSecretaryList,
        }}
      >
        <RouterProvider router={router}></RouterProvider>
      </GlobalContext.Provider>
    </LoadScript>
  );
}
