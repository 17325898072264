import React, { useState, useRef, useEffect, useContext } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import {
  findElectorateFromPostcode,
  findMpFromElectorate,
} from "../functions/GeneralFunctions";
import { addToMailingList } from "../functions/UserFunctions";
import LetterTemplate from "../components/flyer-components/LetterTemplate";
import { sendCustomisedLetter } from "../functions/MailingFunctions";
import { useWindowSize } from "../utils/Hooks";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import EmailIcon from "@mui/icons-material/Email";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";

import { GlobalContext } from "../App";

const divStyle = {
  height: "100vh",
  width: "100vw",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: "10vh",
};

export default function LetterFlow() {
  ////  INITS
  const div0Ref = useRef();
  const div1Ref = useRef();
  const div2Ref = useRef();
  const navigate = useNavigate();

  ////  STATES
  const [currentDiv, setCurrentDiv] = useState(0);
  const [mpKnown, setMpKnown] = useState(null);
  const [linkedEmail, setLinkedEmail] = useState("");
  const [electorate, setElectorate] = useState("");
  const [electorateOptions, setElectorateOptions] = useState(null);
  const [mpName, setMpName] = useState("");
  const [postcode, setPostcode] = useState("");
  const [senderName, setSenderName] = useState("");
  const [senderAddress, setSenderAddress] = useState("");
  const [letterSent, setLetterSent] = useState(null);
  const [commsConsent, setCommsConsent] = useState(false);

  ////  FUNCTIONS
  const scrollToRef = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  };

  const checkElectorate = async () => {
    try {
      setElectorate("Fetching ...");
      const elec = await findElectorateFromPostcode(postcode);
      if (Array.isArray(elec)) {
        if (elec.length === 1) {
          setElectorate(elec[0].name);
        } else {
          const options = elec.map((option) => option.name);
          setElectorateOptions(options);
          setElectorate(null);
        }
      } else {
        setElectorate(null);
      }
    } catch (err) {
      console.log(err);
      setElectorate(null);
      alert("Postcode not recognised! Please double check and try again.");
    }
  };

  const checkMP = async () => {
    try {
      setMpName("Fetching ...");
      const mp = await findMpFromElectorate(electorate);
      if (mp?.data?.full_name) {
        setMpName(mp.data.full_name);
      } else if (mp?.data?.name) {
        setMpName(mp.data.name);
      }
    } catch (err) {
      console.log(err);
      alert(
        "Unable to retrieve MP data. Please refresh the page to try again."
      );
      setMpName("");
    }
  };

  const sendLetter = async () => {
    try {
      setLetterSent("sending");
      await sendCustomisedLetter({
        recipientAddress: linkedEmail
          ? DOMPurify.sanitize(linkedEmail.trim())
          : "",
        address: senderAddress ? DOMPurify.sanitize(senderAddress) : "",
        mpName: mpName ? DOMPurify.sanitize(mpName) : "",
        senderName: senderName ? DOMPurify.sanitize(senderName) : "",
      });
      setLetterSent(true);
      if (commsConsent) {
        await addToMailingList({
          email: DOMPurify.sanitize(linkedEmail.trim()),
        });
      }
    } catch (err) {
      console.log(err);
      alert("Error sending letter! Please refresh the page to try again.");
      setLetterSent(false);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (localStorage.getItem("electorate") && localStorage.getItem("email")) {
      setLinkedEmail(localStorage.getItem("email"));
    }
  }, []);

  useEffect(() => {
    // Scroll to the correct div based on currentDiv
    if (currentDiv === 0) scrollToRef(div0Ref);
    if (currentDiv === 1) scrollToRef(div1Ref);
    if (currentDiv === 2) scrollToRef(div2Ref);
  }, [currentDiv]);

  useEffect(() => {
    if (mpKnown) {
      setCurrentDiv(1);
    } else if (mpKnown === null) {
      setElectorate("");
      setElectorateOptions(null);
    }
  }, [mpKnown]);

  useEffect(() => {
    if (electorate && electorate !== "Fetching ...") {
      checkMP();
    }
  }, [electorate]);

  useEffect(() => {
    if (mpName && mpName !== "Fetching ...") {
      setTimeout(() => {
        setCurrentDiv(1);
      }, 1000);
    }
  }, [mpName]);

  return (
    <ScreenContainer
      styling={{
        paddingTop: 0,
        background: "linear-gradient(to bottom, #C5D2C0, #B0C2B4, #A2B5BA)",
      }}
    >
      <div ref={div0Ref} style={divStyle}>
        <p style={{ textAlign: "center", width: "98vw", maxWidth: 660 }}>
          The best way to put the politicians under the pump is to contact them
          personally. We encourage everyone to compose a letter and deliver it
          to their MP's office by email or, better yet, in person.
        </p>
        <p style={{ textAlign: "center", width: "98vw", maxWidth: 660 }}>
          For those who don't know where to start, we've created a tool that
          generates a personalised letter in a matter of seconds. The letter
          will be sent to you via email so that you can print, sign and deliver
          it in your own time.
        </p>
        <h2>Do you know your local MP?</h2>
        <div
          style={{
            width: "98vw",
            maxWidth: 400,
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          {mpKnown === false ? (
            <Tooltip title="Reset">
              <IconButton onClick={() => setMpKnown(null)}>
                <RestartAltIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          ) : (
            <>
              <Button
                variant="contained"
                sx={{ minWidth: "150px" }}
                onClick={() => setMpKnown(true)}
                color="success"
              >
                YES
              </Button>
              <Button
                variant="contained"
                sx={{ minWidth: "150px" }}
                onClick={() => setMpKnown(false)}
                color="success"
              >
                NO
              </Button>
            </>
          )}
        </div>
        {mpKnown === false ? (
          <hr style={{ width: "100%", maxWidth: 400 }}></hr>
        ) : null}
        {mpKnown === false ? (
          <div
            style={{
              width: "100vw",
              maxWidth: 400,
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth={true}
              placeholder="Enter your postcode"
              sx={{ backgroundColor: "whitesmoke", borderRadius: "4px" }}
              type="number"
              value={postcode}
              onChange={(e) => {
                if (e.target.value <= 9999) {
                  setPostcode(e.target.value);
                }
              }}
            />
            <Button
              variant="outlined"
              sx={{ marginLeft: "5px" }}
              onClick={checkElectorate}
            >
              SUBMIT
            </Button>
          </div>
        ) : null}
        {electorate || electorateOptions ? (
          <div
            style={{
              width: "100vw",
              maxWidth: 600,
              backgroundColor: "rgba(0,0,0,0.2)",
              padding: 10,
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            {electorate ? (
              <>
                <h4>
                  <span style={{ fontWeight: 400 }}>Electorate: </span>
                  {electorate}
                </h4>
                {mpName ? (
                  <h4>
                    <span style={{ fontWeight: 400 }}>Local MP: </span>
                    {mpName}
                  </h4>
                ) : null}
              </>
            ) : (
              <>
                <p style={{ fontSize: 14 }}>Select one:</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {electorateOptions.map((el, i) => {
                    return (
                      <Button
                        key={`${i}-${el}`}
                        sx={{ margin: "5px" }}
                        variant="contained"
                        onClick={() => setElectorate(el)}
                      >
                        {el}
                      </Button>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        ) : null}
      </div>
      <div ref={div1Ref} style={divStyle}>
        <h2>A few more details ...</h2>
        <div
          style={{
            width: "100vw",
            maxWidth: 600,
            backgroundColor: "whitesmoke",
            padding: 10,
            boxSizing: "border-box",
            border: "1px solid black",
            borderRadius: 8,
          }}
        >
          <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
            <h4 style={{ marginRight: 10 }}>To:</h4>
            <TextField
              fullWidth={true}
              value={mpName}
              onChange={(e) => {
                setMpName(e.target.value);
              }}
              placeholder="Recipient's name ..."
            />
          </div>
          <TextField
            fullWidth={true}
            sx={{ marginTop: "5px" }}
            placeholder="Your full name ..."
            value={senderName}
            onChange={(e) => {
              setSenderName(e.target.value);
            }}
          />
          <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
            <TextField
              fullWidth={true}
              sx={{ marginTop: "5px" }}
              placeholder="Your residential address ..."
              value={senderAddress}
              onChange={(e) => {
                setSenderAddress(e.target.value);
              }}
            />
            <IconButton
              sx={{ marginLeft: "5px" }}
              onClick={() => {
                alert(
                  "If you don't wish to disclose your full residential address, a suburb or street name will suffice.\n\nWe encourage you to include your full address if you feel comfortable doing so, as this strengthens the veracity of the letter."
                );
              }}
            >
              <HelpIcon />
            </IconButton>
          </div>
          <TextField
            fullWidth={true}
            sx={{ marginTop: "5px" }}
            placeholder="Your email address ..."
            value={linkedEmail}
            onChange={(e) => {
              setLinkedEmail(e.target.value);
            }}
          />
        </div>
        <br></br>
        <Button
          variant="contained"
          color="success"
          onClick={() => setCurrentDiv(2)}
        >
          GENERATE LETTER!
        </Button>
      </div>
      <div
        ref={div2Ref}
        style={{ ...divStyle, backgroundColor: "rgba(0,0,0,0.1)" }}
      >
        {letterSent === "sending" ? (
          <>
            <br></br>
            <CircularProgress />
            <h4 style={{ textAlign: "center" }}>
              Sending your personalised email ...
            </h4>
          </>
        ) : letterSent ? (
          <>
            <br></br>
            <h1 style={{ textAlign: "center" }}>Success!</h1>
            <h4 style={{ textAlign: "center", width: "98vw", maxWidth: 660 }}>
              Keep an eye on your inbox. Your letter template should arrive
              shortly (if you can't see it, check your spam folder).
            </h4>
            <hr style={{ width: "98%" }}></hr>
            <p style={{ textAlign: "center", width: "98vw", maxWidth: 660 }}>
              We're currently spreading this message with the help of a
              nationwide team of volunteers. Together we've delivered flyers to
              more than a million Australian households since April of this
              year!
            </p>
            <p style={{ textAlign: "center", width: "98vw", maxWidth: 660 }}>
              If you're interested in joining our ever-growing network, click
              the button below to learn more!
            </p>
            <br></br>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                navigate("/flyer-flow");
              }}
            >
              GET INVOLVED
            </Button>
          </>
        ) : letterSent === false ? (
          <>
            <br></br>
            <h1 style={{ textAlign: "center" }}>Oops ...</h1>
            <h4 style={{ textAlign: "center" }}>
              An error occurred while sending your personalised letter. Please
              check your internet connection and try again.
            </h4>
          </>
        ) : (
          <>
            <div
              style={{
                width: "100%",
                maxWidth: 768,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 5,
                boxSizing: "border-box",
              }}
            >
              <h3>Your letter:</h3>
              <p style={{ opacity: 0.5 }}>Scrollable</p>
            </div>
            <div
              style={{
                width: "100vw",
                maxHeight: "50vh",
                maxWidth: 768,
                backgroundColor: "whitesmoke",
                padding: 5,
                boxSizing: "border-box",
                overflowY: "auto",
                border: "1px solid black",
                borderRadius: 2,
              }}
            >
              <LetterTemplate
                address={senderAddress}
                recipientName={mpName}
                senderName={senderName}
                repType="Member of Parliament"
                todaysDate={Date.now().toLocaleString()}
              />
            </div>
            <p style={{ textAlign: "center", fontWeight: "bold" }}>
              Click the button below to get this template sent straight to your
              email inbox!
            </p>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 5,
              }}
            >
              <Checkbox
                checked={commsConsent}
                onChange={(e) => {
                  setCommsConsent(e.target.checked);
                }}
              />
              <p style={{ fontSize: 14, opacity: 0.75 }}>
                Send me occasional updates regarding this campaign and others
                like it.
              </p>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{ marginTop: "5px" }}
                startIcon={<EmailIcon />}
                onClick={sendLetter}
              >
                SEND ME THE LETTER!
              </Button>
            </div>
          </>
        )}
      </div>
    </ScreenContainer>
  );
}

/*

*/
