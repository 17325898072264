//  REVISIT: unfinished

import React, { useState, useEffect, useRef } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import DynamicFrame from "../components/common-components/DynamicFrame";
import GenericMain from "../components/common-components/GenericMain";
import GenericSidebar from "../components/common-components/GenericSidebar";
import GenericTextField from "../components/common-components/GenericTextField";
import SignInPrompt from "../components/common-components/SignInPrompt";
import { useWindowSize } from "../utils/Hooks";
import { submitNCDoc } from "../functions/ContentFunctions";
import { getArchivedWebpage } from "../functions/NewsFunctions";
import { validateDescription } from "../functions/GeneralFunctions";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import StayCurrentLandscapeIcon from "@mui/icons-material/StayCurrentLandscape";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";

import "../App.css";
import "animate.css";

export default function NewsChuteSubmit() {
  ////  INITS
  const windowSize = useWindowSize();
  const iframeRef = useRef();
  const navigate = useNavigate();

  ////  STATES
  const [mobileAspectRatio, setMobileAspectRatio] = useState(true);
  const [urlSubmit, setUrlSubmit] = useState("");
  const [urlDraft, setUrlDraft] = useState("");
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState("current-news");
  const [description, setDescription] = useState("");
  const [submitted, setSubmitted] = useState(false);

  ////  FUNCTIONS
  const submitDraftUrl = async () => {
    try {
      setLoading(true);
      const newUrl = await getArchivedWebpage(urlDraft);
      if (newUrl) {
        setUrlSubmit(newUrl);
      } else {
        setUrlSubmit(urlDraft);
      }
    } catch (err) {
      console.log(err);
      alert("Error!");
      setLoading(false);
    }
  };

  const handleDescriptionUpdate = (text) => {
    setDescription(text);
  };

  const handleUpload = async () => {
    try {
      if (!description) {
        alert("Please enter a short description.");
        return;
      }
      const isValid = validateDescription(description);
      if (!isValid) {
        alert(
          "Please enter a description that doesn't contain any unusual characters."
        );
        return;
      }
      await submitNCDoc({
        url: urlSubmit,
        description: DOMPurify.sanitize(description),
        type: category,
      });
      setSubmitted(true);
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  const handleLoad = () => {
    setLoading(false);
  };

  const handleError = () => {
    console.log("ERROR");
  };

  ////  EFFECTS
  useEffect(() => {
    const iframe = iframeRef.current;

    if (iframe) {
      iframe.addEventListener("load", handleLoad);
      iframe.addEventListener("error", handleError);
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener("load", handleLoad);
        iframe.removeEventListener("error", handleError);
      }
    };
  }, []);

  useEffect(() => {
    setUrlDraft("");
    setLoading(false);
  }, [urlSubmit]);

  return (
    <ScreenContainer>
      <Navbar />
      <div style={{ width: "96vw" }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate("/news-chute")}
          color="info"
          sx={{ marginBottom: "5px" }}
          size="small"
        >
          BACK
        </Button>
      </div>
      <DynamicFrame
        windowSize={windowSize}
        styling={{
          height: windowSize.width > 768 ? "80vh" : "auto",
          flexDirection: windowSize.width > 768 ? "row" : "column",
        }}
      >
        <GenericSidebar
          windowSize={windowSize}
          styling={{
            width: mobileAspectRatio
              ? windowSize.width * 0.65
              : windowSize.width * 0.1,
            alignItems: "center",
            minWidth: windowSize.width > 768 ? null : "100vw",
          }}
        >
          {windowSize.width > 768 ? (
            <div
              style={{
                width: "100%",
                maxWidth: 350,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                startIcon={
                  mobileAspectRatio ? (
                    <StayCurrentLandscapeIcon />
                  ) : (
                    <StayCurrentPortraitIcon />
                  )
                }
                onClick={() => setMobileAspectRatio((prev) => !prev)}
                variant="contained"
              >
                {mobileAspectRatio ? `VIEW LANDSCAPE` : `VIEW PORTRAIT`}
              </Button>
            </div>
          ) : null}

          {!mobileAspectRatio ? null : (
            <>
              <div
                style={{
                  width: "100%",
                  padding: "40px 10px 40px 10px",
                  marginTop: 40,
                  marginBottom: 40,
                  backgroundColor: "#0f2027",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {loading ? (
                  <>
                    <p style={{ textAlign: "center", color: "white" }}>
                      Generating an embeddable version of your webpage. This may
                      take up to a minute ...
                    </p>
                    <CircularProgress />
                  </>
                ) : (
                  <>
                    {urlSubmit ? (
                      <>
                        {submitted ? (
                          <>
                            <h2 style={{ color: "limegreen" }}>Submitted!</h2>
                            <p style={{ textAlign: "center", color: "white" }}>
                              Your document has been submitted for approval.
                            </p>
                            <Button
                              onClick={() => {
                                navigate("/news-chute/browse");
                              }}
                            >
                              BROWSE THE NEWS CHUTE
                            </Button>
                          </>
                        ) : (
                          <>
                            <div style={{ width: "100%" }}>
                              <IconButton
                                onClick={() => {
                                  setUrlSubmit("");
                                }}
                              >
                                <ArrowBackIcon sx={{ color: "grey" }} />
                              </IconButton>
                            </div>
                            <h3 style={{ color: "lightgrey" }}>What's next?</h3>
                            <p
                              style={{
                                textAlign: "center",
                                color: "lightgrey",
                                maxWidth: 600,
                              }}
                            >
                              If the content window displays an embedded version
                              of your webpage, congrats! You've successfully
                              generated an article for the News Chute.
                            </p>
                            <p
                              style={{
                                textAlign: "center",
                                color: "lightgrey",
                                maxWidth: 600,
                              }}
                            >
                              Every article that is submitted must be approved
                              by a moderator before becoming visible on this
                              website. We're committed to approving every
                              article that doesn't include spam or pornographic
                              imagery.
                            </p>
                            <p
                              style={{
                                textAlign: "center",
                                color: "orange",
                                maxWidth: 600,
                              }}
                            >
                              If nothing has appeared after about a minute, the
                              website you're attempting to embed may not be
                              compatible with the News Chute feature.{" "}
                              <span style={{ fontWeight: 600 }}>TIP:</span>{" "}
                              Links may work better if they start with 'www.'
                              rather than 'http://' or 'https://'.
                            </p>

                            <GenericTextField
                              placeholder="Why do you find this interesting?"
                              rows={3}
                              charLimit={250}
                              divStyling={{
                                backgroundColor: "whitesmoke",
                                borderRadius: "4px",
                              }}
                              buttonVariant="hidden"
                              handleChange={handleDescriptionUpdate}
                            />

                            <br></br>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <p style={{ color: "white", marginRight: "5px" }}>
                                Category:
                              </p>
                              <Select
                                sx={{ backgroundColor: "whitesmoke" }}
                                onChange={(e) => setCategory(e.target.value)}
                                value={category}
                                size="small"
                              >
                                <MenuItem value="current-news">
                                  Current news
                                </MenuItem>
                                <MenuItem value="archived-news">
                                  Archived news
                                </MenuItem>
                                <MenuItem value="studies">Studies</MenuItem>
                              </Select>
                            </div>
                            <br></br>
                            <Button
                              variant="contained"
                              color="success"
                              size="large"
                              onClick={handleUpload}
                            >
                              SUBMIT!
                            </Button>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {localStorage.getItem("electorate") ? (
                          <>
                            <p
                              style={{
                                textAlign: "center",
                                color: "lightgrey",
                              }}
                            >
                              Paste your link into the field below and click{" "}
                              <span style={{ fontWeight: 600 }}>
                                PREPARE CHUTE!
                              </span>
                            </p>
                            <TextField
                              placeholder="Enter URL ..."
                              fullWidth={true}
                              value={urlDraft}
                              onChange={(e) => {
                                setUrlDraft(e.target.value);
                              }}
                              sx={{
                                maxWidth: "600px",
                                backgroundColor: "whitesmoke",
                                borderRadius: "4px",
                                margin: "5px 0px 10px 0px",
                              }}
                              inputProps={{
                                style: {
                                  textAlign: "center",
                                },
                              }}
                            />
                            <Button variant="outlined" onClick={submitDraftUrl}>
                              PREPARE CHUTE!
                            </Button>
                          </>
                        ) : (
                          <SignInPrompt action="posting" />
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
              <div style={{ width: "98%", color: "lightgrey" }}>
                <h3>How does the News Chute work?</h3>
                <p>
                  When you submit a link to a webpage, we attempt to embed that
                  webpage on our site using something called a{" "}
                  <span style={{ fontWeight: "bold" }}>sandboxed iframe.</span>
                </p>
                <p>
                  This approach prevents the embedded webpage from running any
                  kind of program that could potentially be dangerous to your
                  device. Due to this important safeguard, the embedded content
                  may look different to how it looks on the original website.
                </p>
                <p>
                  It's not uncommon for content to only load partially.{" "}
                  <span style={{ fontWeight: "bold" }}>
                    Some websites may not load at all.
                  </span>{" "}
                  If your website isn't loading, we suggest searching for an
                  alternative source of the same information.
                </p>
              </div>
            </>
          )}
        </GenericSidebar>
        <GenericMain
          styling={{
            backgroundColor: "white",
            alignItems: "center",
            justifyContent: "center",
            minWidth: windowSize.width > 768 ? null : "100vw",
            minHeight: windowSize.width > 768 ? null : "80vh",
          }}
        >
          <iframe
            src={urlSubmit}
            style={{
              width: "100%",
              height: "100%",
              minHeight: "75vh",
            }}
            sandbox
            referrerPolicy="no-referrer"
            ref={iframeRef}
          />
        </GenericMain>
      </DynamicFrame>
    </ScreenContainer>
  );
}
