import React, { useState, useEffect } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import { getTestimoniesByChar } from "../functions/AffidavitFunctions";
import { bulkSendColdEmails } from "../functions/MailingFunctions";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { replaceAndSanitize } from "../functions/GeneralFunctions";

const alphabetRef = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];

export default function TestimonyAdmin() {
  ////  STATES
  const [pw, setPw] = useState("");
  const [letterRef, setLetterRef] = useState("a");
  const [testimonies, setTestimonies] = useState(null);

  ////  FUNCTIONS
  const fetchTestimonies = async () => {
    try {
      const docs = await getTestimoniesByChar(letterRef, pw);
      if (docs?.data) {
        setTestimonies(docs.data);
      }
    } catch (err) {
      console.log(err);
      alert("Error!");
    }
  };

  const handleSend = async () => {
    try {
      await bulkSendColdEmails(testimonies);
      alert("Success!");
      setTestimonies(null);
    } catch (err) {
      console.log(err);
      alert("Error!");
    }
  };

  return (
    <ScreenContainer>
      <Navbar />
      <div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
        <select
          value={letterRef}
          onChange={(e) => {
            setLetterRef(e.target.value);
          }}
          style={{ marginRight: 10, height: "100%" }}
        >
          {alphabetRef.map((el, i) => {
            return (
              <option value={el} key={`${i}_${el}`}>
                {el}
              </option>
            );
          })}
        </select>
        <TextField
          value={pw}
          onChange={(e) => {
            setPw(e.target.value);
          }}
          sx={{ backgroundColor: "whitesmoke", borderRadius: "4px" }}
        />
      </div>
      {testimonies ? (
        <Button variant="contained" color="warning" onClick={handleSend}>
          SEND
        </Button>
      ) : (
        <Button variant="contained" size="small" onClick={fetchTestimonies}>
          RUN
        </Button>
      )}

      <div
        style={{
          width: "100%",
          maxWidth: 900,
          backgroundColor: "rgba(0,0,0,0.2)",
          height: "100vw",
          overflowY: "auto",
          border: "1px solid black",
          padding: 10,
          boxSizing: "border-box",
          marginTop: 20,
        }}
      >
        {Array.isArray(testimonies) &&
          testimonies.map((el, i) => {
            return (
              <div
                key={el._id}
                style={{
                  width: "100%",
                  padding: 5,
                  boxSizing: "border-box",
                  backgroundColor: el?.opened ? "lightgreen" : "whitesmoke",
                  marginBottom: 5,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h4>{el.email}</h4>
                </div>
                <p>{replaceAndSanitize(el.text)}</p>
              </div>
            );
          })}
      </div>
    </ScreenContainer>
  );
}
