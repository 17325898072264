import React, { useState, useRef, useEffect } from "react";

import SingleReel from "./SingleReel";
import { formatNumberWithCommas } from "../../functions/GeneralFunctions";

import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";

import "animate.css";

export default function AutoReel({ countTo, height, handleClick }) {
  ////  INITS
  const containerRef = useRef();

  ////  STATES
  const [componentsArray, setComponentsArray] = useState(null);
  const [containerSize, setContainerSize] = useState(null);
  const [animationComplete, setAnimationComplete] = useState(0);
  const [nextGoal, setNextGoal] = useState(0);

  ////  FUNCTIONS
  const updateAnimation = () => {
    setAnimationComplete((prev) => prev + 1);
  };

  ////  EFFECTS
  useEffect(() => {
    if (countTo) {
      let arr = [];
      const countToStr = countTo.toString();
      for (let i = 0; i < countToStr.length; i++) {
        arr.push(parseInt(countToStr.charAt(i)));
      }
      setComponentsArray(arr);

      let roundedValue;
      if (countTo < 100) {
        roundedValue = Math.ceil(countTo / 10) * 10;
      } else if (countTo < 1000) {
        roundedValue = Math.ceil(countTo / 100) * 100;
      } else if (countTo < 10000) {
        roundedValue = Math.ceil(countTo / 1000) * 1000;
      } else if (countTo < 50000) {
        roundedValue = Math.ceil(countTo / 5000) * 5000;
      } else {
        roundedValue = Math.ceil(countTo / 10000) * 10000;
      }
      setNextGoal(roundedValue);
    }
  }, [countTo]);

  useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        setContainerSize({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight,
        });
      }
    };

    updateSize();
    window.addEventListener("resize", updateSize);

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (animationComplete && animationComplete === componentsArray.length) {
      setAnimationComplete(99);
    }
  }, [animationComplete]);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "auto",
          display: "flex",
          justifyContent: "space-evenly",
          fontFamily: "'Roboto', sans-serif",
          color: "#D1D5DB",
        }}
        ref={containerRef}
      >
        {componentsArray &&
          componentsArray.map((num, index) => (
            <SingleReel
              targetNumber={num}
              key={index}
              pos={index}
              tileWidth={containerSize.width / componentsArray.length - 5}
              height={height}
              leftToRight={true}
              updateAnimation={updateAnimation}
              animationComplete={animationComplete}
            />
          ))}
      </div>
      {animationComplete && animationComplete === 99 ? (
        <div
          style={{ width: "100%", paddingTop: "10px" }}
          className="animate__animated animate__fadeIn"
        >
          <br></br>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: handleClick ? "space-between" : "space-evenly",
              alignItems: "center",
            }}
          >
            <p style={{ fontWeight: 300, fontSize: 14, color: "#E5E7EB" }}>
              Next goal:{" "}
              <span style={{ fontWeight: 600, color: "#F3F4F6" }}>
                {formatNumberWithCommas(nextGoal)}
              </span>
            </p>
            {handleClick ? (
              <Button
                variant="contained"
                size="small"
                onClick={() => handleClick.func()}
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "#F9FAFB",
                  backgroundColor: "#10B981",
                  borderRadius: "8px",
                  textTransform: "none",
                  padding: "6px 12px",
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: "#059669",
                  },
                }}
              >
                {handleClick.label}
              </Button>
            ) : null}
          </div>

          <div
            style={{
              width: "100%",
              padding: 5,
              boxSizing: "border-box",
              /*border: "1px solid #374151",*/
              borderRadius: "8px",
              backgroundColor: "#1F2937",
            }}
          >
            <LinearProgress
              value={(countTo / nextGoal) * 100}
              variant="determinate"
              sx={{
                height: "10px",
                borderRadius: "5px",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#10B981",
                },
              }}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}
