import React, { useState, useEffect } from "react";

export default function TypeEffectText({ text, textColor }) {
  const [displayedText, setDisplayedText] = useState("");

  const typeText = (render) => {
    setDisplayedText(render);
  };

  // SOLUTION A
  useEffect(() => {
    let timer = 1;
    for (let i = 0; i <= text.length + 10; i += 10) {
      timer++;
      setTimeout(() => {
        typeText(text.substring(0, i));
      }, timer * 100);
    }
  }, [text]);

  return (
    <div
      style={{
        padding: 5,
        boxSizing: "border-box",
        backgroundColor: "rgba(0,0,0,0.5)",
        borderRadius: 2,
      }}
    >
      <p style={{ opacity: 0.5, color: textColor, fontStyle: "italic" }}>
        AI summary:
      </p>
      <p style={{ color: textColor }}>{displayedText}</p>
    </div>
  );
}
