import React, { useState, useRef, useEffect, useContext } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import GenericModal from "../components/common-components/GenericModal";
import FlyerDiv1 from "../components/flyer-components/FlyerDiv1";
import FlyerDiv3 from "../components/flyer-components/FlyerDiv3";
import PreviewText from "../components/flyer-components/PreviewText";
import DynamicMap from "../components/common-components/DynamicMap";
import { useWindowSize } from "../utils/Hooks";

import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";

import { GlobalContext } from "../App";

const divStyle = {
  height: "100vh",
  width: "100vw",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: "12vh",
};

export default function FlyerFlow() {
  ////  INITS
  const div0Ref = useRef();
  const div1Ref = useRef();
  const div2Ref = useRef();
  const div3Ref = useRef();
  const windowSize = useWindowSize();
  const GlobalCtxt = useContext(GlobalContext);

  ////  STATE
  const [currentDiv, setCurrentDiv] = useState(0);
  const [div1Modal, setDiv1Modal] = useState(false);
  const [linkedEmail, setLinkedEmail] = useState(null);
  const [mapHelperModal, setMapHelperModal] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  ////  FUNCTIONS
  const scrollToRef = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  };

  const updateLinkedEmail = (email) => {
    setLinkedEmail(email);
    setCurrentDiv(2);
  };

  ////  EFFECTS
  useEffect(() => {
    if (localStorage.getItem("electorate") && localStorage.getItem("email")) {
      setLinkedEmail(localStorage.getItem("email"));
    }
  }, []);

  useEffect(() => {
    // Scroll to the correct div based on currentDiv
    if (currentDiv === 0) scrollToRef(div0Ref);
    if (currentDiv === 1) {
      if (linkedEmail) {
        scrollToRef(div2Ref);
      } else {
        scrollToRef(div1Ref);
      }
    }
    if (currentDiv === 2) scrollToRef(div2Ref);
    if (currentDiv === 3) scrollToRef(div3Ref);
  }, [currentDiv]);

  return (
    <ScreenContainer
      styling={{
        paddingTop: 0,
        background: "linear-gradient(to bottom, #C5D2C0, #B0C2B4, #A2B5BA)",
      }}
    >
      <GenericModal open={div1Modal} handleClose={() => setDiv1Modal(false)}>
        <p>
          Authenticating submissions is essential to upholding the integrity of
          this exercise.
        </p>
        <p>
          If we didn't require authentication, bad actors could undermine the
          Interactive Map by covering it with false delivery zones.
        </p>
        <p>
          Linking each delivery zone to a verified email address ensures that
          such interference is kept to a minimum.
        </p>
      </GenericModal>
      <GenericModal
        open={mapHelperModal}
        handleClose={() => setMapHelperModal(false)}
      >
        <h4 style={{ textAlign: "center" }}>How to use the Interactive Map:</h4>
        <p>
          Each marker on the Map represents a registered delivery zone. You can
          register your own zone by the following these simple steps:
        </p>
        <ol>
          <li>
            Click the <span style={{ fontWeight: 600 }}>'ADD MARKER'</span>{" "}
            button to place a marker on the map (it will appear in the middle of
            the map by default).
          </li>
          <li>
            Drag the marker to the approximate location of where you intend to
            deliver.
          </li>
          <li>
            Zoom in on the map, adjusting the marker until it is positioned
            precisely at your desired location.
          </li>
          <li>
            Click the <span style={{ fontWeight: 600 }}>'ADD RADIUS'</span>{" "}
            button. A blue bubble will appear around your marker representing
            the approximate size of your delivery zone.
          </li>
          <li>
            Shrink or expand your zone's radius by updating the number field
            above the map (maximum radius: 2,000 metres).
          </li>
          <li>
            Once you're happy with the size and position of your zone, click the
            <span style={{ fontWeight: 600 }}>'SUBMIT MARKER'</span> button.
          </li>
        </ol>
        <p>
          If you're a registered PhaseChamber user, your marker will appear
          instantly when you reload the map. If you're not a registered user,
          you will need to authenticate your marker by clicking the link sent to
          the address provided above.
        </p>
        <p>
          <span style={{ fontWeight: 600 }}>NOTE: </span>Maximum of five
          delivery zones permitted per user.
        </p>
      </GenericModal>
      <Drawer
        anchor="bottom"
        open={drawerOpen}
        transitionDuration={1000}
        onClose={() => {
          setDrawerOpen((el) => !el);
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minHeight: "50vh",
            padding: "4px 8px 24px 8px",
            boxSizing: "border-box",
          }}
        >
          <PreviewText />
          <Button onClick={() => setDrawerOpen(false)}>GET STARTED!</Button>
        </div>
      </Drawer>
      <Navbar />
      <div ref={div0Ref} style={divStyle}>
        <h2>Help us spread the word!</h2>
        {windowSize.width <= 768 ? (
          <Button onClick={() => setDrawerOpen(true)}>LEARN MORE</Button>
        ) : null}
        {windowSize.width <= 768 ? (
          <img
            src="https://storage.googleapis.com/bucket-775/flyer2_artwork_bw.png"
            style={{ height: 420, width: 296 }}
          />
        ) : (
          <div
            style={{
              width: "100vw",
              maxWidth: 900,
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <img
              src="https://storage.googleapis.com/bucket-775/flyer2_artwork_bw.png"
              style={{ height: 525, width: 370 }}
            />
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignItems: "center",
                padding: 20,
                boxSizing: "border-box",
                backgroundColor: "rgba(255,255,255,0.5)",
                textAlign: "center",
              }}
            >
              <PreviewText />
            </div>
          </div>
        )}

        <Button
          variant="contained"
          sx={{ marginTop: "10px" }}
          onClick={() => {
            setCurrentDiv(1);
          }}
        >
          I WANT TO HELP!
        </Button>
      </div>
      <div ref={div1Ref} style={divStyle}>
        <FlyerDiv1
          updateDiv1Modal={() => {
            setDiv1Modal(true);
          }}
          updateLinkedEmail={updateLinkedEmail}
          linkedEmail={linkedEmail}
        />
      </div>
      <div ref={div2Ref} style={divStyle}>
        <h2>Set your delivery zone</h2>
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            setMapHelperModal(true);
          }}
          style={{ marginBottom: 10 }}
        >
          Need help?
        </a>
        <div
          style={{
            width: "100vw",
            maxWidth: 768,
            backgroundColor: "rgba(0,0,0,0.8)",
            padding: 10,
            boxSizing: "border-box",
          }}
        >
          {linkedEmail ? (
            <DynamicMap
              channelId={GlobalCtxt.nationalId}
              fixedClass="we-say-no"
              maxRadius={2000}
              linkedEmail={linkedEmail}
            />
          ) : (
            <p style={{ textAlign: "center", color: "lightgrey" }}>
              You must select a verification method before setting your delivery
              zone.
            </p>
          )}
        </div>
        <br></br>
        {linkedEmail ? (
          <p style={{ opacity: 0.5 }}>Scroll down when finished.</p>
        ) : null}
      </div>
      <div ref={div3Ref} style={divStyle}>
        <FlyerDiv3 />
      </div>
    </ScreenContainer>
  );
}
