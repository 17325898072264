import React, { useState, useEffect } from "react";

import CircularProgress from "@mui/material/CircularProgress";

import { getAsset } from "../../functions/StreamingFunctions";

export default function RecordingPreview({ data, handleClick, selectedAsset }) {
  ////  STATES
  const [asset, setAsset] = useState("loading");
  const [highlighted, setHighlighted] = useState(false);

  ////  FUNCTIONS
  const fetchAsset = async () => {
    try {
      const res = await getAsset(data);
      if (res?.data?.data) {
        setAsset(res.data.data);
      } else {
        setAsset(null);
      }
    } catch (err) {
      setAsset(null);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    fetchAsset();
  }, []);

  useEffect(() => {
    if (selectedAsset) {
      if (selectedAsset === asset) {
        console.log(asset);
        setHighlighted(true);
      } else {
        setHighlighted(false);
      }
    }
  }, [selectedAsset]);

  return (
    <>
      {!asset ? null : asset === "loading" ? (
        <CircularProgress />
      ) : (
        <div
          key={data}
          style={{
            fontSize: 14,
            padding: "10px",
            marginBottom: "10px",
            border: "1px solid green",
            borderRadius: "5px",
            backgroundColor: highlighted ? "gold" : "lightgrey",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => handleClick(asset)}
        >
          <span
            style={{
              display: "inline-block",
              width: "20px",
              height: "20px",
              marginRight: "10px",
              backgroundColor: "green",
              borderRadius: "50%",
              textAlign: "center",
              lineHeight: "24px",
              fontWeight: "bold",
            }}
          ></span>
          {data.substring(0, 16) + " ..."}
        </div>
      )}
    </>
  );
}
