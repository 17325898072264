//  REVISIT: MeetingPreview, especially JOIN button

import React, { useState, useEffect, useContext } from "react";

import { replaceAndSanitize } from "../../functions/GeneralFunctions";
import Countdown from "../common-components/Countdown";
import {
  changeEventPrivacy,
  createMeetingCode,
} from "../../functions/ConferenceFunctions";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import GroupsIcon from "@mui/icons-material/Groups";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import SchoolIcon from "@mui/icons-material/School";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CircularProgress from "@mui/material/CircularProgress";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { GlobalContext } from "../../App";

export default function MeetingAdminPreview({ data }) {
  ////  INITS
  const navigate = useNavigate();
  const GlobalCtxt = useContext(GlobalContext);

  ////  STATES
  const [timeStr, setTimeStr] = useState("");
  const [modStr, setModStr] = useState("");
  const [meetingLive, setMeetingLive] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [privacy, setPrivacy] = useState(null);
  const [accessLinks, setAccessLinks] = useState(data.codes);
  const [creatingLink, setCreatingLink] = useState(false);
  const [copied, setCopied] = useState(false);

  ////  FUNCTIONS
  const initStrings = () => {
    try {
      if (data.time) {
        const toDate = new Date(data.time);
        const str = toDate.toLocaleString();
        setTimeStr(str);
        if (data.time <= Date.now()) {
          setMeetingLive(true);
        }
      }
      if (data.moderators) {
        let mods = "";
        if (data.moderators.length === 1) {
          mods = data.moderators[0].username;
        } else {
          mods = data.moderators.map((el) => el.username).join(", ");
        }
        setModStr(mods);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePrivacyChange = async () => {
    try {
      await changeEventPrivacy({ meetingId: data._id, privacy: !privacy });
      setPrivacy((prev) => !prev);
    } catch (err) {
      console.log(err);
      alert("Error modifying privacy settings. Please try again later.");
    }
  };

  const generateAccessLink = async () => {
    try {
      setCreatingLink(true);
      const code = await createMeetingCode({
        channelId: data.channelId,
        channelName: data.channelName,
        roomName: data.roomName,
      });
      setAccessLinks((prev) => [...prev, code.data]);
      setCreatingLink(false);
    } catch (err) {
      console.log(err);
      alert("Error generating free access link. Please try again later.");
      setCreatingLink(false);
    }
  };

  const handleCopy = async (link, code) => {
    try {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          setCopied(code);
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  /*
  REVISIT: add moderators
  const modSubmit = async () => {
    try {
      await addModerator({ username: modName, meetingId: data._id });
      setAddingMod("submitted");
      setModStr((prev) => prev + `, ${modName}`);
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };
  */

  ////  EFFECTS
  useEffect(() => {
    initStrings();
  }, []);

  useEffect(() => {
    if (data.privacy) {
      setPrivacy(data.privacy);
    } else {
      setPrivacy(false);
    }
  }, [data.privacy]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "whitesmoke",
        border: "1px solid black",
        borderRadius: 4,
        padding: 8,
        boxSizing: "border-box",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        marginBottom: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <CalendarMonthIcon size="small" sx={{ marginRight: "8px" }} />
          <p style={{ fontWeight: 500, fontSize: 14 }}>{timeStr}</p>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p style={{ marginRight: 8, color: "grey", fontSize: 14 }}>
            {data.callType.toUpperCase()}
          </p>
          {data.callType === "meeting" ? (
            <GroupsIcon />
          ) : data.callType === "broadcast" ? (
            <PodcastsIcon />
          ) : (
            <SchoolIcon />
          )}
        </div>
      </div>
      <hr style={{ width: "100%" }}></hr>
      <p style={{ marginBottom: 0 }}>
        Event:{" "}
        <span style={{ fontWeight: 600 }}>
          {replaceAndSanitize(data.eventName)}
        </span>
      </p>
      {data.duration === 21600000 ? (
        <p style={{ marginBottom: 0 }}>
          Duration: <span style={{ fontWeight: 600 }}>6 hours</span>
        </p>
      ) : !isNaN(data.duration) && data.duration < 21600000 ? (
        <p style={{ marginBottom: 0 }}>
          Duration:{" "}
          <span style={{ fontWeight: 600 }}>
            {parseInt(data.duration) / 60000} minutes
          </span>
        </p>
      ) : null}
      <p>
        Hosted by: <span style={{ fontWeight: 600 }}>{modStr}</span>
      </p>
      {!expanded ? (
        <Button
          variant="outlined"
          onClick={() => setExpanded(true)}
          size="small"
        >
          VIEW OPTIONS
        </Button>
      ) : (
        <Button
          variant="outlined"
          onClick={() => setExpanded(false)}
          size="small"
        >
          HIDE OPTIONS
        </Button>
      )}
      {expanded ? (
        <>
          <div
            style={{
              width: "100%",
              borderBottom: "0.5px solid grey",
            }}
          >
            <p>
              <span style={{ fontWeight: 600 }}>Visibility: </span>
              {privacy ? `Private` : `Public`}
              <span>
                <IconButton
                  sx={{ marginLeft: "5px" }}
                  onClick={handlePrivacyChange}
                >
                  <ChangeCircleIcon sx={{ color: "dodgerblue" }} />
                </IconButton>
              </span>
            </p>
          </div>
          <div
            style={{
              width: "100%",
              borderBottom: "0.5px solid grey",
            }}
          >
            <p>
              <span style={{ fontWeight: 600 }}>Free access codes:</span>
              <span>
                <IconButton
                  sx={{ marginLeft: "5px" }}
                  onClick={generateAccessLink}
                >
                  <AddCircleIcon sx={{ color: "green" }} />
                </IconButton>
              </span>
            </p>
            {creatingLink ? <CircularProgress /> : null}
            {Array.isArray(accessLinks) && accessLinks.length > 0 ? (
              <>
                {accessLinks.map((el, i) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: i % 2 === 0 ? "lightgrey" : "white",
                        padding: 5,
                        boxSizing: "border-box",
                        borderRadius: 4,
                        marginBottom: 5,
                      }}
                    >
                      <p
                        style={{
                          wordBreak: "break-all",
                          whiteSpace: "normal",
                          overflowWrap: "break-word",
                          fontSize: 14,
                        }}
                        key={`${i}-${el}`}
                      >{`${window.location.origin}/live-call/${data.roomName}/${data.channelId}/${data.channelName}/${data.callType}/${el}`}</p>
                      {copied === el ? (
                        <p
                          style={{
                            marginLeft: "10px",
                            color: "green",
                            fontWeight: "bold",
                          }}
                        >
                          Copied!
                        </p>
                      ) : (
                        <IconButton
                          sx={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleCopy(
                              `${window.location.origin}/live-call/${data.roomName}/${data.channelId}/${data.channelName}/${data.callType}/${el}`,
                              el
                            );
                          }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      )}
                    </div>
                  );
                })}
              </>
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  );
}
