import React, { useContext, useState, useEffect } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import DynamicFrame from "../components/common-components/DynamicFrame";
import GenericSidebar from "../components/common-components/GenericSidebar";
import GenericMain from "../components/common-components/GenericMain";
import GenericModal from "../components/common-components/GenericModal";
import ChannelBrowseSidebar from "../components/home-components/ChannelBrowseSidebar";
import MobileUserModal from "../components/common-components/MobileUserModal";
import CoverImage from "../components/common-components/CoverImage";
import ChannelOverview from "../components/home-components/ChannelOverview";
//import ConfirmChannelsModal from "../components/common-components/ConfirmChannelsModal";
import GenericTextField from "../components/common-components/GenericTextField";
import SignInPrompt from "../components/common-components/SignInPrompt";
import UserPreview from "../components/home-components/UserPreview";
import PostPreview from "../components/home-components/PostPreview";
import PollComponent from "../components/common-components/PollComponent";
import { useWindowSize } from "../utils/Hooks";
import {
  prepareImage,
  replaceAndSanitize,
} from "../functions/GeneralFunctions";
import { createPost } from "../functions/ContentFunctions";

import Button from "@mui/material/Button";
import { useParams, useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CircularProgress from "@mui/material/CircularProgress";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import "../App.css";

import { GlobalContext } from "../App";

export default function Home() {
  ////  INITS
  const windowSize = useWindowSize();
  const GlobalCtxt = useContext(GlobalContext);
  const params = useParams();
  const navigate = useNavigate();

  ////  STATES
  const [posting, setPosting] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  ////  FUNCTIONS
  const handlePostSubmit = async (draft) => {
    try {
      setPosting("uploading");
      let file = null;
      if (draft.file) {
        const img = await prepareImage(
          draft.file,
          localStorage.getItem("username").slice(0, 4)
        );
        file = img.path;
      }
      await createPost({
        text: draft.text,
        file,
        channelName: decodeURIComponent(params.channelName),
        channelId: params.channelId,
      });
      setPosting("success");
    } catch (err) {
      console.log(err);
      alert("Error uploading post!");
      setPosting("posting");
    }
  };

  const handlePostCancel = () => {
    setPosting(false);
  };

  const initChannelFromParams = () => {
    try {
      if (params.channelName) {
        GlobalCtxt.updateCurrentChannel(decodeURIComponent(params.channelName));
      } else {
        GlobalCtxt.updateCurrentChannel("National");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePostClick = (post) => {
    navigate(`/post/${post._id}/${params.channelId}/${params.channelName}`);
  };

  ////  EFFECTS
  useEffect(() => {
    if (params.reload) {
      if (params.channelId && params.channelName) {
        navigate(`/home/${params.channelId}/${params.channelName}`);
      } else {
        navigate(`/home/${GlobalCtxt.nationalId}/National`);
      }
      window.location.reload();
      return;
    }
    if (!GlobalCtxt.globalCurrentChannel) {
      initChannelFromParams();
    }
  }, []);

  useEffect(() => {
    if (GlobalCtxt.globalCurrentChannel) {
      navigate(
        `/home/${GlobalCtxt.globalCurrentChannel._id}/${GlobalCtxt.globalCurrentChannel.name}`
      );
      GlobalCtxt.updateFeed();
    }
  }, [GlobalCtxt.globalCurrentChannel]);

  useEffect(() => {
    if (posting === "success") {
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    }
  }, [posting]);

  return (
    <ScreenContainer>
      <Navbar />
      <GenericModal
        open={modalVisible}
        handleClose={() => setModalVisible(false)}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.8)",
            padding: 5,
            boxSizing: "border-box",
          }}
        >
          {GlobalCtxt.globalCurrentUser ? (
            <UserPreview data={GlobalCtxt.globalCurrentUser} />
          ) : null}
          <ChannelOverview data={GlobalCtxt.globalCurrentChannel} />
        </div>
      </GenericModal>
      {!modalVisible ? <MobileUserModal /> : null}
      <DynamicFrame windowSize={windowSize} styling={{ height: "120vh" }}>
        {windowSize.width > 900 ? (
          <GenericSidebar
            windowSize={windowSize}
            styling={{
              width: "auto",
              maxWidth: windowSize.width * 0.15,
              backgroundColor: "rgba(0,0,0,0.8)",
            }}
          >
            <ChannelBrowseSidebar />
          </GenericSidebar>
        ) : null}

        <GenericMain styling={{ backgroundColor: "lightgrey" }}>
          {GlobalCtxt.globalCurrentChannel ? (
            <>
              {windowSize.width <= 768 ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p style={{ marginRight: 8 }}>Browse channels:</p>
                  <select
                    onChange={(e) => {
                      GlobalCtxt.updateCurrentChannel(e.target.value);
                    }}
                    value={GlobalCtxt.globalCurrentChannel?.name || "National"}
                  >
                    <option>National</option>
                    <option>Troubleshooting</option>
                    {localStorage.getItem("electorate") ? (
                      <option>{localStorage.getItem("electorate")}</option>
                    ) : null}
                    {localStorage.getItem("affiliation") &&
                    localStorage.getItem("affiliation") !== "null" &&
                    localStorage.getItem("affiliation") !== "undefined" ? (
                      <option>{localStorage.getItem("affiliation")}</option>
                    ) : null}
                    {GlobalCtxt.globalChannelShortlist.map((el, i) => {
                      return <option key={`${i}-${el}`}>{el}</option>;
                    })}
                    {GlobalCtxt.globalChannelLonglist.map((el, i) => {
                      return <option key={`${i}-${el}`}>{el}</option>;
                    })}
                  </select>
                </div>
              ) : null}
              <CoverImage
                url={GlobalCtxt.globalCurrentChannel?.coverPhoto}
                channelName={GlobalCtxt.globalCurrentChannel?.name}
              />
              <div
                style={{
                  width: "100%",
                  padding: "0px 10px",
                  boxSizing: "border-box",
                  backgroundColor: GlobalCtxt.colourScheme.primaryGreen,
                }}
                className="dark-container"
              >
                <p>
                  {replaceAndSanitize(
                    GlobalCtxt.globalCurrentChannel.explainer
                  )}
                </p>
              </div>
              {windowSize.width <= 768 && GlobalCtxt.globalCurrentChannel ? (
                <Button
                  variant="contained"
                  sx={{ margin: "10px 0px" }}
                  onClick={() => setModalVisible(true)}
                >
                  VIEW CHANNEL OVERVIEW
                </Button>
              ) : null}
              <div
                style={{
                  width: "100%",
                  backgroundColor: "rgba(0,0,0,0.8)",
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  margin: "10px 0px",
                }}
              >
                {GlobalCtxt.globalCurrentChannel?.public ? (
                  <>
                    <p
                      style={{
                        width: "33%",
                        textAlign: "center",
                        color: "white",
                        cursor: "pointer",
                        fontWeight:
                          GlobalCtxt.globalFeedIndex === "posts" ? "bold" : 300,
                      }}
                      onClick={() => {
                        GlobalCtxt.updateFeedIndex("posts");
                      }}
                    >
                      POSTS
                    </p>
                    <p
                      style={{
                        width: "33%",
                        textAlign: "center",
                        color: "white",
                        cursor: "pointer",
                        fontWeight:
                          GlobalCtxt.globalFeedIndex === "polls" ? "bold" : 300,
                      }}
                      onClick={() => {
                        GlobalCtxt.updateFeedIndex("polls");
                      }}
                    >
                      POLLS
                    </p>
                    <p
                      style={{
                        width: "33%",
                        textAlign: "center",
                        color: "white",
                        cursor: "pointer",
                        fontWeight:
                          GlobalCtxt.globalFeedIndex === "proposals"
                            ? "bold"
                            : 300,
                      }}
                      onClick={() => {
                        GlobalCtxt.updateFeedIndex("proposals");
                      }}
                    >
                      PROPOSALS
                    </p>
                  </>
                ) : (
                  <></>
                )}
              </div>
              {GlobalCtxt.globalFeedIndex === "posts" ? (
                <>
                  {!localStorage.getItem("electorate") ? (
                    <SignInPrompt action="posting" />
                  ) : (
                    <>
                      {posting === true ? (
                        <>
                          <GenericTextField
                            placeholder="Speak your mind!"
                            charLimit={5000}
                            buttonVariant="contained"
                            attachments={true}
                            rows={windowSize.width <= 768 ? 8 : 4}
                            handleSubmit={handlePostSubmit}
                            handleCancel={handlePostCancel}
                            divStyling={{
                              backgroundColor: "rgba(255,255,255,0.5)",
                            }}
                          />
                          <br></br>
                        </>
                      ) : posting === "uploading" ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: 10,
                          }}
                        >
                          <CircularProgress />
                        </div>
                      ) : posting === "success" ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: 10,
                          }}
                        >
                          <div style={{ height: 40, width: 40 }}>
                            <DotLottieReact
                              src="https://lottie.host/ed77a13f-b127-4131-b8cb-b3e7bab6ab25/Fpxtr3MZdz.lottie"
                              autoplay
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            padding: 5,
                            boxSizing: "border-box",
                            backgroundColor: "whitesmoke",
                            marginBottom: 10,
                            boxShadow: GlobalCtxt.genericBoxShadow,
                            borderRadius: 4,
                          }}
                        >
                          <Button
                            startIcon={<AddCircleIcon />}
                            color="success"
                            variant="contained"
                            onClick={() => setPosting(true)}
                          >
                            CREATE POST
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : null}
              {GlobalCtxt.globalFeedIndex === "posts" ? (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ opacity: 0.5, marginRight: 5 }}>Sort by:</p>
                    <Select
                      value={GlobalCtxt.globalQueryIndexRanking}
                      size="small"
                      onChange={(e) => {
                        GlobalCtxt.updateQueryIndexRanking(e.target.value);
                      }}
                      sx={{ marginRight: "5px" }}
                    >
                      <MenuItem value="most-recent">Most recent</MenuItem>
                      <MenuItem value="most-likes">Most liked</MenuItem>
                      {GlobalCtxt.globalFeedIndex === "posts" ? (
                        <MenuItem value="hidden">Hidden</MenuItem>
                      ) : null}
                    </Select>
                    {GlobalCtxt.globalQueryIndexRanking === "most-likes" ? (
                      <Select
                        value={GlobalCtxt.globalQueryIndexTime}
                        size="small"
                        onChange={(e) => {
                          GlobalCtxt.updateQueryIndexTime(e.target.value);
                        }}
                      >
                        <MenuItem value="90-days">Last 90 days</MenuItem>
                        <MenuItem value="last-week">Last week</MenuItem>
                        <MenuItem value="last-month">Last month</MenuItem>
                        <MenuItem value="last-year">Last year</MenuItem>
                        <MenuItem value="all-time">All time</MenuItem>
                      </Select>
                    ) : null}
                  </div>
                </div>
              ) : null}

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  flexGrow: 1,
                  borderTop: "0.1px solid grey",
                  padding: "10px 0px 0px 0px",
                  overflowY: "auto",
                }}
              >
                {GlobalCtxt.globalFeedIndex === "posts" ? (
                  <>
                    {Array.isArray(GlobalCtxt.globalFeed) &&
                    GlobalCtxt.globalFeed.length > 0 ? (
                      GlobalCtxt.globalFeed.map((el, i) => {
                        return (
                          <PostPreview
                            data={el}
                            key={el._id}
                            handleClick={handlePostClick}
                          />
                        );
                      })
                    ) : (
                      <p style={{ opacity: 0.5 }}>
                        Be the first to post in this Channel!
                      </p>
                    )}
                  </>
                ) : GlobalCtxt.globalFeedIndex === "polls" ? (
                  <>
                    {Array.isArray(GlobalCtxt.globalFeed) &&
                    GlobalCtxt.globalFeed.length > 0
                      ? GlobalCtxt.globalFeed.map((el, i) => {
                          return (
                            <PollComponent key={`${i}-${el._id}`} data={el} />
                          );
                        })
                      : null}
                  </>
                ) : GlobalCtxt.globalFeedIndex === "proposals" ? null : null}
                {Array.isArray(GlobalCtxt.globalFeed) &&
                GlobalCtxt.globalFeed.length > 0 &&
                GlobalCtxt.globalFeed.length % 12 === 0 ? (
                  <Button
                    onClick={GlobalCtxt.expandFeed}
                    variant="outlined"
                    sx={{ marginTop: "10px" }}
                  >
                    MORE
                  </Button>
                ) : null}
              </div>
            </>
          ) : null}
        </GenericMain>
        {windowSize.width > 768 ? (
          <GenericSidebar windowSize={windowSize}>
            {GlobalCtxt.globalCurrentUser ? (
              <UserPreview data={GlobalCtxt.globalCurrentUser} />
            ) : null}
            {GlobalCtxt.globalCurrentChannel ? (
              <ChannelOverview data={GlobalCtxt.globalCurrentChannel} />
            ) : null}
          </GenericSidebar>
        ) : null}
      </DynamicFrame>
    </ScreenContainer>
  );
}
